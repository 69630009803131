import {makeStyles, pallete} from "app/styles";

export const useFormStyles = makeStyles()((theme) => {
  return {
    securityBox: {
      padding: "50px 0",
    },
    label: {
      // Custom styles for the label
      color: "#B9B9B9 !important", // Change label text color
      // fontSize: '1rem', // Change font size
      "&.Mui-focused": {
        color: "blue", // Color of the label when the input is focused
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
      // border: "1px solid #FFFFFF",
    },
    formContainerBox: {
      display: "flex",
      // flexDirection: "column",
      justifyContent: "center",
      //height: "100%",
      position: "relative",
      padding: "30px 10px",
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
      textAlign: "center",
    },
  };
});
