import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography,} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useStyles} from "../Style";

const faqQuestions = [
  {
    ques: "What is TradeBp crypto Trading platform?",
    ans: "TradeBp crypto Trading platform is a digital platform that allows businesses to buy, sell, and trade cryptocurrencies.",
  },
  {
    ques: "Who can use TradeBp crypto Trading platform?",
    ans: "TradeBp crypto Trading platform is exclusively for businesses. You must be a registered business with a valid tax identification number to use our platform.",
  },
  {
    ques: "What cryptocurrencies can be traded TradeBp crypto Trading platform?",
    ans: "TradeBP offers a variety of cryptocurrencies for trading, including Bitcoin, Ethereum, Litecoin, and Ripple. We are constantly adding new cryptocurrencies to our platform to ensure our clients have access to the latest opportunities.",
  },
  {
    ques: "What are the fees for trading on TradeBp crypto Trading platform?",
    ans: "Our fees vary based on the type of transaction, volume, and other factors. Please visit our website for a full breakdown of our fees.",
  },
  {
    ques: "What security measures do you have in place to protect my business and its assets?",
    ans: "We take security very seriously, and our platform is designed to ensure the safety of your assets. We use advanced encryption, two-factor authentication, and other measures to protect against unauthorized access and attacks.",
  },
  {
    ques: "How long does it take for a transaction to be completed on TradeBp crypto Trading platform?",
    ans: "The time it takes for a transaction to be completed varies depending on a number of factors, including the type of transaction, the cryptocurrency being traded, and other factors. In general, most transactions are completed within a matter of minutes.",
  },
  {
    ques: "Can I access TradeBp crypto Trading platform from anywhere in the world?",
    ans: "Yes, our platform is accessible from anywhere in the world, as long as you have an internet connection.",
  },
  {
    ques: "What is the process for onboarding new businesses onto TradeBp crypto Trading platform?",
    ans: "To get started, simply create an account on our website and follow the instructions provided. we will require a complete registration process, including KYB and AML checks. Once the registration process is complete, the business can begin trading on our platform.",
  },
  {
    ques: "What is your customer support policy?",
    ans: " We have a dedicated customer support team that is available to answer any questions you may have. You can reach us via phone, email, or live chat, and we will do our best to resolve any issues you may be experiencing.",
  },
  {
    ques: "How do you ensure compliance with relevant laws and regulations?",
    ans: "TradeBp crypto Trading platform is fully regulated and licensed as a Virtual Asset Service Provider by the Central Bank of Portugal. We are committed to complying with all relevant laws and regulations to ensure the safety and security of our clients' assets.",
  },
  {
    ques: "How do you ensure compliance with anti-money laundering (AML) and know your customer (KYC) regulations?",
    ans: "We have a comprehensive AML and KYC program in place that includes verification of user identities, transaction monitoring, and reporting of suspicious activity. We also work closely with regulatory authorities to ensure compliance with relevant regulations.",
  },
  {
    ques: "How do you ensure the safety of my funds on your platform?",
    ans: "We use a combination of cold storage and hot wallets to store our clients' assets, and we have robust security measures in place to protect against theft and hacking attempts. Additionally, we maintain adequate reserves to ensure the safety of our clients' funds.",
  },
];
const Faq = () => {
  const { classes } = useStyles();
  return (
    <>
      <Box  className={classes.aboutBox}>
        <Typography color="primary" variant="h1" align="center">
          FAQ
        </Typography>
        <Box className={classes.accordionBox}>
          <Grid container lg={10} xs={12}>
            {faqQuestions.map((x: any) => (
              <Accordion key={x.ques} className={classes.accordionMain}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{ fontWeight: 600 }}
                    color="primary"
                    variant="h5"
                  >
                    Q: {x.ques}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{ fontWeight: 400 }}
                    color="primary"
                    variant="h6"
                  >
                    A: {x.ans}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Faq;
