import React from "react";
import {TabContent} from "app/components/tabs";
import CryptoHubDashboard from "./CryptoHubDashboard";
import CryptoHubOrders from "./CryptoHubOrders";
import CryptoHubCredentialsView from "./CryptoHubCredentialsView";
import { CryptoWebHooks } from "./CryptohubWebHooks";

const CryptoHubView = () => {

    const tabHeading: string = "CryptoHub";
    const tabData = [
        {label: "Dashboard", component: <CryptoHubDashboard />},
        {label: "Integration", component: <CryptoHubCredentialsView />},
        {label: "Orders", component: <CryptoHubOrders />},
        {label: "Web Hooks", component: <CryptoWebHooks />},
    ];

    return TabContent({tabData, tabHeading});

};

export default CryptoHubView;
