import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {useDownloadButtonStyles} from "./ButtonStyles";

export type T_DownloadButtonProps = {
  onClickHandler: () => Promise<void>;
  disabled?: boolean;
  text?: string;
  isLoading?: boolean | undefined;
};

export const DownloadButton = ({
  onClickHandler,
  text,
  ...opt
}: T_DownloadButtonProps) => {
  const { classes } = useDownloadButtonStyles();
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadButtonText: string = text || "Download";
  const handleOnClick = async () => {
    setIsDownloading(true);
    return onClickHandler()
      .catch((error: Error) => console.log(error))
      .finally(() => setIsDownloading(false));
  };

  return (
    <LoadingButton
      disabled={opt?.disabled && opt.disabled === true}
      loading={isDownloading}
      loadingPosition={"start"}
      startIcon={<CloudDownloadIcon />}
      // variant="outlined"
      className={classes.downloadButton}
      onClick={handleOnClick}
    >
      {downloadButtonText}
    </LoadingButton>
  );
};
