import React from "react";
import { TabContent } from "app/components/tabs";
import { BillingBalance2, BillingBalance1 } from "./BillingBalance";

export const Billing: React.FC = () => {
  const tabHeading: string = " Billing & Balance";
  const tabData = [
    { label: "Biling & Balance", component: <BillingBalance1 /> },
    { label: "Payout Setting", component: <BillingBalance2 /> },
  ];

  return TabContent({ tabData, tabHeading });
};
