import React, {useEffect, useState} from "react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  T_Asset,
  useAssetsList,
  useCryptohubTransactionRequest,
  useCurrencyExchangeRatesQuery,
} from "./CryptohubPaymentQueries";
import { T_UseCryptohubPaymentState } from "./CryptohubPaymentHooks";
import Loader from "app/components/unsorted/Loader";
import { useTranslation } from "react-i18next";
import { useLocalization } from "../../../app/hooks";

type T_ViewProps = {
  classes: any;
  paymentState: T_UseCryptohubPaymentState;
  transactionId: string | any;
};

type T_AmountDetails = {
  amount: number;
  currency: string;
  asset: string;
  assetAmount: number;
  fees?: number;
};

export const AmountConfirmationView = ({
  classes,
  paymentState,
  transactionId,
}: T_ViewProps) => {
  const transactionRequest = useCryptohubTransactionRequest(transactionId);
  const assetsList = useAssetsList();
  const amountDetails: T_AmountDetails = {} as T_AmountDetails;
  const { t, i18n } = useTranslation();
  const { languageCode, initializeLanguage } = useLocalization();
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);

useEffect(() => {
  const initLang = async () => {
    if (!transactionRequest.result || isLanguageInitialized) return;

    let sessionLanguage = sessionStorage.getItem("userLanguage");

    let languageSymbol =  sessionLanguage || transactionRequest.result.language_symbol;

    if (languageSymbol === "zh-CN"){
      languageSymbol = "zh";
    }

    if (!["en", "pt", "zh"].includes(languageSymbol)) {
      languageSymbol = "en";
    }

    if (!sessionLanguage) {
      sessionStorage.setItem("userLanguage", languageSymbol);
      await initializeLanguage(languageSymbol);
    }

    setIsLanguageInitialized(true);
  };

  initLang();
}, [transactionRequest.result, initializeLanguage, isLanguageInitialized]);


  const getAssetSymbol = (asset: string) => {
    let assetSymbol = "";

    if (assetsList.result) {
      const assetObj = assetsList.result.find(
        (assetObj: T_Asset) => assetObj.fireblocks_asset_id === asset
      ) as T_Asset;
      if (assetObj) assetSymbol = assetObj.acts_as;
    }

    return assetSymbol;
  };

  const exchangeRates = useCurrencyExchangeRatesQuery(
    getAssetSymbol(transactionRequest.result?.fireblocks_asset_id),
    transactionRequest.result?.currency_symbol
  );

  if (transactionRequest.result) {
    amountDetails.amount = transactionRequest.result.amount;
    amountDetails.currency = transactionRequest.result.currency_symbol;
    amountDetails.asset = transactionRequest.result.fireblocks_asset_id;
    amountDetails.assetAmount =
      amountDetails.amount / exchangeRates.result?.best_price;
    amountDetails.fees =
      transactionRequest.result.fees / exchangeRates.result?.best_price;
  }

  const updatePaymentStateOnNext = () => {
    paymentState.update({
      isConfirmed: true,
      transactionId: transactionRequest.result.id,
    });
  };

  const onNext = () => {
    updatePaymentStateOnNext();
  };

  if (
    assetsList.isResolving ||
    transactionRequest.isResolving ||
    !amountDetails.assetAmount
  ) {
    return <Loader isLoading={true} />;
  }

  if (transactionRequest.result?.verified_at) {
    let updateState: Partial<T_UseCryptohubPaymentState> = {
      isConfirmed: true,
      transactionId: transactionRequest.result.id,
      isOtpVerified: true,
    };
    if (!transactionRequest.result?.kyc_status.simple_kyc_required) {
      updateState = {
        ...updateState,
        isSimpleKycCompleted: true,
        isSimpleKycOtpVerified: true,
      };
    }
    if (!transactionRequest.result?.kyc_status.full_kyc_required) {
      updateState = {
        ...updateState,
        isKycCompleted: true,
      };
    }

    paymentState.update(updateState);
  }

  return (
    <Box className={classes.mainCard}>
      <Box className={classes.confirmationCard}>
        <Box className={classes.ConfirmationInnerCard}>
          <Typography variant="h2">
            {t("Would you like to proceed?")}
          </Typography>
          <Divider />
          <Box className={classes.midBoxC1}>
            <Box className={classes.midBoxC1}>
              <Box className={classes.cryptoBox}>
                <Typography variant="h5" sx={{ width: "30" }}>
                  {" "}
                  <strong>{t("Selected Crypto")} : </strong>{" "}
                  {amountDetails.assetAmount}{" "}
                  <strong>{getAssetSymbol(amountDetails.asset)}</strong>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.midBoxC1}>
              <Box className={classes.cryptoBox}>
                <Typography variant="h5" sx={{ width: "30" }}>
                  <strong>{t("Fees")} : </strong> {amountDetails?.fees || 0}{" "}
                  <strong>{getAssetSymbol(amountDetails.asset)}</strong>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.termsAndConditions}>
              <Typography variant="h6" fontStyle={"italic"}>
                {t(
                  "By proceeding with this deposit, you are agreeing with our"
                )}
                <span>
                  {" "}
                  <Link to="/privacy-policy" target="_blank">
                    Terms and Conditions
                  </Link>
                </span>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.midBoxC1}>
            <Box className={classes.confirmationButtonBox}>
              <Button
                className={classes.confirmationButton}
                variant="contained"
                color="primary"
                onClick={onNext}
              >
                {t("Yes, Proceed")}
                <IconButton>
                  <ArrowForward />
                </IconButton>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};

export default AmountConfirmationView;
