/**
 * @file: reactQueryProvider.ts
 * @date: 26 Feb 2024
 * @description: $
 */


import React, {HTMLProps, JSX} from "react";
import {Environment} from "app/config";
import {GoogleOAuthProvider} from "@react-oauth/google";

const AuthenticationProvider = (props: HTMLProps<JSX.Element>): JSX.Element => {
    return (<GoogleOAuthProvider clientId={Environment.GOOGLE_CLIENT_ID}> {props.children} </GoogleOAuthProvider>)
}


export default AuthenticationProvider;
