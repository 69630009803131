import {ReactElement} from "react";
import PUBLIC_VIEWS_MAPPING from "./public";
import INTEGRATION_VIEWS_MAPPING from "./integration";
import PRIVATE_VIEWS_MAPPING from "./private";


const ROUTES_AND_VIEW_MAP: Record<string, ReactElement> = {
    ...PUBLIC_VIEWS_MAPPING,
    ...PRIVATE_VIEWS_MAPPING,
    ...INTEGRATION_VIEWS_MAPPING,
};

const getViewForRoute = (route: string, defaultView?: ReactElement): ReactElement => {
    route = route.toString().toLowerCase().trim();
    defaultView = defaultView || ROUTES_AND_VIEW_MAP["/not-found"];
    return (route in ROUTES_AND_VIEW_MAP) ? ROUTES_AND_VIEW_MAP[route]:defaultView;
}

export default getViewForRoute;
