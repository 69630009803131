import * as RHF from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

export type T_UseFormProps = RHF.UseFormProps & {
    name: string;
    validationSchema: any;
    onSubmitHandler?: RHF.SubmitHandler<any>;
}


export type T_FormInstance = RHF.UseFormReturn & {
    id: string;
    onSubmit: RHF.SubmitHandler<any>;
    isLoading: boolean;
}


export type T_UseFormBindProps = {
    name:string;
    label:string;
}
export type T_UseFormBindResult = T_UseFormBindProps & {
    formInstance:T_FormInstance;
}

export type T_UseFormResult = {
    instance:T_FormInstance;
    bind:(props:T_UseFormBindProps)=>T_UseFormBindResult;
}

export const useForm = <T extends RHF.FieldValues>(props: T_UseFormProps): T_UseFormResult => {

    const {validationSchema, onSubmitHandler, ...formOptions}:T_UseFormProps = props;

    const id: string = `#form-${formOptions.name.trim().toLowerCase().split(" ").join("-")}`;

    const onSubmit: RHF.SubmitHandler<any> = onSubmitHandler || ((data) => console.log({data}));

    const form: RHF.UseFormReturn = RHF.useForm({
        resolver: yupResolver(validationSchema),
        ...formOptions
    });

    const isLoading: boolean = (form.formState.isSubmitting || form.formState.isValidating || form.formState.isLoading);

    const formInstance: T_FormInstance = {...form, id, onSubmit, isLoading};


    const bind = ({name,label}:T_UseFormBindProps):T_UseFormBindResult => {
        return {name, label, formInstance};
    }

    return {instance:formInstance, bind};
}


export {RHF};
