/**
 * @file: UserContext.ts
 * @date: 20 Sep 2023
 * @description: $
 */

import {useAppContext} from "../useAppContext";
import {IS_TESTING,IS_DEVELOPMENT} from "../../config/Environment";
import {useNotification} from "../../providers";

 const PERMISSION_ERROR_MESSAGE :string = "You do not have required permissions to perform this action.";
/**
 * Defines the structure for menu permissions to enable granular access control
 * This type is crucial for implementing RBAC (Role Based Access Control) at the UI level
 * Each method returns a boolean to make permission checks declarative and reusable
 */
export type T_Permission = {
    canCreate:()=>boolean;
    canRead:()=>boolean;
    canUpdate:()=>boolean;
    canCreateAndUpdate:()=>boolean;
    canDelete:()=>boolean;
    canReadUserData:()=>boolean;
    canWriteUserData:()=>boolean;
    canPerformCrud:()=>boolean;
    canReadWriteUserData:()=>boolean;
    permissionsString:()=>string;
    warn:()=>void;

}

/**
 * Custom hook for managing menu-based permissions
 * Implements a binary string-based permission system where each character represents a specific permission
 * Uses URL paths as keys to map permissions to specific routes/features
 * This approach was chosen because:
 * 1. It's memory efficient (uses single characters instead of boolean flags)
 * 2. Easy to serialize/deserialize for API communication
 * 3. Simple to extend with additional permissions by adding more positions
 */
export const usePermissions = (path?:string):T_Permission => {
    const notification = useNotification();
    /**
     * Retrieves global context to access user data and menu configurations
     * This centralized approach ensures consistent permission checking across the application
     */
    const {user,menus}= useAppContext();

    /**
     * Loads pre-initialized permissions map
     * Throws error if permissions aren't loaded to prevent unauthorized access
     */
    const menuPermissions = menus.getMenuPermissions();

    if (!menuPermissions) {
        throw new Error("Menu Permissions are not initialized!!");
    }

    /**
     * Uses provided path or falls back to current route
     * Enables permission checking for both current and arbitrary routes
     */
    const pathName:string = path || window.location.pathname;

    /**
     * Retrieves permission string for current path
     * Format: "101010" where each digit represents a permission
     * Empty string with trim handles undefined routes gracefully
     */
    const permissionsString = ():string => (menuPermissions.get(pathName) || "").trim();

    /**
     * Core permission checking logic
     * Uses array indexing for performance and maintainability
     * Returns false for invalid indices to fail safely
     */
    const hasPermission = (index:number):boolean => {
        if (index < 0 || index > permissionsString().length) return false;
        return parseInt(permissionsString()[index]) === 1;
    }

    /**
     * Permission check methods
     * Each position in the permission string corresponds to a specific capability:
     * 0: Create - Adding new resources
     * 1: Read - Viewing existing resources
     * 2: Update - Modifying existing resources
     * 3: Delete - Removing resources
     * 4: Read User Data - Accessing user-specific information
     * 5: Write User Data - Modifying user-specific information
     */
    const canCreate = ():boolean => {
        return hasPermission(0);
    };

    const canRead = ():boolean => {
        return hasPermission(1);
    };

    const canUpdate = ():boolean => {
        return hasPermission(2);
    };

    const canDelete = ():boolean => {
        return hasPermission(3);
    };

    const canReadUserData = ():boolean => {
        return hasPermission(4);
    };

    const canWriteUserData = ():boolean => {
        return hasPermission(5);
    };

    const canPerformCrud = ():boolean => (canCreate() && canRead() && canUpdate() && canDelete());

    const canReadWriteUserData = ():boolean => (canReadUserData() && canWriteUserData());

    const canCreateAndUpdate = ():boolean => (canCreate() && canUpdate());

    const warn = ():void => notification.warning(PERMISSION_ERROR_MESSAGE);


    /**
     * Logs permissions for debugging purposes
     * Ensures sensitive information is not logged in production environments
     */
    if (IS_TESTING || IS_DEVELOPMENT){
        console.table({
            [pathName]:permissionsString(),canCreate:canCreate(),canRead:canRead(),canUpdate:canUpdate(),
            canDelete:canDelete(),canPerformCrud:canPerformCrud(),canReadUserData:canReadUserData(),
            canWriteUserData:canWriteUserData(),canReadWriteUserData:canReadWriteUserData()
        });
    }

    /**
     * Exposes methods for permission checking
     */

    return {
        canCreate,canRead,canUpdate,canDelete,canReadUserData,canWriteUserData,
        permissionsString,canPerformCrud,canReadWriteUserData,canCreateAndUpdate,
        warn
    }
}
