import React from "react"
import {Box, Card, Skeleton, Typography,} from "@mui/material";
import {useCredentialViewStyles} from "./Styles";
import {CopyToClipboardButton} from "app/components/inputs/buttons";


type T_CredentialRowProps = {
    classes: any;
    name: string;
    value: string;
    allowCopy?: boolean;
    isSkeleton?: boolean;
}


export type T_IntegrationCredentialProps = {
    apiKey: string;
    apiSecret: string;
    expiry: string;
    description: string;
    status: boolean;
    isSkeleton?: boolean;
};


const CredentialsDetail = ({classes, name, value, allowCopy, isSkeleton}: T_CredentialRowProps): React.JSX.Element => {
    isSkeleton = isSkeleton && isSkeleton === true;
    const trimmedValue:string = `${value.substring(0, 32)} ...`;
    return (
        <Box className={classes.copyBoxParent}>
            <Box className={classes.copyBox}>
                <Typography variant="h6" color="primary" sx={{margin: "auto 5px"}}>
                    {isSkeleton ? <Skeleton variant="text" sx={{fontSize: '1rem',width:'80px'}} /> : name}
                </Typography>
            </Box>
            <Box className={classes.copyBox}>
                <Typography variant="body1" color="primary" sx={{margin: "auto 5px"}}>
                    {isSkeleton ? <Skeleton variant="text" sx={{fontSize: '1rem', width: '180px'}} /> : trimmedValue}
                </Typography>
                {allowCopy && !isSkeleton && (<CopyToClipboardButton fieldName={name} fieldValue={value} />)}
            </Box>
        </Box>
    );
}


export const IntegrationCredential = (credential: T_IntegrationCredentialProps): React.JSX.Element => {
    const {classes} = useCredentialViewStyles();
    const apiKey = `${credential?.apiKey || ''}`;
    const apiSecret = `${credential?.apiSecret || ''}`;
    const credentialStatus = credential.status ? "Active" : "Inactive";
    const expiry = credential?.expiry?.toString() || "N/A";
    const apiDescription = credential.description?.substring(0, 32) || "N/A";
    const isSkeleton = credential?.isSkeleton && credential?.isSkeleton === true;

    return (
        <Card className={classes.credCard}>
            <CredentialsDetail name={"API Key"} value={apiKey} allowCopy={true} classes={classes}
                               isSkeleton={isSkeleton} />
            <CredentialsDetail name={"API Secret"} value={apiSecret} allowCopy={true} classes={classes}
                               isSkeleton={isSkeleton} />
            <CredentialsDetail name={"Expiry"} value={expiry} classes={classes} isSkeleton={isSkeleton} />
            <CredentialsDetail name={"Status"} value={credentialStatus} classes={classes} isSkeleton={isSkeleton} />
            <CredentialsDetail name={"Description"} value={apiDescription} classes={classes} isSkeleton={isSkeleton} />
        </Card>
    )
}

export const IntegrationCredentialSkeleton = ({classes, count}: any): React.JSX.Element[] => {
    const skeletons: React.JSX.Element[] = [];
    const credentialDetails: T_IntegrationCredentialProps = {
        apiKey: "", apiSecret: "", expiry: "", description: "", status: false, isSkeleton: true
    };
    for (let i = 0; i < count; i++) {
        skeletons.push(<Box className={classes.credentialsCardContainer}>
            <IntegrationCredential {...credentialDetails} />
        </Box>)
    }
    return skeletons;
}
