import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IntegrationView } from "../common";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { usePaymentMethodStyles } from "./PaymentMethodStyles";
import { motion } from "framer-motion";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import MyCryptForm from "./MyCryptForm";
import { useMyCryptState } from "./MyCryptHooks";
import { ApiConfig } from "app/config";
import { RQ } from "app/hooks";
import Loader from "app/components/unsorted/Loader";
import { useNotification } from "app/providers";
import { useAssetsList } from "./MyCryptQueries";

const paymentMethods = [
    { title: "MoneyHub", description: "Pay using MoneyHub", url: "/moneyhub-payment" },
    { title: "CryptoHub", description: "Pay using Cryptohub", url: "/cryptohub-payment"},
];

type T_PaymentMethodCardProps = {
    title: any;
    description: any;
    selected?: boolean;
    onClick?: () => void;
};

const PaymentMethodCard = ({ title, description, selected, onClick } : T_PaymentMethodCardProps) => {
    const { classes } = usePaymentMethodStyles();
    return (
        <Card 
            className={selected ? classes.paymentMethodCardSelected : classes.paymentMethodCard}
            variant="outlined" 
            onClick={onClick}
        >
            <Typography variant="h6">{title}</Typography>
            <Divider />
            <Typography variant="body1">{description}</Typography>
        </Card>
    );
}

const SelectPaymentMethodView = ({ classes, paymentState, transactionId} : any ) => {

    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<number>(0);

    const handleContinue = () => {
        if (selectedPaymentMethod === 0) {
            paymentState.update({isPaymentMethodSelected: true, paymentMethod: 1, isAssetSelected: true});
        }
        else {
            paymentState.update({isPaymentMethodSelected: true, paymentMethod: 2});
        }
    }

    return (
        <Box className={classes.mainCard}>
            <Box className={classes.titleBox}>
                <Typography className={classes.title} variant="h2">Please select your payment method</Typography>
            </Box>
            <Divider />
            <Box className={classes.subtitleBox}>
                <Typography className={classes.title} variant="h6">Payment methods:</Typography>
            </Box>
            <Box className={classes.paymentMethodBox}>
                {paymentMethods.map((paymentMethod, index) => (
                    <PaymentMethodCard 
                        key={index} 
                        title={paymentMethod.title} 
                        description={paymentMethod.description}
                        selected={selectedPaymentMethod === index}
                        onClick={() => setSelectedPaymentMethod(index)} 
                    />
                    
                ))}
            </Box>
            <Box className={classes.submitButtonBox}>
                <Button className={classes.submitButton} onClick={handleContinue}>
                    <Typography variant="h6" color={"white"}>Continue</Typography>
                    <motion.div
                        style={{ paddingTop: "5px" }}
                        animate={{
                            x: [0, 12, 0],
                        }}
                        transition={{
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut",
                        }}
                    >
                        <ArrowForwardIosRounded />
                    </motion.div>
                </Button>
            </Box>
        </Box>
    );
}

const MyCryptSelectAssetView = ({ classes, paymentState, transactionId } : any) => {
    const notification = useNotification();
    const asset = useAssetsList();
    const [ selectedAsset, setSelectedAsset ] = React.useState<string | null>(null);

    const handleContinue = () => {
        if (!selectedAsset) {
            notification.warning("Please select an asset");
            return;
        }
        paymentState.update({isAssetSelected: true, asset: selectedAsset});
    }

    const handleBack = () => {
        paymentState.update({isPaymentMethodSelected: false, paymentMethod: '', isAssetSelected: false});
    }

    if (asset.isLoading || !asset.result) {
        return <Loader isLoading={true} />
    }

    return (
        <Box className={classes.mainCard}>
            <Box className={classes.titleBox}>
                <Typography className={classes.title} variant="h2">Please select your asset</Typography>
            </Box>
            <Divider />
            <Box className={classes.subtitleBox}>
                <Typography className={classes.title} variant="h6">Asset:</Typography>
            </Box>
            <Box className={classes.paymentMethodBox}>
                {asset.result?.map((asset: any, index: number) => (
                    <PaymentMethodCard 
                        key={index} 
                        title={asset.name} 
                        description={`Pay using ${asset.name}`}
                        selected={selectedAsset === asset.id}
                        onClick={() => setSelectedAsset(asset.id)}
                    />
                ))}
            </Box>
            <Box className={classes.submitButtonBox}>
                <Button className={classes.submitButton} onClick={handleBack}>
                    <motion.div
                        style={{ paddingTop: "5px" }}
                    >
                        <ArrowBackIosRounded />
                    </motion.div>
                    <Typography variant="h6" color={"white"}>Back</Typography>
                </Button>
                <Button className={classes.submitButton} onClick={handleContinue}>
                    <Typography variant="h6" color={"white"}>Continue</Typography>
                    <motion.div
                        style={{ paddingTop: "5px" }}
                        animate={{
                            x: [0, 12, 0],
                        }}
                        transition={{
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut",
                        }}
                    >
                        <ArrowForwardIosRounded />
                    </motion.div>
                </Button>
            </Box>
        </Box>
    );
}

const MyCryptInitiateTransactionView = ({paymentState}: any) => {
    const notification = useNotification();
    const navigate = useNavigate();

    const apiConfig = ApiConfig.create(
        'my-crypt-transaction-request/select-payment', ['my-crypt-transaction-request/select-payment'],
    );

    const transactionRequest = RQ.usePostRequest({
        url: apiConfig.url,
    });

    useEffect(() => {
        const payload : any = {
            "request_uid": paymentState.transactionId,
            "payment_method": paymentState.paymentMethod,
        }

        if (paymentState.paymentMethod === 2) {
            payload["asset_id"] = paymentState.asset;
        }

        transactionRequest.EXECUTE({
            payload: payload,
            onSuccessFn: (data, message) => {
                if(paymentState.paymentMethod === 1) {
                    navigate(`/moneyhub-payment/${data.transaction_uid}`);
                }
                else {
                    navigate(`/cryptohub-payment/${data.transaction_uid}`);
                }
            },
            onErrorFn: (data, message) => {
                notification.error(message);
            }
        })
    }, []);


    if (transactionRequest.isPending) {
        return <Loader isLoading={true} />
    }


    return (<></>)
};

export const SelectPaymentMethod = () => {
    const { transactionId } = useParams();
    const paymentState = useMyCryptState(transactionId);
    const { classes } = usePaymentMethodStyles();
    
    const ActiveComponent = () => {
        if (!transactionId) {
            return <MyCryptForm />;
        }
        
        if (!paymentState.isPaymentMethodSelected) {
            return <SelectPaymentMethodView {...({classes, paymentState, transactionId})}/>;
        }

        if(!paymentState.isAssetSelected) {
            return <MyCryptSelectAssetView {...({classes, paymentState, transactionId})} />;
        }

        if(paymentState.isPaymentMethodSelected && paymentState.isAssetSelected) {
            return <MyCryptInitiateTransactionView {...({ paymentState })} />;
        }

        return <></>;
    }

    return (
        <IntegrationView activeComponent={ActiveComponent()} />
    );
};