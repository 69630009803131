import React, {createContext, useContext, useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Defines the context type for loader state and methods.
 *
 */
export type T_LoaderContext = {
    isLoading: boolean;
    show: () => void;
    hide: () => void;
};

/**
 * Creates a React context for managing loader state and actions.
 *
 * This context provides a consistent way to manage the visibility of a loading indicator
 * throughout your application, allowing components to access and control the loader state.
 */
export const LoaderContext = createContext<T_LoaderContext>({
    isLoading: false,
    show: () => void (0),
    hide: () => void (0),
});

/**
 * Custom hook to access the Loader context.
 *
 * This hook allows components to easily access the current loader state and methods
 * provided by the `LoaderContext`.
 *
 * @returns {T_LoaderContext} - The current loader context value
 */
const useLoader = () => useContext(LoaderContext);

/**
 * Provider component for the Loader context.
 *
 * This component wraps your application's content and provides the loader context
 * to its children. It manages the loader state using the `useState` hook and exposes
 * functions to show and hide the loader.
 *
 * @param {object} props - Props passed to the provider component
 * @returns {JSX.Element} - The wrapped application content with the Loader context
 */
const LoaderProvider = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Context value object containing the loader state and methods.
     */
    const loaderContextValue: T_LoaderContext = {
        isLoading,
        show: () => setIsLoading(true),
        hide: () => setIsLoading(false),
    };

    return (
        <LoaderContext.Provider value={loaderContextValue}>
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={isLoading}>
                <CircularProgress size={60} thickness={5.6} color="primary" />
            </Backdrop>

            {props.children}
        </LoaderContext.Provider>
    );
};

/**
 * Exports the LoaderProvider component and useLoader hook.
 *
 * These components facilitate using the loader functionality throughout your application.
 */
export {LoaderProvider, useLoader};
