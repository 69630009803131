/**
 * @file: useQueryRequest.ts
 * @date: 03 Mar 2024
 * @description: $
 */


import * as ReactQuery from "@tanstack/react-query";
import {ApiRequest, ApiResponse, T_ArrayOrObject} from "app/utils/http";

/**
 * Type for specifying payload and parameters for mutation execution.
 */

type T_MutateFnCallbacks<Response = any> = {
    onSuccessFn?: (data: Response, message: string) => void;
    onErrorFn?: (error: Error | any, message: string) => void;
}

export type T_MutateFnParams<Response = any, Payload = any> = T_MutateFnCallbacks & {
    payload?: Payload;
    params?: T_ArrayOrObject;
}

type T_UseMutationResult = ReactQuery.UseMutationResult<unknown, Error, void, unknown>;

export type T_MutationResult<Tdata = any, Payload = any> = T_UseMutationResult & {
    EXECUTE: (params: T_MutateFnParams<Tdata, Payload>) => Promise<any>;
}

export type T_MutationRequestParams = ReactQuery.UseMutationOptions & {
    url: string;
    invalidate?: boolean;
}

export type T_MutationRequestOptions<T = any> = T_MutationRequestParams & {
    method: string;
}


/**
 * Custom hook for making mutation requests.
 * This hook combines useMutation from React Query with custom execution and callback functionality.
 */

export const useMutationRequest = <TD = any>(mutationParams: T_MutationRequestOptions): T_MutationResult<TD> => {
    const queryClient = ReactQuery.useQueryClient();

    const {
        url, method, invalidate, ...options
    } = mutationParams;


    const mutationKey: ReactQuery.MutationKey = options.mutationKey || [];

    const mutationFn = async (mutationParams: any): Promise<ApiResponse<TD>> => {
        const {params, payload} = mutationParams;
        return ApiRequest.Request<TD>({method, url, params, payload}).apiResponse().catch((err) => err);
    }

    const onSettled = (data: any, error: Error | null, variables: any, context: any) => {
        const onSettledCallback = options.onSettled ?? ((t: any) => void (0));
        onSettledCallback(data, error, variables, context);
    }

    const onSuccess = (data: any, variables: any, context: any) => {
        if (invalidate) {
            queryClient.invalidateQueries({queryKey: mutationKey});
        }
        options.onSuccess ? options.onSuccess(data, variables, context) : void (0);
    }

    /**
     *  Use useMutation from React Query with the defined mutation options.
     */
    const mutationRequest = ReactQuery.useMutation({
        ...options, mutationKey, mutationFn, onSuccess, onSettled
    }, queryClient);

    /**
     * Custom execute function that calls the mutationQuery's mutate method.
     */
    const EXECUTE = async ({payload, params, onSuccessFn, onErrorFn}: T_MutateFnParams) => {
        return mutationRequest.mutateAsync({payload, params} as any, {
            onSettled: (data: any, error: Error | null, variables: any, context: any) => {
                const response = data as ApiResponse<TD>;
                options.onSettled ? options.onSettled(data, error, variables, context) : void (0);
                return response.isSuccess() ? onSuccessFn?.(response.getData() as TD, response.getMessage()) :
                    onErrorFn?.(response.getData(), response.getMessage());
            }
        });
    };

    // Return the combined result of mutationQuery and the custom execute function.
    return {...mutationRequest, EXECUTE};
}

export const usePostRequest = <TD = any>(mutationParams: T_MutationRequestParams): T_MutationResult<TD> => {
    return useMutationRequest<TD>({...mutationParams, method: "POST"});
}

export const usePutRequest = <TD = any>(mutationParams: T_MutationRequestParams): T_MutationResult<TD> => {
    return useMutationRequest<TD>({...mutationParams, method: "PUT"});
}

export const useDeleteRequest = <TD = any>(mutationParams: T_MutationRequestParams): T_MutationResult<TD> => {
    return useMutationRequest<TD>({...mutationParams, method: "DELETE"});
}
