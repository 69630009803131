import * as React from "react";
import {Autocomplete, Box, TextField} from "@mui/material";

export function SearchInput({
  options,
  onSelect,
  label,
  displayValue,
  formikValue,
  renderOption,
}: any) {
  return (
    <Box sx={{ padding: "10px 0" }}>
      <Autocomplete
        disablePortal
        options={options}
        getOptionLabel={(option: any) => (option ? option[displayValue] : "")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={renderOption}
        onChange={(event, value) =>
          onSelect(formikValue, value ? value.id : "")
        }
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Box>
  );
}
