import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Card } from "@mui/material";
import { pallete, styles } from "app/styles";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useStyles } from "../Styles";

export const Otc: React.FC = () => {
  const { classes } = useStyles();
  const handleTelegramClick = () => {
    window.open(`https://t.me/${process.env.REACT_APP_OCT_CONTACT_NUMBER}`);
  };
  const handleWhatsappClick = () => {
    window.open(`https://wa.me/${process.env.REACT_APP_OCT_CONTACT_NUMBER}`);
  };

  return (
    <Box>
      <Box className={classes.otcBox}>
        <Card className={classes.otcGrid}>
          <Typography variant="body2">
            We prioritize transparency and efficiency in all our interactions.
            Rest assured, your experience with us will be characterized by
            seamless transactions, cutting-edge technology, and unparalleled
            support from our dedicated team. It's important to note that the
            minimum deposit amount to engage in trading activities is $50,000.
            This ensures that you have sufficient capital to initiate and manage
            your trades effectively.
          </Typography>
          <Box className={classes.octBtnBox}>
            <Button
              endIcon={<TelegramIcon sx={{ color: pallete.selections.blue }} />}
              onClick={() => handleTelegramClick()}
              variant="contained"
              className={classes.otcBtn}
            >
              Telegram
            </Button>
            <Button
              endIcon={
                <WhatsAppIcon sx={{ color: pallete.selections.green }} />
              }
              onClick={() => handleWhatsappClick()}
              variant="contained"
              className={classes.otcBtn}
            >
              WhatsApp
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
