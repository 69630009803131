/**
 * @file: MenuContext.ts
 * @date: 24 Sep 2023

 * @description: $
 */



export type T_AppMenu = {
    key: number;
    label: string;
    route: string;
    path: string;
    order: number;
    permissions: string;
}

export type T_AppMenuList = T_AppMenu[];

export class PublicMenu implements T_AppMenu {
    key = 0;
    label = 'Public';
    route = '/';
    path = '/';
    order = 0;
    permissions = 'public';

    constructor(order: number, path: string, route: string = '') {
        this.order = order;
        this.key = order;
        this.path = path;
        this.route = route;
        this.permissions = "011000";

        // Split the input string into words
        const words: string[] = path.split(" ");
        this.label = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join("");

    }
}



export class AppMenus {

    private readonly menuMap: Map<string, T_AppMenu> = new Map();

    private readonly permissionsMap : Map<string, string>= new Map();

    constructor(menuList?: T_AppMenuList) {
        const appMenus = menuList || new Array<T_AppMenu>()
        if (appMenus.length > 0) {
            appMenus.forEach(menu => {
                this.menuMap.set(menu.path, menu);
                this.permissionsMap.set(menu.path, menu.permissions);
            });
        }
    }

    public isValid = (): boolean => this.menuMap.size > 0;

    public getMenuList(): T_AppMenuList {
        return Array.from(this.menuMap.values());
    }

    public getByUrl(url: string): T_AppMenu | null {
        return this.menuMap.get(url) ?? null;
    };

    public getMenuPermissions(): Map<string, string> {
        return this.permissionsMap;
    };

}
