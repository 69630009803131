import {useState} from 'react';

type T_SearchFilters = {
    [key: string]: any;
}

type T_OrderBy = 'ASC' | 'DESC';


export type T_LimitOptions = {
    limit: number;
    offset: number;
}

export type T_OrderingOptions = {
    order_field: string;
    order_by: T_OrderBy;
}

export type T_SearchParamsOptions = T_LimitOptions & T_OrderingOptions & {
    fields: string[];
    filters: T_SearchFilters;
}

type T_SearchParamsFuncs = {
    addSearchFields: (fields: string[]) => void;
    addSearchFilters: (filters: T_SearchFilters, reset: boolean) => void;
    setLimitOptions: ({limit, offset}: Partial<T_LimitOptions>) => void;
    setOrderOptions: ({order_field, order_by}: Partial<T_OrderingOptions>) => void;
}

export type T_UseSearchParametersResult = T_SearchParamsFuncs & {
    values: T_SearchParamsOptions;
};
const parsedSearchParameters = (searchParams: Partial<T_SearchParamsOptions>): T_SearchParamsOptions => {
    return {
        /**
         * (required), default set to -1
         */
        limit: searchParams?.limit || -1,

        /**
         * (required), default set to 0
         */
        offset: searchParams?.offset || 0,

        /**
         * filters object (required), default set to {}
         */
        filters: searchParams?.filters || {},

        /**
         * (required), default set to {}
         */
        fields: searchParams?.fields || [] as string[],

        /**
         * order_field (optional), default set to null
         */
        order_field: searchParams?.order_field || '',

        /**
         * order_by (optional), default set to null
         */
        order_by: searchParams?.order_by || 'ASC',
    }
}

export const useSearchQueryParameters = (opt: Partial<T_SearchParamsOptions>): T_UseSearchParametersResult => {

    const [searchParameters, setSearchParameters] = useState<T_SearchParamsOptions>(() => {
        return {...parsedSearchParameters(opt)}
    });

    const updateParameters = (current: Partial<T_SearchParamsOptions>, resetFilters: boolean = false) => {

        setSearchParameters((previous: T_SearchParamsOptions): T_SearchParamsOptions => {

            const previousFilters: T_SearchFilters = (resetFilters) ? {} : previous.filters;

            const limit: number = (current?.limit) ? current.limit : previous.limit;
            const offset: number = (current?.offset) ? current.offset : previous.offset;
            const order_field: string = (current?.order_field) ? current.order_field : previous.order_field;
            const order_by: T_OrderBy = (current?.order_by) ? current.order_by : previous.order_by;
            const fields: string[] = (current?.fields) ? current.fields : previous.fields;
            const filters: T_SearchFilters = (
                current?.filters ? {...previousFilters, ...current.filters} : previousFilters
            );

            return {limit, offset, order_field, order_by, fields, filters};

        });
    }

    const addSearchFields = (fields: string[]) => updateParameters({fields});

    const addSearchFilters = (filters: T_SearchFilters, reset: boolean = false) => {
        setSearchParameters({...searchParameters, filters: {...searchParameters.filters, ...filters}});
    }

    const setLimitOptions = (newOptions: Partial<T_LimitOptions>) => updateParameters({...newOptions});

    const setOrderOptions = (newOptions: Partial<T_OrderingOptions>) => updateParameters({...newOptions})

    return {values: searchParameters, addSearchFields, addSearchFilters, setLimitOptions, setOrderOptions};
}
