import {useCallback, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSessionStorage} from "./useStorage";
import i18n from "i18next";

const LOCALIZATION_CONST = {
    LANGUAGE_KEY: "lang",
    STORAGE_KEY: "TBP-LOCALIZATION",
    DEFAULT_LOCALIZATION_DATA: {
    languageCode: "en",
  },
};

export type T_Localization = {
     languageCode: string;
};

type T_LocalizationOptions = {
    languageCode?: string;
}

export type T_UseLocalizationResult = T_Localization & {
  initializeLanguage: (langCode: string) => void;
};

export const useLocalization = (opt?: T_LocalizationOptions): T_UseLocalizationResult => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [localizationData, setLocalizationData] = useState<T_Localization>(() => {
    const stored = sessionStorage.getItem(LOCALIZATION_CONST.STORAGE_KEY);
    return stored ? JSON.parse(stored) : LOCALIZATION_CONST.DEFAULT_LOCALIZATION_DATA;
  });

  const localizationStorage = useSessionStorage<T_Localization>(
      LOCALIZATION_CONST.STORAGE_KEY,
      LOCALIZATION_CONST.DEFAULT_LOCALIZATION_DATA
  );

  const initializeLanguage = useCallback(async (langCode: string) => {
    try {
      setLocalizationData({ languageCode: langCode });

      sessionStorage.setItem(
        LOCALIZATION_CONST.STORAGE_KEY,
        JSON.stringify({ languageCode: langCode })
      );

      await i18n.changeLanguage(langCode);
      window.dispatchEvent(new Event('languageChanged'));

      console.log(`Language changed to ${langCode}`);
    } catch (error) {
      console.error("Failed to change language:", error);
    }
  }, [localizationStorage]);

  useEffect(() => {
    const handleLanguageChange = () => {
      const stored = sessionStorage.getItem(LOCALIZATION_CONST.STORAGE_KEY);
      if (stored) {
        const {languageCode} = JSON.parse(stored);
        setLocalizationData({languageCode});
      }
    };
    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  return {
    ...localizationData,
    initializeLanguage
  };
};