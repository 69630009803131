import React from "react";
import {Box, Card, Divider, Grid, Skeleton, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {styles} from "app/styles";
import {useAssetsList} from "app/queries/CryptoQueries";

export const DashboardCardPrimary = ({ data }: { data: any }) => {
  const { classes } = useStyles();
  return (
    <Card className={classes.primaryCard}>
      <Typography color="primary" variant="h6" align="center">
        {data.title}
      </Typography>
      <Box className={classes.midBoxC0}>
        {data.innerData.map((x: any) => (
          <Box key={x.id} className={classes.midBoxC1}>
            {x.img ? (
              <Box component="img" src={x.img} className={classes.centerImg} />
            ) : null}
            <Typography variant="h3">{x.amount}</Typography>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box sx={{ paddingTop: "10px" }}>
        <Typography align="center" variant="subtitle2">
          {data.subtile}
        </Typography>
        <Typography align="center" color="primary" variant="h6">
          {data.subtileAmt}
        </Typography>
      </Box>
    </Card>
  );
};

export const DashboardCardSecondary = ({
  limit,
  mdSize,
}: {
  limit: number;
  mdSize: number;
}) => {
  const { classes } = useStyles();
  const cryptoAssets: any = useAssetsList();

  // const findObjectsByNames = (names: string[]) => {
  //   return cryptoAssets.filter((item: any) => names.includes(item.id));
  // };

  // const namesToFind: string[] = ["bitcoin", "ethereum"];
  // const foundObjects = findObjectsByNames(namesToFind);

  const isGrowing = (amt: number) => {
    if (amt > 0) {
      return true;
    } else {
      return false;
    }
  };

  if (cryptoAssets.isLoading || !cryptoAssets.result) {
    return (
      <Grid container key={"xcon1"}>
        {[...Array(8)].slice(0, limit).map((i: any, index: number) => (
          <Grid
            key={`${index}-${i}`}
            item
            md={mdSize}
            sm={6}
            xs={12}
            className={classes.topCard1}
          >
            <Skeleton variant="rounded" width={210} height={60} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!cryptoAssets.isLoading && cryptoAssets.result) {
    // console.log(cryptoAssets.result);
    return (
      <Grid container key={"xcon"}>
        {cryptoAssets.result.slice(0, limit).map((data: any, index: any) => (
          <Grid
            item
            md={mdSize}
            sm={6}
            xs={12}
            key={`#${index}-${data.id}`}
            className={classes.topCard1}
          >
            <Card className={classes.secondCard} key={`#SC-${index}`}>
              <Box className={classes.card2Box1}>
                <Typography color="primary" variant="h5">
                  {data.name}
                </Typography>
                <Box component="img" src={data.img} />
              </Box>
              <Box className={classes.card2Box1}>
                <Box
                  className={
                    isGrowing(parseFloat(data.changePercent24Hr))
                      ? classes.plusRating
                      : classes.minusRating
                  }
                >
                  <Typography
                    variant="subtitle1"
                    className={
                      isGrowing(parseFloat(data.changePercent24Hr))
                        ? classes.ratingAmtP
                        : classes.ratingAmtN
                    }
                  >
                    {data.type}
                    {parseFloat(data.changePercent24Hr).toFixed(2)}%
                  </Typography>
                  <Box
                    component="img"
                    className={classes.grphnIcons}
                    src={
                      isGrowing(parseFloat(data.changePercent24Hr))
                        ? "assets/icons/arrow-up-right.svg"
                        : "assets/icons/arrow-down-right.svg"
                    }
                  />
                </Box>
                <Typography color="primary" variant="h6">
                  ${parseFloat(data.priceUsd).toFixed(2)}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
};

DashboardCardSecondary.defaultProps = {
  limit: 5,
  mdSize: 4,
};

const useStyles = makeStyles()((theme) => {
  return {
    topCard1: {
      padding: "0 20px 10px 0",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    ratingAmtP: {
      color: "#00E069",
      fontWeight: 600,
    },
    ratingAmtN: {
      color: "#E13A05",
      fontWeight: 600,
    },
    plusRating: {
      borderRadius: "4px",
      backgroundColor: "#E5FFF1",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    minusRating: {
      borderRadius: "4px",
      backgroundColor: "#FFF0EB",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    ratingbox: {
      display: "flex",
      flexDirection: "row",
    },
    grphnIcons: {
      height: "15px",
      width: "15px",
      padding: "2px",
    },
    secondCard: {
      padding: "12px",
      // margin: "0 20px 20px 0",
      height: "99px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    card2Box1: {
      ...styles.flexDRC,
      justifyContent: "space-between",
    },
    centerImg: {
      height: "32px",
      width: "32px",
      margin: "auto 10px",
    },
    midBoxC0: {
      ...styles.flexDCC,
      height: "70%",
      // alignItems:"center",
      // alignContent:"center",
    },
    midBoxC1: {
      ...styles.flexDRC,
    },
    primaryCard: {
      padding: "12px 0",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      height: "244px",
      width: "100%",
      // marginRight: "20px",
    },
  };
});
