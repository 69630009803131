import * as React from "react";
import {Button} from "@mui/material";
import "./index.css";
import {useNavigate} from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="error-container">
        <span>4</span>
        <span>
          <span className="screen-reader-text">0</span>
        </span>
        <span>4</span>
      </section>
      <h1>Page Not Found!</h1>
      <h2 className="zoom-area">
        <b>The page your are looking for is not exist.</b>
      </h2>
      <div className="link-container">
        <Button className="back-button" onClick={() => navigate("/")}>
          Go Back
        </Button>
      </div>
    </>
  );
};

export default function NotFoundPage() {
  return <ErrorPage />;
}
