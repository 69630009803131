import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";

export const TRADE_APIS = {
    USER: {
        CRUD: ApiConfig.create("crypto-hub-user"),
        TRANSACTION: ApiConfig.create("crypto-hub-user"),
    },
    TRANSACTION: {
        SEARCH: ApiConfig.create("trade/search"),
    },
}

export type T_TradeOrder = {
    id: string;
    user_id: string;
    user_account: string;
    asset: string;
    currency: string;
    best_price: number;
    quantity: number;
    amount: number;
    fees: number;
    status_id: number;
    status: string;
    type: number;
    uid: string | null;
    created_at: string;
};
type T_TradeOrderList = T_TradeOrder[];
export const useTradeOrdersSearchQuery = (opt?: RQ.T_UseSearchQueryParams): RQ.T_UseSearchQueryResult<T_TradeOrderList> => {
    return RQ.useSearchQuery<T_TradeOrderList>({
        url: TRADE_APIS.TRANSACTION.SEARCH.url,
        queryKey: TRADE_APIS.TRANSACTION.SEARCH.key,
        searchParams: opt?.searchParams || {}
    })
}
