import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";

export const CRYPTOHUB_APIS = {
    USER: {
        CRUD: ApiConfig.create("crypto-hub-user"),
        TRANSACTION: ApiConfig.create("crypto-hub-user"),
    },
    TRANSACTION: {
        COMPLETE: ApiConfig.create("crypto-hub-transaction/complete-payment"),
    },
    TRANSACTION_REQUEST:{
        SEARCH:ApiConfig.create('crypto-hub-transaction-request/search')
    }
}

export type T_CHUserAccount = {
    id: string;
    full_name: string;
    email: string;
    dob: string;
    mobile: string | null;
    is_registered: boolean;
    kyc_is_verified: boolean;
    kyc_is_in_progress: boolean;
    kyc_is_completed: boolean;
    email_verified_at: string;
};

export const useUseCryptoHubUserAccountsListQuery = (): RQ.T_QueryResult<T_CHUserAccount[]> => {
    return RQ.useQueryRequest<T_CHUserAccount[]>({
        url: CRYPTOHUB_APIS.USER.CRUD.url,
        queryKey: CRYPTOHUB_APIS.USER.CRUD.key,
    });
}

export type T_CHCompleteTranReqParams = {};
type T_CHCompleteTranReqResult = RQ.T_QueryResult<{}>;
export const useCompleteCryptohubTransactionRequest = (opt: T_CHCompleteTranReqParams): T_CHCompleteTranReqResult => {
    return RQ.useQueryRequest<T_CHUserAccount[]>({
        url: CRYPTOHUB_APIS.TRANSACTION.COMPLETE.url,
        queryKey: CRYPTOHUB_APIS.TRANSACTION.COMPLETE.key,
    });
}


export type T_CryptohubOrder = {
    id: string;
    sender_id: number;
    receiver_id: number;
    sender_email: string;
    receiver_email: string;
    user_id_in_merchant_site: string;
    currency_id: number;
    currency_symbol: string;
    fireblocks_asset_id: string;
    amount: number;
    fees: number;
    verified_at: string;
    one_time_wallet_address: string;
    asset_quantity: number;
    fee_quantity: number;
    status_id: number;
    status_name: string;
};
type T_CryptohubOrderList = T_CryptohubOrder[];
export const useCryptohubOrdersSearchQuery = (opt?: RQ.T_UseSearchQueryParams): RQ.T_UseSearchQueryResult<T_CryptohubOrderList> => {
    return RQ.useSearchQuery<T_CryptohubOrderList>({
        url: CRYPTOHUB_APIS.TRANSACTION_REQUEST.SEARCH.url,
        queryKey: CRYPTOHUB_APIS.TRANSACTION_REQUEST.SEARCH.key,
        searchParams: opt?.searchParams || {}
    })
}
