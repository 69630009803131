import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {makeStyles} from "tss-react/mui";
import {tokenListIconsData} from "app/components/Data";

export default function SelectToken({
  lable,
  data = tokenListIconsData,
  type,
  getSelectedValues,
  value,
  isMarginRequired = false,
  disabled = false,
}: {
  lable: string;
  data: any;
  type: string;
  getSelectedValues: any;
  value: any;
  isMarginRequired: boolean;
  disabled: boolean;
}) {
  const { classes } = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedId = event.target.value as string;
    // console.log(selectedId);
    getSelectedValues(type, selectedId);
  };

  return (
    <Box
      className={classes.mainBox}
      sx={{ margin: isMarginRequired ? "15px" : "0" }}
    >
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">
          <Typography >{lable}</Typography>
        </InputLabel> */}

        <InputLabel id="demo-multiple-name-label" className={classes.root}>
          {lable}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type === "asset" ? value.asset : value.curr}
          label="value"
          onChange={handleChange}
          // disabled={disabled}
        >
          {data.map((item: any) => (
            <MenuItem value={item}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

SelectToken.defaultProps = {
  lable: "Loading...",
  data: tokenListIconsData,
  type: "Loading...",
  getSelectedValues: undefined,
  value: {},
  isMarginRequired: false,
  disabled: true,
};

const useStyles = makeStyles()((theme) => {
  return {
    mainBox: {
      width: "110%",
      margin: "15px",
    },
    root: {
      background: "white",
      paddingRight: 5,
    },
  };
});
