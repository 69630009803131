import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import axios, {AxiosResponse} from 'axios';

interface TokenResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

const Callback: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authorizationCode = params.get('code');

    if (authorizationCode) {
      // Exchange authorization code for access token
      exchangeCodeForToken(authorizationCode)
        .then((token) => {
          if (token) {
            localStorage.setItem('access_token', token); // Store token locally
            navigate('/dashboard'); // Redirect to another page, e.g., dashboard
          }
        })
        .catch((err) => setError('Failed to authenticate.'))
        .finally(() => setLoading(false));
    } else {
      setError('Authorization code not found');
      setLoading(false);
    }
  }, [location, navigate]);

  const exchangeCodeForToken = async (code: string): Promise<string | null> => {
    try {
      const response: AxiosResponse<TokenResponse> = await axios.post('/auth/autenticacao-token', {
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: 'https://dev.tradebp.com/OAuthAuthenticator',
      });

      const { access_token } = response.data;
      return access_token; // Return the access token
    } catch (error) {
      console.error('Error exchanging code for token:', error);
      return null;
    }
  };

  if (loading) return loading;
  if (error) return error;

  return null;
};

export default Callback;
