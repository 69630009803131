import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AuthScreenLeftBox from "app/components/unsorted/AuthScreenLeft";
import { pallete, styles } from "app/styles";
import { useLoader, useNotification } from "app/common";
import { Formik } from "formik";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getAxiosAPI } from "app/utils/axiosApiRequests";
import dayjs from "dayjs";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { SearchInput } from "app/components/inputs";
import { T_UseAccountStatus } from "app/hooks";
import { QUserAccount } from "app/queries";
import { MuiPhone } from "../../../../app/components/inputs/fields/MobileNumberWIthFlag";

Yup.addMethod(Yup.string, "phone", function (errorMessage) {
  return this.test("phone", errorMessage, (value: any) => {
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber ? phoneNumber.isValid() : false;
  });
});

const info_schema: any = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  dob: Yup.string()
    .required("Date of birth is required")
    .test("dob", "You must be at least 18 years old", (value) => {
      const today = dayjs();
      const dob = dayjs(value);
      const age = today.diff(dob, "year");
      return age >= 18;
    }),
  country: Yup.string().required("Country is required"),
  phone_number: Yup.string()
    .min(10, "Phone Number should be at least 10 digits")
    .max(10, "Phone Number should be at most 10 digits")
    //@ts-ignore
    //.phone("Invalid phone number")
    .required("Phone number is required"),
});

type T_AccountInfo = {
  first_name: string;
  last_name: string;
  dob: string;
  country: string;
  phone_number: string;
};

const PersonalInfo = ({ updateAccountStatus }: T_UseAccountStatus) => {
  const { classes } = useStyles();
  const [countries, setCountries] = useState([]);
  const updateAccountsDetailsQuery =
    QUserAccount.useUpdateAccountsDetailsQuery();
  const notification = useNotification();
  const loader = useLoader();

  const updateAccountDetails = (data: T_AccountInfo) => {
    loader.show();
    updateAccountsDetailsQuery.EXECUTE({
      payload: {
        first_name: data.first_name,
        last_name: data.last_name,
        dob: new Date(data.dob).toISOString().slice(0, 10),
        country: data.country,
        mobile_no: data.phone_number.replace(" ", ""),
      },
      onSuccessFn: () => {
        loader.hide();
        updateAccountStatus({ isProfileCompleted: true });
      },
      onErrorFn: () => {
        loader.hide();
        notification.error("Failed to update account details!");
      },
    });
  };

  const getCountry = async () => {
    loader.show();
    await getAxiosAPI("/country")
      .then((res: any) => {
        setCountries(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
        notification.error("Failed to fetch countries!");
      })
      .finally(() => {
        loader.hide();
      });
  };

  useEffect(() => {
    getCountry();
  }, []);

  return (
    <Grid container className={classes.mainGrid}>
      <Grid item md={6} className={classes.leftGrid}>
        <AuthScreenLeftBox
          title="Welcome to TradeBP"
          bttomText=""
          routeName=""
          path=""
        />
      </Grid>
      <Grid item md={6} className={classes.rightGrid}>
        <Formik
          initialValues={{
            first_name: "",
            dob: "",
            country: "",
            last_name: "",
            phone_number: "",
          }}
          validationSchema={info_schema}
          onSubmit={updateAccountDetails}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }: any) => (
            <Box className={classes.formBox}>
              <Typography
                variant="h3"
                align="center"
                className={classes.formHeading}
              >
                Personal Information
              </Typography>

              <Box className={classes.imputGrids}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  className={classes.inputBox}
                  value={values.first_name}
                  onBlur={handleBlur("first_name")}
                  onChange={handleChange("first_name")}
                />
                {touched.first_name && errors.first_name && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.first_name}
                  </Typography>
                )}

                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  className={classes.inputBox}
                  value={values.last_name}
                  onBlur={handleBlur("last_name")}
                  onChange={handleChange("last_name")}
                />
                {touched.last_name && errors.last_name && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.company_name}
                  </Typography>
                )}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%", margin: "10px 0" }}
                    label="Date Of Birth"
                    value={values.dob}
                    format={"DD-MM-YYYY"}
                    onChange={(newValue) => {
                      // Update Formik's state for the 'dob' field with the new date value
                      setFieldValue(
                        "dob",
                        newValue ? newValue.toISOString() : ""
                      );
                    }}
                  />
                </LocalizationProvider>
                {touched.dob && errors.dob && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.dob}
                  </Typography>
                )}
                <SearchInput
                  options={countries}
                  label="Select Country"
                  onSelect={(field: any, value: any) =>
                    setFieldValue(field, value)
                  }
                  displayValue="name"
                  formikValue="country"
                />
                {touched.country && errors.country && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.country}
                  </Typography>
                )}

                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  className={classes.inputBox}
                  value={values.phone_number}
                  onBlur={handleBlur("phone_number")}
                  onChange={handleChange("phone_number")}
                />
                {touched.phone_number && errors.phone_number && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.phone_number}
                  </Typography>
                )}
                <Box className={classes.btnsBox}>
                  <Button
                    disabled
                    variant="outlined"
                    className={classes.submitBtnOutloined}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => handleSubmit()}
                    variant="contained"
                    className={classes.submitBtn}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;

const useStyles = makeStyles()((theme) => {
  return {
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    imputGrids: {
      padding: "0 10px",
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
  };
});
