import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {styles} from "app/styles";

const ErrorComponent: React.FC = () => {
  return (
    <Box
      sx={{
        padding: 4,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography color="primary" variant="h4" component="h1" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography color="primary" variant="body1" gutterBottom>
        We're sorry, but something went wrong. Please try again.
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 2,
          bgcolor: "#FFFFFF",
          color: "#573A9B",
          ...styles.webButton,
        }}
        onClick={() => window.location.reload()}
      >
        Reload Page
      </Button>
    </Box>
  );
};

export default ErrorComponent;
