/**
 * @file: reactQueryProvider.ts
 * @date: 26 Feb 2024

 * @description: $
 */


import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import React, {HTMLProps, JSX} from "react";
import {Environment} from "app/config";

const ReactQueryProvider = (props: HTMLProps<JSX.Element>): JSX.Element => {

    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            {props.children}
            {Environment.IS_DEVELOPMENT && <ReactQueryDevtools initialIsOpen={false}/>}
        </QueryClientProvider>
    );
}

export default ReactQueryProvider;
