import { ApiConfig } from "app/config";
import { RQ } from "app/hooks";

const WHITELISTED_ADDRESS_APIS = {
    GET_WHITELISTED_ADDRESS: ApiConfig.create(
        "whitelisted-wallet-address/list"
    ),
    POST_WHITELISTED_ADDRESS: ApiConfig.create(
        "whitelisted-wallet-address"
    ),
};

const useWhitelistedAddressQuery = () : RQ.T_QueryResult<any> => {
    return RQ.useQueryRequest<any>({
        url: WHITELISTED_ADDRESS_APIS.GET_WHITELISTED_ADDRESS.url,
        queryKey: WHITELISTED_ADDRESS_APIS.GET_WHITELISTED_ADDRESS.key,
    });
};

export const useWhitelistedAddressMutation = (): RQ.T_MutationResult<any> => {
    return RQ.usePostRequest<any>({
        url: WHITELISTED_ADDRESS_APIS.POST_WHITELISTED_ADDRESS.url,
    });
}


export {
    useWhitelistedAddressQuery,
};
