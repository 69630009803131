import {ApiConfig} from "app/config";
import {RQ} from "app/hooks";

const CRYPTO_PAYMENT_APIS = {
    TRANSACTION_REQUEST: ApiConfig.create('crypto-hub-transaction-request'),
    TRANSACTION_REQUEST_SETTLED: ApiConfig.create('crypto-hub-transaction-request'),
    CURRENCY_REQUEST: ApiConfig.create('currency'),
    ASSET_REQUEST: ApiConfig.create('asset'),
    EXCHANGE_RATE_REQUEST: ApiConfig.create('exchange/best-price'),
    COMPLETE_TRANSACTION: ApiConfig.create(`crypto-hub-transaction/complete-payment`, []),
    SETTLE_PAYMENT: ApiConfig.create(`crypto-hub-transaction/settle-payment`),
    BLOCKCHAIN_DETAILS: ApiConfig.create(`crypto-hub-transaction`),
}

export type T_CryptohubTransactionRequest = {
    id: string;
    sender_id: number;
    receiver_id: number;
    sender_email: string;
    receiver_email: string;
    user_id_in_merchant_site: string;
    currency_id: number;
    currency_symbol: string;
    fireblocks_asset_id: string;
    amount: number;
    fees: number;
    language_symbol: string,
    verified_at: string | null;
    one_time_wallet_address: string;
    received_quantity: number;
    asset_quantity: number;
    fee_quantity: number;
    status_id: number;
    status_name: string;
    kyc_status: {
        full_kyc_required: boolean;
        simple_kyc_required: boolean;
    }
    allow_reduced_payment?: boolean;
}

export const useCryptohubTransactionRequest = (transactionId: string): RQ.T_QueryResult<T_CryptohubTransactionRequest> => {
    return RQ.useQueryRequest<T_CryptohubTransactionRequest>({
        url: CRYPTO_PAYMENT_APIS.TRANSACTION_REQUEST.url,
        queryKey: CRYPTO_PAYMENT_APIS.TRANSACTION_REQUEST.key,
        params: [transactionId],
        refetchOnWindowFocus: false,
    })
}

export const useCryptohubSettleRequest = (transactionId: string): RQ.T_QueryResult<any> => {

    return RQ.useQueryRequest<any>({
        url: CRYPTO_PAYMENT_APIS.TRANSACTION_REQUEST.url,
        queryKey: CRYPTO_PAYMENT_APIS.TRANSACTION_REQUEST.key,
        params: [transactionId, 'settle-details'],
        refetchOnWindowFocus: false,
    })
}

export type T_Asset = {
    id: string;
    name: string;
    fireblocks_asset_id: string;
    parent_asset: string | null;
    acts_as: string;
}

export type T_AssetList = T_Asset[];
export const useAssetsList = (): RQ.T_QueryResult<T_AssetList> => {
    return RQ.useQueryRequest<T_AssetList>({
        url: CRYPTO_PAYMENT_APIS.ASSET_REQUEST.url,
        queryKey: CRYPTO_PAYMENT_APIS.ASSET_REQUEST.key,
        refetchOnWindowFocus: false,
    });
}

export type T_Currency = {
    id: string;
    name: string;
    symbol: string;
}

export type T_CurrencyList = T_Currency[];
export const useCurrencyList = (): RQ.T_QueryResult<T_CurrencyList> => {
    return RQ.useQueryRequest<T_CurrencyList>({
        url: CRYPTO_PAYMENT_APIS.CURRENCY_REQUEST.url,
        queryKey: CRYPTO_PAYMENT_APIS.CURRENCY_REQUEST.key,
        refetchOnWindowFocus: false,
    });
}


export type T_CurrencyExchangeRates = {
    best_price: number;
    exchange: string;
}

export const useCurrencyExchangeRatesQuery = (asset: string, currency: string): RQ.T_QueryResult<T_CurrencyExchangeRates> => {
    return RQ.useQueryRequest<T_CurrencyExchangeRates>({
        url: `${CRYPTO_PAYMENT_APIS.EXCHANGE_RATE_REQUEST.url}/${asset}/0/${currency}`,
        queryKey: [CRYPTO_PAYMENT_APIS.EXCHANGE_RATE_REQUEST.key, asset, currency],
        refetchInterval: 5000,
        refetchOnWindowFocus: false,
    });
}


export const useCompleteCryptohubPaymentRequest = <T>(): RQ.T_MutationResult<T> => {
    return RQ.usePostRequest<T>({
        url: CRYPTO_PAYMENT_APIS.COMPLETE_TRANSACTION.url,
        mutationKey: [CRYPTO_PAYMENT_APIS.COMPLETE_TRANSACTION.key],
    });
}

export const useSettleCryptohubPaymentRequest = <T>(): RQ.T_MutationResult<T> => {
    return RQ.usePostRequest<T>({
        url: CRYPTO_PAYMENT_APIS.SETTLE_PAYMENT.url,
        mutationKey: [CRYPTO_PAYMENT_APIS.SETTLE_PAYMENT.key],
    });
}

export const useCryptohubBlockChainDetailsRequest = (transactionId: String): RQ.T_QueryResult<any> => {
    return RQ.useQueryRequest<any>({
        url: CRYPTO_PAYMENT_APIS.BLOCKCHAIN_DETAILS.url,
        queryKey: CRYPTO_PAYMENT_APIS.BLOCKCHAIN_DETAILS.key,
        params: [transactionId, 'blockchain-details'],
        refetchOnWindowFocus: false,
    })
}