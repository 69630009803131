/**
 * Imports necessary libraries for context creation and local storage interaction.
 */
import {AppMenus, AuthUser, T_AppMenu, T_AuthUser} from "app/models";
import {useLocalStorage} from "app/hooks";
import React, {createContext, useState} from "react";


export type T_AppContext = {
    user?: T_AuthUser;
    menus?: T_AppMenu[];
}


class AppContext {

    public readonly user: AuthUser;

    public readonly menus: AppMenus;

    public constructor({user, menus,}: T_AppContext) {
        this.user = new AuthUser(user);
        this.menus = new AppMenus(menus);
    };


}

/**
 * Defines the type for the Application context object.
 *
 */
type T_ApplicationContext = {
    context: AppContext;
    setContextData: (authContextData: T_AppContext) => void;
};

/**
 * Defines the props type for the `AppContextProvider` component.
 * @typedef {Object} T_ContextProviderProps - AppContextProvider props
 * @property {React.ReactNode} children - Child components to be rendered within the context
 */
export type T_ContextProviderProps = {
    children: React.ReactNode;
};

/**
 * Creates a React context for managing application-wide state and actions.
 *
 * This context provides a centralized location to store and access application data,
 * allowing components at any level of the hierarchy to retrieve and update it.
 */
export const AuthUserContext: React.Context<T_ApplicationContext> = createContext<T_ApplicationContext>({
    context: new AppContext({}),
    setContextData: () => void 0,
});

/**
 * Provider component for the `ApplicationContext`.
 *
 * This component wraps your application's content and provides the application context
 * to its children. It manages the context state using the `useState` hook and offers
 * functions to access and modify the context data.
 *
 * @param {T_ContextProviderProps} props - Props passed to the provider component
 * @returns {JSX.Element} - The wrapped application content with the Application context
 */
export const AuthUserProvider = (props: T_ContextProviderProps): React.JSX.Element => {

    /**
     * Key used to store application context data in local storage.
     */
    const storageKey: string = "SH56GcgFJSfCkaCBzAdwDwK2k6mPK6";

    /**
     * Custom hook to interact with local storage.
     *
     * This hook simplifies the process of retrieving and storing data in local storage.
     */
    const _storage = useLocalStorage(storageKey, undefined);

    const [context, setContext] = useState<AppContext>(() => {
        return _storage.getValue((data?: T_AppContext) => {
            const contextData = data || {} as T_AppContext;
            return new AppContext({
                user: contextData.user,
                menus: contextData.menus,
            });
        });
    });


    /**
     * Retrieves the application context data from local storage or initializes an empty object.
     *
     * This function first checks if the context data exists in the state. If not, it attempts
     * to retrieve the data from local storage using the `useLocalStorage` hook. If no data is
     * found, it creates a new `AppContext` object with an empty argument.
     *
     * @returns {T_AppContext} - Application context data
     */


    const getContextData = (): AppContext => {
        if (context) {
            return context;
        }

        return _storage.getValue((data?: T_AppContext) => {
            const contextData = data || {} as T_AppContext;
            return new AppContext({
                user: contextData.user,
                menus: contextData.menus,
            });
        });
    };

    /**
     * Updates the application context data and stores it in local storage.
     *
     * This function accepts an `AppContext` object as the new context data. It updates the
     * state and calls the `useLocalStorage` hook to store the data in local storage.
     *
     * @param {T_AppContext} contextData - New application context data
     */
    const setContextData = (contextData: T_AppContext) => {
        _storage.setValue(contextData);
        setContext(new AppContext(contextData));
    };


    /**
     * Creates and returns the application context object with accessors for data and update.
     *
     * This function combines the `getContextData` and `setContextData` functions within the
     * context object, providing convenient access to both for child components.
     *
     * @returns {T_AppContext} - Application context object
     */
    const getAuthContext = (): T_ApplicationContext => {
        return {context, setContextData};
    };


    /**
     * Renders the child components within the `ApplicationContext.Provider`, making the context
     * accessible to all descendants.
     */
    return (<AuthUserContext.Provider value={getAuthContext()}>{props.children}</AuthUserContext.Provider>);

};
