import {ApiConfig} from "app/config";
import {RQ} from "app/hooks";

const CRYPTO_PAYMENT_APIS = {
    ASSET_REQUEST: ApiConfig.create('asset'),
}

export type T_Asset = {
    id: string;
    name: string;
    fireblocks_asset_id: string;
    parent_asset: string | null;
    acts_as: string;
}

export type T_AssetList = T_Asset[];
export const useAssetsList = (): RQ.T_QueryResult<T_AssetList> => {
    return RQ.useQueryRequest<T_AssetList>({
        url: CRYPTO_PAYMENT_APIS.ASSET_REQUEST.url,
        queryKey: CRYPTO_PAYMENT_APIS.ASSET_REQUEST.key,
    });
}