import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";

export const WALLET_APIS = {
    LIST: ApiConfig.create("user-account/wallet"),
    DETAILS: ApiConfig.create("user-account/wallet"),
}

export type T_WalletDetails = {
    [key: string]: any;
};

export type T_WalletDetailsList = T_WalletDetails[];

export const useWalletDetailsQuery = (wallet_id: String): RQ.T_QueryResult<T_WalletDetails> => {
    return RQ.useQueryRequest<T_WalletDetails>({
        url: WALLET_APIS.DETAILS.url,
        queryKey: [...WALLET_APIS.DETAILS.key],
        params: [wallet_id],
        refetchOnWindowFocus: false,
    })
}

const BILLING_BALANCE_APIS = {
    GET_USER_WALLET: ApiConfig.create(
        "user-account/wallet"
    ),
};

export const useUserWalletQuery = () : RQ.T_QueryResult<any> => {
    return RQ.useQueryRequest<any>({
        url: BILLING_BALANCE_APIS.GET_USER_WALLET.url,
        queryKey: BILLING_BALANCE_APIS.GET_USER_WALLET.key,
    });
};