import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./settingStyle";
import { pallete } from "app/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import { decodeToken } from "app/utils/commonFunctions";
import { Formik } from "formik";
import * as Yup from "yup";
import PersonIcon from "@mui/icons-material/Person";
import { useAppContext } from "../../../../app/hooks";

const form_schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  fullName: Yup.string().required("Full name is required"),
  // dob: Yup.string().required("Date of birth is required"),
  country: Yup.string().required("Country is required"),
  company_name: Yup.string().required("Company name is required"),
});

const passwrd_schema = Yup.object().shape({
  oldPass: Yup.string().required("Current password is required"),
  newPass: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      "Password must be 8-16 characters long, with at least one uppercase letter, one number, and one special character"
    ),
  confirmPass: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPass"), ""], "Passwords must match"),
});

const ProfileImageSelector = ({ imageSrc }: { imageSrc: string }) => {
  const { classes } = useStyles();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const CurrentImage = () => {
    if (selectedImage) {
      return (
        <Avatar
          src={selectedImage || undefined}
          alt="Selected Profile"
          className={classes.avatar}
          onClick={() => document.getElementById("imageInput")?.click()}
        />
      );
    } else if (imageSrc) {
      return (
        <Avatar
          src={imageSrc}
          alt="Selected Profile"
          className={classes.avatar}
          onClick={() => document.getElementById("imageInput")?.click()}
        />
      );
    } else {
      return (
        <PersonIcon
          sx={{
            fontSize: "100px",
            color: pallete.primaryWhite,
            cursor: "pointer",
          }}
          onClick={() => document.getElementById("imageInput")?.click()}
        />
      );
    }
  };

  return (
    <Box>
      <CurrentImage />
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        className={classes.inputFile}
        onChange={handleImageChange}
      />
    </Box>
  );
};

const Settings = () => {
  const { classes } = useStyles();
  const { user } = useAppContext();
  const [showPassword, setShowPassword] = React.useState({
    oldPas: false,
    newPas: false,
    confirmPass: false,
  });
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [userDetails, setUserDetails] = useState({
    fullname: "",
    companyName: "",
    mobileNumber: "",
    workNumber: "",
    email: "",
    corporateEmail: "",
    countryCity: "",
    address: "",
    image: "",
  });

  const decoded: any = decodeToken();

  const handleClickShowPassword = (value: string) => {
    setShowPassword((pre: any) => ({ ...pre, [value]: !pre[value] }));
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account/details", true)
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          const values = res.data.data;
          setUserDetails({
            fullname: values.username,
            companyName: values.company_name,
            mobileNumber: values.mobile_no,
            workNumber: "",
            email: values.email,
            corporateEmail: "",
            countryCity: values.country,
            address: "",
            image: values.image,
          });
          // setSnackbarMessage("Login Success!");
          // setShowSnackbar(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const updatePassword = async (values: any) => {
    setLoading(true);
    const passwordChangePath = user.isTeamMember() ? "member" : "user";
    const data = {
      current_password: values.oldPass,
      new_password: values.newPass,
      confirmed_password: values.confirmPass,
    };
    await postAxiosAPI(
      `/${passwordChangePath}-account/password/change`,
      JSON.stringify(data),
      true
    )
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          setSnackbarMessage("Password update successfully!");
          setSeverity("success");
          setShowSnackbar(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to update password!");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  console.log({ userDetails });

  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Box className={classes.mainBox}>
        <Typography
          variant="h4"
          color="primary"
          className={classes.topHeadingWeb}
        >
          Settings
        </Typography>

        <Card className={classes.mainCard}>
          <Grid container>
            <Grid item lg={3} xs={12} className={classes.userImgGrid}>
              <Box className={classes.personIconBox}>
                {/* <PersonIcon
                  sx={{ fontSize: "100px", color: pallete.primaryWhite }}
                  onClick={() => console.log("clicked")}
                /> */}
                <ProfileImageSelector imageSrc={userDetails.image} />
              </Box>
              <Button
                variant="contained"
                // disabled={true}
                className={classes.deletImgBtn}
                startIcon={
                  <Box
                    component="img"
                    className={classes.deletIcon}
                    src="assets/icons/delete.svg"
                    alt="Descriptive Alt Text"
                  />
                }
              >
                Delete photo
              </Button>
            </Grid>
            <Grid container lg={9} xs={12}>
              <Formik
                initialValues={userDetails}
                validationSchema={form_schema}
                enableReinitialize={true}
                onSubmit={(values: any) => {
                  console.log(values, "cred");
                }}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values,
                }: any) => (
                  <>
                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Full Name<span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Full Name"
                        variant="outlined"
                        autoComplete="off"
                        disabled={true}
                        className={classes.inputBox}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                        }}
                        value={values.fullname}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Email<span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        autoComplete="off"
                        disabled={true}
                        className={classes.inputBox}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                        }}
                        value={values.email}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Company Name<span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                        autoComplete="off"
                        disabled={true}
                        className={classes.inputBox}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                        }}
                        value={values.companyName}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Mobile Phone<span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Mobile Phone"
                        variant="outlined"
                        autoComplete="off"
                        disabled={true}
                        className={classes.inputBox}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                        }}
                        value={values.mobileNumber}
                      />
                    </Grid>

                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Country<span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        autoComplete="off"
                        disabled={true}
                        className={classes.inputBox}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                        }}
                        value={values.countryCity}
                      />
                    </Grid>

                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Address<span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Enter Address"
                        variant="outlined"
                        autoComplete="off"
                        disabled={true}
                        className={classes.inputBox}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                        }}
                        value={values.address}
                      />
                    </Grid>
                  </>
                )}
              </Formik>
              <Grid item xs={12} sx={{ padding: "20px 10px" }}>
                <Typography variant="h4" color="primary">
                  Update Password
                </Typography>
              </Grid>
              {/*========================== PASSWORD ============================================================ */}
              <Formik
                initialValues={{
                  oldPass: "",
                  newPass: "",
                  confirmPass: "",
                }}
                validationSchema={passwrd_schema}
                enableReinitialize={true}
                onSubmit={(values: any) => {
                  console.log(values, "cred");
                  updatePassword(values);
                }}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values,
                }: any) => (
                  <>
                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Current Password
                        <span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <FormControl
                        className={classes.inputBox}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          label="Password"
                          id="outlined-adornment-password"
                          type={showPassword.oldPas ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword("oldPas")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword.oldPas ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          autoComplete="off"
                          value={values.oldPass}
                          onBlur={handleBlur("oldPass")}
                          onChange={handleChange("oldPass")}
                        />
                        {touched.oldPass && errors.oldPass && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.oldPass}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        New Password<span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <FormControl
                        className={classes.inputBox}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          New Password
                        </InputLabel>
                        <OutlinedInput
                          label="New Password"
                          id="outlined-adornment-password"
                          type={showPassword.newPas ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword("newPas")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword.newPas ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          autoComplete="off"
                          value={values.newPass}
                          onBlur={handleBlur("newPass")}
                          onChange={handleChange("newPass")}
                        />
                        {touched.newPass && errors.newPass && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.newPass}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.imputGrids}>
                      <Typography color="primary" variant="body2">
                        Confirm Password
                        <span style={{ color: "#E13A05" }}>*</span>
                      </Typography>
                      <FormControl
                        className={classes.inputBox}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          label="Confirm Password"
                          id="outlined-adornment-password"
                          type={showPassword.confirmPass ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword("confirmPass")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword.confirmPass ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          autoComplete="off"
                          value={values.confirmPass}
                          onBlur={handleBlur("confirmPass")}
                          onChange={handleChange("confirmPass")}
                        />
                        {touched.confirmPass && errors.confirmPass && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.confirmPass}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.saveBtnBox}>
                        <Button
                          disabled={decoded.session.auth_type !== 1}
                          onClick={() => handleSubmit()}
                          variant="contained"
                          className={classes.saveBTN}
                        >
                          Save password
                        </Button>
                      </Box>
                    </Grid>
                    {/* Checks */}
                    <Box className={classes.bewloBox}>
                      <Typography
                        color="primary"
                        variant="body2"
                        sx={{ fontWeight: "600", padding: "10px" }}
                      >
                        Notifications:
                      </Typography>
                      <Box className={classes.belwoTextBox}>
                        <Checkbox defaultChecked />
                        <Typography className={classes.belowTexts}>
                          Changes made to your account
                        </Typography>
                      </Box>
                      <Box className={classes.belwoTextBox}>
                        <Checkbox defaultChecked />
                        <Typography className={classes.belowTexts}>
                          Marketing and promo offers
                        </Typography>
                      </Box>
                      <Box className={classes.belwoTextBox}>
                        <Checkbox defaultChecked />
                        <Typography className={classes.belowTexts}>
                          Security alerts
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default Settings;
