import React from "react";
import {QCryptohubQueries as QCHU} from "app/queries";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF} from "app/components/table";
import {getKycStatusProps, redirectTo} from "app/utils";


const renderFunction = (data: QCHU.T_CHUserAccount): React.JSX.Element[] => {
    return [
        <TDF.TextContent value={data.email} />,
        <TDF.TextContent value={`${data.full_name}`} />,
        <TDF.NumericContent value={`${data.mobile}`} />,
        <TDF.DateTimeContent dateTime={`${data.dob}`} />,
        <TDF.StatusBadge {...getKycStatusProps(data)} />,
        <TDF.DateTimeContent dateTime={data.email_verified_at} />,
    ]
}

const cryptohubAccountsDataMap = {
    "Email": "email",
    "Full Name": "full_name",
    "Mobile": "mobile",
    "Date Of Birth": "dob",
    "Verified": "kyc_is_verified",
    "Date": "email_verified_at",
};


export const CryptohubAccounts = (): React.JSX.Element => {
    const merchantAccountsQuery = QCHU.useUseCryptoHubUserAccountsListQuery();
    const headings: string[] = Object.keys(cryptohubAccountsDataMap);
    const tableData = merchantAccountsQuery.isResolved ? merchantAccountsQuery.result : [];

    const getMenuItemOptionsFn = async (data: QCHU.T_CHUserAccount): Promise<TDF.T_MenuItemOptionList> => {
        const viewAllTransactions = () => redirectTo({query: {chid: data.id}});
        return Promise.resolve([
            {name: "View All Transactions", onClickHandler: viewAllTransactions},
        ]);
    }

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Cryptohub Accounts",
        headings: headings,
        isLoading: merchantAccountsQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: tableData,
        actionColumn: true,

        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ['email', 'full_name', 'mobile',],
        },
        downloadOptions: {
            fileName: "cryptohub-user-accounts.csv",
            dataMap: cryptohubAccountsDataMap,
        },
        // actionOptions: {getMenuItemOptionsFn}

    }

    return (<DefaultTableComponent {...tableComponentProps} />)
}
