import React from "react";
import { TabContent } from "app/components/tabs";
import { WithdrawHistoryTable } from "./withdrawHistory";
import { AddWithdraw } from "./addWithdraw";


const Withdraw = () => {
    const tabHeading: string = "Withdraw";
    const resetUrlOnTabChange: boolean = true;

    const tabData = [
        {label: "History", component: <WithdrawHistoryTable />},
        {label: "Withdraw", component: <AddWithdraw />},
    ];

    return TabContent({tabHeading, tabData, resetUrlOnTabChange});
};

export default Withdraw;
