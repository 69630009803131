type T_RedirectToOptions = {
    url?: string;
    params?: string[],
    query?: any;
}


export const redirectTo = ({url, params, query}: T_RedirectToOptions): any => {

    // @ts-ignore
    const {origin, pathname} = window.location;

    const _location = {
        path: url ? (url.endsWith("/") ? url.substring(0, url.length - 1) : url) : `${origin}${pathname}`,
        paramString: '', queryString: ''
    };
    if (params && Array.isArray(params)) {
        _location.paramString = ((params.length > 0) ? `/${params.join("/")}` : ``);
    }
    if (query && typeof query == "object") {
        _location.queryString = (Object.keys(query).length > 0) ? `?${(new URLSearchParams(query).toString())}` : ``;
    }
    return (window.location.href = `${_location.path}${_location.paramString}${_location.queryString}`);
}

type T_SetUrlStateOptions = {
    url?: string;
    data?: any;
    unused?: any;
}

export const setUrlState = ({url, data, unused}: T_SetUrlStateOptions): void => {
    url = url || `${window.location.origin}${window.location.pathname}`;
    data = data || {};
    unused = unused || '';
    window.history.replaceState(data, unused, url);
}
