/**
 * @file: useAccountStatus.ts
 * @date: 12 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {ApiConfig} from "app/config";
import {RQ} from "app/hooks";

const WalletHUB_PAYMENT_APIS = {
    TRANSACTION_REQUEST: ApiConfig.create('wallet-hub-transaction-request')

}

export type T_WallethubTransactionRequest = {
    id: string;
    sender_id: number;
    receiver_id: number;
    valid_till: string;
    redirect_url: string;
    amount: number;
    currency: string;
}

export const useWallethubTransactionRequest = (transactionId: string): RQ.T_QueryResult<T_WallethubTransactionRequest> => {

    return RQ.useQueryRequest<T_WallethubTransactionRequest>({
        url: WalletHUB_PAYMENT_APIS.TRANSACTION_REQUEST.url,
        queryKey: WalletHUB_PAYMENT_APIS.TRANSACTION_REQUEST.key,
        params: [transactionId],
    })
}
