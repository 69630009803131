import React, { useState } from "react";
import { TradeOrders } from "./TradeOrders";
import { UserTradeOrders } from "./UserTradeOrders";

export const TradeOrdersView = (): React.JSX.Element => {
  const [userId, setUserId] = useState<string | null>(null);
  const setUserIdFn = (userId: string | null): void => {
    setUserId(userId);
  };

  if (userId) {
    return <UserTradeOrders userId={userId} setUserIdFn={setUserIdFn} />;
  }
  return <TradeOrders setUserIdFn={setUserIdFn} />;
};
