import React from "react";
import {useTabContentStyles} from "./Styles";
import * as TBC from "./TabContentView";
import {useLocalStorage} from "app/hooks";
import {setUrlState} from "app/utils";

export type T_BasicTabProps = {
    tabHeading: string;
    tabData: TBC.T_TabData[];
    activeTab?: number;
    resetUrlOnTabChange?: boolean;

}


export const TabContent = ({tabHeading, tabData, activeTab, ...opt}: T_BasicTabProps): React.JSX.Element => {
    const {classes} = useTabContentStyles();
    const basicTabKey: string = `#active-tab-${tabHeading.toLowerCase().split(" ").join("-")}`;
    const activeTabStorage = useLocalStorage(basicTabKey, 0);
    const [activeTabIndex, setActiveTabIndex] = React.useState(
        activeTab || activeTabStorage.getValue()
    );
    const resetUrlOnTabChange: boolean = opt?.resetUrlOnTabChange || true;
    const tabMenuList: React.JSX.Element [] = [];
    const tabPanelList: React.JSX.Element [] = [];

    const keyPrefix: string = `#tab-content-${tabHeading.toLowerCase().trim().split(' ').join("-")}`;


    const changeActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTabIndex(newValue);
        activeTabStorage.setValue(newValue);
        if (resetUrlOnTabChange) {
            setUrlState({});
        }

    };

    tabData.forEach((tab, index) => {
        const tabMenu: React.JSX.Element = TBC.TabContentMenu({
            index, name: tab.label,
            value: activeTabIndex, classes, keyprefix: keyPrefix
        });

        const tabPanel: React.JSX.Element = TBC.TabContentPanel({
            index, children: tab.component,
            name: tab.label, value: activeTabIndex, classes, keyprefix: keyPrefix
        });

        tabMenuList.push(tabMenu);
        tabPanelList.push(tabPanel);
    });

    return TBC.TabContentView({
        tabMenuList, tabPanelList, keyprefix: keyPrefix, classes,
        activeTabIndex, tabHeading, changeActiveTab
    });
}
