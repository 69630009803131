import React from "react";
import { TabContent } from "app/components/tabs";
import { TradingHistory } from "./tabContent/TradingHistory";
import { Trade } from "./tabContent/Trade";
import { Otc } from "./tabContent/Otc";

export const Trading: React.FC = () => {
  const tabHeading: string = " Trading Page";
  const tabData = [
    { label: "Trade", component: <Trade /> },
    { label: "Otc", component: <Otc /> },
    { label: "Trading History", component: <TradingHistory /> },
  ];

  return TabContent({ tabData, tabHeading });
};
