/**
 * @file: InputDataQueries.ts
 * @date: 11 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {RQ} from "app/hooks"
import {KEYS, URLS as URLS} from "./QueryConstants";


export type T_Country = {
    id: string;
    name: string;
    code: string;
    phone_code: string;
    is_supported: boolean;
}
export type T_CountryList = T_Country[];
export const useCountryList = (): RQ.T_QueryResult<T_CountryList> => {
    // Use the useQueryRequest hook with the defined query options
    return RQ.useQueryRequest<T_CountryList>({
        queryKey: KEYS.COUNTRY_LIST,
        url: URLS.INPUT_DATA.GET_COUNTRY_LIST,
        staleTime: 60 * 60,
        refetchOnWindowFocus: false,
    });
}


export type T_Currency = {
    id: string;
    name: string;
    symbol: string;
}

export type T_CurrencyList = T_Currency[];
export const useCurrencyList = (): RQ.T_QueryResult<T_CurrencyList> => {
    return RQ.useQueryRequest<T_CurrencyList>({
        queryKey: KEYS.CURRENCY_LIST,
        url: URLS.CRYPTO.GET_CURRENCY_LIST,
        staleTime: 60 * 60,
    });
}
