// FullScreenLoader.tsx

import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface FullScreenLoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<FullScreenLoaderProps> = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress size={60}  thickness={5.6} color="primary" />
    </Backdrop>
  );
};

export default Loader;
