import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../Style";
import { motion } from "framer-motion";

const Security = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.securityBox}>
        <Typography color="primary" variant="h1" align="center">
          Security
        </Typography>
        <Grid container className={classes.securityGrid}>
          <Grid item lg={6} className={classes.securityLeftGrid}>
            <Typography
              color="primary"
              variant="h5"
              sx={{ fontWeight: 600, padding: "20px 0" }}
            >
              High-level Cyber Protection{" "}
            </Typography>
            <Typography color="primary" variant="body2">
              We’ve developed & implemented the world’s first risk based
              Cold/Warm Vault based on your need, capable of creating, signing
              and sending secure blockchain transactions. Eliminating all
              potential cyber attack vectors.
            </Typography>
            <Button
              sx={{ marginTop: "50px" }}
              className={classes.btn1}
              onClick={() => navigate("/signup")}
            >
              Get Started
            </Button>
          </Grid>
          <Grid item lg={4}>
            {/* <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 1.5,
              }}
              
            > */}
              <Box
                className={classes.securityImgs}
                component="img"
                src="/assets/images/security_img.png"
                alt="Descriptive Alt Text"
              />
            {/* </motion.div> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Security;
