import * as React from "react";
import { TabContent } from "app/components/tabs";
import { TeamInvitationForm } from "./TeamInvitationForm";
import { TeamMembersView } from "./TeamMembersView";

const Team = () => {
  const tabHeading: string = "Team";
  const resetUrlOnTabChange: boolean = true;

  const TeamsViewComponent = (): React.JSX.Element => {
    return (
      <>
        <TeamInvitationForm />
        <TeamMembersView />
      </>
    );
  };

  const tabData = [{ label: "Members", component: <TeamsViewComponent /> }];
  return TabContent({ tabHeading, tabData, resetUrlOnTabChange });
};
export default Team;
