import * as YUP from "yup";

const validationSchema = {
    email:()=>{
        return YUP.string().email("Invalid email address").required("Email is required")
    },
    // text:({name})=>{
    //     return YUP.string().required("Text is required")
    // }
};

export {YUP,validationSchema};