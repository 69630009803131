import React from "react";
import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import ComingSoonPage from "app/components/unsorted/CommingSoon";

const Notification = () => {
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.mainBox}>
        <ComingSoonPage />
      </Box>
    </>
  );
};

export default Notification;

const useStyles = makeStyles()((theme) => {
  return {
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 70px",
      overflowY: "auto",
    },
  };
});
