import {URLS} from "./QueryConstants";
import {RQ} from "app/hooks"


export type T_LoginRequestParams = {
    auth_type: string;
    user: string;
    password: string;

}


/**
 * Custom hook to fetch currency exchange rates from the API.
 * @returns An object containing the result of the API query.
 */
export type T_AuthResponse = {
    access: string;
    refresh: string;
};


export const useLoginQuery = (): RQ.T_MutationResult<T_AuthResponse> => {
    return RQ.usePostRequest<T_AuthResponse>({
        url: URLS.AUTH.LOGIN_USER,
    });
}
