/**
 * Imports the required modules and components from React and Material-UI
 * React: The core React library
 * useState: A React hook for managing state
 * Box, Card, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography: Material-UI components
 * useDefaultTableContainerStyles: A custom hook for styling the table container
 */
import React, { useEffect, useState } from "react";
import { useDefaultTableComponentStyles } from "./TableStyles";

/**
 * Imports all the required fragments (components or utilities) from the './fragments' module
 */
import {
  T_DownloadPluginProps,
  T_TableDownloadOptions,
  T_TableSearchPluginOptions,
  T_TableSearchPluginProps,
  TableBodyFragments as TBF,
  TableCommonFragments as TCF,
  TableDataFragments as TDF,
} from "./fragments";

import { DefaultTableView, T_DefaultTableViewProps } from "./DefaultTableView";

export type T_DefaultTableComponentProps<Tdata = any> = {
  // The title of the table
  title: string;

  subTitle?: string;
  // The headings of the table
  headings: string[];
  // Indicates whether the table is in a loading state or not
  isLoading: boolean | undefined;

  tableData: Tdata[];

  initialPage?: number;

  initialRowsPerPage?: number;

  // An optional function to render custom table rows
  renderFunction: (rowData: Tdata) => React.JSX.Element[];

  rowsPerPageOptions?: number[];

  searchOptions: T_TableSearchPluginOptions;

  downloadOptions?: T_TableDownloadOptions;

  paginatorProps?: TBF.T_TablePaginatorProps;

  actionColumn?: boolean;

  actionOptions?: TDF.T_TableRowActionOption;

  tableBreadcrumbsOptions?: TBF.T_TableBreadcrumbsOptions;
};

export const DefaultTableComponent = <Tdata = any,>(
  opt: T_DefaultTableComponentProps<Tdata>
): React.JSX.Element => {
  const { classes } = useDefaultTableComponentStyles();

  const keyPrefix: string = `#table-${opt.title.split(" ").join("-")}`;

  const tableActionHeading = opt?.actionOptions
    ? [opt?.actionOptions?.name || "Action"]
    : [];

  const headings: string[] = [...opt.headings, ...tableActionHeading];

  const tableData: Tdata[] = opt.tableData || [];

  const [paginationState, setPaginationState] = useState<TBF.T_PaginationState>(
    () => {
      return {
        currentPage: opt.initialPage || 0,
        rowsPerPage: opt.initialRowsPerPage || 10,
      };
    }
  );

  const [visibleTableData, setVisibleTableData] = useState<Tdata[]>([]);
  const [filteredTableData, setFilteredTableData] =
    useState<Tdata[]>(tableData);

  const onPaginateCallback = (rowsPerPage: number, currentPage: number) => {
    setPaginationState({ currentPage, rowsPerPage });
  };

  /**
   * Updates the table data when the data prop changes
   */

  useEffect(() => {
    // Check if there's filtered data, otherwise use the original tableData
    const dataToDisplay =
      filteredTableData.length > 0 ? filteredTableData : tableData;
    // const dataToDisplay =
    //   filteredTableData.length > 0 ? filteredTableData : tableData;
    // console.log(filteredTableData);
    // Apply pagination on the correct data set
    const slicedData =
      Array.isArray(dataToDisplay) && dataToDisplay.length > 0
        ? dataToDisplay.slice(
            paginationState.currentPage * paginationState.rowsPerPage,
            (paginationState.currentPage + 1) * paginationState.rowsPerPage
          )
        : [];

    setVisibleTableData(slicedData);
  }, [tableData, filteredTableData, paginationState]);

  // Update the search callback to update the filteredTableData

  const commonTableProps: TCF.T_CommonTableComponentProps = {
    classes,
    keyPrefix,
    columnCount: headings.length,
    paginationState,
    isLoading: opt.isLoading,
    hasAction: !!opt?.actionOptions,
  };

  const searchPluginProps: T_TableSearchPluginProps<Tdata> = {
    ...commonTableProps,
    searchKeys: opt.searchOptions?.searchKeys || [],
    tableData,
    searchFnCallback: (filteredData: Tdata[]) => {
      // console.log("searchFnCallback", filteredData);
      setFilteredTableData(filteredData); // Update the filtered data state
      setPaginationState((prevState) => ({ ...prevState, currentPage: 0 })); // Reset to the first page after searching
    },
  };

  // const getMenuItemOptionsFn = () => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => [{}], 2000);
  //   });
  // };

  const headingProps: TBF.T_TableHeadingsProps = {
    ...commonTableProps,
    headings,
  };

  const titleProps: TBF.T_TableTitleProps = {
    ...commonTableProps,
    title: opt.title,
  };

  const paginatorProps: TBF.T_TablePaginatorProps = {
    ...commonTableProps,
    paginationState,
    recordsCount: tableData.length,
    onPaginateCallback,
    rowsPerPageOptions: opt.rowsPerPageOptions,
  };

  // const searchPluginProps: T_TableSearchPluginProps<Tdata> = {
  //   ...commonTableProps,
  //   searchKeys: opt.searchOptions?.searchKeys || [],
  //   tableData,
  //   searchFnCallback: (filteredData: Tdata[]) => {
  //     setVisibleTableData(filteredData);
  //   },
  // };

  const downloadPluginProps: T_DownloadPluginProps = {
    ...commonTableProps,
    fileName: opt.downloadOptions?.fileName || "table-data",
    dataMap: opt.downloadOptions?.dataMap || {},
    columnValueParser: opt.downloadOptions?.columnValueParser,
    getDataFn:
      opt.downloadOptions?.getDataFn ||
      (async (): Promise<any> => {
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve(tableData), 1000);
        });
      }),
  };

  const pluginProps: TBF.T_TablePluginProps = {
    classes,
    search: searchPluginProps,
    download: downloadPluginProps,
  };

  const rowActionPluginProps: TDF.T_TableRowActionProps = {
    ...commonTableProps,
    ...(opt?.actionOptions || {}),
  };

  const tableBreadcrumbsProps: TBF.T_TableBreadcrumbsProps = {
    ...commonTableProps,
    ...(opt?.tableBreadcrumbsOptions || { breadcrumbs: [] }),
  };

  const tableBodyContentProps: TBF.T_TableContentProps = {
    ...commonTableProps,
    renderedTableRows: visibleTableData.map(
      (rowData, index): React.JSX.Element[] => {
        // console.log(rowData);
        const renderedRows: React.JSX.Element[] = opt.renderFunction(rowData);
        if (opt?.actionOptions) {
          renderedRows.push(
            <TDF.TableRowAction {...rowActionPluginProps} rowData={rowData} />
          );
        }
        return renderedRows;
      }
    ),
  };

  return DefaultTableView({
    isLoading: opt.isLoading,
    titleProps,
    headingProps,
    paginatorProps,
    pluginProps,
    tableBodyContentProps,
    rowActionPluginProps,
    tableBreadcrumbsProps,
  });
};
