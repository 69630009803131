import React from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LandingPageHeader from "app/components/unsorted/LandingPageHeader";
import { pallete, styles } from "app/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../Style";
// import { styles } from 'app/styles';

const certificates = [
  {
    certificates_No: "QCC/355E/0524",
    logo: "/assets/certificates/iso-9001.png",
  },
  {
    certificates_No: "CIS/6C54/0524",
    logo: "/assets/certificates/ISO-27032.png",
  },
  {
    certificates_No: "ITST/740D/0524",
    logo: "/assets/certificates/ISO-27017.png",
  },
  {
    certificates_No: "PII/AE52/0524",
    logo: "/assets/certificates/ISO-2018.png",
  },
  {
    certificates_No: "QCC/3BDA/0524",
    logo: "/assets/certificates/iso-27001.png",
  },
  // {
  //   // certificates_No: "QCC/3BDA/0524",
  //   // logo: "",
  // },
];

const handleCheckCertificates = () => {
  window.open(`https://qccertification.com/Client`);
};

const Certificates = () => {
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.aboutBox}>
        <Typography color="primary" variant="h1" align="center">
          Certificates
        </Typography>
        <Container
          sx={{
            display: "flex",
            gap: 7,
            alignItems: "center",
            marginBottom: 10,
            marginTop: 10,
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {certificates.map((certificate, index) => (
            <Box
              key={index} // Ensure each rendered element has a unique key
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                component="img"
                sx={{ height: 100 }}
                src={certificate.logo}
                alt="Certificate Logo"
              />
              <Typography color={"black"}>Certificate No:</Typography>
              <Typography color={"black"}>
                {certificate.certificates_No}
              </Typography>
            </Box>
          ))}
          <Box
            component="img"
            sx={{ height: 100, borderRadius: "50%", marginBottom: "auto" }}
            src="/assets/certificates/STAR-Level-1.png"
            alt="Certificate Logo"
          />
        </Container>
        {/* <Typography onClick={() => handleCheckCertificates} color="black" variant="h4">check certificate</Typography> */}
        <Button
          onClick={handleCheckCertificates}
          color="primary"
          variant="contained"
          style={{ textAlign: "center", textTransform: "none" }}
        >
          certification verification
        </Button>
      </Box>
    </>
  );
};

export default Certificates;
