import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { RQ } from "app/hooks";
import { T_UseWallethubPaymentState } from "./WallethubPaymentHooks";
import { createAPIURL } from "app/config";
import { useLoader, useNotification } from "app/providers";
import { AnimatedOtpInput } from "app/components/inputs";

type T_ViewProps = {
  paymentState: T_UseWallethubPaymentState;
};

const otpSchema = Yup.object().shape({
  otp: Yup.string().required("Otp is required"),
});

export const VerifyOTP = ({ paymentState }: T_ViewProps) => {
  const { classes } = useStyles();
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("countdownTime");
    return savedTime ? parseInt(savedTime, 10) : 60;
  });
  const loader = useLoader();
  const notification = useNotification();
  const verifyOTP = RQ.usePostRequest({
    url: createAPIURL("wallet-hub-transaction-request", [
      paymentState.transactionId,
      "verify-otp",
    ]),
  });

  const resendOTP = RQ.usePostRequest({
    url: createAPIURL("wallet-hub-transaction-request", [
      paymentState.transactionId,
      "resend-otp",
    ]),
  });

  const sendOtp = async () => {
    loader.show();
    await resendOTP.EXECUTE({
      onSuccessFn: () => {
        setTimeLeft(60);
        notification.success("OTP sent successfully");
      },
    });
    loader.hide();
  };

  const verifyOtp = async (value: any) => {
    loader.show();
    const payload = { otp_code: value.otp };
    await verifyOTP.EXECUTE({
      payload,
      onSuccessFn: (data, message) => {
        notification.success("OTP verified successfully");
        paymentState.update({ isOtpVerified: true });
      },
      onErrorFn: (data, message) => {
        notification.error(message);
      },
    });
    loader.hide();
  };

  const goBack = () => {
    paymentState.update({ isConfirmed: false });
  };

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft <= 0) return;

    // Save timeLeft to localStorage on change
    localStorage.setItem("countdownTime", timeLeft.toString());

    // Decrease timeLeft by 1 every second
    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const showTimer = () => {
    if (timeLeft === 0) {
      return null;
    } else if (timeLeft < 10) {
      return "00:0" + timeLeft + "Sec";
    } else {
      return "00:" + timeLeft + "Sec";
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    handleSubmit: () => {}
  ) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Formik
      initialValues={{
        otp: "",
      }}
      validationSchema={otpSchema}
      onSubmit={(values: any) => verifyOtp(values)}
    >
      {({ errors, touched, handleChange, handleSubmit, values }: any) => (
        <Box
          className={classes.formBox}
          onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
        >
          <Typography
            variant="h3"
            align="center"
            className={classes.formHeading}
          >
            One time password
            <br /> verification
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            align="center"
            sx={{ fontWeight: "500" }}
          >
            We have sent a OTP to your email
          </Typography>
          <Box className={classes.otpBoxInput}>
            <AnimatedOtpInput
              value={values.otp}
              onChange={handleChange("otp")}
              numInputs={6}
              shouldAutoFocus={true}
            />
          </Box>
          {touched.otp && errors.otp && (
            <Typography
              variant="subtitle2"
              align="center"
              className={classes.errorMsg}
            >
              {errors.otp}
            </Typography>
          )}
          <Box className={classes.btnsBox}>
            <Button
              onClick={() => goBack()}
              variant="outlined"
              className={classes.submitBtnOutloined}
            >
              Back
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              className={classes.submitBtn}
            >
              Submit
            </Button>
          </Box>
          <Box className={classes.otpBox}>
            <Typography
              variant="body2"
              color="primary"
              align="center"
              className={
                timeLeft < 1 ? classes.resentOtpText : classes.resentOtpTextD
              }
              onClick={() => (timeLeft < 1 ? sendOtp() : null)}
            >
              Resent OTP
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              sx={{ margin: "auto 20px", width: "50px" }}
            >
              {showTimer()}
            </Typography>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    otpBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    otpBoxInput: {
      ...styles.flexDRS,
      justifyContent: "center",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },

    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    resentOtpTextD: {
      textDecorationLine: "underline",
      padding: "20px 0",
      color: "grey",
    },
    inputBoxes: {
      // backgroundColor:"pink",
      // height:"40px",
      // width:"40px",
      color: pallete.primaryBlack,
      padding: "10px",
      margin: "20px 10px",
      boxSizing: "content-box",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px 0",
      textTransform: "none",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      // alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
