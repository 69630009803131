import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { DashboardCardSecondary } from "./Cards";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import TransactionsTable from "./TransactionTable";
import SelectToken from "./SelectToken";
import PercentageSlider from "./SliderPercent";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import useTimer from "app/providers/Timer";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import { RefreshRounded } from "@mui/icons-material";
import { useUserWalletQuery } from "app/queries/UserWalletQueries";
import { usePermissions } from "../../hooks";
// import { Convert } from "easy-currencies/dist/converter";

const form_schema = Yup.object().shape({
  amount: Yup.string()
    .matches(
      /^(?!0\d)\d*(\.\d+)?$/,
      "Only positive digits and decimals are allowed"
    )
    .required("Amount is required"),
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type FormType = "buy" | "sell" | "review" | "complete";

export function CustomTabPanel(props: TabPanelProps) {
  const { classes } = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const handleTelegramClick = () => {
  window.open(`https://t.me/${process.env.REACT_APP_OCT_CONTACT_NUMBER}`);
};
const handleWhatsappClick = () => {
  window.open(`https://wa.me/${process.env.REACT_APP_OCT_CONTACT_NUMBER}`);
};

export default function BasicTabs() {
  const _permission = usePermissions();
  const { classes } = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [currency, setCurrency] = useState([]);
  const [assets, setAssets] = useState([]);
  const [sellAmount, setSellAmount] = useState("");
  const [bestPrice, setBestPrice] = useState<any>("");
  const [userDetails, setUserDetails] = useState<any>({});
  const [reqBestPrice, setReqbestPrice] = useState<any>({});
  const [exchangerate, setExchangerate] = useState<any>({});
  const [valueS, setValueS] = useState({
    asset: {
      name: "",
      id: "",
      symbol: "",
      fireblocks_asset_id: "",
    },
    curr: {
      name: "",
      id: "",
      symbol: "",
    },
  });
  const userWallet = useUserWalletQuery();

  // const euroBest =  Convert(bestPrice).from("USD").to("EUR");
  // console.log(valueS.curr.name);
  const handleCompletion = () => {
    console.log("timer ends");
  };

  const { timeLeft, start, pause } = useTimer(4, handleCompletion);

  const [buySellCard, setBuySellCard] = useState<{
    [key in FormType]: boolean;
  }>({
    buy: false,
    sell: true,
    review: false,
    complete: false,
  });

  // console.log(buySellCard);

  const switchPage = (pageName: FormType) => {
    setBuySellCard((current) => ({
      ...Object.keys(current).reduce((acc, key) => {
        acc[key as FormType] = false; // Set all pages to false
        return acc;
      }, {} as { [key in FormType]: boolean }),
      [pageName]: true, // Set the specified page to true
    }));
  };

  useEffect(() => {
    getAssets();
    getAllCurrency();
    getUserDetails();
    liveExchangeRate();
  }, []);

  const getPram = (name: string) => {
    if (name === "Bitcoin") {
      return "bitcoin";
    } else if (name === "Etherum") {
      return "ethereum";
    } else {
      return "tether";
    }
  };

  useEffect(() => {
    if (valueS.asset.name) {
      const createRqData = {
        postUrl: { ccy1: valueS.asset.fireblocks_asset_id, ccy2: "USD" },
        crypto: getPram(valueS.asset.name),
        coincap: getPram(valueS.asset.name),
      };
      setReqbestPrice(createRqData);
      makeRequests(createRqData);
    }
  }, [valueS]);

  const POST_URL = "https://api-pub.bitfinex.com/v2/calc/fx";
  // const GET_URL_1 = "https://api.binance.com/api/v3/avgPrice?symbol=BTCUSDT";
  const GET_URL_2 = "https://price-api.crypto.com/price/v1/token-price/";
  const GET_URL_3 = "https://api.coincap.io/v2/assets/";

  const handleReload = () => {
    makeRequests(reqBestPrice);
    start();
  };

  const findMinimumPrice = (prices: { [key: string]: number }): number => {
    let minPrice: number = Infinity;
    for (const value of Object.values(prices)) {
      if (value < minPrice) {
        minPrice = value;
      }
    }

    return minPrice;
  };

  const makeRequests = (reqData: any) => {
    setLoading(true);
    // const postData = reqData.postUrl;
    // console.log(reqData.crypto);
    // console.log(reqData.coincap);
    Promise.all([
      // axios.post(POST_URL, postData),
      // axios.get(GET_URL_1),
      axios.get(GET_URL_2 + reqData.crypto),
      axios.get(GET_URL_3 + reqData.coincap),
      // axios.get(GET_URL_3 + reqData.coincap),
    ])
      .then((responses) => {
        const [getResponse1, getResponse2] = responses;
        // console.log("Post response 1:", postResponse.data);
        console.log("Get response 2:", getResponse1.data.usd_price);
        console.log("Get response 3:", getResponse2.data);
        // console.log("Get response 3:", getResponse3.data);
        const priceObj = {
          // p1: parseInt(postResponse.data.price),
          p2: +parseFloat(getResponse1.data.usd_price).toFixed(4),
          p3: +parseFloat(getResponse2.data.data.priceUsd).toFixed(4),
        };

        const minPrice: number = findMinimumPrice(priceObj);
        console.log(minPrice);

        setBestPrice(minPrice);
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const getAllCurrency = async () => {
    setLoading(true);
    await getAxiosAPI("/currency")
      .then((res: any) => {
        setCurrency(res.data.data);
        // console.log("helo", res);
        if (res.data.data[0].symbol === "usd") {
          setValueS((pre: any) => ({ ...pre, curr: res.data.data[0] }));
        } else {
          setValueS((pre: any) => ({ ...pre, curr: res.data.data[1] }));
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getUserDetails = async () => {
    await getAxiosAPI("/user-account/details", true)
      .then((res: any) => {
        // console.log(res.data.data);
        setUserDetails(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const liveExchangeRate = async () => {
    try {
      const res = await axios.get(
        "https://api.exchangerate-api.com/v4/latest/usd"
      );
      // const res = await getAxiosAPI(
      //   "https://api.exchangerate-api.com/v4/latest/usd",
      //   true
      // );
      // console.log(res.data.rates.EUR);
      setExchangerate(res.data.rates.EUR);
    } catch (err) {
      console.log(err);
    }
  };

  const getAssets = async () => {
    await getAxiosAPI("/asset", true)
      .then((res: any) => {
        setAssets(res.data.data);
        // console.log(res.data.data);
        setValueS((pre: any) => ({ ...pre, asset: res.data.data[0] }));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  // console.log(getAssets())
  const getSelectedValues = (type: string, value: any) => {
    console.log("value : ", value);
    console.log(type);

    if (type === "asset") {
      console.log(value);
      setValueS((pre: any) => ({ ...pre, asset: value }));
    } else {
      // if (value.name === "Euro") {
      //   const currEuro =
      //   setValueS((pre: any) => ({ ...pre, curr: value }));
      // } else {
      // }
      console.log(value);
      setValueS((pre: any) => ({ ...pre, curr: value }));
    }
  };
  // console.log(getSelectedValues());
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTrade = async () => {
    pause();
    setLoading(true);
    const payload = {
      // user_account_id: decode.userid,
      asset_id: valueS.asset.id,
      currency_id: valueS.curr.id,
      quantity: parseFloat(sellAmount),
      type: 0,
    };
    await postAxiosAPI("/trade", JSON.stringify(payload), true)
      .then((res: any) => {
        setLoading(false);
        console.log(res);
        if (res.data.code !== 200) {
          setSeverity("error");
          setSnackbarMessage(res.data.message);
          setShowSnackbar(true);
        } else {
          switchPage("complete");
          setSnackbarMessage("Sell completed successfully");
          setShowSnackbar(true);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setSeverity("error");
        setSnackbarMessage("Something went wrong!");
        setShowSnackbar(true);
      });
  };

  const handleOtcClick = () => {
    window.open(`https://wa.me/${process.env.REACT_APP_OCT_CONTACT_NUMBER}`);
  };

  // dollar to euro convert
  // console.log(exchangerate);

  const MainForm = () => {
    return (
      <Card className={classes.buysellCard}>
        <Box className={classes.buysellBtnBox}>
          <Button disabled={true} className={classes.btnsBSActive}>
            Buy
          </Button>
          <Button onClick={() => switchPage("sell")} className={classes.btnsBS}>
            Sell
          </Button>
        </Box>
        <Box className={classes.selectTokenBox}>
          <SelectToken
            lable="Asset"
            data={assets}
            type="asset"
            getSelectedValues={getSelectedValues}
            value={valueS}
            isMarginRequired={true}
          />
          <SelectToken
            lable="Currency"
            data={currency}
            type="currency"
            getSelectedValues={getSelectedValues}
            value={valueS}
            isMarginRequired={true}
          />
        </Box>
        <Box className={classes.bottomDataBox}>
          <Box className={classes.piceBOX}>
            <Box>
              <Typography variant="h6">Best price</Typography>
              <Typography variant="h2">
                {valueS.curr.name === "Euro"
                  ? `€ ${(bestPrice * exchangerate).toFixed(4)}`
                  : `$ ${bestPrice}`}
                {/* $ {bestPrice} */}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#B9B9B9", margin: "auto 8px" }}
                >
                  Binance
                </Typography>
                <Box component="img" src="assets/icons/binance.png" />
              </Box>
              <Box className={classes.card2Box1}>
                <Box className={classes.plusRating}>
                  <Typography
                    variant="subtitle1"
                    className={classes.ratingAmtP}
                  >
                    + 1.01%
                  </Typography>
                </Box>
              </Box>
            </Box> */}
          </Box>
          {/* INPUT  */}
          <OutlinedInput
            placeholder="Amount"
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">ETH</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            className={classes.inputOutlined}
          />
          <PercentageSlider />
          <OutlinedInput
            placeholder="Amount"
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            className={classes.inputOutlined}
          />
          <Button
            variant="contained"
            className={classes.tradeBtn}
            onClick={() => {
              setSnackbarMessage("Buy Now feature is comming soon!");
              setShowSnackbar(true);
            }}
          >
            Buy Now
          </Button>
        </Box>
      </Card>
    );
  };

  const SellCard = () => {
    return (
      <Formik
        initialValues={{
          amount: "",
        }}
        validationSchema={form_schema}
        onSubmit={(values: any) => {
          console.log(values);
          setSellAmount(values.amount);
          switchPage("review");
          start();
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
        }: any) => (
          <Card className={classes.buysellCard}>
            <Box className={classes.buysellBtnBox}>
              <Button
                // disabled={true}
                onClick={() => switchPage("buy")}
                className={classes.btnsBS}
              >
                Buy
              </Button>
              <Button className={classes.btnsBSActive}>Sell</Button>
            </Box>
            <Box className={classes.selectTokenBox}>
              <SelectToken
                lable="Asset"
                data={assets}
                type="asset"
                getSelectedValues={getSelectedValues}
                value={valueS}
                isMarginRequired={true}
              />
              <SelectToken
                lable="Currency"
                data={currency}
                type="currency"
                getSelectedValues={getSelectedValues}
                value={valueS}
                isMarginRequired={true}
              />
            </Box>
            <Box className={classes.bottomDataBox}>
              <Box className={classes.piceBOX}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Wallet Balance</Typography>
                    <IconButton
                      sx={{
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        userWallet.refetch();
                      }}
                    >
                      <RefreshRounded fontSize="small" />
                    </IconButton>
                  </Box>
                  <Typography variant="h2">
                    {userWallet.result?.find(
                      (item: any) => item.asset_name === valueS.asset.name
                    )?.balance || 0}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">Best Price</Typography>
                  <Typography variant="h2">
                    {valueS.curr.name === "Euro"
                      ? `€ ${(bestPrice * exchangerate).toFixed(4)}`
                      : `$ ${bestPrice}`}
                  </Typography>
                </Box>
              </Box>
              {/* <Typography variant="body2" color="primary">
                we are supporting crypto conversion to USDT first
              </Typography> */}
              {/* INPUT  */}
              <OutlinedInput
                placeholder="Quantity"
                id="outlined-adornment-weight"
                // endAdornment={
                //   <InputAdornment position="end">$</InputAdornment>
                // }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputOutlined}
                value={values.amount}
                onChange={handleChange("amount")}
                onBlur={handleBlur("amount")}
              />
              {touched.amount && errors.amount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.amount}
                </Typography>
              )}
              {/* <PercentageSlider />
          <OutlinedInput
            placeholder="Amount"
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            className={classes.inputOutlined}
          /> */}
              <Button
                variant="contained"
                className={classes.tradeBtn}
                onClick={() =>
                  _permission.canCreateAndUpdate()
                    ? handleSubmit()
                    : _permission.warn()
                }
              >
                Sell Now
              </Button>
            </Box>
          </Card>
        )}
      </Formik>
    );
  };

  const TransectionData = () => {
    const total: number = parseFloat(sellAmount) * parseFloat(bestPrice);
    const fees: number = total * (+userDetails.trade_fees / 100);
    return (
      <Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Instrument
          </Typography>
          <Typography variant="h6" color="primary">
            BTCUSD_SPOT
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Direction
          </Typography>
          <Typography variant="h6" color="primary">
            {buySellCard.buy ? "Buy" : "Sell"}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Amount
          </Typography>
          <Typography variant="h6" color="primary">
            {sellAmount} {valueS.asset.name}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Price
          </Typography>
          <Typography variant="h6" color="primary">
            {valueS.curr.name === "Euro"
              ? `€ ${(bestPrice * exchangerate).toFixed(4)}`
              : `$ ${bestPrice}`}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Fees
          </Typography>
          <Typography variant="h6" color="primary">
            {/* $ {fees.toFixed(4)} */}
            {valueS.curr.name === "Euro"
              ? `€ ${(fees * exchangerate).toFixed(4)}` // Perform multiplication first, then use toFixed
              : `$ ${fees.toFixed(4)}`}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Total
          </Typography>
          <Typography variant="h6" color="primary">
            {/* ${total} */}

            {valueS.curr.name === "Euro"
              ? `€ ${(total * exchangerate).toFixed(4)}` // Perform multiplication first, then use toFixed
              : `$ ${total.toFixed(4)}`}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Expiry
          </Typography>
          <Typography variant="h6" color="primary">
            {timeLeft} seconds
          </Typography>
        </Box>
      </Box>
    );
  };

  const ReviewTransaction = () => {
    return (
      <Card className={classes.buysellCardR}>
        <Typography
          sx={{ padding: "10px 0" }}
          variant="h4"
          color="primary"
          align="center"
        >
          Review and execute
        </Typography>
        <TransectionData />
        {timeLeft <= 0 ? (
          <Typography align="center" className={classes.errorMsg}>
            Transaction time expired!
          </Typography>
        ) : null}
        <Box className={classes.flexRow}>
          <Button
            onClick={() => switchPage("sell")}
            className={classes.tradeBtn0}
          >
            Cancel
          </Button>
          {timeLeft <= 0 ? (
            <Button
              className={classes.tradeBtn1}
              onClick={() => handleReload()}
            >
              Reload
            </Button>
          ) : (
            <Button className={classes.tradeBtn1} onClick={() => handleTrade()}>
              {buySellCard.buy ? "Buy" : "Sell"} {sellAmount}{" "}
              {valueS.asset.name}
            </Button>
          )}
        </Box>
      </Card>
    );
  };

  const CompleteTransations = () => {
    return (
      <Card className={classes.buysellCardR}>
        <Box className={classes.successImgBox}>
          <Box
            component="img"
            src="assets/icons/successGreen.svg"
            sx={{ height: "80px", width: "80px" }}
          />
        </Box>
        <Typography
          sx={{ padding: "10px 0" }}
          variant="h4"
          color="primary"
          align="center"
        >
          Order executed
        </Typography>
        <TransectionData />
        <Box>
          <Button
            onClick={() => switchPage("sell")}
            variant="contained"
            className={classes.tradeBtn}
          >
            Done
          </Button>
        </Box>
      </Card>
    );
  };

  const renderForm = () => {
    const activePage: any = Object.keys(buySellCard).find(
      // @ts-ignore
      (key: any) => buySellCard[key] === true
    );

    switch (activePage) {
      case "buy":
        return <MainForm />;
      case "sell":
        return <SellCard />;
      case "review":
        return <ReviewTransaction />;
      case "complete":
        return <CompleteTransations />;
      default:
        return <MainForm />;
    }
  };

  if (userWallet.isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          className={classes.topHeadingWeb}
        >
          Trading Page
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab className={classes.tabsText} label="Trade" {...a11yProps(0)} />
          <Tab className={classes.tabsText} label="Otc" {...a11yProps(1)} />
          <Tab
            className={classes.tabsText}
            label="Trading History"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <DashboardCardSecondary limit={8} mdSize={3} />
        <Grid container sx={{ ...styles.flexDRS }}>
          <Grid item md={4} sx={{ paddingTop: "10px" }}>
            {renderForm()}
          </Grid>
          <Grid item xs={12}>
            <TransactionsTable />
          </Grid>
        </Grid>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Box className={classes.otcBox}>
          <Card className={classes.otcGrid}>
            <Typography variant="body2">
              We prioritize transparency and efficiency in all our interactions.
              Rest assured, your experience with us will be characterized by
              seamless transactions, cutting-edge technology, and unparalleled
              support from our dedicated team. It's important to note that the
              minimum deposit amount to engage in trading activities is $50,000.
              This ensures that you have sufficient capital to initiate and
              manage your trades effectively.
            </Typography>
            <Box className={classes.octBtnBox}>
              <Button
                endIcon={
                  <TelegramIcon sx={{ color: pallete.selections.blue }} />
                }
                onClick={() => handleTelegramClick()}
                variant="contained"
                className={classes.otcBtn}
              >
                Telegram
              </Button>
              <Button
                endIcon={
                  <WhatsAppIcon sx={{ color: pallete.selections.green }} />
                }
                onClick={() => handleWhatsappClick()}
                variant="contained"
                className={classes.otcBtn}
              >
                WhatsApp
              </Button>
            </Box>
          </Card>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <TransactionsTable />
      </CustomTabPanel>
    </Box>
  );
}

const useStyles = makeStyles()((theme) => {
  return {
    octBtnBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    otcGrid: {
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
    },
    otcBtn: {
      ...styles.appButton,
      margin: "17px 3px 0px 3px",
    },
    otcBox: {},

    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    successImgBox: {
      ...styles.flexDCC,
      alignItems: "center",
    },
    flexRow: {
      ...styles.flexDRS,
      padding: "8px",
    },
    topCard1: {
      padding: "0 20px 10px 0",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    tradeBtn: {
      width: "100%",
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      textTransform: "none",
      borderRadius: "4px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22.4px",
      padding: "15px 0",
      marginTop: "20px",
      "&:hover": {
        backgroundColor: pallete.primaryPurple,
        color: pallete.primaryWhite,
      },
    },
    tradeBtn0: {
      width: "100%",
      color: pallete.primaryPurple,
      backgroundColor: pallete.primaryWhite,
      textTransform: "none",
      borderRadius: "4px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22.4px",
      padding: "15px 0",
      margin: "10px 3px",
    },
    tradeBtn1: {
      width: "100%",
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      textTransform: "none",
      borderRadius: "4px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22.4px",
      padding: "15px 0",
      margin: "10px 3px",
      "&:hover": {
        backgroundColor: pallete.primaryPurple,
        color: pallete.primaryWhite,
      },
    },
    inputOutlined: {
      width: "100%",
      margin: "10px 0",
    },
    grphnIcons: {
      height: "15px",
      width: "15px",
      padding: "2px",
    },
    ratingAmtN: {
      color: "#E13A05",
      fontWeight: 600,
    },
    ratingAmtP: {
      color: "#00E069",
      fontWeight: 600,
    },
    plusRating: {
      borderRadius: "4px",
      backgroundColor: "#E5FFF1",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    minusRating: {
      borderRadius: "4px",
      backgroundColor: "#FFF0EB",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    card2Box1: {
      ...styles.flexDRC,
      justifyContent: "space-between",
    },
    bottomDataBox: {
      padding: "25px",
    },
    piceBOX: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    selectTokenBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    btnsBS: {
      width: "100%",
      padding: "15px 5px",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: "#E0E3F2",
      },
    },
    btnsBSActive: {
      width: "100%",
      padding: "15px 5px",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      backgroundColor: "#E0E3F2",
    },
    buysellBtnBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "stretch",
    },
    buysellCard: {
      border: `1px soild ${pallete.primaryBlack}`,
      width: "150%",
      margin: "0 15px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      borderRadius: "8px",
      [theme.breakpoints.down("lg")]: {
        margin: "10px 0",
        width: "100%",
      },
    },
    buysellCardR: {
      border: `1px soild ${pallete.primaryBlack}`,
      width: "100%",
      margin: "0 15px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      borderRadius: "8px",
      padding: "15px 20px",
      [theme.breakpoints.down("lg")]: {
        margin: "10px 0",
      },
    },
    tabsText: {
      fontSize: "16px",
      fontWeight: 600,
      color: pallete.selections.blue,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
    },
    startIconBoxCal: {
      height: "24px",
      width: "24px",
    },
    commonCardsTable: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      width: "100%",
      margin: "20px 0",
    },
    startIconBox: {
      backgroundColor: pallete.primaryGreen,
      borderRadius: "50%",
      padding: "5px",
      height: "15px",
      width: "15px",
    },
    iconsBtn: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      // lineHeight: "140%",
      padding: "10px 20px",
      borderRadius: "8px",
    },
    startIconBoxEth: {
      borderRadius: "50%",
      // padding: "5px",
      height: "24px",
      width: "24px",
    },
    commonCardsHBox: {
      ...styles.flexDRS,
      padding: "20px 0",
    },
    commonCards: {
      padding: "12px",
      borderRadius: "8px",
      width: "100%",
      // height: "90%",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      [theme.breakpoints.down("lg")]: {
        margin: "10px 0",
      },
      margin: "10px 0",
    },
  };
});
