import React from "react";
import {T_TwoPaneLayoutProps, TwoPaneLayout,} from "app/components/layouts";
import {SignupRequestForm} from "./SignupRequestForm";
export const Signup = () => {

    const layoutProps:T_TwoPaneLayoutProps = {
        leftBoxProps:{
            bottomTextOptions:{
                text:"Already have an account?",
                routeName:"Sign in",
                routePath:"/login"
            },
        },
        rightBoxElement:<SignupRequestForm />
    };

    return (<TwoPaneLayout {...layoutProps} />);
};
