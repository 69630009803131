import React from "react";
import { TabContent } from "app/components/tabs";
import MoneyHubDashboardView from "./DashboardView";
import MoneyHubCredentialsView from "./MoneyHubCredentialsView";
import MoneyHubOrders from "./MoneyHubOrders";

const MoneyHubView = () => {
  const tabHeading: string = "MoneyHub";
  const tabData = [
    { label: "Dashboard", component: <MoneyHubDashboardView /> },
    { label: "Integration", component: <MoneyHubCredentialsView /> },
    { label: "Orders", component: <MoneyHubOrders /> },
  ];

  return TabContent({ tabData, tabHeading });
};

export default MoneyHubView;
