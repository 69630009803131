import React from "react";
import { QTradeQueries as QTRQ } from "app/queries";
import {
  DefaultTableComponent,
  T_DefaultTableComponentProps,
  TDF,
} from "app/components/table";
import { getStatusBadgePropsForTradeOrder } from "app/utils";

const renderFunction = (data: QTRQ.T_TradeOrder): React.JSX.Element[] => {
  const keyPrefix: string = `trade-order-${data.id}`;
  const statusBadgeProps = getStatusBadgePropsForTradeOrder(
    data?.status_id?.toString(),
    data.status
  );
  // console.log(data);
  return [
    <TDF.TextContent value={data.user_account} key={`${keyPrefix}-USR`} />,
    <TDF.TextContent value={`${data.currency}`} key={`${keyPrefix}-CURR`} />,
    <TDF.TextContent value={`${data.asset}`} key={`${keyPrefix}-AST`} />,
    <TDF.NumericContent value={`${data.quantity}`} key={`${keyPrefix}-QNT`} />,
    <TDF.NumericContent value={`${data.amount}`} key={`${keyPrefix}-AMT`} />,
    <TDF.NumericContent
      value={`${data.best_price}`}
      key={`${keyPrefix}-BSP`}
    />,
    <TDF.NumericContent value={`${data.fees}`} key={`${keyPrefix}-FEE`} />,
    <TDF.StatusBadge {...statusBadgeProps} key={`${keyPrefix}-STB`} />,
    <TDF.DateTimeContent dateTime={data.created_at} key={`${keyPrefix}-DTM`} />,
  ];
};

const tradeOrdersDataMap = {
  User: "user_account",
  Currency: "currency",
  Asset: "asset",
  Quantity: "quantity",
  Amount: "amount",
  "Best Price": "best_price",
  Fees: "fees",
  Status: "status",
  Date: "created_at",
};

type T_TradeOrdersProps = {
  setUserIdFn: (userId: string | null) => void;
};

export const TradeOrders = ({
  setUserIdFn,
}: T_TradeOrdersProps): React.JSX.Element => {
  const [tradeSearchParams, tradeSearchQuery] =
    QTRQ.useTradeOrdersSearchQuery();
  // console.log(tradeSearchQuery);
  const headings: string[] = Object.keys(tradeOrdersDataMap);
  const tradeOrders = tradeSearchQuery.isResolved
    ? tradeSearchQuery.result.records
    : [];
  const getMenuItemOptionsFn = async (
    data: QTRQ.T_TradeOrder
  ): Promise<TDF.T_MenuItemOptionList> => {
    return new Promise((resolve, reject) => {
      resolve([
        {
          name: "All User Trades",
          onClickHandler: () => setUserIdFn(data.user_id),
        },
      ]);
    });
  };

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Trades",
    headings: headings,
    isLoading: tradeSearchQuery.isResolving,
    initialRowsPerPage: 10,
    tableData: tradeOrders,
    actionColumn: true,

    renderFunction: renderFunction,
    searchOptions: {
      searchKeys: [
        "user_account",
        "asset",
        "currency",
        "status",
        "created_at",
        "amount",
        "best_price",
        "fees",
        "quantity",
      ],
    },
    downloadOptions: {
      fileName: "trade-orders.csv",
      dataMap: tradeOrdersDataMap,
    },
    actionOptions: { getMenuItemOptionsFn },

    // tableBreadcrumbsOptions: {
    //   breadcrumbs: [
    //     { name: "Trade Orders", onClickHandler: () => setUserIdFn(null) },
    //   ],
    // },
  };

  return <DefaultTableComponent {...tableComponentProps} />;
};
