import {Environment} from "app/config";

const CryptoJS = require("crypto-js");

/**
 * A utility class for encryption and decryption using AES algorithm.
 */
class Cipher {


    /**
     * Encrypts the provided data using AES algorithm.
     * @param data The data to be encrypted.
     * @param secretKey
     * @returns The encrypted ciphertext.
     */
    public static encrypt(data: any, secretKey: string = Environment.SECRET_KEY): string {
        const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
        return ciphertext;
    }

    /**
     * Decrypts the provided ciphertext using AES algorithm.
     * @param data The ciphertext to be decrypted.
     * @param secretKey
     * @returns The decrypted data.
     */
    public static decrypt(data: any, secretKey: string = Environment.SECRET_KEY): any {
        const bytes = CryptoJS.AES.decrypt(data, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }


    /**
     * creates a MD5 has for the provided input string.
     * @param inputString The string to be hashed.
     * @returns The decrypted data.
     */
    public static MD5Hash(inputString: string): string {
        return CryptoJS.MD5(inputString).toString();
    }


}

export default Cipher;
