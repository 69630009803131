import {useForm} from "./useForm";
import {FormInput, FormSubmitButton} from "./FormComponents";
import {FormHeading, FormProvider} from "./FormWrappers";

export const FORM = {
    useForm,
    Provider: FormProvider,
    Heading: FormHeading,
    Input: FormInput,
    SubmitButton: FormSubmitButton,
}
