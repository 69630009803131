import React, {useState} from "react";
import {IconButton, Tooltip as MuiTooltip} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type T_CopyToClipboardButtonProps = {
  fieldName: string;
  fieldValue: any;
};

export const CopyToClipboardButton = ({
  fieldName,
  fieldValue,
}: T_CopyToClipboardButtonProps): React.JSX.Element => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const tooltipStatusText = isCopied ? "Copied!" : "Copy";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(fieldValue).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <MuiTooltip
      title={`${tooltipStatusText} ${fieldName}`}
      arrow
      sx={{ padding: "0px" }}
    >
      <IconButton onClick={copyToClipboard}>
        <ContentCopyIcon />
      </IconButton>
    </MuiTooltip>
  );
};
