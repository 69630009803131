import React from "react";
import moment from "moment";

import { Avatar, Chip, Grid, Typography } from "@mui/material";
import {
  StyledTableCell,
  T_CommonTableComponentProps,
} from "./TableCommonFragments";
import { DropdownMenuButton, T_MenuItemOptionList } from "../../inputs/buttons";

export type T_CellDataCommonProps = {
  value: string | number;
  color?: string;
};
export type T_CellDataComponentProps = T_CellDataCommonProps & {
  variant?:
    | "body1"
    | "body2"
    | "subtitle1"
    | "subtitle2"
    | "caption"
    | "button"
    | "overline"
    | "inherit";
};

export const RawElement = ({
  element,
}: {
  element: React.JSX.Element;
}): React.JSX.Element => {
  //   console.log(element);
  return (
    <StyledTableCell align="left" keyPrefix={"tdf-text-content"}>
      {element}
    </StyledTableCell>
  );
};

type T_TextContentProps = T_CellDataCommonProps & {};

export const TextContent = ({
  value,
  color,
}: T_TextContentProps): React.JSX.Element => {
  color = color || "inherit";
  return (
    <StyledTableCell align="left" keyPrefix={"tdf-text-content"}>
      <Typography variant={"body1"} component={"span"} sx={{ color: color }}>
        {value}
      </Typography>
    </StyledTableCell>
  );
};

export type T_StatusBadgeProps = {
  statusColor?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "default";
  color?: string;
  statusText: string;
};

export const StatusBadge = ({
  statusText,
  color,
  statusColor,
}: T_StatusBadgeProps): React.JSX.Element => {
  color = color || "inherit";
  statusColor = statusColor || "default";

  return (
    <StyledTableCell align="left" keyPrefix={"tdf-status-badge"}>
      <Chip
        label={statusText}
        size="small"
        variant={"outlined"}
        color={statusColor}
        sx={{ color: color, textDecoration: "bolder" }}
      />
    </StyledTableCell>
  );
};

type T_DateTimeContentProps = {
  dateTime: string | undefined | null;
  format?: string;
};
export const DateTimeContent = ({
  dateTime,
  format,
}: T_DateTimeContentProps): React.JSX.Element => {
  format = format || "Do MMMM YYYY, h:mm A";
  const dateObject = moment(dateTime).format(format);

  return (
    <StyledTableCell align="left" keyPrefix={"tdf-date-time-content"}>
      <Typography variant={"body1"} component={"span"}>
        {dateObject !== "Invalid date" ? dateObject : "N/A"}
      </Typography>
    </StyledTableCell>
  );
};

type T_NumericContentProps = T_CellDataCommonProps & {};
export const NumericContent = ({
  value,
  color,
}: T_NumericContentProps): React.JSX.Element => {
  color = color || "inherit";
  return (
    <StyledTableCell align="left" keyPrefix={"tdf-numeric-content"}>
      <Typography variant={"body1"} component={"span"} sx={{ color: color }}>
        {value}
      </Typography>
    </StyledTableCell>
  );
};

export type T_UserInfoContentProps = {
  name?: string | null;
  email?: string | null;
  image?: string | null;
};
export const UserInfoContent = ({
  name,
  email,
  image,
}: T_UserInfoContentProps): React.JSX.Element => {
  return (
    <StyledTableCell align="left" keyPrefix={"tdf-user-info-content"}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <Avatar alt={`${name || ""} Avatar Image`} src={image ?? "#"}>
            H
          </Avatar>
        </Grid>
        <Grid item xs={8}>
          <Grid item>
            {name && (
              <Typography variant="body1" component="span">
                {name}
              </Typography>
            )}
          </Grid>
          {email && (
            <Typography
              variant="caption"
              component="span"
              color="textSecondary"
            >
              {email}
            </Typography>
          )}
        </Grid>
      </Grid>
    </StyledTableCell>
  );
};

export type T_TableRowActionOption<Tdata = any> = {
  name?: string;
  getMenuItemOptionsFn?: (data: Tdata) => Promise<T_MenuItemOptionList>;
};

export type T_TableRowActionProps<Tdata = any> = T_TableRowActionOption &
  T_CommonTableComponentProps & {
    rowData?: Tdata;
  };

export type { T_MenuItemOptionList };

export const TableRowAction = (
  opt: T_TableRowActionProps
): React.JSX.Element => {
  const dropdownMenuButtonProps = {
    keyPrefix: opt.keyPrefix,
    getMenuItemOptionsFn: opt.getMenuItemOptionsFn,
    rowData: opt.rowData,
  };
  return (
    <StyledTableCell align="center" keyPrefix={"tdf-row-actions-content"}>
      {DropdownMenuButton(dropdownMenuButtonProps)}
    </StyledTableCell>
  );
};
