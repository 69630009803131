import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
    .use(HttpApi) // Loads translation files over HTTP
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        supportedLngs: ['en', 'pt' , 'zh'], // Specify all supported languages
        fallbackLng: 'en', // Fallback language if the preferred one is not available
        debug: false, // Enable debug mode to see logs in the console

        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        },

        backend: {
            loadPath: '/languages/{{lng}}/{{lng}}.json',// Path where i18next will look for translations
        },

        react: {
            useSuspense: false
        }
    });

export default i18n;
