// MessageSnackbar.tsx

import React from "react";
import {MaterialDesignContent, OptionsWithExtraProps, SnackbarProvider, useSnackbar} from "notistack";
import {styled} from "@mui/material/styles";


type  T_NotOptions = OptionsWithExtraProps<any> & {
    showBar?: boolean;
    onClose?: () => void;
}

export type T_UseNotificationResult = {
    /**
     * Function to show a notification with the specified severity.
     * @param {string} message - The message to display in the notification.
     */
    show: (message: string, options?: T_NotOptions) => void;
    success: (message: string, options?: T_NotOptions) => void;
    info: (message: string, options?: T_NotOptions) => void;
    warning: (message: string, options?: T_NotOptions) => void;
    error: (message: string, options?: T_NotOptions) => void;
    close: () => void;
};

const useNotification = (): T_UseNotificationResult => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const close = () => {
        closeSnackbar();
    };

    const show = (message: string, options?: T_NotOptions) => {
        return enqueueSnackbar(message, {...options, variant: "default"});
    }

    const success = (message: string, options?: T_NotOptions) => {
        return enqueueSnackbar(message, {...options, variant: "success"})
    }

    const info = (message: string, options?: T_NotOptions) => {
        return enqueueSnackbar(message, {...options, variant: "info"})
    }

    const warning = (message: string, options?: T_NotOptions) => {
        return enqueueSnackbar(message, {...options, variant: "warning"})
    }

    const error = (message: string, options?: T_NotOptions) => {
        return enqueueSnackbar(message, {...options, variant: "error"})
    }
    return {show, success, info, warning, error, close};

}

/*
    https://notistack.com/features/customization#custom-component
 */
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#ebf5eb',
        color: "#3f8843"

    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#fbebeb',
        color: "#d54040"
    }, '&.notistack-MuiContent-warning': {
        backgroundColor: '#fdf2e3',
        color: "#ed7818"
    }, '&.notistack-MuiContent-info': {
        backgroundColor: '#e3f4fb',
        color: "#1892d4"
    },
}));

const NotificationProvider = (props: React.PropsWithChildren<{}>) => {
    return (
        <SnackbarProvider
            dense preventDuplicate
            autoHideDuration={5000}
            maxSnack={3}
            anchorOrigin={{vertical: 'bottom', horizontal: "center"}}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
            }}

        >
            {props.children}
        </SnackbarProvider>

    );
};


export {NotificationProvider, useNotification};
