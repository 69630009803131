import React from "react";
import {useParams} from 'react-router-dom';
import {useWallethubPaymentStyles} from "./WallethubPaymentStyles";
import {IntegrationView} from "../common";
import {AmountConfirmationView} from "./AmountConfirmation";
import {useWallethubPaymentState} from "./WallethubPaymentHooks";
import {VerifyOTP} from "./VerifyOTP";
import LivelinessVerification from "./LivelinessVerification";
import {InitiateTransactionView} from "./InitiateTransactionView";
import {TransactionStatusView} from "./TransactionStatusView";
import {redirectTo} from "app/utils"
import WallethubFormView from "./WallethubFormView";


export const WallethubPaymentView = (): React.JSX.Element => {
    const {transactionId, paymentId} = useParams();
    const {classes} = useWallethubPaymentStyles();
    const paymentState = useWallethubPaymentState(transactionId);


    const ActiveComponent = (): React.JSX.Element => {

        if (!transactionId) {
            return <WallethubFormView />
        }

        if (paymentState.isTransactionCompleted && paymentState.redirectUrl) {
            redirectTo({url: paymentState.redirectUrl});
            return <></>;
        }

        if (paymentId) {
            return <TransactionStatusView {...({classes, paymentState, paymentId})} />
        }

        if (!paymentState.isConfirmed) {
            return <AmountConfirmationView {...({classes, paymentState, transactionId})} />
        }

        if (!paymentState.isOtpVerified) {
            return <VerifyOTP paymentState={paymentState} />
        }

        if (!paymentState.isKycCompleted) {
            return <LivelinessVerification paymentState={paymentState} />
        }

        if (!paymentState.isTransactionInitiated) {
            return <InitiateTransactionView paymentState={paymentState} />
        }

        if (paymentState.transactionLink && paymentState.transactionLink.trim().length > 0) {
            redirectTo({url: paymentState.transactionLink});
        }

        return (<></>);
    }

    return (<IntegrationView activeComponent={ActiveComponent()} />);

}
