import React, { useState } from "react";
import {Button, FormControl, Grid, InputLabel, Menu, MenuItem, Select, TextField} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {Controller, SubmitHandler, useForm, UseFormReturn} from "react-hook-form";
import * as YUP from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from "react-i18next";
import {t} from "i18next";


export type T_TransactionDetail = {
    type: string;
    tx_hash: string ;
    quantity: number ;
    disabled: boolean;
}

type T_WalletDetailsFormProps = {
    onSubmitHandler: (values: T_TransactionDetail) => void
}

const TranslatedFormSchema = () => {
    const {t} = useTranslation();
    const walletDetailsFormSchema: YUP.ObjectSchema<T_TransactionDetail> = YUP.object({
        type: YUP.string().required(t("Transaction type is required.")),
        tx_hash: YUP.string()
            .min(6, t("Transaction hash should be minimum 6 characters."))
            .max(256, t("Transaction hash should be max 256 digits."))
            .trim(t("Transaction hash can't be empty spaces."))
            .required(t("Transaction hash is required.")),
        quantity: YUP.number().positive(t("Asset quantity should be greater than zero."))
            .min(0.000001, t("Asset quantity should be a non zero value."))
            .typeError(t('Asset quantity should be numeric value (upto 6 decimal).'))
            .required(t("Asset quantity is required.")),
        disabled: YUP.boolean().default(false)
    }).required();
    return walletDetailsFormSchema;
}


export const TransactionDetailsForm = ({onSubmitHandler}: T_WalletDetailsFormProps): React.JSX.Element => {
    const {t} = useTranslation();
    const walletDetailsFormSchema = TranslatedFormSchema();

    const {handleSubmit,control, formState:{errors},resetField,reset,setValue}: UseFormReturn<T_TransactionDetail> = useForm<T_TransactionDetail>({
        resolver:yupResolver(walletDetailsFormSchema),
        defaultValues:{
            type:'',
            quantity:0.00, 
            tx_hash:"",
            disabled:false
        }
    });

    const [selectedTransactionType, setSelectedTransactionType] = useState("");
    const handleTransactionTypeChange = (event:any) => {
        setSelectedTransactionType(event.target.value);
    };


    const onSubmit: SubmitHandler<T_TransactionDetail> = ({type, tx_hash,quantity}:T_TransactionDetail):void => {
        const parsed_quantity = quantity.toFixed(6).replace(/\.?0+$/, "");
        onSubmitHandler({type:type, tx_hash: tx_hash, quantity:parseFloat(parsed_quantity), disabled:false});
        setValue("type",'');
        setValue("tx_hash","");
        setValue("quantity",0.0);
    };

    const parseQuantity = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void =>{
        const value = e.target.value;
        if (Number(value) < 0 ){
            e.target.value = "0.00";
        }

        if(value.length > 8) {
            e.target.value =  Number(e.target.value).toFixed(7);
        }
    }

    const transaction_type = [
        { id: '1', name: "tx_hash", display_name: "Transaction Hash" },
        { id: '2', name: "source_address", display_name: "Source Wallet Address" },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{
            maxWidth: "100%",
        }}>
            <Grid container spacing={1} columns={{ xs: 12, md: 26 }} maxWidth={"100%"}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <FormControl fullWidth >
                                <InputLabel id="transaction-type-select">
                                    {t("Transaction Type")}
                                </InputLabel>
                                <Select
                                    labelId="transaction-type-select"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        handleTransactionTypeChange(e);
                                    }}
                                    value={field.value}
                                    label={"Transaction Type"}
                                    fullWidth={true}
                                    error={errors.type && Boolean(errors.type)}
                                    >
                                    {transaction_type.map((option) => (
                                        <MenuItem key={option.id} value={option.name}>
                                            {t(option.display_name)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Controller
                        name="tx_hash"
                        control={control}
                        rules={{required:true}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label={`${transaction_type.find(option => option.name === selectedTransactionType)?.display_name || ''} ${t("at least last 6 digits")}`}
                                fullWidth={true}
                                error={errors.tx_hash && Boolean(errors.tx_hash)}
                                helperText={errors.tx_hash?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <Controller
                        name="quantity"
                        control={control}
                        rules={{required:true}}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type={"number"}
                                label={t("Asset Quantity")}
                                inputProps={{
                                    maxLength: 10,
                                    step: "0.000001"
                                }}
                                onChange={(e)=>{
                                    parseQuantity(e);
                                    field.onChange(e);
                                }}
                                error={errors.quantity && Boolean(errors.quantity)}
                                helperText={errors.quantity?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <Button
                        variant="outlined" startIcon={<AddIcon/>}
                        size={"medium"} color={"info"}
                        sx={{padding:"0.8rem", width:"100%"}}
                        type={"submit"}
                    > {t("Add")} </Button>
                </Grid>
            </Grid>
        </form>
    )
}
