import React, {useRef} from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "../Style";

const team = [
  {
    name: "HADAR SHEMER",
    position: "Founder & CEO",
    img: "/assets/images/Hadarphoto.png",
    text: "I don't run away from a challenge because i am afraid. Indtead, I run toward it because the only way to escape fear is to trample it beneath your feet.",
    textBy: "— Nadia Comaneci.",
  },
  {
    name: "SAGI IFRACH",
    position: "Founder & CCO",
    img: "/assets/images/Sagiphoto.png",
    text: "You're only given one little spark of madness. You mustn't lose it.",
    textBy: "— Robin Williams.",
  },
  {
    name: "AVI ZEIGER",
    position: "Founder & CFO",
    img: "/assets/images/Aviphoto.png",
    text: "My passion for surfing was more than my fear of sharks.",
    textBy: "— Bethany Hamilton.",
  },
  {
    name: "ELI ASSIF",
    position: "Founder & CTO",
    img: "/assets/images/Eliphoto.png",
    text: "For a list of all the ways technology has failed to improve the quality of life, please press three.",
    textBy: "— Alice Kahn.",
  },
];
const AboutUs = () => {
  const { classes } = useStyles();
  
  // const aboutus = useRef(null);
  return (
    <>
     
      <Box className={classes.aboutBox}>
        <Typography color="primary" variant="h1" align="center">
          About Us
        </Typography>
        <Grid container className={classes.aboutGrid}>
          <Grid item lg={8}>
            <Typography
              color="primary"
              variant="body2"
              align="center"
              sx={{ padding: "20px 0" }}
            >
              TradeBP's passion is to make a positive impact in the financial
              sector. To give advanced services that achieve the future
              solutions. Our portfolio offers a dynamic and ever — changing set
              of financial services. EU regulated Company, holds a VASP
              registration by Bank of Portugal.
            </Typography>
          </Grid>
        </Grid>
        <Grid container lg={10} xs={12} className={classes.aboutGrid}>
          {team.map((x: any) => (
            <Grid item lg={3} sm={6} xs={12} key={x.name}>
              <Box className={classes.teamPBox}>
                <Box className={classes.temImgBox}>
                  <Box
                    component="img"
                    className={classes.teamImg}
                    src={x.img}
                    alt="Descriptive Alt Text"
                  />
                  <Box className={classes.teamHiddenText}>
                    <Typography variant="h6" align="center" color="white">
                      {x.text}
                    </Typography>
                    <Typography variant="h5" align="center" color="white">
                      {x.textBy}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography variant="h4" align="center" color="primary">
                    {x.name}
                  </Typography>
                  <Typography variant="h6" align="center" color="primary">
                    {x.position}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default AboutUs;
