import React from "react";
import Loader from "app/components/unsorted/Loader";
import {RQ} from "app/hooks";
import {T_ApiConfig} from "app/config";
import {useLoader} from "app/providers";
import {SumsubSdkView} from "./SumsubSdkView";


type T_InitiateKycQueryResponse = {
    access_token: string;
    kyc_is_completed: boolean;
    kyc_url?: string;
    expire_at: string;
}

export type T_OnExitParams = {
    isSubmitted:boolean;
    isInitiated:boolean;
    error?:string|undefined;
}

export type T_SumsubKycProcessViewProps = {
    initKycApiConfig: T_ApiConfig;
    onExitHandler: (args: T_OnExitParams) => void;
}

export const SumsubKycProcessView = (props: T_SumsubKycProcessViewProps): React.JSX.Element => {

    const {initKycApiConfig: {url, key: queryKey}, onExitHandler} = props;

    const loader = useLoader();

    const initiateKycQuery = RQ.usePostQuery<T_InitiateKycQueryResponse>({url, queryKey});


    const onSuccessFn = async (payload: any): Promise<void> => {
        onExitHandler({isSubmitted: true, isInitiated: false});
    }

    const onErrorFn = (error: any) => {
        onExitHandler({isSubmitted: false, isInitiated: false,error: error});
    }

    if (initiateKycQuery.isResolving) {
        return <Loader isLoading={true}/>
    }

    if (initiateKycQuery.isResolved) {
        loader.hide();
    }

    if (initiateKycQuery.error) {
        console.log({"kyc-initiate-error": initiateKycQuery.error})
    }

    const {access_token, kyc_is_completed, ...rest} = initiateKycQuery.result;

    return (<SumsubSdkView accessToken={access_token} onSuccess={onSuccessFn} onError={onErrorFn}/>);
}

