import React from "react";

import {PrivateApplication} from "./common";
import MANAGEMENT_VIEWS_MAPPING from "./management";
import CUSTOMER_VIEWS_MAPPING from "./customer";

const PRIVATE_VIEWS_MAPPING = {
    "/private-view": <PrivateApplication />,
    ...MANAGEMENT_VIEWS_MAPPING,
    ...CUSTOMER_VIEWS_MAPPING,
};

export default PRIVATE_VIEWS_MAPPING;
