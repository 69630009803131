import {pallete} from "./Theme";


export const styles: any = {
  flexDRC: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  flexDRS: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexDCC:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  homeButton:{
      borderRadius: 0,
      backgroundColor: pallete.primaryWhite,
      color: pallete.primaryPurple,
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "none",
      padding: "10px 30px",
      "&:hover": {
        color: pallete.primaryWhite,
        backgroundColor: pallete.primaryPurple,
      },
  },
  appButton:{
      borderRadius: "4px",
      color: pallete.primaryWhite,
      backgroundColor: pallete.primaryPurple,
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "none",
      padding: "10px 30px",
      "&:hover": {
        color: pallete.primaryWhite,
        backgroundColor: pallete.primaryPurple,
      },
  },
  webButton:{
      borderRadius: 4,
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "none",
      padding: "10px 30px",
      "&:hover": {
        color: pallete.primaryWhite,
        backgroundColor: pallete.primaryPurple,
      },
  }
};
