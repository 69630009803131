

export const messageEventTypes = {
    onApplicantSubmitted: "idCheck.onApplicantSubmitted",
    onApplicantStatusChanged: "idCheck.onApplicantStatusChanged"
}

export const reviewStatusNames = {
    pending: "pending",
    completed: "completed"
}