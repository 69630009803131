import {useState} from "react";
import {useSessionStorage} from "app/hooks";

type T_TransactionLink = {
    transaction_id: string;
    fireblocks_wallet_address: string;
}


export type T_FlexhubPaymentState = {
    transactionId: string;
    isOtpVerified: boolean;
    isTermsConfirmed: boolean;
    isTransactionInitiated: boolean;
    transactionLink: T_TransactionLink;
    isTransactionCompleted: boolean;
    redirectUrl:string;
}

export type T_UseFlexhubPaymentState = T_FlexhubPaymentState & {
    update: (newStatus: Partial<T_FlexhubPaymentState>) => void;
}


export const useFlexhubPaymentState = (transactionId:any): T_UseFlexhubPaymentState => {

    const dataKey: string = transactionId;

    const _storage = useSessionStorage<T_FlexhubPaymentState>(dataKey, 
        {
            transactionId: transactionId, 
            isOtpVerified: false, 
            isTermsConfirmed: false, 
            isTransactionInitiated:false, 
            transactionLink:null, 
            isTransactionCompleted:false,
            redirectUrl:''
        }
    );

    const [paymentState, setPaymentState] = useState<T_FlexhubPaymentState>(() => {
        return _storage.getValue();
    });


    const updatePaymentState = (newTransactionState: Partial<T_FlexhubPaymentState>): void => {

        const updatedPaymentState: T_FlexhubPaymentState = {
            ...paymentState, ...newTransactionState
        };
        _storage.setValue(updatedPaymentState);
        setPaymentState(updatedPaymentState);
    }

    return {...paymentState, update: updatePaymentState};

}
