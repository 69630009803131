import {InvalidTokenError, jwtDecode} from "jwt-decode";
import {getTokens} from "app/utils/http";


export const decodeToken = () => {
    const {accessToken} = getTokens()
    const decoded: any = decode_token(accessToken);
    return decoded
}

function decode_token(token: string) {
    try {
        return jwtDecode(token)
    } catch (error: any) {
        if (error instanceof InvalidTokenError) {
            localStorage.clear();
        } else {
            console.error("An unexpected error occurred:", error.message);
        }
    }
}

export const truncateAddress = (address: string) => {
    return address.substring(0, 12) + "..." + address.substring(address.length - 4);
}
