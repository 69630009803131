import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAuthManager } from "./auth";

const useInactivityLogout = (
  timeout: number = 180000,
  ignoredRoutes: string[] = []
) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  const authManager = useAuthManager();
  const location = useLocation();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const logout = () => {
      authManager.logoutUser(); // Call API
    };

    const resetTimer = () => {
      if (ignoredRoutes.some((ignoredPath) => location.pathname.startsWith(ignoredPath))) {
        return;
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        logout();
        setIsActive(false);
      }, timeout);
    };

    const handleActivity = () => {
      setIsActive(true);
      resetTimer();
    };

    const handleFocus = () => {
      if (!isActive) {
        setIsActive(true);
      }
      resetTimer();
    };

    // Register event listeners
    ["mousemove", "keydown", "click", "scroll", "touchstart"].forEach((event) =>
      window.addEventListener(event, handleActivity)
    );
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", resetTimer);

    resetTimer();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      ["mousemove", "keydown", "click", "scroll", "touchstart"].forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", resetTimer);
    };
  }, [authManager, timeout, ignoredRoutes, location.pathname]);

  return isActive;
};

export default useInactivityLogout;
