import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import * as Yup from "yup";
import dayjs from "dayjs";
import { SearchInput } from "app/components/inputs";
import { useNavigate } from "react-router-dom";

const form_schema = Yup.object().shape({
  amount: Yup.number().required("Amount is required"),
  apiKey: Yup.string().required("API Key is required"),
  dob: Yup.string()
    .required("Date of birth is required")
    .test("dob", "You must be at least 18 years old", (value) => {
      const today = dayjs();
      const dob = dayjs(value);
      const age = today.diff(dob, "year");
      return age >= 18;
    }),
  currency: Yup.string().required("Currency is required"),
  country: Yup.string().required("Country is required"),
  fullName: Yup.string().required("Full name is required"),
  redirectUrl: Yup.string().required("Redirect URL is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const WallethubFormView = () => {
  const { classes } = useStyles();
  const [currency, setCurrency] = useState([]);
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const getCurrency = async () => {
    setLoading(true);
    await getAxiosAPI("/currency")
      .then((res: any) => {
        setLoading(false);
        setCurrency(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error(err);
      });
  };

  const getCountry = async () => {
    setLoading(true);
    await getAxiosAPI("/country")
      .then((res: any) => {
        setLoading(false);
        setCountry(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const birthDate = values.dob.split("T")[0];
    const validity = formatDate(values.validTill);
    const payload = {
      user_email: values.email,
      api_key: values.apiKey,
      amount: parseFloat(values.amount),
      currency_id: values.currency,
      full_name: values.fullName,
      birthday: birthDate,
      country: values.country,
      redirect_url: values.redirectUrl,
      valid_till: validity,
    };
    await postAxiosAPI(
      "/wallet-hub-transaction-request",
      JSON.stringify(payload)
    )
      .then((res: any) => {
        setLoading(false);
        if (res.data.code === 200) {
          setShowSnackbar(true);
          setSeverity("success");
          setSnackbarMessage("Form submitted successfully");
          window.open(
            `/wallethub-payment/${res.data.data.transaction_uid}`,
            "_blank"
          );
        } else {
          setSeverity("error");
          setSnackbarMessage("Error submitting form");
          setShowSnackbar(true);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setSeverity("error");
        setSnackbarMessage("Error submitting form");
        setShowSnackbar(true);
        console.error(err);
      });
  };

  useEffect(() => {
    getCurrency();
    getCountry();
  }, []);

  return (
    <Box className={classes.mainBoxForm}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Typography variant="h4" gutterBottom>
        Another Form
      </Typography>
      <Formik
        initialValues={{
          email: "",
          apiKey: "",
          amount: 0.0,
          currency: "",
          fullName: "",
          dob: null,
          country: "",
          redirectUrl: "",
          validTill: "",
        }}
        validationSchema={form_schema}
        onSubmit={(values: any) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }: any) => (
          <Grid container>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                className={classes.inputBox}
                value={values.email}
                onBlur={handleBlur("email")}
                onChange={handleChange("email")}
              />
              {touched.email && errors.email && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.email}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                className={classes.inputBox}
                value={values.fullName}
                onBlur={handleBlur("fullName")}
                onChange={handleChange("fullName")}
              />
              {touched.fullName && errors.fullName && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.fullName}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Api Key"
                variant="outlined"
                className={classes.inputBox}
                value={values.apiKey}
                onBlur={handleBlur("apiKey")}
                onChange={handleChange("apiKey")}
              />
              {touched.apiKey && errors.apiKey && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.apiKey}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", margin: "10px 0" }}
                  label="Date Of Birth"
                  value={values.dob}
                  onChange={(newValue) => {
                    setFieldValue(
                      "dob",
                      newValue ? newValue.toISOString() : ""
                    );
                  }}
                />
              </LocalizationProvider>
              {touched.dob && errors.dob && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.dob}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                className={classes.inputBox}
                value={values.amount}
                onBlur={handleBlur("amount")}
                onChange={handleChange("amount")}
              />
              {touched.amount && errors.amount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.amount}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={currency}
                label="Select Currency"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="currency"
              />
              {touched.currency && errors.currency && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.currency}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={country}
                label="Select Country"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="country"
              />
              {touched.country && errors.country && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.country}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Redirect Url"
                variant="outlined"
                className={classes.inputBox}
                value={values.redirectUrl}
                onBlur={handleBlur("redirectUrl")}
                onChange={handleChange("redirectUrl")}
              />
              {touched.redirectUrl && errors.redirectUrl && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.redirectUrl}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Valid Till"
                name="dateTime"
                type="datetime-local" // Set type to datetime-local
                value={values.validTill}
                onBlur={handleBlur("validTill")}
                onChange={handleChange("validTill")}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                margin="normal"
              />
              {touched.validTill && errors.validTill && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.validTill}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={classes.gridItemPadding}>
              <Box className={classes.buttonBox}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  className={classes.submitButton}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainBoxForm: {
    width: "100%",
    maxWidth: "800px",
    margin: "0 auto",
    padding: theme.spacing(3),
    boxShadow: styles.boxShadow,
    borderRadius: theme.shape.borderRadius,
  },
  inputBox: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
  errorMsg: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
  },
  submitButton: {
    minWidth: "150px",
  },
  gridItemPadding: {
    padding: theme.spacing(1),
  },
}));

export default WallethubFormView;
