import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import * as layoutStyles from "./LayoutStyles";
import {pallete} from "app/styles";
import {useNavigate} from "react-router-dom";

export type T_TwoPaneLayoutBottomText = {
  text: string;
  routeName: string;
  routePath: string;
};

export const TwoPaneLayoutBottomText = (
  opt: T_TwoPaneLayoutBottomText
): React.JSX.Element => {
  const { classes } = layoutStyles.useTwoPaneLayoutBottomTextStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.bottomText}>
      <Typography variant="h5" color={pallete.primaryWhite}>
        {opt.text ?? ""}
      </Typography>
      <Typography
        variant="h5"
        className={classes.routeName}
        onClick={() => navigate(opt.routePath)}
      >
        <b>{opt.routeName}</b>
      </Typography>
    </Box>
  );
};

export type T_TwoPaneLayoutLeftBoxProps = {
  title?: string;
  bottomTextOptions?: T_TwoPaneLayoutBottomText;
};

export const TwoPaneLayoutLeftBox = ({
  title,
  bottomTextOptions,
}: T_TwoPaneLayoutLeftBoxProps): React.JSX.Element => {
  const { classes } = layoutStyles.useTwoPaneLayoutLeftBoxStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.headingBox}>
      <Box
        component="img"
        className={classes.iconWeb}
        src="assets/logos/tradeBpLogin.svg"
        alt="Descriptive Alt Text"
        onClick={() => navigate("/")}
      />
      <Typography variant="h1" className={classes.headingTypo}>
        {title ?? "Welcome to TradeBP"}
      </Typography>

      {bottomTextOptions ? TwoPaneLayoutBottomText(bottomTextOptions) : ""}
    </Box>
  );
};

export type T_TwoPaneLayoutProps = {
  leftBoxProps: T_TwoPaneLayoutLeftBoxProps;
  rightBoxElement: React.ReactNode;
};

export const TwoPaneLayout = ({
  leftBoxProps,
  rightBoxElement,
}: T_TwoPaneLayoutProps): React.JSX.Element => {
  const { classes } = layoutStyles.useTwoPaneLayoutStyles();

  return (
    <Grid container className={classes.mainGrid}>
      <Grid item md={6} className={classes.leftGrid}>
        <TwoPaneLayoutLeftBox {...leftBoxProps} />
      </Grid>
      <Grid item md={6} className={classes.rightGrid}>
        {rightBoxElement}
      </Grid>
    </Grid>
  );
};
