import { makeStyles, pallete, styles } from "app/styles";

const useWallethubPaymentStyles = makeStyles()((theme) => {
  return {
    crossIconBtn: {
      position: "absolute",
      top: "20px",
      right: "35px",
    },
    feeBox: {
      ...styles.flexDRS,
      padding: "5px 0",
    },
    bottomDetailsBox: {
      margin: "20px 0",
      backgroundColor: "#f7f7f7",
      border: "1px solid #d9d9d9",
      padding: "20px",
      borderRadius: "8px",
    },
    currBox: {
      ...styles.flexDRS,
    },
    paymentCard: {
      border: "1px solid #000",
      padding: "30px 20px",
      borderRadius: "8px",
      width: "auto",
    },
    merchantCard: {
      border: "1px solid #000",
      padding: "30px 20px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      // cursor:"pointer"
    },
    centerItemBox: {
      position: "relative",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    inputBoxes: {
      padding: "10px",
      margin: "20px 10px",
    },
    otpBoxInput: {
      ...styles.flexDRS,
      justifyContent: "center",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    routeName: {
      paddingLeft: "10px",
      cursor: "pointer",
      color: pallete.primaryPurple,
      "&:hover": {
        color: pallete.parimayBlue,
        textDecorationLine: "underline",
        transition: "ease-in",
      },
    },
    bottomText: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "10px",
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    termsText: {
      color: pallete.primaryBlack,
      textDecorationLine: "underline",
      margin: "auto 0",
      cursor: "pointer",
    },
    checkBoxB: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px 0",
      textTransform: "none",
      cursor: "pointer",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    headingBox: {
      backgroundColor: pallete.primaryPurple,
      padding: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    formBox: {
      margin: "20px 0",
    },
    imagBoxOther: {
      position: "absolute",
      top: "20px",
      left: "35px",
    },
    imagBox: {
      display: "flex",
      flexDirection: "row",
    },
    btnS10: {
      border: "none",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      borderRadius: 0,
      "&:hover": {
        backgroundColor: pallete.primaryWhite,
        color: pallete.primaryPurple,
      },
    },
    btnS11: {
      margin: "20px 0",
      border: "none",
      borderRadius: 0,
      backgroundColor: pallete.primaryWhite,
      color: pallete.primaryPurple,
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: pallete.primaryPurple,
        color: pallete.primaryWhite,
      },
    },
    buttonBoxS1: {
      backgroundColor: pallete.primaryPurple,
      width: "250px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "10px 0",
      marginTop: "20px",
    },
    innerBoxS1: {
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      height: "90%",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        padding: "15px 30px",
      },
    },
    innerBoxOther: {
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "10px 40px",
      [theme.breakpoints.down("md")]: {
        margin: "10px 20px",
        padding: "15px",
      },
    },
    innerBoxS: {
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      // borderLeft: "1px solid #000",
      margin: "10px 40px",
      [theme.breakpoints.down("md")]: {
        margin: "10px 30px",
        padding: "15px",
      },
    },
    innerBoxSignIn: {
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      // borderLeft: "1px solid #000",
      margin: "10px 40px",
      [theme.breakpoints.down("md")]: {
        margin: "10px 30px",
        padding: "15px",
      },
    },
    bottomTextS1: {
      color: pallete.primaryWhite,
    },
    headingTypo: {
      color: pallete.primaryWhite,
    },
    iconWeb: {
      height: "45px",
    },
    successIcon: {
      height: "100px",
    },
    mainBoxStepOne: {
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100vh", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        // display: "none",
      },
    },
    footerDivider: {
      width: "100%",
      backgroundColor: pallete.primaryBlack,
    },
    footerBox: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "20px",
      // backgroundColor: pallete.primaryPurple,
    },
    footerContent: {
      textAlign: "center",
      fontSize: "12px",
      fontStyle: "italic",
    },

    footerCopyright: {
      fontWeight: "bold",
    },
  };
});

export { useWallethubPaymentStyles };
