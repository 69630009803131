import React from "react";
import { CryptoHubOrders } from "./CryptoHubOrders";

type T_CryptoHubIdState = {
  userId?: string;
  merchantId?: string;
};
export const CryptoHubOrdersView = (): React.JSX.Element => {
  // const [cryptoHubIdState, setCryptoHubIdState] = useState<T_CryptoHubIdState>({});
  //
  // const setCryptoHubIdFn = ({userId, merchantId}: Partial<T_CryptoHubIdState>) => {
  //     setCryptoHubIdState({userId, merchantId});
  // }

  // if (cryptoHubIdState.userId) {
  //     return <CryptoHubUserOrders userId={cryptoHubIdState.userId} setCryptoHubIdFn={setCryptoHubIdFn} />;
  // }
  //
  // if (cryptoHubIdState.merchantId) {
  //     return <CryptoHubMerchantOrders merchantId={cryptoHubIdState.merchantId} setCryptoHubIdFn={setCryptoHubIdFn} />;
  // }
  return <CryptoHubOrders />;
};
