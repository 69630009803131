import React, { ReactElement } from "react";
import Dashboard from "./dashboard/Dashboard";
// import {Trading} from "./trading";
import CryptoHubView from "./cryptohub";
// import BillingBalance from "./billing/BillingBalance";
import { Billing } from "./billing/BilingAndBalance";
import Security from "./security/Security";
import Notification from "./notifications/Notification";
import MoneyHubView from "./moneyhub";
import Settings from "./settings/Settings";
import Team from "./team";
import WhitelistedAddress from "./whitelistedAddress";
import Withdraw from "./withdraw";
import { Trading } from "./trading/Trading";

const CUSTOMER_VIEWS_MAPPING: Record<string, ReactElement> = {
  "/dashboard": <Dashboard />,
  "/trade": <Trading />,
  "/cryptohub": <CryptoHubView />,
  "/billing-balance": <Billing />,
  "/team": <Team />,
  "/security": <Security />,
  "/notifications": <Notification />,
  "/white-listed-address": <WhitelistedAddress />,
  "/withdraw": <Withdraw />,
  "/moneyhub": <MoneyHubView />,
  "/settings": <Settings />,
};
export default CUSTOMER_VIEWS_MAPPING;
