import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";

export const useStyles = makeStyles()((theme) => {
  return {
    securityBox: {
      padding: "50px 0",
    },
    label: {
      // Custom styles for the label
      color: "#B9B9B9 !important", // Change label text color
      // fontSize: '1rem', // Change font size
      "&.Mui-focused": {
        color: "blue", // Color of the label when the input is focused
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
      // border: "1px solid #FFFFFF",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      position: "relative",
    },
    secImgFooter: {
      height: "40px",
      width: "110px",
      // marginRight: "20px",
    },

    socialIcons: {
      height: "40px",
      width: "40px",
      margin: "0 15px ",
      [theme.breakpoints.up("md")]: {
        marginLeft: "20px",
      },
    },
    socialIcons1: {
      height: "40px",
      width: "50px",
      // margin: "0 8px 0 auto !important",
      // marginRight: "auto"
    },
    menuTypos: {
      padding: "10px 0",
      fontWeight: 500,
      cursor: "pointer",
      color: pallete.primaryWhite,
      width: "200px",
      "&:hover": {
        color: "#ab288f",
      },
    },
    menue: {
      [theme.breakpoints.up("md")]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    footerBox: {
      backgroundColor: pallete.primaryBlack,
      height: "70vh",
      padding: "50px 0",
      ...styles.flexDCC,
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        padding: "50px 20px",
      },
    },
    accordionMain: {
      margin: "10px 20px",
      width: "100%",
      border: "2px solid transparent",
      borderImage: "linear-gradient(45deg, #ED00F0 , #50308D)",
      borderImageSlice: 1,
      padding: "5px 0",
    },
    accordionBox: {
      margin: "20px 0",
      ...styles.flexDCC,
      alignItems: "center",
    },
    teamPBox: {
      margin: "10px",
    },
    teamImg: {
      width: "100%",
      height: "100%",
    },
    temImgBox: {
      position: "relative",
      overflow: "hidden",
      "&:hover .css-288yq0-teamHiddenText": {
        opacity: "1",
        visibility: "visible",
      },
      "&:hover .css-288yq0-teamImg": {
        filter: "blur(9px)",
      },
    },
    teamHiddenText: {
      position: "absolute",
      top: "0",
      left: "0",
      background: "rgba(56, 42, 96, 0.6)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "0 24px",
      textAlign: "center",
      opacity: "0",
      visibility: "hidden",
      transition: "ease-in-out 0.4s",
    },
    aboutBox: {
      padding: "50px 0",
      ...styles.flexDCC,
      alignItems: "center",
    },
    aboutGrid: {
      ...styles.flexDRC,
      alignContent: "center",
      alignItems: "center",
      width: "100%",
    },
    openAccBox: {
      ...styles.flexDRS,
      backgroundColor: pallete.primaryBlack,
      margin: "0 50px",
      padding: "20px 50px",
      [theme.breakpoints.down("lg")]: {
        margin: "0 20px",
        padding: "20px",
      },
    },
    verificationBox: {
      backgroundColor: "#e8dbff",
      padding: "50px 0",
      // position:"relative"
    },
    platformBox: {
      backgroundColor: pallete.primaryBlack,
      padding: "50px 0",
    },
    walletBox: {
      padding: "50px 0",
    },
    securityLeftGrid: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "50px",
      [theme.breakpoints.down("lg")]: {
        padding: "20px",
      },
    },
    walletRightGrid: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingRight: "50px",
      [theme.breakpoints.down("lg")]: {
        padding: "20px",
      },
    },
    securityImgs: {
      height: "380px",
      width: "355px",
      marginTop: "30px",
      [theme.breakpoints.down("lg")]: {
        width: "300px",
        height: "340px",
        // marginTop: "30px",
      },
    },
    walletImg: {
      height: "341px",
      width: "355px",
      marginTop: "30px",

      marginBottom: "30px",
      [theme.breakpoints.down("lg")]: {
        width: "300px",
        height: "343px",
        // marginTop: "30px",
      },
      [theme.breakpoints.down("lg")]: {
        width: "315px",
        height: "343px",
        // marginTop: "30px",
      },
      // position:'absolute',
      // right:0,
    },
    securityGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between !important",
      // gap: "auto",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        flexDirection: "column-reverse",
        justifyContent: "center",
        alignItems: "center",
      },
      // backgroundColor: "pink",
    },
    walletGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: "auto",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      // backgroundColor: "pink",
    },
    platformGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    productGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      paddingTop: "20px",
    },
    productCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "300px",
      alignItems: "center",
      padding: "0 20px",
      margin: "20px 10px",
    },
    productImgs: {
      height: "105px",
      width: "95px",
    },
    gridContainer1: {
      [theme.breakpoints.down("lg")]: {
        padding: "50px 0",
        height: "auto",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100vh",
      background: "linear-gradient(to bottom, #e8dbff 50%, #ffffff 50%)",
    },
    btn0: {
      ...styles.homeButton,
      padding: "10px 50px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 35%",
      },
      [theme.breakpoints.down("md")]: {
        padding: "10px 50px",
      },
    },
    btnAc: {
      ...styles.homeButton,
      padding: "10px 50px",
      [theme.breakpoints.down("lg")]: {
        padding: "10px 30px",
      },
    },
    btn1: {
      ...styles.homeButton,
      backgroundColor: pallete.primaryBlack,
      color: pallete.primaryWhite,
      padding: "10px 50px",
    },
    leftHeading0: {
      color: pallete.primaryWhite,
      padding: "50px 0",
      fontSize: "3.2rem !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "2rem !important",
      },
    },
    gridContainer0: {
      backgroundColor: pallete.primaryBlack,
      height: "100%",
      paddingTop: "100px",

      [theme.breakpoints.down("md")]: {
        // height: "100%",
        paddingBottom: "50px",
        display: "flex",
        flexDirection: "column-reverse",
      },
    },
    leftGrid0: {
      [theme.breakpoints.up("xl")]: {
        // height: "100%",
        paddingBottom: "50px",
        // display: "flex",
        // flexDirection: "column-reverse",
        marginTop: "auto",
        marginBottom: "auto",
      },
      padding: "104px 100px",
      [theme.breakpoints.down("md")]: {
        padding: "0px 20px",
        marginTop: "-74px",
        marginBottom: "64px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      [theme.breakpoints.between("sm", "md")]: {
        padding: "0px 20px",
        marginTop: "-74px",
        marginBottom: "64px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
    },

    ContainerBox: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column-reverse",
        gap: 22,
      },
    },
    Graph: {
      width: "100%",
      marginTop: "-30%",
      [theme.breakpoints.down("md")]: {
        marginTop: "-56%",
      },
    },
    GraphTooltip: {
      position: "relative",
      // position: "absolute",
      [theme.breakpoints.up("lg")]: {
        bottom: "-24%",
        left: "41%",
        width: "30%",
      },
      [theme.breakpoints.down("lg")]: {
        bottom: "-24%",
        left: "41%",
        width: "30%",
      },
      [theme.breakpoints.down("md")]: {
        bottom: "62%",
        left: "19.9%",
        width: "56%",
      },
    },
    Ticker: {
      height: "100px",
      width: "100%",
      position: "relative",
      left: "0px",
      // top: "85vh",
      // [theme.breakpoints.down("lg")]: {
      //   top: "",
      // },
    },
    TickerSmall: {
      // height: "86px",
      width: "100%",
      // position: "relative",
      // left: "0px",
      // top: "100vh",
      // [theme.breakpoints.down("md")]: {

      //   top: "100vh",
      // },
    },
    imgMainBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    imgMainBox2: {
      // marginRight: "330px",
      [theme.breakpoints.between("md", "xl")]: {
        marginRight: "auto",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "auto",
      },
      [theme.breakpoints.between("xs", "md")]: {
        marginRight: "0px",
      },
    },
    leftGrid1: {
      display: "inline-flex",
      flexDirection: "column-reverse",
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: 150,
      },
    },
  };
});
