import {TDF} from "../components/table";
import {pallete} from "../styles";

type T_KYCStatusOptionsProps = {
  kyc_is_verified?: boolean;
  kyc_is_in_progress?: boolean;
};
export const getKycStatusProps = (
  row: T_KYCStatusOptionsProps
): TDF.T_StatusBadgeProps => {
  if (row.kyc_is_verified) {
    return { statusText: "Verified", color: pallete.selections.green };
  } else if (row.kyc_is_in_progress) {
    return { statusText: "In Progress", color: pallete.selections.blue };
  } else {
    return { statusText: "Unverified", color: pallete.selections.red };
  }
};

export const get2FAStatusProps = (status: boolean): TDF.T_StatusBadgeProps => {
  return {
    statusText: status === true ? "Active" : "Inactive",
    color: status === true ? pallete.selections.green : pallete.selections.red,
  };
};

export const getTransactionStatusProps = (
  status: string,
  statusText?: string
): TDF.T_StatusBadgeProps => {
  switch (status) {
    case "-1":
      return {
        statusText: statusText || "Failed",
        color: pallete.selections.red,
      };
    case "0":
      return {
        statusText: statusText || "Pending",
        color: pallete.selections.yellow,
      };
    case "1":
      return {
        statusText: statusText || "In Progress",
        color: pallete.selections.blue,
      };
    case "2":
      return {
        statusText: statusText || "Completed",
        color: pallete.selections.green,
      };
    default:
      return {
        statusText: statusText || "Unknown",
        color: pallete.selections.brown,
      };
  }
};

export const getStatusBadgePropsForTradeOrder = (
  status: string,
  statusText?: string
): TDF.T_StatusBadgeProps => {
  switch (status) {
    case "-1":
      return {
        statusText: statusText || "Failed",
        color: pallete.selections.red,
      };
    case "0":
      return {
        statusText: statusText || "Pending",
        color: pallete.selections.yellow,
      };
    case "1":
      return {
        statusText: statusText || "In Progress",
        color: pallete.selections.green,
      };
    default:
      return {
        statusText: statusText || "Unknown",
        color: pallete.selections.brown,
      };
  }
};
