import React, {useEffect, useState} from "react";

const QRCode = require("qrcode");

const QRCodeGenerator = ({ data, width, heigth }: any) => {
  const [qrCodeURL, setQRCodeURL] = useState("");

  useEffect(() => {
    generateQRCode(JSON.stringify(data));
  }, [data]);

  const generateQRCode = (text: any) => {
    QRCode.toDataURL(text, (err: any, url: string) => {
      if (err) {
        console.log("Error in generating QR code");
      } else {
        setQRCodeURL(url);
      }
    });
  };

  return (
    <div>
      {qrCodeURL && (
        <img
          style={{ width: width || "150px", height: heigth || "150px" }}
          src={qrCodeURL}
          alt="qr code"
        />
      )}
    </div>
  );
};

export { QRCodeGenerator };
