/**
 * @file: Storage.ts
 * @date: 05 Oct 2023
 * @description: $
 */


import Cipher from "./Cipher";
import {Environment} from "app/config"


class StorageInterface {

    private readonly _storageInterface: Storage;

    private readonly _useEncryption: boolean;

    private hashedKey(key:string):string {
        return Cipher.MD5Hash(`${Environment.ENV_NAME}-${key}`);
    }

    constructor(storageInterface: Storage, useEncryption: boolean = true) {
        this._storageInterface = storageInterface;
        this._useEncryption = useEncryption;
    }

    public setItem(key: string, value: any) {
        const stringifiedValue = this._useEncryption ? Cipher.encrypt(JSON.stringify(value)) : JSON.stringify(value);
        return this._storageInterface.setItem(this.hashedKey(key), stringifiedValue);
    }

    public getItem<T = any>(key: string, defaultValue: any=null, callback?: (value: any) => void) {
        const storageValue = this._storageInterface.getItem(this.hashedKey(key)) || defaultValue;
        let parsedValue;
        try {
            parsedValue = this._useEncryption ? Cipher.decrypt(storageValue) : storageValue;
        } catch (error) {
            parsedValue = storageValue;
        }
        return (callback instanceof Function) ? callback(parsedValue as T) : parsedValue as T;
    }

    public removeItem(key: string) {
        this._storageInterface.removeItem(this.hashedKey(key));
    }

}


export class AppStorage {

    protected static _localStorageInstance: StorageInterface;

    protected static _sessionStorageInstance: StorageInterface;

    public static getLocalStorage(useEncryption: boolean = true): StorageInterface {
        if (!this._localStorageInstance) {
            this._localStorageInstance = new StorageInterface(localStorage, useEncryption);
        }
        return this._localStorageInstance;
    };

    public static getSessionStorage(useEncryption: boolean = true): StorageInterface {
        if (!this._sessionStorageInstance) {
            this._sessionStorageInstance = new StorageInterface(sessionStorage, useEncryption);
        }
        return this._sessionStorageInstance;

    };

}
