import * as React from "react";
import {Box, Button, Container, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

// Custom components using the styled API from MUI
const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height:"100%"
});

const StyledTypography = styled(Typography)({
  color: '#573A9B', // Using primaryPurple for text
  textAlign: 'center',
  marginBottom: '3rem',
  fontWeight: '600',
});

const StyledButton = styled(Button)({
  backgroundColor: '#573A9B', // Using primaryPurple for the button
  color: '#FFFFFF', // Text color on the button
  '&:hover': {
    backgroundColor: '#41256e', // Darken the button when hovered
  },
  padding: '10px 30px',

});

type T_ComingSoonPageProps = {
  title?: string;
  description?: string;
}

export default function ComingSoonPage({title,description}:T_ComingSoonPageProps) {
  return (
    <StyledContainer maxWidth="sm">
      <Box>
        <StyledTypography variant="h3" gutterBottom>
          {title || 'Coming Soon' }
        </StyledTypography>
        <StyledTypography variant="body1" >
          {description || "We'll be here soon with awesome features."}
        </StyledTypography>
      </Box>
    </StyledContainer>
  );
}
