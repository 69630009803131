import React from "react";
import { TabContent } from "app/components/tabs";
import { WhitelistedAddressTable } from "./whitelistedTable";
import { AddWhitelistedAddress } from "./addWhitelistedAddress";


const WhitelistedAddress = () => {
    const tabHeading: string = "Whitelisted Address";
    const resetUrlOnTabChange: boolean = true;

    const tabData = [
        {label: "Address", component: <WhitelistedAddressTable />},
        {label: "Add", component: <AddWhitelistedAddress />},
    ];

    return TabContent({tabHeading, tabData, resetUrlOnTabChange});
};

export default WhitelistedAddress;
