import React from "react";
import {
    DefaultTableComponent,
    T_DefaultTableComponentProps,
    TDF,
} from "app/components/table";
import { useVerifyWhitelistedAddress, useWhitelistedAddressQuery } from "../WhitelistedAddressQueries";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useNotification } from "app/providers";

const renderFunction = (data: any): React.JSX.Element[] => {
    const keyPrefix: string = `white-listed-address-${data.id}`;
    return [
        <TDF.TextContent
            value={data.wallet_address}
            key={`${keyPrefix}-wallet`}
        />,
        <TDF.TextContent
            value={data.nick_name}
            key={`${keyPrefix}-nick_name`}
        />,
        <TDF.TextContent
            value={data.fireblocks_asset_id}
            key={`${keyPrefix}-asset`}
        />,
        <TDF.TextContent
            value={data.verified ? "Yes" : "No"}
            key={`${keyPrefix}-verified`}
        />,
        <TDF.RawElement element={data.action} key={`${keyPrefix}-action`} />,
    ];
};

const whitelistedaddressDataMap = {
    "Wallet Address": "wallet_address",
    "Nick Name": "nick_name",
    "Asset Id": "fireblocks_asset_id",
    Verified: "verified",
    Action: "action",
};

export const WhitelistedAddressTable = () => {
    const whitelistedAddressQuery = useWhitelistedAddressQuery();
    const headings: string[] = Object.keys(whitelistedaddressDataMap);
    const tradeOrdersRaw = whitelistedAddressQuery.isResolved
        ? whitelistedAddressQuery.result
        : [];
    const [open, setOpen] = React.useState(false);
    const [SelectedTradeOrder, setSelectedTradeOrder] =
        React.useState<any>(null);
    const verifyRequest = useVerifyWhitelistedAddress();
    const notification = useNotification();

    const tradeOrders = tradeOrdersRaw.map((tradeOrder: any) => {
        return {
            ...tradeOrder,
            action: (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={tradeOrder.verified}
                    onClick={() => {
                        setSelectedTradeOrder(tradeOrder);
                        setOpen(true);
                    }}
                >
                    {"Verify"}
                </Button>
            ),
        };
    });

    const verifyAddress = () => {
        verifyRequest.EXECUTE({
            payload: {
                user_whitelisted_wallet_address_id: SelectedTradeOrder.id,
            },
            onSuccessFn: () => {
                whitelistedAddressQuery.refetch();
                setOpen(false);
                notification.success("Address Verified");
            },
            onErrorFn: (error: any, message: any) => {
                setOpen(false);
                notification.error(message);
            }
        });
    };

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Whitelisted Address",
        headings: headings,
        isLoading: whitelistedAddressQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: tradeOrders,
        actionColumn: true,

        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ["wallet_address", "fireblocks_asset_id"],
        },
        downloadOptions: {
            fileName: "whitelistedAddress.csv",
            dataMap: whitelistedaddressDataMap,
        },
    };

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <Box p={4}
                    sx={{
                        width: 500,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5" fontWeight={"bold"}>Verify Wallet Address</Typography>
                    <Typography variant="body2">
                        Are you sure you want to verify this address? This will
                        Execute a 5 Euro Transaction.
                    </Typography>
                    <Box display="flex" justifyContent="space-between" width={"100%"}>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={verifyAddress}
                        >
                            Verify
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <DefaultTableComponent {...tableComponentProps} />
        </>
    );
};
