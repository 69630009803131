import {LandingPage} from "./landing";
import {ERROR_ROUTES_AND_VIEW_MAP} from "./errors";
import LoginView from "./login";
import {Signup} from "./register";
import React from "react";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import TermsAndCondition from "./terms&conditions/TermsAndCondition";
import OAuthAuthenticator from "./login/OAuthAuthenticator";

const PUBLIC_VIEWS_MAPPING = {
    "/": <LandingPage />,
    "/login": <LoginView />,
    "/signup": <Signup />,
    "/privacy-policy": <PrivacyPolicy />,
    "/terms-and-conditions": <TermsAndCondition />,
    "/OAuthAuthenticator": <OAuthAuthenticator />,
    ...ERROR_ROUTES_AND_VIEW_MAP,
};

export default PUBLIC_VIEWS_MAPPING;
