import { ApiConfig, createAPIURL } from "app/config";
import { RQ } from "app/hooks";
import { useEffect, useState } from "react";

const CRYPTOHUB_APIS = {
  TRANSACTION_STATS: ApiConfig.create(
    "user/crypto-hub-transaction-request/get-totals"
  ),
  TRANSACTION_DAY_BASED_STATS: ApiConfig.create(
    "user/crypto-hub-transaction-request/get-daily-totals"
  ),
  TRANSACTION_TABLE_DATA: ApiConfig.create(
    "user/crypto-hub-transaction-request"
  ),
  WEBHOOK_REQUEST: ApiConfig.create(
    "user/webhook-details"
  ),
};

export type T_CryptohubTransactionStats = {
  Total: number;
  "Payment Pending": number;
  "Payment Received": number;
  "Sent To Merchant": number;
  "Settled With Merchant": number;
};

export type T_CryptohubDayBasedTransactionStats = {
  [key: string]: T_CryptohubTransactionStats;
};

export type T_CryptohubTransactionTableData = {
  id: string;
  sender_id: number;
  receiver_id: number;
  sender_email: string;
  receiver_email: string;
  user_id_in_merchant_site: string;
  currency_id: number;
  currency_symbol: string;
  fireblocks_asset_id: string;
  amount: number;
  fees: number;
  verified_at: string;
  one_time_wallet_address: string;
  asset_quantity: number;
  received_quantity: number;
  fee_quantity: number;
  status_id: number;
  status_name: string;
};

export type T_CryptohubTransactionTableDataArray =
  Array<T_CryptohubTransactionTableData>;

export const StatsKey: Array<keyof T_CryptohubTransactionStats> = [
  "Total",
  "Payment Pending",
  "Payment Received",
  "Sent To Merchant",
  "Settled With Merchant",
];

const useCryptoHubTransactionStatsRequest =
  (): RQ.T_QueryResult<T_CryptohubTransactionStats> => {
    return RQ.useQueryRequest<T_CryptohubTransactionStats>({
      url: CRYPTOHUB_APIS.TRANSACTION_STATS.url,
      queryKey: CRYPTOHUB_APIS.TRANSACTION_STATS.key,
    });
  };

const useCryptoHubDayBasedTransactionStatsRequest = (days: number) => {
  return RQ.useQueryRequest<T_CryptohubDayBasedTransactionStats>({
    url: CRYPTOHUB_APIS.TRANSACTION_DAY_BASED_STATS.url,
    payload: { days },
    queryKey: [...CRYPTOHUB_APIS.TRANSACTION_DAY_BASED_STATS.key, days],
    method: "POST",
  });
};

const useCryptoHubTransactionTableRequest = (status: Array<number>) => {
  return RQ.useQueryRequest<T_CryptohubTransactionTableDataArray>({
    url: CRYPTOHUB_APIS.TRANSACTION_TABLE_DATA.url,
    payload: { status },
    queryKey: [...CRYPTOHUB_APIS.TRANSACTION_TABLE_DATA.key, ...status],
    method: "POST",
  });
};

type T_CryptohubWebhookRequest = {
  new_transaction_webhook: string;
  minimum_amount_for_new_transaction: number;
};

const useCryptohubWebhookRequest = () => {
  return RQ.useQueryRequest<T_CryptohubWebhookRequest>({
    url: CRYPTOHUB_APIS.WEBHOOK_REQUEST.url,
    queryKey: CRYPTOHUB_APIS.WEBHOOK_REQUEST.key,
    refetchOnWindowFocus: false,
  });
}

const useCryptohubWebhookPutRequest = (): RQ.T_MutationResult<T_CryptohubWebhookRequest> => {
  return RQ.usePutRequest<T_CryptohubWebhookRequest>({
      url: CRYPTOHUB_APIS.WEBHOOK_REQUEST.url,
  });
}

export {
  useCryptoHubTransactionStatsRequest,
  useCryptoHubDayBasedTransactionStatsRequest,
  useCryptoHubTransactionTableRequest,
  useCryptohubWebhookRequest,
  useCryptohubWebhookPutRequest,
};
