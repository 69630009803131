import React, { ReactElement } from "react";
import { MoneyhubPaymentView } from "./moneyhub-payment";
import { CryptohubPaymentView } from "./cryptohub-payment";
import { WallethubPaymentView } from "./wallethub-payment";
import { SelectPaymentMethod } from "./my-crypt";
import { CasinoDashboard } from "./casino";
import { CryptohubSettlement } from "./cryptohub-payment/CryptohubSettlement";
import { FlexhubPaymentView } from "./flexhub-payment";

const INTEGRATION_VIEWS_MAPPING: Record<string, ReactElement> = {
  "/moneyhub-payment": <MoneyhubPaymentView />,
  "/cryptohub-payment": <CryptohubPaymentView />,
  "/cryptohub-settle-payment": <CryptohubSettlement />,
  "/wallethub-payment": <WallethubPaymentView />,
  "/my-crypt": <SelectPaymentMethod />,
  "/casino": <CasinoDashboard />,
  "/flexhub-payment": <FlexhubPaymentView />,
};
export default INTEGRATION_VIEWS_MAPPING;
