import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Text} from "recharts";

// Define the data type
type DataItem = {
  name: string;
  value: number;
  color: string;
};

// Sample data
const data: DataItem[] = [
  { name: 'Group A', value: 400, color: '#FF8042' }, // Example color
  { name: 'Group B', value: 300, color: '#0088FE' }, // Example color
  { name: 'Group C', value: 300, color: '#00C49F' }, // Example color
  { name: 'Group D', value: 200, color: '#FFBB28' }, // Example color
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}) => {
  // Position the label based on the calculated coordinates
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomPieChart: React.FC = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" className="progress-label">
          ~$146863
        </Text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
