import {RQ} from "app/hooks"
import {ApiConfig} from "app/config";

export const MONEYHUB_APIS = {
    TRANSACTIONS: {
        CRUD: ApiConfig.create("moneyhub-transaction"),

    },
    TRANSACTION_REQUEST: {
        CRUD: ApiConfig.create("moneyhub-transaction-request"),
        SEARCH: ApiConfig.create("moneyhub-transaction-request/search"),
        DETAILS: ApiConfig.create("moneyhub-transaction-request/details"),
        LIST: ApiConfig.create("moneyhub-transaction-request/list"),
    },
    CUSTOMER_MONEYHUB_TRANSACTIONS:ApiConfig.create('user/money-hub-transaction-request')
}

export type T_MoneyHubTransaction = {
    id: number;
    receiver_id: string;
    sender_id: string;
    fees: number;
    sender_email: string;
    receiver_email: string;
    user_id_in_merchant_site: string;
    currency_symbol: string;
    amount: number;
    verified_at: Date | null;
    created_at: Date | null;
    redirect_url: string;
    asset_name: string;
    asset_quantity: number;
    status_id: number;
    status_name: string;
};
export type T_MoneyHubTransactionList = T_MoneyHubTransaction[];

export const useMoneyhubOrdersSearchQuery = (opt?: RQ.T_UseSearchQueryParams): RQ.T_UseSearchQueryResult<T_MoneyHubTransactionList> => {
    return RQ.useSearchQuery<T_MoneyHubTransactionList>({
        url: MONEYHUB_APIS.TRANSACTION_REQUEST.SEARCH.url,
        queryKey: MONEYHUB_APIS.TRANSACTION_REQUEST.SEARCH.key,
        searchParams: opt?.searchParams || {}
    });
}


export const useMoneyHubOrdersQuery = (): RQ.T_QueryResult<T_MoneyHubTransactionList> => {
    return RQ.useQueryRequest<T_MoneyHubTransactionList>({
        url: MONEYHUB_APIS.TRANSACTION_REQUEST.LIST.url,
        queryKey: MONEYHUB_APIS.TRANSACTION_REQUEST.LIST.key,
    });
}

type T_UseCustomerMoneyHubOrdersQueryParams = {
    customerId: string;
}

export const useCustomerMoneyHubOrdersQuery = (opt: T_UseCustomerMoneyHubOrdersQueryParams): RQ.T_QueryResult<T_MoneyHubTransactionList> => {
    const {customerId} = opt;
    return RQ.useQueryRequest<T_MoneyHubTransactionList>({
        url: MONEYHUB_APIS.CUSTOMER_MONEYHUB_TRANSACTIONS.url,
        params:[opt.customerId],
        queryKey: MONEYHUB_APIS.TRANSACTION_REQUEST.CRUD.key,
    });
}


type T_UseMoneyHubUserTransactionsQueryParams = {
    moneyHubUserId: string;
}
export const useMoneyHubUserTransactionsQuery = (opt: T_UseMoneyHubUserTransactionsQueryParams): RQ.T_QueryResult<T_MoneyHubTransactionList> => {
    const {moneyHubUserId} = opt;
    const apiConfig = ApiConfig.create("moneyhub-user", undefined, [moneyHubUserId, 'transactions'])
    return RQ.useQueryRequest<T_MoneyHubTransactionList>({
        url: apiConfig.url,
        queryKey: apiConfig.key,
    });
}
