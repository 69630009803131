import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { DashboardCardSecondary } from "app/components/unsorted";
import { styles } from "app/styles";
import TransactionsTable from "app/components/unsorted/TransactionTable";
import SelectToken from "app/components/unsorted/SelectToken";
import PercentageSlider from "app/components/unsorted/SliderPercent";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import Loader from "app/components/unsorted/Loader";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import useTimer from "app/providers/Timer";
import { RefreshRounded } from "@mui/icons-material";
import { useUserWalletQuery } from "app/queries/UserWalletQueries";
import { usePermissions } from "app/hooks";
import { useStyles } from "../Styles";
import { ApiConfig } from "app/config";

const form_schema = Yup.object().shape({
  amount: Yup.string()
    .matches(
      /^(?!0\d)\d*(\.\d+)?$/,
      "Only positive digits and decimals are allowed"
    )
    .required("Amount is required"),
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type FormType = "buy" | "sell" | "review" | "complete";

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Trade: React.FC = () => {
  const { classes } = useStyles();
  const _permission = usePermissions();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [currency, setCurrency] = useState([]);
  const [assets, setAssets] = useState([]);
  const [sellAmount, setSellAmount] = useState("");
  const [bestPrice, setBestPrice] = useState<any>({
    sell: 0,
    buy: 0,
  });
  const [userDetails, setUserDetails] = useState<any>({});
  const [reqBestPrice, setReqbestPrice] = useState<any>({});
  const [exchangerate, setExchangerate] = useState<any>({});
  const [valueS, setValueS] = useState({
    asset: {
      name: "",
      id: "",
      symbol: "",
      fireblocks_asset_id: "",
      acts_as: "",
    },
    curr: {
      name: "",
      id: "",
      symbol: "",
    },
  });
  const userWallet = useUserWalletQuery();

  const handleCompletion = () => {
    console.log("timer ends");
  };

  const { timeLeft, start, pause } = useTimer(4, handleCompletion);

  const [buySellCard, setBuySellCard] = useState<{
    [key in FormType]: boolean;
  }>({
    buy: false,
    sell: true,
    review: false,
    complete: false,
  });

  const switchPage = (pageName: FormType) => {
    setBuySellCard((current) => ({
      ...Object.keys(current).reduce((acc, key) => {
        acc[key as FormType] = false; // Set all pages to false
        return acc;
      }, {} as { [key in FormType]: boolean }),
      [pageName]: true, // Set the specified page to true
    }));
  };

  useEffect(() => {
    getAssets();
    getAllCurrency();
    getUserDetails();
    liveExchangeRate();
  }, []);

  const getPram = (name: string) => {
    if (name === "Bitcoin") {
      return "bitcoin";
    } else if (name === "Etherum") {
      return "ethereum";
    } else {
      return "tether";
    }
  };

  useEffect(() => {
    if(valueS.asset.acts_as !== "" && valueS.curr.symbol !== "") {
      makeRequests({ acts_as: valueS.asset.acts_as, symbol: valueS.curr.symbol});
    }
  }, [valueS.asset.acts_as, valueS.curr.symbol]);

  // const POST_URL = "https://api-pub.bitfinex.com/v2/calc/fx";
  // const GET_URL_1 = "https://api.binance.com/api/v3/avgPrice?symbol=BTCUSDT";
  const GET_URL_2 = "https://price-api.crypto.com/price/v1/token-price/";
  const GET_URL_3 = "https://api.coincap.io/v2/assets/";
  /////////////////////////
  const handleReload = () => {
    makeRequests(reqBestPrice);
    start();
  };

  const findMinimumPrice = (prices: { [key: string]: number }): number => {
    let minPrice: number = Infinity;
    for (const value of Object.values(prices)) {
      if (value < minPrice) {
        minPrice = value;
      }
    }

    return minPrice;
  };

  const makeRequests = ({acts_as, symbol} : any) => {
    setLoading(true);
    const sellURL = ApiConfig.create(`exchange/best-price/${acts_as}/0/${symbol}`).url;
    const buyURL = ApiConfig.create(`exchange/best-price/${acts_as}/1/${symbol}`).url;
    
    Promise.all([
      axios.get(sellURL),
      axios.get(buyURL),
    ])
      .then((responses) => {
        const [getResponse1, getResponse2] = responses;
        console.log("Get response 2:", getResponse1);
        console.log("Get response 3:", getResponse2);

        setBestPrice({
          sell: getResponse1.data.data.best_price,
          buy: getResponse2.data.data.best_price,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false);
      });
  };

  const getAllCurrency = async () => {
    setLoading(true);
    await getAxiosAPI("/currency")
      .then((res: any) => {
        setCurrency(res.data.data);
        // console.log("helo", res);
        if (res.data.data[0].symbol === "usd") {
          setValueS((pre: any) => ({ ...pre, curr: res.data.data[0] }));
        } else {
          setValueS((pre: any) => ({ ...pre, curr: res.data.data[1] }));
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getUserDetails = async () => {
    await getAxiosAPI("/user-account/details", true)
      .then((res: any) => {
        // console.log(res.data.data);
        setUserDetails(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const liveExchangeRate = async () => {
    try {
      const res = await axios.get(
        "https://api.exchangerate-api.com/v4/latest/usd"
      );
      setExchangerate(res.data.rates.EUR);
    } catch (err) {
      console.log(err);
    }
  };

  const getAssets = async () => {
    await getAxiosAPI("/asset", true)
      .then((res: any) => {
        setAssets(res.data.data);
        // console.log(res.data.data);
        setValueS((pre: any) => ({ ...pre, asset: res.data.data[0] }));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const getSelectedValues = (type: string, value: any) => {
    console.log("value : ", value);
    console.log(type);

    if (type === "asset") {
      console.log(value);
      setValueS((pre: any) => ({ ...pre, asset: value }));
    } else {
      // if (value.name === "Euro") {
      //   const currEuro =
      //   setValueS((pre: any) => ({ ...pre, curr: value }));
      // } else {
      // }
      console.log(value);
      setValueS((pre: any) => ({ ...pre, curr: value }));
    }
  };

  const handleTrade = async () => {
    pause();
    setLoading(true);
    const payload = {
      // user_account_id: decode.userid,
      asset_id: valueS.asset.id,
      currency_id: valueS.curr.id,
      quantity: parseFloat(sellAmount),
      type: 0,
    };
    await postAxiosAPI("/trade", JSON.stringify(payload), true)
      .then((res: any) => {
        setLoading(false);
        console.log(res);
        if (res.data.code !== 200) {
          setSeverity("error");
          setSnackbarMessage(res.data.message);
          setShowSnackbar(true);
        } else {
          switchPage("complete");
          setSnackbarMessage("Sell completed successfully");
          setShowSnackbar(true);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setSeverity("error");
        setSnackbarMessage("Something went wrong!");
        setShowSnackbar(true);
      });
  };

  const MainForm = () => {
    return (
      <Card className={classes.buysellCard}>
        <Box className={classes.buysellBtnBox}>
          <Button disabled={true} className={classes.btnsBSActive}>
            Buy
          </Button>
          <Button onClick={() => switchPage("sell")} className={classes.btnsBS}>
            Sell
          </Button>
        </Box>
        <Box className={classes.selectTokenBox}>
          <SelectToken
            lable="Asset"
            data={assets}
            type="asset"
            getSelectedValues={getSelectedValues}
            value={valueS}
            isMarginRequired={true}
          />
          <SelectToken
            lable="Currency"
            data={currency}
            type="currency"
            getSelectedValues={getSelectedValues}
            value={valueS}
            isMarginRequired={true}
          />
        </Box>
        <Box className={classes.bottomDataBox}>
          <Box className={classes.piceBOX}>
            <Box>
              <Typography variant="h6">Best price</Typography>
              <Typography variant="h2">
                {valueS.curr.name === "Euro"
                  ? `€ ${(bestPrice.buy).toFixed(4)}`
                  : `$ ${(bestPrice.buy).toFixed(4)}`}
                {/* $ {bestPrice} */}
              </Typography>
            </Box>
          </Box>
          {/* INPUT  */}
          <OutlinedInput
            placeholder="Amount"
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">ETH</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            className={classes.inputOutlined}
          />
          <PercentageSlider />
          <OutlinedInput
            placeholder="Amount"
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            className={classes.inputOutlined}
          />
          <Button
            variant="contained"
            className={classes.tradeBtn}
            onClick={() => {
              setSnackbarMessage("Buy Now feature is comming soon!");
              setShowSnackbar(true);
            }}
          >
            Buy Now
          </Button>
        </Box>
      </Card>
    );
  };

  const SellCard = () => {
    return (
      <Formik
        initialValues={{
          amount: "",
        }}
        validationSchema={form_schema}
        onSubmit={(values: any) => {
          //   console.log(values);
          setSellAmount(values.amount);
          switchPage("review");
          start();
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
        }: any) => (
          <Card className={classes.buysellCard}>
            <Box className={classes.buysellBtnBox}>
              <Button
                // disabled={true}
                onClick={() => switchPage("buy")}
                className={classes.btnsBS}
              >
                Buy
              </Button>
              <Button className={classes.btnsBSActive}>Sell</Button>
            </Box>
            <Box className={classes.selectTokenBox}>
              <SelectToken
                lable="Asset"
                data={assets}
                type="asset"
                getSelectedValues={getSelectedValues}
                value={valueS}
                isMarginRequired={true}
              />
              <SelectToken
                lable="Currency"
                data={currency}
                type="currency"
                getSelectedValues={getSelectedValues}
                value={valueS}
                isMarginRequired={true}
              />
            </Box>
            <Box className={classes.bottomDataBox}>
              <Box className={classes.piceBOX}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Wallet Balance</Typography>
                    <IconButton
                      sx={{
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        userWallet.refetch();
                      }}
                    >
                      <RefreshRounded fontSize="small" />
                    </IconButton>
                  </Box>
                  <Typography variant="h2">
                    {userWallet.result?.find(
                      (item: any) => item.asset_name === valueS.asset.name
                    )?.balance || 0}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">Best Price</Typography>
                  <Typography variant="h2">
                    {valueS.curr.name === "Euro"
                      ? `€ ${(bestPrice.buy).toFixed(4)}`
                      : `$ ${(bestPrice.buy).toFixed(4)}`}
                  </Typography>
                </Box>
              </Box>
              {/* <Typography variant="body2" color="primary">
                      we are supporting crypto conversion to USDT first
                    </Typography> */}
              {/* INPUT  */}
              <OutlinedInput
                placeholder="Quantity"
                id="outlined-adornment-weight"
                // endAdornment={
                //   <InputAdornment position="end">$</InputAdornment>
                // }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputOutlined}
                value={values.amount}
                onChange={handleChange("amount")}
                onBlur={handleBlur("amount")}
              />
              {touched.amount && errors.amount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.amount}
                </Typography>
              )}
              {/* <PercentageSlider />
                <OutlinedInput
                  placeholder="Amount"
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end">USD</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  className={classes.inputOutlined}
                /> */}
              <Button
                variant="contained"
                className={classes.tradeBtn}
                onClick={() =>
                  _permission.canCreateAndUpdate()
                    ? handleSubmit()
                    : _permission.warn()
                }
              >
                Sell Now
              </Button>
            </Box>
          </Card>
        )}
      </Formik>
    );
  };

  const TransectionData = () => {
    const total: number = parseFloat(sellAmount) * parseFloat(bestPrice);
    const fees: number = total * (+userDetails.trade_fees / 100);
    return (
      <Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Instrument
          </Typography>
          <Typography variant="h6" color="primary">
            BTCUSD_SPOT
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Direction
          </Typography>
          <Typography variant="h6" color="primary">
            {buySellCard.buy ? "Buy" : "Sell"}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Amount
          </Typography>
          <Typography variant="h6" color="primary">
            {sellAmount} {valueS.asset.name}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Price
          </Typography>
          <Typography variant="h6" color="primary">
            {valueS.curr.name === "Euro"
              ? `€ ${(bestPrice * exchangerate).toFixed(4)}`
              : `$ ${bestPrice}`}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Fees
          </Typography>
          <Typography variant="h6" color="primary">
            {/* $ {fees.toFixed(4)} */}
            {valueS.curr.name === "Euro"
              ? `€ ${(fees * exchangerate).toFixed(4)}` // Perform multiplication first, then use toFixed
              : `$ ${fees.toFixed(4)}`}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Total
          </Typography>
          <Typography variant="h6" color="primary">
            {/* ${total} */}

            {valueS.curr.name === "Euro"
              ? `€ ${(total * exchangerate).toFixed(4)}` // Perform multiplication first, then use toFixed
              : `$ ${total.toFixed(4)}`}
          </Typography>
        </Box>
        <Box className={classes.flexRow}>
          <Typography variant="body2" color="primary">
            Expiry
          </Typography>
          <Typography variant="h6" color="primary">
            {timeLeft} seconds
          </Typography>
        </Box>
      </Box>
    );
  };

  const ReviewTransaction = () => {
    return (
      <Card className={classes.buysellCardR}>
        <Typography
          sx={{ padding: "10px 0" }}
          variant="h4"
          color="primary"
          align="center"
        >
          Review and execute
        </Typography>
        <TransectionData />
        {timeLeft <= 0 ? (
          <Typography align="center" className={classes.errorMsg}>
            Transaction time expired!
          </Typography>
        ) : null}
        <Box className={classes.flexRow}>
          <Button
            onClick={() => switchPage("sell")}
            className={classes.tradeBtn0}
          >
            Cancel
          </Button>
          {timeLeft <= 0 ? (
            <Button
              className={classes.tradeBtn1}
              onClick={() => handleReload()}
            >
              Reload
            </Button>
          ) : (
            <Button className={classes.tradeBtn1} onClick={() => handleTrade()}>
              {buySellCard.buy ? "Buy" : "Sell"} {sellAmount}{" "}
              {valueS.asset.name}
            </Button>
          )}
        </Box>
      </Card>
    );
  };

  const CompleteTransations = () => {
    return (
      <Card className={classes.buysellCardR}>
        <Box className={classes.successImgBox}>
          <Box
            component="img"
            src="assets/icons/successGreen.svg"
            sx={{ height: "80px", width: "80px" }}
          />
        </Box>
        <Typography
          sx={{ padding: "10px 0" }}
          variant="h4"
          color="primary"
          align="center"
        >
          Order executed
        </Typography>
        <TransectionData />
        <Box>
          <Button
            onClick={() => switchPage("sell")}
            variant="contained"
            className={classes.tradeBtn}
          >
            Done
          </Button>
        </Box>
      </Card>
    );
  };

  const renderForm = () => {
    const activePage: any = Object.keys(buySellCard).find(
      // @ts-ignore
      (key: any) => buySellCard[key] === true
    );

    switch (activePage) {
      case "buy":
        return <MainForm />;
      case "sell":
        return <SellCard />;
      case "review":
        return <ReviewTransaction />;
      case "complete":
        return <CompleteTransations />;
      default:
        return <MainForm />;
    }
  };

  if (userWallet.isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <Box>
      <DashboardCardSecondary limit={8} mdSize={3} />
      <Grid container sx={{ ...styles.flexDRS }}>
        <Grid item md={4} sx={{ paddingTop: "10px" }}>
          {renderForm()}
        </Grid>
        <Grid item xs={12}>
          <TransactionsTable />
        </Grid>
      </Grid>
    </Box>
  );
};
