/**
 * @file: useAuthToken.ts
 * @date: 21 Sep 2023

 * @description: $
 */
import {useLocalStorage} from "../useStorage";
import {AuthToken, T_AuthToken} from "app/models/AuthToken";
import {jwtDecode} from "jwt-decode";


export type TAuthTokens = {
    accessToken: string;
    refreshToken: string;
};

export type UseAuthTokenManagerResult = {
    getAuthTokens: () => TAuthTokens;
    setAuthTokens: (accessToken: string, refreshToken: string) => void;
    refreshAuthTokens: () => void;
    getJwtToken: () => AuthToken;
}


export const useAuthTokenManager = () => {

    const initialValue: TAuthTokens = {accessToken: "", refreshToken: ""};

    const storageKey = 'authToken';

    const tokenStorage = useLocalStorage(storageKey, initialValue);

    const getAuthTokens = () => {
        return tokenStorage.getValue() as TAuthTokens;
    };

    const setAuthTokens = (accessToken: string, refreshToken: string) => {
        tokenStorage.setValue({accessToken, refreshToken})
    };

    const refreshAuthTokens = () => {
        // TODO : logic to refresh token

    };


    const getJwtToken = (): AuthToken => {
        const {accessToken} = getAuthTokens();
        let decodedToken: T_AuthToken;
        try {
            decodedToken = jwtDecode(accessToken);
        } catch (e) {
            decodedToken = {} as T_AuthToken;
        }
        return new AuthToken(decodedToken);
    };

    return {getAuthTokens, setAuthTokens, refreshAuthTokens, getJwtToken};


};

export {AuthToken}
