/**
 * @file: UserContext.ts
 * @date: 20 Sep 2023
 * @description: $
 */
import {useContext} from "react";
import {AppMenus, AuthUser} from "app/models";
import {AuthUserContext} from "app/providers";

export type T_UseAppContextResult = {
    user: AuthUser;
    menus: AppMenus;
}
export {AppMenus, AuthUser}

export const useAppContext = (): T_UseAppContextResult => {
    const {context, setContextData} = useContext(AuthUserContext);

    if (!context) {
        throw new Error("useAppContext must be used within a AppContextProvider");
    }

    return {...context}

}
