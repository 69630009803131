import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Divider, Typography } from "@mui/material";
import { T_UseCryptohubPaymentState } from "./CryptohubPaymentHooks";
import {
  T_Asset,
  useAssetsList,
  useCryptohubTransactionRequest,
  useCurrencyList,
} from "./CryptohubPaymentQueries";
import { CopyToClipboardButton } from "app/components/inputs/buttons";
import Loader from "app/components/unsorted/Loader";
import { useTranslation } from "react-i18next";
import { useLocalization } from "../../../app/hooks";

const QRCode = require("qrcode");

const QRCodeGenerator = ({ data }: any) => {
  const [qrCodeURL, setQRCodeURL] = useState("");

  useEffect(() => {
    generateQRCode(JSON.stringify(data.one_time_wallet_address));
  }, [data]);

  const generateQRCode = (text: any) => {
    QRCode.toDataURL(text, (err: any, url: string) => {
      if (err) {
        console.log("Error in generating QR code");
      } else {
        setQRCodeURL(url);
      }
    });
  };

  return (
    <div>
      {qrCodeURL && (
        <img
          style={{ width: "150px", height: "150px" }}
          src={qrCodeURL}
          alt="qr code"
        />
      )}
    </div>
  );
};

function formatTime(seconds: number) {
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds % 60;

  let minutesString = minutes < 10 ? "0" + minutes : minutes;
  let secondsString =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  return minutesString + ":" + secondsString;
}

function Timer({ time }: { time: number }) {
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = sessionStorage.getItem(
      "countdownTimePaymentCryptohubPayment"
    );
    return savedTime ? parseInt(savedTime, 10) : time;
  });

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft <= 0) {
      sessionStorage.setItem("countdownTimePaymentCryptohubPayment", "55");
      window.location.reload();
      return;
    }

    // Save timeLeft to localStorage on change
    sessionStorage.setItem(
      "countdownTimePaymentCryptohubPayment",
      timeLeft.toString()
    );

    // Decrease timeLeft by 1 every second
    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return <Typography variant="h6"> {formatTime(timeLeft)} </Typography>;
}

type T_ViewProps = {
  classes: any;
  paymentState: T_UseCryptohubPaymentState;
  transactionId: string | any;
};

type T_AmountDetails = {
  amount: number;
  currency: string;
  asset: string;
  assetAmount: number;
};

export const TransactionView = ({
  classes,
  paymentState,
  transactionId,
}: T_ViewProps) => {
  const { t, i18n } = useTranslation();
  const transactionRequest = useCryptohubTransactionRequest(transactionId);
  const assetsList = useAssetsList();
  const currencyList = useCurrencyList();
  const amountDetails: T_AmountDetails = {} as T_AmountDetails;
  const { languageCode, initializeLanguage } = useLocalization();

  const getAssetSymbol = (asset: string) => {
    let assetSymbol = "";

    if (assetsList.result) {
      const assetObj = assetsList.result.find(
        (assetObj: T_Asset) => assetObj.fireblocks_asset_id === asset
      ) as T_Asset;
      if (assetObj) assetSymbol = assetObj.acts_as;
    }

    return assetSymbol;
  };

  if (transactionRequest.result) {
    amountDetails.amount = transactionRequest.result.amount;
    amountDetails.currency = transactionRequest.result.currency_symbol;
    amountDetails.asset = transactionRequest.result.fireblocks_asset_id;
  }

  if (
    assetsList.isResolving ||
    currencyList.isResolving ||
    transactionRequest.isResolving
  ) {
    return <Loader isLoading={true} />;
  }

  const handleOnNext = () => {
    paymentState.update({
      isTransactionCompleted: true,
    });
  };

  return (
    <Box className={classes.mainCard}>
      <Alert severity={"warning"} className={classes.alertBox}>
        <Box className={classes.alertBox1}>
          <Typography variant="h6"> {t("Awaiting payment")} </Typography>
          <Timer time={55} />
        </Box>
      </Alert>

      <Box className={classes.innerCard}>
        {/* <Box className={classes.midBoxC1}>
                    <Box className={classes.currencyBox}>
                        <Typography variant="h5" fontWeight={"bold"}>
                            {" "}
                            Currency :{" "}
                        </Typography>
                        <Typography variant="h5">
                            {amountDetails.amount}
                        </Typography>
                        <Typography variant="h5" fontWeight={"bold"}>
                            {amountDetails.currency}
                        </Typography>
                    </Box>
                </Box> */}
        <Box className={classes.midBoxC1}>
          <Box className={classes.cryptoBox}>
            <Typography variant="h5">
              {" "}
              <strong>{t("Coin")} : </strong>{" "}
              {paymentState.transactionLink?.amount || 0}{" "}
              <strong>{getAssetSymbol(amountDetails.asset)}</strong>
            </Typography>
            {/* <Typography variant="h5">
              {paymentState.transactionLink?.amount || 0}
            </Typography>
            <Typography variant="h5" fontWeight={"bold"}>
              {getAssetSymbol(amountDetails.asset)}
            </Typography> */}
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Typography variant="h6">
          {t("Please send only")} {getAssetSymbol(amountDetails.asset)}{" "}
          {t("coins to this address on the")}{" "}
          {getAssetSymbol(amountDetails.asset)} .
        </Typography>
        <QRCodeGenerator data={paymentState.transactionLink} />
        <Box className={classes.addressBox}>
          <Typography variant="h6">
            {" "}
            {t("One - time wallet address")}{" "}
          </Typography>
          <Box className={classes.addressBox1}>
            <Typography variant="h6" className={classes.addressText}>
              {" "}
              {paymentState.transactionLink?.one_time_wallet_address || ""}{" "}
            </Typography>
            <CopyToClipboardButton
              fieldName={"Copy"}
              fieldValue={
                paymentState.transactionLink?.one_time_wallet_address || ""
              }
            />
          </Box>
        </Box>
        <Button
          className={classes.doneButton}
          onClick={() => {
            handleOnNext();
          }}
        >
          {t("Next")}
        </Button>
      </Box>
    </Box>
  );
};
