import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import AuthScreenLeftBox from "app/components/unsorted/AuthScreenLeft";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { useNavigate } from "react-router-dom";
import { postAxiosAPI } from "app/utils/axiosApiRequests";
import QRCode from "react-qr-code";
import { T_UseAccountStatus } from "app/hooks";

const otpSchema = Yup.object().shape({
  otp: Yup.string().required("Otp is required"),
});

const SetupGoogle2FA = ({
  accountStatus,
  updateAccountStatus,
}: T_UseAccountStatus) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [qrData, setQrData] = useState({
    qr_code: "loading",
  });

  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const getQRDATA = async () => {
    setLoading(true);
    await postAxiosAPI("/verification/setup-google-2fa", {}, true)
      .then((res: any) => {
        setLoading(false);
        const { ch_url, is_2fa_active, qr_code } = res.data?.data || {};
        if (ch_url && qr_code) {
          setQrData(res.data.data);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (!accountStatus.is2faSetupRequired()) {
      //navigate("/verify-google-auth");
    } else {
      getQRDATA();
    }
  }, []);

  const handleContinueBtnSubmit = () => {
    updateAccountStatus({ is2faActive: true });
  };

  return (
    <Grid container className={classes.mainGrid}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Grid item md={6} className={classes.leftGrid}>
        <AuthScreenLeftBox
          title="Welcome to TradeBP"
          bttomText=""
          routeName=""
          path=""
        />
      </Grid>
      <Grid item md={6} className={classes.rightGrid}>
        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={otpSchema}
          onSubmit={(values: any) => {
            // same shape as initial values
            console.log(values);
          }}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
          }: any) => (
            <Box className={classes.formBox}>
              <Typography
                variant="h3"
                align="center"
                className={classes.formHeading}
              >
                Set up Google Authenticator
              </Typography>
              <div
                style={{
                  height: "auto",
                  margin: "10px auto",
                  maxWidth: 150,
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrData.qr_code}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <Typography
                sx={{ textDecorationLine: "underline" }}
                variant="h5"
                color="primary"
                align="center"
              >
                Download Google Authenticator
              </Typography>
              <br />
              <Typography align="center" variant="h6" color="primary">
                Set up your two factor authentication by scanning the barcode
                above.
              </Typography>
              <br />
              <Typography align="center" variant="h6" color="primary">
                You must set up your Google Authenticator app before continuing.
                You will be unable to login otherwise
              </Typography>
              <br />
              <Box className={classes.btnsBox}>
                <Button
                  disabled
                  variant="outlined"
                  className={classes.submitBtnOutloined}
                >
                  Back
                </Button>
                <Button
                  onClick={handleContinueBtnSubmit}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default SetupGoogle2FA;
const useStyles = makeStyles()((theme) => {
  return {
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    createPasswordImg: {},
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    inputBoxes: {
      // backgroundColor:"pink",
      // height:"40px",
      // width:"40px",
      padding: "10px",
      margin: "20px 10px",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
