import {ApiConfig} from "./Funcs";


export const AUTH_APIS = {
    LOGIN_USER: ApiConfig.create("auth/access"),
    REFRESH_TOKEN: ApiConfig.create("auth/refresh"),
    LOGOUT_USER: ApiConfig.create("auth/logout"),
    REGISTER_USER: ApiConfig.create("user-account/register"),
    CREATE_PASSWORD: ApiConfig.create("user-account/password/create"),
    CHANGE_PASSWORD: ApiConfig.create("user-account/password/change"),

};


export const USER_ACCOUNT_APIS = {
    GET_USER_MENUS: ApiConfig.create("user-account/menus"),
    GET_ACCOUNT_DETAILS: ApiConfig.create("user-account/details"),

    GET_ACCOUNT_STATUS: ApiConfig.create("verification/details"),
    SEND_OTP: ApiConfig.create("verification/send-otp"),
    VERIFY_OTP: ApiConfig.create("verification/verify-otp"),
    SETUP_GOOGLE_AUTH: ApiConfig.create("verification/setup-google-2fa"),
    VERIFY_GOOGLE_AUTH: ApiConfig.create("verification/verify-google-2fa"),
    RESET_GOOGLE_AUTH: ApiConfig.create("verification/reset-google-2fa"),
    KYC_INITIATE: ApiConfig.create("verification/kyc-initiate"),
    KYC_RESULT: ApiConfig.create("verification/kyc-result"),

};

export const CRYPTO_APIS = {
    GET_CURRENCY_EXCHANGE_RATES_FROM_CRYPTO: ApiConfig.create("https://price-api.crypto.com/price/v1/currency/all"),
    GET_TOKEN_PRICES_FROM_CRYPTO: ApiConfig.create("https://price-api.crypto.com/price/v1/token-price/bitcoin"),
    GET_ASSETS_FROM_COINCAP: ApiConfig.create("https://api.coincap.io/v2/assets"),
    CALCULATE_FX_FROM_BITFINEX: ApiConfig.create("https://api-pub.bitfinex.com/v2/calc/fx"),
    GET_BEST_PRICE_FROM_SERVICE: "",
}


export const MONEYHUB_APIS = {
    CRUD: ApiConfig.create("integration-credentials/moneyhub"),
    VERIFY: ApiConfig.create("integration-credentials/moneyhub/verify"),
}

export const MONEYHUB_TRANSACTION_APIS = {
    CRUD: ApiConfig.create("integration-credentials/moneyhub"),
    VERIFY: ApiConfig.create("integration-credentials/moneyhub/verify"),
}


export const INPUT_DATA_APIS = {
    GET_COUNTRY_LIST: ApiConfig.create("countries"),
}
