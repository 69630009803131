import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    card: {
        height: "100%",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    gameCard: {
        borderRadius: "10px",
        height: "100%",
    },
    ongoingGames: {
        flexGrow: 1,
    },
    gameCardContent: {
        padding: "10px",
        paddingInline: "20px",
        display: "flex",
        alignItems: "center",
    },
    joinButton: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: "5px 10px",
        borderRadius: "5px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const ongoingGames = [
    { id: 1, entryFeeMin: 10, entryFeeMax: 20, image: "assets/images/casino1.jpeg", date: "31/06/2024"},
    { id: 2, entryFeeMin: 20, entryFeeMax: 100, image: "assets/images/casino2.jpeg", date: "12/07/2024"},
    { id: 3, entryFeeMin: 30, entryFeeMax: 80, image: "assets/images/casino3.jpeg", date: "15/08/2024"},
    { id: 4, entryFeeMin: 40, entryFeeMax: 60, image: "assets/images/casino2.jpeg", date: "20/07/2024"},
    { id: 5, entryFeeMin: 50, entryFeeMax: 100, image: "assets/images/casino3.jpeg", date: "25/08/2024"},
    { id: 6, entryFeeMin: 60, entryFeeMax: 90, image: "assets/images/casino1.jpeg", date: "30/06/2024"},
]
const OngoingGamesCard = () => {
    const { classes } = useStyles();
    return (
        <Box className={classes.card}>
            <Typography variant="h5" fontWeight={"bold"}>
                Ongoing Games
            </Typography>
            <Grid container spacing={3} className={classes.ongoingGames}>
                {ongoingGames.map((game: any) => (
                    <Grid item xs={4} key={game.id}>
                        <Card className={classes.gameCard} variant={"outlined"}>
                            <Box
                                sx={{
                                    backgroundImage: `url(${game.image})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    height: "150px",
                                    borderRadius: "10px",
                                }}
                            />
                            <Box className={classes.gameCardContent}>
                              <Box>
                                <Typography variant="h6">Game {game.id}</Typography>
                                <Typography variant="body1">
                                    Entry Fee: ${game.entryFeeMin} - ${game.entryFeeMax}
                                </Typography>
                                <Typography variant="body1">
                                    Last Date: {game.date}
                                </Typography>
                              </Box>
                              <Box marginLeft={"auto"}>
                                  <Button className={classes.joinButton}>Join</Button>
                              </Box>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OngoingGamesCard;
