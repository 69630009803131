/**
 * @file: useAccountStatus.ts
 * @date: 12 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {useState} from "react";
import {useSessionStorage} from "app/hooks";


export type T_MoneyhubPaymentState = {
    transactionId: string;
    isOtpVerified: boolean;
    isConfirmed: boolean;
    isTransactionInitiated: boolean;
    transactionLink: string;
    isTransactionCompleted: boolean;
    redirectUrl:string;
    isKycCompleted: boolean;
    isKycInitiated: boolean;
    isKycSubmitted: boolean;
}

export type T_UseMoneyhubPaymentState = T_MoneyhubPaymentState & {
    update: (newStatus: Partial<T_MoneyhubPaymentState>) => void;
}


export const useMoneyhubPaymentState = (transactionId:any): T_UseMoneyhubPaymentState => {

    const dataKey: string = transactionId;//'122a2bea78af5420dc2e7fd72ef6a253';

    const _storage = useSessionStorage<T_MoneyhubPaymentState>(dataKey, {
        transactionId:'', isOtpVerified: false, isConfirmed: false, isKycCompleted:false,
        isTransactionInitiated:false, transactionLink:'', isTransactionCompleted:false,
        redirectUrl:'',isKycInProgress:false,isKycSubmitted:false,
    });

    const [paymentState, setPaymentState] = useState<T_MoneyhubPaymentState>(() => {
        return _storage.getValue();
    });


    const updatePaymentState = (newTransactionState: Partial<T_MoneyhubPaymentState>): void => {

        const updatedPaymentState: T_MoneyhubPaymentState = {
            ...paymentState, ...newTransactionState
        };
        _storage.setValue(updatedPaymentState);
        setPaymentState(updatedPaymentState);
    }

    return {...paymentState, update: updatePaymentState};

}
