import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { pallete } from "app/styles";
import { useLoader, useNotification } from "app/providers";
import { getAxiosAPI } from "app/utils/axiosApiRequests";
import CancelIcon from "@mui/icons-material/Cancel";
import { T_UseMoneyhubPaymentState } from "./MoneyhubPaymentHooks";
import { RQ } from "app/hooks";
import { ApiConfig } from "app/config";
import Loader from "app/components/unsorted/Loader";

type PageName = "sendToMerchant" | "successCard" | "failure";

type T_StatusViewProps = {
  classes: any;
  onClickHandler: () => void;
};

type T_ViewProps = {
  classes: any;
  paymentState: T_UseMoneyhubPaymentState;
  paymentId: string | any;
};

const TransactionSuccessView = ({
  classes,
  onClickHandler,
}: T_StatusViewProps) => {
  return (
    <Card className={classes.merchantCard} sx={{ padding: "100px 50px" }}>
      <Box
        className={classes.imagBox}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <Box
          component="img"
          className={classes.successIcon}
          src="/assets/icons/success.svg"
          alt="Descriptive Alt Text"
        />
      </Box>
      <br />
      <Typography variant="h6" align="center">
        Your transaction is Sucessfully done
      </Typography>
      <Button
        onClick={() => onClickHandler()}
        variant="contained"
        className={classes.submitBtn}
      >
        Go to Home
      </Button>
    </Card>
  );
};

const TransactionFailedView = ({
  classes,
  onClickHandler,
}: T_StatusViewProps) => {
  return (
    <Card className={classes.merchantCard} sx={{ padding: "100px 50px" }}>
      <Box
        className={classes.imagBox}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <CancelIcon sx={{ fontSize: "100px", color: pallete.selections.red }} />
      </Box>
      <br />
      <Typography variant="h6" align="center">
        Your transaction is failed
      </Typography>
      <Button
        onClick={() => onClickHandler()}
        variant="contained"
        className={classes.submitBtn}
      >
        Go to Home
      </Button>
    </Card>
  );
};

export const TransactionStatusView = ({
  classes,
  paymentState,
  paymentId,
}: T_ViewProps) => {
  const notification = useNotification();
  const loader = useLoader();
  const apiConfig = ApiConfig.create("moneyhub-transaction", [], [paymentId]);

  const transactionStatus = RQ.useQueryRequest({
    url: apiConfig.url,
    queryKey: apiConfig.key,
  });

  const sendCryptoToMerchant = async () => {
    loader.show();
    const url = "/moneyhub-transaction/" + paymentId + "/send-to-merchant";
    await getAxiosAPI(url)
      .then((res) => {
        loader.hide();
        const { success, data, message, code } = res.data;
        if (success) {
          paymentState.update({
            isTransactionCompleted: true,
            redirectUrl: data.redirect_url,
          });
        } else {
          notification.error(res.data?.message);
        }
      })
      .catch((err: any) => {
        loader.hide();
        notification.error("unable to send this crypto to merchant");
        console.log(err);
      });
  };

  const closeTransaction = async () => {
    window.location.href = `${paymentState.redirectUrl}/${paymentId}`;
  };

  if (paymentState.isTransactionCompleted) {
    return (
      <TransactionSuccessView
        classes={classes}
        onClickHandler={closeTransaction}
      />
    );
  }

  if (transactionStatus.isResolving) {
    return <Loader isLoading={true} />;
  }

  if (transactionStatus.isError) {
    return (
      <TransactionFailedView
        classes={classes}
        onClickHandler={closeTransaction}
      />
    );
  }

  if (transactionStatus.result) {
    const transactionDetails = transactionStatus.result;

    return (
      <Card className={classes.merchantCard}>
        <Box
          className={classes.imagBox}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Box
            component="img"
            className={classes.iconWeb}
            src="/assets/logos/tradeBp.svg"
            alt="Descriptive Alt Text"
          />
        </Box>
        <br />
        <Typography align="center" variant="subtitle2">
          {transactionDetails.amount} EUR * USDT Price at the moment
        </Typography>
        <Typography variant="h2" align="center">
          {transactionDetails.asset_quantity} USDT
        </Typography>
        <br />
        <Typography variant="body2" align="center">
          Your transaction is successfully done. Now you can transfer to
          Merchant Wallet
        </Typography>
        <Button
          onClick={() => sendCryptoToMerchant()}
          className={classes.submitBtn}
          variant="contained"
        >
          Send To Merchant
        </Button>
        <Button
          onClick={() => closeTransaction()}
          className={classes.submitBtn}
          variant="contained"
        >
          Keep Crypto for now
        </Button>
      </Card>
    );
  }
};
