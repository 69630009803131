import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Drawer } from "@mui/material";
import SidebarView from "./SidebarView";
import { useNavigate } from "react-router-dom";
import { useHeaderStyles } from "./PrivateLayoutStyles";

export const HeaderView = ({ title }: { title: string }): React.JSX.Element => {
  const { classes } = useHeaderStyles();
  const [drawerState, setDrawerState] = useState(false);
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" className={classes.appBarMain}>
        <Toolbar className={classes.toolBarMain}>
          <IconButton onClick={() => navigate("/")}>
            <Box
              component="img"
              className={classes.iconWeb}
              src="assets/logos/tradeBpMobile.svg"
              alt="Descriptive Alt Text"
            />
          </IconButton>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
          <IconButton onClick={() => setDrawerState(true)}>
            <MenuRoundedIcon className={classes.menueIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerState}
        onClose={() => setDrawerState(false)}
        sx={{ width: "80%" }}
        variant="temporary"
        ModalProps={{
          style: { transitionDuration: "400ms" }, // Set the transition duration to 500 milliseconds
        }}
      >
        <SidebarView />
      </Drawer>
    </Box>
  );
};
