import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { pallete } from "app/styles";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../Style";
import { motion } from "framer-motion";
const Wallet = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.walletBox}>
        <Typography color="primary" variant="h1" align="center">
          Wallet
        </Typography>
        <Grid container className={classes.walletGrid}>
          <Grid item lg={6}>
            {/* <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 1.5,
              }}
            > */}
            <Box
              component="img"
              className={classes.walletImg}
              src="/assets/images/wallet_img.png"
              alt="Descriptive Alt Text"
            />
            {/* </motion.div> */}
          </Grid>
          <Grid item lg={4} className={classes.walletRightGrid}>
            <Typography
              color="primary"
              variant="h5"
              sx={{ fontWeight: 600, padding: "20px 0" }}
            >
              Your Personal Wallet{" "}
            </Typography>
            <Typography color="primary" variant="body2">
              Wallets and custody for major cryptocurrencies, stablecoins and
              other digital assets alongside fiat currency accounts.
            </Typography>
            <Button
              sx={{ margin: "50px 0" }}
              className={classes.btn1}
              onClick={() => navigate("/signup")}
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.openAccBox}>
          <Typography
            sx={{ fontWeight: "600" }}
            variant="h3"
            color={pallete.primaryWhite}
          >
            Open Business Account
          </Typography>
          <Button className={classes.btnAc} onClick={() => navigate("/signup")}>
            Get Started
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Wallet;
