import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFlexPaymentStyles } from "./FlexhubPaymentStyles";
import { T_UseFlexhubPaymentState } from "./FlexhubPaymentHooks";
import { Link } from "react-router-dom";
import { useFlexhubTransactionRequest } from "./FlexhubPaymentQueries";
import { useLocalization } from "app/hooks";
import Loader from "app/components/unsorted/Loader";

const FlexhubTermsView = ({ paymentState } : { paymentState: T_UseFlexhubPaymentState }) => {
  const { t } = useTranslation();
  const { classes } = useFlexPaymentStyles();
  const [isChecked, setIsChecked] = useState(false);
  const transactionRequest = useFlexhubTransactionRequest(paymentState.transactionId);
  const { initializeLanguage } = useLocalization();

  useEffect(() => {
    const initLang = async () => {
      if (!transactionRequest.result) return;

      let languageSymbol =  transactionRequest.result.language_symbol;

      if (languageSymbol === "zh-CN"){
        languageSymbol = "zh";
      }

      if (!["en", "pt", "zh"].includes(languageSymbol)) {
        languageSymbol = "en";
      }

      sessionStorage.setItem("userLanguage", languageSymbol);
      initializeLanguage(languageSymbol);
    };

    initLang();
  }, [transactionRequest.result?.language_symbol]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleProceed = () => {
    paymentState.update({ isTermsConfirmed: true });
  };

  if(transactionRequest.isResolving) {
    return <Loader isLoading={true} />
  }

  return (
    <Box className={classes.mainCard}>
      <Box className={classes.confirmationCard}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            <Typography variant="h4" component="div" >
                {t("Disclaimer")}
            </Typography>
        </Box>
        <Typography variant="h5">{t("If funds of 10 euros or less are transferred, funds will not be credited to you after conversion.")}</Typography>
        <Divider />
        <Box className={classes.termsAndConditions}>
          <Typography variant="h5">
            {t("Please read and accept our terms and conditions to proceed.")}
            <span>
              {" "}
              <Link to="/privacy-policy" target="_blank">
                Terms and Conditions
              </Link>
            </span>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                name="termsCheckbox"
                color="primary"
              />
            }
            label={t("I accept the terms and conditions")}
          />
        </Box>
        <Box className={classes.confirmationButtonBox}>
          <Button
            className={classes.confirmationButton}
            variant="contained"
            color="primary"
            onClick={handleProceed}
            disabled={!isChecked}
          >
            {t("Proceed")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FlexhubTermsView;
