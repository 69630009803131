import React from "react";
import {useNotification} from "app/common";

import {downloadCSVFile} from "app/utils";
import {T_CommonTableComponentProps} from "./TableCommonFragments";
import {TablePluginsSkeleton,} from "./TableSkeletons";
import {DownloadButton} from "app/components/inputs/buttons";


export type T_DownloadDataFnResult<Tdata = any> = {
    data: Tdata[];
    headings: string[];
}

type T_DownloadKeysMap = {
    [key: string]: any;
}


export type T_TableDownloadOptions<Tdata = any> = {
    fileName: string;
    dataMap: T_DownloadKeysMap;
    nullValue?: string;
    getDataFn?: () => Promise<Tdata[]>;
    columnValueParser?: (key: string, row: Tdata) => any;
};

export type T_DownloadPluginProps<Tdata = any> = T_TableDownloadOptions & T_CommonTableComponentProps & {
    getDataFn: () => Promise<T_DownloadDataFnResult<Tdata>>;
}


export const TableDownloadCsvPlugin = (options: T_DownloadPluginProps): React.JSX.Element => {

    const notification = useNotification();

    const {classes, dataMap, fileName, getDataFn, ...opt} = options;

    const headings = Object.keys(dataMap);

    const dataKeys = Object.values(dataMap);

    const nullValue = options.nullValue || "NULL";

    const keyPrefix: string = `${opt.keyPrefix}-download-csv-plugin`;

    const parsedColumnValue = options.columnValueParser || ((key: string, row: any) => {
        return (row[key] !== undefined) ? row[key] : nullValue;
    });

    const parsedRowData = (row: any) => {
        return dataKeys.map(key => parsedColumnValue(key, row));
    }

    const handleCsvDownload = async (): Promise<void> => {
        return getDataFn().then(async (tableDataList) => {
            const data = tableDataList.map((row: any) => parsedRowData(row));
            await downloadCSVFile({data, headings, fileName});
            notification.success("CSV downloaded sucessfully");
        }).catch(e => {
            notification.error("Unable to download the CSV");
        }).finally(() => {
        });

    }

    if (opt.isLoading) {
        return TablePluginsSkeleton({keyPrefix})
    }

    return (<DownloadButton onClickHandler={handleCsvDownload} />);
}
