import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import Loader from "app/components/unsorted/Loader";
import { useLocation } from "react-router-dom";
import { RQ } from "app/hooks/";
import { T_UseCryptohubPaymentState } from "./CryptohubPaymentHooks";
import { ApiConfig } from "app/config";
import { useLoader, useNotification } from "app/providers";
import { useCryptohubTransactionRequest } from "./CryptohubPaymentQueries";
import { useTranslation } from "react-i18next";

type T_ViewProps = {
  paymentState: T_UseCryptohubPaymentState;
};

const LivelinessVerification = ({ paymentState }: T_ViewProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loader = useLoader();
  const notification = useNotification();
  const kyc_result = queryParams.get("kyc_callback");
  const apiConfig = ApiConfig.create(
    "crypto-hub-transaction-request",
    ["crypto-hub-transaction-request"],
    [paymentState.transactionId, "kyc-verification"]
  );

  const kycStatus = RQ.useQueryRequest({
    url: apiConfig.url,
    queryKey: apiConfig.key,
  });

  const kycRequest = RQ.usePostRequest({
    url: apiConfig.url,
    mutationKey: apiConfig.key,
  });

  const transactionDetails = useCryptohubTransactionRequest(
    paymentState.transactionId
  );

  const updatePaymentState = () => {
    paymentState.update({ isKycCompleted: true });
  };

  if (
    transactionDetails.isResolved &&
    !transactionDetails.result?.kyc_status.full_kyc_required
  ) {
    updatePaymentState();
  }

  const kycInitiate = async () => {
    await kycRequest.EXECUTE({
      onSuccessFn: (data, message) => {
        const { expire_at, kyc_is_completed, kyc_url } = data;
        return (window.location.href = kyc_url);
      },
    });
  };

  if (
    kycStatus.isResolving ||
    kycRequest.isPending ||
    transactionDetails.isRefetching
  ) {
    return <Loader isLoading={true} />;
  }

  if (kycStatus.isResolved) {
    loader.hide();
  }

  if (kycStatus.result) {
    const { is_kyc_completed, result } = kycStatus.result;
    if (is_kyc_completed) {
      paymentState.update({ isKycCompleted: true });
    }
  }

  return (
    <Grid item md={6} className={classes.rightGrid}>
      <Box className={classes.formBox}>
        <Typography variant="h3" align="center" className={classes.formHeading}>
          {t("Complete you KYC")}
        </Typography>
        <br />
        <Typography align="center" variant="h6" color="primary">
          {t("Click below to start your KYC Process.")}
        </Typography>
        <br />
        <Button
          onClick={() => {
            kycInitiate();
          }}
          variant="contained"
          className={classes.submitBtn}
        >
          {t("Continue with KYC")}
        </Button>
      </Box>
    </Grid>
  );
};

export default LivelinessVerification;
const useStyles = makeStyles()((theme) => {
  return {
    label: {
      // Custom styles for the label
      color: "#B9B9B9 !important", // Change label text color
      // fontSize: '1rem', // Change font size
      "&.Mui-focused": {
        color: "blue", // Color of the label when the input is focused
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    createPasswordImg: {},
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    inputBoxes: {
      // backgroundColor:"pink",
      // height:"40px",
      // width:"40px",
      padding: "10px",
      margin: "20px 10px",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
