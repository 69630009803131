/**
 * @file: useStorage.ts
 * @date: 21 Sep 2023

 * @description: $
 */
import {AppStorage} from "app/utils";

const useStorage = <T = any>(key: string, defaultValue: any, useLocalStorage: boolean = false) => {
    const _storage = useLocalStorage ? AppStorage.getLocalStorage() : AppStorage.getSessionStorage();

    const setValue = (value: T) => {
        return _storage.setItem(key, value);
    };

    const getValue = (callback?: (value: any) => void): T => {
        return _storage.getItem(key, defaultValue, callback) ?? defaultValue;
    };

    const removeValue = (value: any) => {
        _storage.removeItem(key);
    };

    return {getValue, setValue, removeValue};
};

export const useLocalStorage = <T = any>(key: string, defaultValue: any) => {
    return useStorage<T>(key, defaultValue, true);
}

export const useSessionStorage = <T = any>(key: string, defaultValue: any) => {
    return useStorage<T>(key, defaultValue, false);
}
