import axios from "axios";
import {getTokens, setTokens} from "app/utils/http";

export let axiosInstance: any;

async function refreshToken() {
    // Replace '/refresh-token' with your actual refresh token endpoint
    const refreshTokenUrl = `${process.env.REACT_APP_BASE_URL_API}/auth/refresh`;
    const tokens = getTokens()

    try {
        // Perform refresh token operation
        const response = await axios.post(refreshTokenUrl, {
            refresh: tokens.refreshToken, // Adjust based on your API's expected parameters
        });
        const { access,  } = response.data;
        setTokens(access,tokens.refreshToken);
        return access;
    } catch (error) {
        console.error("Failed to refresh token", error);
        // Handle token refresh failure (e.g., redirect to login)
        return null;
    }
}

export function getAxiosInstance() {
    return axios.create({
        baseURL: process.env.REACT_APP_BASE_URL_API,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}


// Private Axios instance for api-requests that require a token
export function getPrivateAxiosInstance() {
    const {accessToken} = getTokens();
    const instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL_API,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    instance.interceptors.response.use(response => response, async (error) => {
        const originalRequest = error.config;
        // Check if the response is 401 and not a result of a retry
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newAccessToken = await refreshToken();
            if (newAccessToken) {
                // Update the authorization header and retry the request
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
                return instance(originalRequest);
            }
        }
        return Promise.reject(error);
    });

    return instance;
}

export async function getAxiosAPI(url: any, usePrivate = false) {
    try {
        const instance = usePrivate ? getPrivateAxiosInstance() : getAxiosInstance();
        const response = await instance.get(url);
        return ({ error: false, data: response.data });
    } catch (error) {
        let err = error as any
        console.log(err.data)
        return ({ error: true, data: err.data });
    }
}

export async function deleteAxiosAPI(url: any) {
    try {
        const response = await getAxiosInstance().delete(url);
        return ({ error: false, data: response.data });
    } catch (error) {
        let err = error as any
        console.log(err.data)
        return ({ error: true, data: err.data });
    }
}

export async function postAxiosAPI(url: any, data: any, usePrivate = false) {
    try {
        const instance = usePrivate ? getPrivateAxiosInstance() : getAxiosInstance();
        const response = await instance.post(url, data);
        return ({ error: false, data: response.data });
    } catch (error) {
        let err = error as any
        console.log(err.data)
        return ({ error: true, data: err.data });
    }
}

export async function patchAxiosAPI(url: any, data: any = null) {
    try {
        const response = await getAxiosInstance().patch(url, data);
        return ({ error: false, data: response.data });
    } catch (error) {
        let err = error as any
        console.log(err.data)
        return ({ error: true, data: err.data });
    }
}

export async function putAxiosAPI(url: any, data: any,  usePrivate = false) {
    try {
        const instance = usePrivate ? getPrivateAxiosInstance() : getAxiosInstance();
        const response = await instance.put(url, data);
        return ({ error: false, data: response.data });
    } catch (error) {
        let err = error as any
        console.log(err.data)
        return ({ error: true, data: err.data });
    }
}

export function capitalizeFirstLetter(str: any) {
    return str[0].toUpperCase() + str.slice(1);
}

export function getErrorMessage(obj: any, isKeyNeed: boolean = false) {
    let err = obj
    let str = '';
    if (!isKeyNeed) {
        for (let k in err) {
            if (err.hasOwnProperty(k)) {
                str += err[k];
            }
        }
        console.log(str);
        return str
    } else {
        for (const [p, val] of Object.entries(obj)) {
            str += `${capitalizeFirstLetter(p)} ${val}\n`;
        }
        return str;
    }
}
