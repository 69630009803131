import React from "react";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF} from "app/components/table";
import {get2FAStatusProps, getKycStatusProps} from "app/utils";
import {useAppContext} from "app/hooks";
import {useNotification} from "app/common";
import {T_TeamMember, useTeamMembersQuery,useUpdateTeamMemberRequest,useRemoveTeamMemberRequest} from "./TeamsHooks";

const renderFunction = (data: T_TeamMember): React.JSX.Element[] => {
    const keyPrefix:string = data.id.toString();
    return [
        <TDF.TextContent value={data.email} key={`${keyPrefix}-EM`} />,
        <TDF.TextContent value={data.full_name} key={`${keyPrefix}-FN`} />,
        <TDF.NumericContent value={`${data.mobile_no}`} key={`${keyPrefix}-PH`} />,
        <TDF.TextContent value={data.role_name || 'N/A'} key={`${keyPrefix}-RLN`} />,

        <TDF.StatusBadge {...get2FAStatusProps(data.is_2fa_active)} key={`${keyPrefix}-2FA`} />,
        <TDF.StatusBadge {...getKycStatusProps(data)} key={`${keyPrefix}-KYC`} />,


        <TDF.DateTimeContent dateTime={`${data.dob}`} key={`${keyPrefix}-DOB`}  format={'Do MMMM YYYY'}/>,
        <TDF.DateTimeContent dateTime={data.email_verified_at} key={`${keyPrefix}-DT`} />,
        <TDF.DateTimeContent dateTime={data.last_login} key={`${keyPrefix}-DTLL`} />,
    ]
}

const teamMembersDataMap = {
    "Email": "email",
    "Name": "first_name",
    "Mobile": "mobile_no",
    "Role": "role_name",
    "2FA": "is_2fa_active",
    "KYC": "kyc_is_verified",
    "Date Of Birth": "dob",
    "Date": "email_verified_at",
    "Last Login": "last_login",
};



type T_TeamMembersViewProps = {};

export const TeamMembersView = (props: T_TeamMembersViewProps): React.JSX.Element => {
    const {user} = useAppContext();


    const notification = useNotification()
    const teamMembersQuery= useTeamMembersQuery();
    const updateTeamMemberRequest = useUpdateTeamMemberRequest();
    const removeTeamMemberRequest = useRemoveTeamMemberRequest();
    const headings: string[] = Object.keys(teamMembersDataMap);
    const teamMembers = teamMembersQuery.isResolved ? teamMembersQuery.result:[];

    const getMenuItemOptionsFn = async (data: T_TeamMember): Promise<TDF.T_MenuItemOptionList> => {
        const msg :string = "You need to wait 5 hrs, before performing this action."
        return new Promise((resolve, reject) => {
            resolve([
                {
                    name: "Remove Team Member", onClickHandler: ()=>{
                        // removeTeamMemberRequest.EXECUTE({params:[data.id]})
                        notification.info(msg)
                    }
                },
                {
                    name: "Edit Team Member", onClickHandler: ()=>notification.info(msg)
                },
            ]);
        });
    }

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Team Members",
        headings: headings,
        isLoading: teamMembersQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: teamMembers,
        actionColumn: !user.isTeamMember(),
        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ['email', 'full_name', 'mobile'],
        },
    }
    if(!user.isTeamMember()){
        tableComponentProps.downloadOptions = {
            fileName: "team-members.csv",
            dataMap: teamMembersDataMap,
        }
        tableComponentProps.actionOptions = {getMenuItemOptionsFn}
    }
    return (<DefaultTableComponent {...tableComponentProps} />)

}
