/**
 * @file: Logging.ts
 * @date: 26 Feb 2024

 * @description: $
 */



const emptyLogger = (...args: any[]): void => {

}

class ConsoleLogger {
    // Define console methods with any signature
    log: (...args: any[]) => void = emptyLogger;
    error: (...args: any[]) => void = emptyLogger;
    warn: (...args: any[]) => void = emptyLogger;
    info: (...args: any[]) => void = emptyLogger;
    debug: (...args: any[]) => void = emptyLogger;
    assert: (...args: any[]) => void = emptyLogger;
    clear: (...args: any[]) => void = emptyLogger;
    count: (...args: any[]) => void = emptyLogger;
    countReset: (...args: any[]) => void = emptyLogger;
    group: (...args: any[]) => void = emptyLogger;
    groupCollapsed: (...args: any[]) => void = emptyLogger;
    groupEnd: (...args: any[]) => void = emptyLogger;
    dir: (...args: any[]) => void = emptyLogger;
    dirxml: (...args: any[]) => void = emptyLogger;
    table: (...args: any[]) => void = emptyLogger;
    time: (...args: any[]) => void = emptyLogger;
    timeLog: (...args: any[]) => void = emptyLogger;
    timeEnd: (...args: any[]) => void = emptyLogger;
    trace: (...args: any[]) => void = emptyLogger;
    profile: (...args: any[]) => void = emptyLogger;
    profileEnd: (...args: any[]) => void = emptyLogger;
    timeStamp: (...args: any[]) => void = emptyLogger;

}


export class ApplicationLogger extends ConsoleLogger {
    private static instance: Map<string, ApplicationLogger> = new Map();
    public readonly name: string;

    constructor(name: string) {
        super();
        this.name = name;
        this.log = console.log.bind(console);
        this.error = console.error.bind(console);
        this.warn = console.warn.bind(console);
        this.info = console.info.bind(console);
        this.debug = console.debug.bind(console);
        this.assert = console.assert.bind(console);
        this.clear = console.clear.bind(console);
        this.count = console.count.bind(console);
        this.countReset = console.countReset.bind(console);
        this.group = console.group.bind(console);
        this.groupCollapsed = console.groupCollapsed.bind(console);
        this.groupEnd = console.groupEnd.bind(console);
        this.dir = console.dir.bind(console);
        this.dirxml = console.dirxml.bind(console);
        this.table = console.table.bind(console);
        this.time = console.time.bind(console);
        this.timeLog = console.timeLog.bind(console);
        this.timeEnd = console.timeEnd.bind(console);
        this.trace = console.trace.bind(console);
        this.profile = console.profile.bind(console);
        this.profileEnd = console.profileEnd.bind(console);
        this.timeStamp = console.timeStamp.bind(console);
    }

    public static getInstance(loggerName: string = "root"): ApplicationLogger {
        loggerName = loggerName.toLowerCase();
        if (!ApplicationLogger.instance.get(loggerName)) {
            const logger: ApplicationLogger = new ApplicationLogger(loggerName);
            ApplicationLogger.instance.set(loggerName, logger);
        }
        return ApplicationLogger.instance.get(loggerName) as ApplicationLogger;
    }
}


export const logger = ApplicationLogger.getInstance("root");
