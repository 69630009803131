import React from "react";
import {TabContent} from "app/components/tabs";
import {MoneyHubAccounts} from "./MoneyhubAccounts";
import {CryptohubAccounts} from "./CryptohubAccounts";
import {CustomerAccounts} from "./CustomerAccounts";
import UserDetails from "./UserDetails";


const UserAccountsView = (): React.JSX.Element => {
    const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
    const tabHeading: string = "User Accounts";
    const resetUrlOnTabChange: boolean = true;

    const CustomerAccountsView = (): React.JSX.Element => {
        const customerId: string | null = queryParams.get("userId");
        return (customerId) ? <UserDetails userId={customerId} /> : <CustomerAccounts />;
    }


    const MoneyHubAccountsView = (): React.JSX.Element => {
        const moneyHubUserId: string | null = queryParams.get("mhid");
        return (moneyHubUserId) ? <MoneyHubAccounts /> : <MoneyHubAccounts />;
    }


    const CryptohubAccountsView = (): React.JSX.Element => {
        const cryptohubUserId: string | null = queryParams.get("chid");
        return (cryptohubUserId) ? <CryptohubAccounts /> : <CryptohubAccounts />;
    }

    const tabData = [
        {label: "Customers", component: <CustomerAccountsView />},
        {label: "Moneyhub", component: <MoneyHubAccountsView />},
        {label: "Cryptohub", component: <CryptohubAccountsView />},
    ];

    return TabContent({tabHeading, tabData, resetUrlOnTabChange});
};
export default UserAccountsView;
