import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import AuthScreenLeftBox from "app/components/unsorted/AuthScreenLeft";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { postAxiosAPI } from "app/utils/axiosApiRequests";
import { useNavigate } from "react-router-dom";
import { T_UseAccountStatus, useAuthManager } from "app/hooks";
import { AnimatedOtpInput } from "app/components/inputs";

const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
    .required("OTP is required"),
});

const VerifyGoogle2FA = ({
  accountStatus,
  updateAccountStatus,
}: T_UseAccountStatus) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const authManager = useAuthManager();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const verifyCOde = async (value: any) => {
    setLoading(true);
    const payload = {
      google_2fa_code: value.otp,
    };
    await postAxiosAPI(
      "/verification/verify-google-2fa",
      JSON.stringify(payload),
      true
    )
      .then((res: any) => {
        setLoading(false);
        const result = res.data;
        if (result?.success === true && result?.code === 200) {
          updateAccountStatus({
            is2faCompleted: true,
            is2faActive: true,
          });
        } else {
          setSeverity("error");
          setSnackbarMessage(result?.message);
          setShowSnackbar(true);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
        setSeverity("error");
        setSnackbarMessage("Something went wrong!");
        setShowSnackbar(true);
      });
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    handleSubmit: () => {}
  ) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Grid container className={classes.mainGrid}>
        <Grid item md={4} className={classes.leftGrid}>
          <AuthScreenLeftBox
            title="Welcome to TradeBP"
            bttomText=""
            routeName=""
            path=""
          />
        </Grid>
        <Grid item md={8} className={classes.rightGrid}>
          <Formik
            initialValues={{
              otp: "",
            }}
            validationSchema={otpSchema}
            onSubmit={(values: any) => {
              // same shape as initial values

              verifyCOde(values);
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              values,
            }: any) => (
              <Box
                className={classes.formBox}
                onKeyDown={(event) => handleKeyDown(event, handleSubmit)}
              >
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.formHeading}
                >
                  One time password{" "}
                </Typography>

                <br />
                <Typography align="center" variant="h6" color="primary">
                  Enter the 6-digit code from your 2-factor authentication (2FA)
                  app.
                </Typography>
                <br />
                <AnimatedOtpInput
                  value={values.otp}
                  onChange={handleChange("otp")}
                  numInputs={6}
                  shouldAutoFocus={true}
                />
                <Box className={classes.btnsBox}>
                  <Button
                    onClick={() => authManager.logoutUser()}
                    variant="outlined"
                    className={classes.submitBtnOutloined}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => handleSubmit()}
                    variant="contained"
                    className={classes.submitBtn}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default VerifyGoogle2FA;
const useStyles = makeStyles()((theme) => {
  return {
    label: {
      // Custom styles for the label
      color: "#B9B9B9 !important", // Change label text color
      // fontSize: '1rem', // Change font size
      "&.Mui-focused": {
        color: "blue", // Color of the label when the input is focused
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    createPasswordImg: {},
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    inputBoxes: {
      // backgroundColor:"pink",
      // height:"40px",
      // width:"40px",
      padding: "10px",
      margin: "20px 10px",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
