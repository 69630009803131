/**
 * @file: AccessToken.ts
 * @date: 24 Sep 2023

 * @description: $
 */

export  type T_UserRole = {
    id: number | undefined;
    name: string | undefined;
}


export type T_UserSession = {
    id: string | undefined;
    email: string | undefined;
    auth_uuid: string | undefined;
    username: string | undefined;
    image: string | undefined;
    auth_type: string | undefined;
    account_type: string | undefined;
    team_member_id:number | undefined;
    role: T_UserRole;
    is_authenticated: boolean;
    is_admin: boolean;
    login_time: string | undefined;
    permissions?: string[]

}


export class UserSession {
    private readonly data: T_UserSession = {} as T_UserSession;

    constructor(session?: T_UserSession) {
        this.data = {...(session || {} as T_UserSession)};
    };

    email(): string | undefined {
        return this.data?.email || undefined;
    }

    username(): string | undefined {
        return this.data?.username || undefined;
    }

    image(): string | undefined {
        return this.data?.image || undefined;
    }

    authType(): string | undefined {
        return this.data?.auth_type || undefined;
    }

    accountType(): string | undefined {
        return this.data?.account_type || undefined;
    }

    role(): T_UserRole {
        return this.data?.role || {} as T_UserRole;
    }

    isAuthenticated(): boolean {
        return this.data?.is_authenticated;
    }

    isAdmin(): boolean {
        return this.data?.is_admin || false;
    }

    teamMemberId(): number | undefined {
        return this.data?.team_member_id;
    }

    isTeamMember(): boolean {
        return !isNaN(parseInt(this.teamMemberId()?.toString() || ''));
    }

    loginTime(): string | undefined {
        return this.data?.login_time || undefined;
    }

    permissions(): string[] | undefined {
        return this.data?.permissions || undefined;
    }

    authUuid(): string | undefined {
        return this.data?.auth_uuid || undefined;
    }

    id(): string | undefined {
        return this.data?.id || undefined;
    }

    getData(): T_UserSession {
        return this.data
    }


}

export type T_AuthToken = {
    exp: number;
    iat: number;
    jti: string;
    id: string;
    ip: string;
    is_valid: boolean;
    owner: string;
    env: string;
    access_type: string;
    session: T_UserSession;
};


export class AuthToken {
    private readonly expiresIn: number;
    private readonly issuedAt: number;
    private readonly jti: string | null;
    private readonly id: string | null;
    private readonly ip: string | null;
    private readonly is_valid: boolean;
    private readonly owner: string | null;
    private readonly env: string | null;
    private readonly access_type: string | null;
    private readonly session: UserSession;


    constructor(token?: T_AuthToken) {
        console.log(token);
        this.expiresIn = token?.exp || 0;
        this.issuedAt = token?.iat || 0;
        this.jti = token?.jti || null;
        this.id = token?.id || null;
        this.ip = token?.ip || null;

        this.is_valid = token?.is_valid || false;
        this.owner = token?.owner || null;
        this.env = token?.env || null;
        this.access_type = token?.access_type || null;
        this.session = new UserSession(token?.session || {} as T_UserSession);

    }

    public getSessionData(): UserSession {
        return this.session;
    };

    public getUserId(): string {
        return this.session.id() as string;
    }

    public isValid(): boolean {
        return (this.is_valid) ? (
            Date.now() - this.issuedAt < this.expiresIn
        ) : false;
    };

    public getAuthType(): string | undefined {
        return this.session.authType();
    };


}
