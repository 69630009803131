/**
 * @file: httpUtils.ts
 * @date: 02 Nov 2023
 * @description: $
 */
import {AppStorage} from "app/utils/AppStorage";

export const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};


export const getTokens = (): { accessToken: string, refreshToken: string } => {
    const storage = AppStorage.getLocalStorage();
    const {accessToken, refreshToken} = storage.getItem("authToken", {
        accessToken: undefined, refreshToken: undefined
    });
    return {accessToken, refreshToken}
}

export const setTokens = (accessToken: string, refreshToken: string): void => {
    const storage = AppStorage.getLocalStorage();
    storage.setItem("authToken", {accessToken, refreshToken});
}
