import React, {ReactElement} from "react";
import {ContactOTC} from "./contact-otc";
import UserAccountsView from "./user-accounts";
import ManualRegistration from "./manual-registration/ManualRegistration";
import FinanceReportView from "./finance-report";
import WhitelistedAddress from "./whitelistedAddress";

const MANAGEMENT_VIEWS_MAPPING: Record<string, ReactElement> = {
    "/contact-otc": <ContactOTC />,
    "/users-list": <UserAccountsView />,
    "/manual-registration": <ManualRegistration />,
    "/finance-report": <FinanceReportView />,
    "/all-white-listed-address": <WhitelistedAddress />,
}

export default MANAGEMENT_VIEWS_MAPPING;
