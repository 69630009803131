import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Popover,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { useLocation, useNavigate } from "react-router-dom";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { decodeToken } from "app/utils/commonFunctions";
import { getAxiosAPI } from "app/utils/axiosApiRequests";
import PersonIcon from "@mui/icons-material/Person";
import { useAppContext, useAuthManager } from "app/hooks";

export const userMenue = [
  {
    id: 1,
    title: "Settings",
    path: "/settings",
    img: "assets/icons/setting.svg",
    isActive: false,
  },
  {
    id: 2,
    title: "Support",
    path: "/support",
    img: "assets/icons/support.svg",
    isActive: false,
  },
  {
    id: 3,
    title: "Log out",
    path: "/logout",
    img: "assets/icons/logout.svg",
    isActive: false,
  },
];

const SidebarView = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [menue, setMenue] = useState<any>([]);
  const [menueChild, setMenueChild] = useState(userMenue);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [anchorEl, setAnchorEl] = useState(null);
  const decoded: any = decodeToken();
  const [userDetails, setUserDetails] = useState({
    fullname: "",
    companyName: "",
    mobileNumber: "",
    workNumber: "",
    email: "",
    corporateEmail: "",
    countryCity: "",
    address: "",
  });
  const { menus, user } = useAppContext();
  const authManager = useAuthManager();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getUserDetails = async () => {
    const response = await getAxiosAPI("/user-account/details", true)
      .then((res: any) => {
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          const values = res.data.data;
          setUserDetails({
            fullname: values.username,
            companyName: values.company_name,
            mobileNumber: values.mobile_no,
            workNumber: "",
            email: values.email,
            corporateEmail: "",
            countryCity: values.country + ", " + values.city,
            address: "",
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    console.log(response);
  };

  const handleNavigate = (item: any) => {
    navigate(item.path);
  };

  const handleNavigatePopover = (item: any) => {
    if (item.path === "/logout") {
      authManager.logoutUser();
    } else if (item.path === "/settings") {
      navigate(item.path);
    } else {
      setSnackbarMessage(item.title + " feature is coming soon...");
      setShowSnackbar(true);
    }
  };

  const setActiveTab = (list: any[], path: string) => {
    const newList = list.map((x: any) => {
      if (x.path === path) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
      return x;
    });
    return newList;
  };

  useEffect(() => {
    if (location.pathname === "/settings" || location.pathname === "/support") {
      const newList = setActiveTab(userMenue, location.pathname);
      setMenueChild(newList);
    }
    getUserDetails();
  }, [location.pathname]);

  useEffect(() => {
    if (decoded && Object.keys(decoded).length && location.pathname) {
      const role = decoded.session.role.name;
      const keys = [10, 15, 9];
      const lists = menus
        .getMenuList()
        .filter((x: any) => !keys.includes(x.key));
      if (role === "Admin") {
        const newList = setActiveTab(lists, location.pathname);
        setMenue(newList);
      } else {
        const newList = setActiveTab(lists, location.pathname);
        setMenue(newList);
      }
    }
  }, [location.pathname]);

  return (
    <Box className={classes.mainBox}>
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Box className={classes.upperBox}>
        <Box
          component="img"
          className={classes.iconWeb}
          src="assets/logos/tradeBp.svg"
          alt="Descriptive Alt Text"
        />
        <Box className={classes.personIconBox}>
          {user.image() ? (
            <Avatar
              alt="Profile Image"
              src={user.image()}
              sx={{ width: 110, height: 110 }}
            />
          ) : (
            <PersonIcon
              sx={{ fontSize: "100px", color: pallete.primaryWhite }}
            />
          )}
        </Box>
        <Box className={classes.namesBox} onClick={handleClick}>
          <Typography variant="h6">{userDetails.fullname}</Typography>
          <Box
            component="img"
            className={classes.downIcn}
            src="assets/icons/downArrow.svg"
            alt="Descriptive Alt Text"
          />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            {menueChild.map((item: any) => (
              <ListItem
                key={item.id}
                className={
                  item.isActive
                    ? classes.listItemBtnActive
                    : classes.listItemBtn
                }
                disablePadding
                onClick={() => handleNavigatePopover(item)}
              >
                <ListItemButton sx={{ padding: "12px 60px 12px 12px" }}>
                  <ListItemIcon className={classes.listImgBtn}>
                    <Box
                      component="img"
                      className={classes.listIcons}
                      src={item.img}
                      alt="Descriptive Alt Text"
                    />
                  </ListItemIcon>
                  <Typography
                    variant="h6"
                    className={
                      item.isActive
                        ? classes.listItemTextActive
                        : classes.listItemText
                    }
                  >
                    {item.title}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Popover>
        {/* <Typography variant="subtitle1">Apple inc</Typography> */}
      </Box>

      {/* List Box */}

      <Box className={classes.listBox}>
        <List>
          {menue.map((item: any, index: number) => (
            <ListItem
              key={`${item.id}-${index}`}
              className={
                item.isActive ? classes.listItemBtnActive : classes.listItemBtn
              }
              disablePadding
              onClick={() => handleNavigate(item)}
            >
              <ListItemButton sx={{ padding: "12px" }}>
                <ListItemIcon className={classes.listImgBtn}>
                  <Box
                    component="img"
                    className={classes.listIcons}
                    src={`assets/icons/${item.path.split("/")[1]}.svg`}
                    alt="Descriptive Alt Text"
                  />
                </ListItemIcon>
                <Typography
                  variant="h6"
                  className={
                    item.isActive
                      ? classes.listItemTextActive
                      : classes.listItemText
                  }
                >
                  {item.label}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default SidebarView;

const useStyles = makeStyles()((theme) => {
  return {
    listImgBtn: {
      ...styles.flexDRC,
    },
    personIconBox: {
      backgroundColor: pallete.primaryPurple,
      height: "120px",
      width: "120px",
      borderRadius: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 0 10px 0",
    },
    listItemText: {
      "&:hover": {
        color: pallete.primaryWhite,
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22.4px",
      },
      transition: "background-color 300ms",
      color: pallete.primaryBlack,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22.4px",
    },
    listItemTextActive: {
      "&:hover": {
        color: pallete.primaryPurple,
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22.4px",
      },
      transition: "background-color 300ms",
      color: pallete.primaryWhite,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22.4px",
    },
    listIcons: {
      height: "24px",
      width: "24px",
    },
    listItemBtn: {
      "&:hover": {
        backgroundColor: pallete.primaryPurple, // or any color you prefer
        // Add more styles as needed
      },
      transition: "background-color 300ms",
      width: "100%",
      //   padding:"5px 0"
    },
    listItemBtnActive: {
      "&:hover": {
        backgroundColor: pallete.primaryWhite, // or any color you prefer
      },
      backgroundColor: pallete.primaryPurple,
      transition: "background-color 300ms",
      width: "100%",
      //   padding:"5px 0"
    },
    listBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: "20px",
      height: "calc(100vh - 270px)",
      overflow: "hidden",
      overflowY: "auto",
    },
    namesBox: {
      display: "flex",
      flexDirection: "row",
      padding: "10px 0",
      cursor: "pointer",
    },
    downIcn: {
      height: "24px",
      width: "24px",
      margin: "auto 5px",
    },
    upperBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0px 0",
    },
    userImg: {
      height: "100px",
      width: "100px",
      margin: "10px 0",
    },
    mainBox: {
      display: "flex",
      flexDirection: "column",
      padding: "24px 0",
    },
    iconWeb: {
      width: "100%",
      height: "45px",
    },
  };
});
