import {QUserAccount} from "app/queries";
import {useContext} from "react";
import {AuthToken, T_AppMenu, T_AuthUser, UserSession} from "app/models";
import {useNavigate} from "react-router-dom";
import {useAccountStatus} from "./useAccountStatus";
import {useAuthTokenManager} from "./useAuthToken";
import {AuthUserContext, T_AppContext, useLoader} from "app/providers";
import {logger} from "app/config";

export type SuccessLoginResponse = {
    access: string;
    refresh: string;
};

type T_AuthManagerResult = {
    loginUser: (accessToken: string, refreshToken: string) => any;
    logoutUser: () => void;
}


export const useAuthManager = (): T_AuthManagerResult => {
    const navigate = useNavigate();
    const loader = useLoader();
    const tokenManager = useAuthTokenManager();
    const {context, setContextData} = useContext(AuthUserContext);
    const accountStatusQuery = QUserAccount.useAccountStatusQuery();
    const userMenusQuery = QUserAccount.useUserMenusQuery();
    const {updateAccountStatus} = useAccountStatus();

    const loginUser = async (accessToken: string, refreshToken: string): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!accessToken || !refreshToken) {
                    reject(new Error("Failed to authenticate."));
                }

                /** set the tokens in local storage **/
                tokenManager.setAuthTokens(accessToken, refreshToken);

                /** get an instance of JWT Token received from server **/
                const tokenData: AuthToken = tokenManager.getJwtToken();

                /** get an instance of UserSession from token **/
                const sessionData: UserSession = tokenData.getSessionData();

                /** user verification details **/
                const accountStatus = await accountStatusQuery.getResult();

                updateAccountStatus({
                    isKycCompleted: accountStatus?.kyc_is_completed ?? true,
                    isEmailVerified: accountStatus?.is_email_verified ?? false,
                    is2faActive: accountStatus?.is_2fa_active ?? false,
                    is2faCompleted: false,
                    isPasswordCreated: !(accountStatus?.is_password_setup_required ?? true),
                    isProfileCompleted: (accountStatus?.is_profile_completed ?? true)
                });

                /** User Session Data **/
                const user: T_AuthUser = sessionData.getData();

                /** User Menus Data **/
                const userMenus: T_AppMenu[] = await userMenusQuery.getResult();

                userMenus.push({
                    key: 1112,
                    label:"Withdraw",
                    route:"",
                    path:"/withdraw",
                    order:1112,
                    permissions:"111111",
                });
                
                const menus: T_AppMenu[] = userMenus.sort((a, b) => a.order - b.order);

                /** Update the auth context data in the app **/
                const authContextData: T_AppContext = {menus, user};
                setContextData(authContextData);
                return "Authentication Succesfull";

            } catch (error: any) {
                reject(new Error("Authentication Failed."));
                logger.error(error);
            }


        });
    }


    const logoutUser = () => {
        // TODO : REfactor this
        localStorage.clear();
        setContextData({} as T_AppContext);
        navigate('/login');
    }

    return {loginUser, logoutUser}

}
