import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";

export const APIS = {
    MONEYHUB_USER_CRUD: ApiConfig.create("moneyhub-user"),
    MONEYHUB_USER_TRANSACTION: ApiConfig.create("moneyhub-user"),
}


export type T_MoneyHubUserAccount = {
    id: string;
    full_name: string;
    email: string;
    dob: string;
    mobile: string | null;
    is_registered: boolean;
    kyc_is_verified: boolean;
    kyc_is_in_progress: boolean;
    kyc_is_completed: boolean;
    email_verified_at: string;
};

export const useUseMoneyHubUserAccountsListQuery = (): RQ.T_QueryResult<T_MoneyHubUserAccount[]> => {
    return RQ.useQueryRequest<T_MoneyHubUserAccount[]>({
        url: APIS.MONEYHUB_USER_CRUD.url,
        queryKey: APIS.MONEYHUB_USER_CRUD.key,
    });
}
