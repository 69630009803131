import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useStyles } from "../Style";
// import { motion } from "framer-motion";
import { motion, Variants } from "framer-motion";

const product = [
  {
    id: 0,
    img: "/assets/images/best-execution-product.png",
    name: "Best Execution",
    about:
      "Cryptocurrency trading, execution management system and seamless settlement",
  },
  {
    id: 1,
    img: "/assets/images/moneyhub-product.png",
    name: "MoneyHub",
    about: "Full-tech platform for money in/money out, with best execution",
  },
  {
    id: 2,
    img: "/assets/images/cryptohub-product.png",
    name: "CryptoHub",
    about: "Accept payments instantly with major cryptocurrencies",
  },
  {
    id: 3,
    img: "/assets/images/custody-product.png",
    name: "Custody",
    about: "Storage with Safeguarding, stablecoins and other digital assets",
  },
];

const Product = () => {
  const { classes } = useStyles();

  const cardVariants: Variants = {
    offscreen: {
      y: 200,
    },
    onscreen: {
      y: 50,
      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  return (
    <>
      <Box className={classes.gridContainer1}>
        <Typography color="primary" variant="h1" align="center">
          Our Product
        </Typography>
        <Box className={classes.productGrid}>
          <Grid container xs={11.2} lg={10}>
            {product.map((x: any) => (
              <Grid key={x.id} item lg={3} sm={6} xs={12}>
                <motion.div
                  whileHover={{ scale: 1.09 }}
                  whileTap={{ scale: 1.09 }}
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                  variants={cardVariants}
                >
                  <Card className={classes.productCard} >
                    <Box
                      component="img"
                      className={classes.productImgs}
                      src={x.img}
                      alt="Descriptive Alt Text"
                    />
                    <Typography
                      color="primary"
                      variant="h5"
                      sx={{ fontWeight: 600, padding: "20px 0" }}
                    >
                      {x.name}
                    </Typography>
                    <Typography color="primary" variant="body2" align="center">
                      {x.about}
                    </Typography>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Product;
