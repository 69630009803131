import React from "react";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF} from "app/components/table";
import { useWhitelistedAddressQuery } from "../WithdrawQueries";


const renderFunction = (data : any): React.JSX.Element[] => {
    const keyPrefix: string = `withdraw-${data.id}`;
    return [
        <TDF.TextContent value={data.user_email} key={`${keyPrefix}-email`} />,
        <TDF.TextContent value={data.nick_name} key={`${keyPrefix}-nick_name`} />,
        <TDF.TextContent value={data.wallet_address} key={`${keyPrefix}-wallet`} />,
        <TDF.TextContent value={data.fireblocks_asset_id} key={`${keyPrefix}-asset`} />,
        <TDF.TextContent value={data.quantity} key={`${keyPrefix}-qunatity`} />,
        <TDF.TextContent value={data.fees_quantity} key={`${keyPrefix}-fees`} />,
    ]
}

const withdrawDataMap = {
    "User Email": "user_email",
    "Nick Name": "nick_name",
    "Wallet Address": "wallet_address",
    "Asset Id": "fireblocks_asset_id",
    "Quantity": "quantity",
    "Fees Quantity": "fees_quantity",
};

export const WithdrawHistoryTable = () => {
    const whitelistedAddressQuery = useWhitelistedAddressQuery();
    const headings: string[] = Object.keys(withdrawDataMap);
    const tradeOrders = whitelistedAddressQuery.isResolved ? whitelistedAddressQuery.result : [];

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Withdraw History",
        headings: headings,
        isLoading: whitelistedAddressQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: tradeOrders,
        actionColumn: true,

        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ['user_email', 'wallet_address', 'fireblocks_asset_id', 'quantity', 'fees_quantity', 'nick_name'],
        },
        downloadOptions: {
            fileName: "withdraw.csv",
            dataMap: withdrawDataMap,
        },
    }

    return (<DefaultTableComponent {...tableComponentProps} />)
}
