import React from "react";
import {Breadcrumbs, Link} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {emphasize, styled} from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const BreadcrumbChip = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

export type T_BreadcrumbProps = {
  name: string;
  onClickHandler: () => void;
};
export type T_BreadcrumbList = T_BreadcrumbProps[];

type T_BreadcrumbsLinksProps = {
  keyPrefix: string;
  breadcrumbs: T_BreadcrumbList;
};

const ChipBreadcrumbLinks = ({
  breadcrumbs,
  keyPrefix,
}: T_BreadcrumbsLinksProps): React.JSX.Element[] => {
  return breadcrumbs.map(
    ({ name, onClickHandler }: T_BreadcrumbProps, index: number) => {
      // const key = `BRD-LNK-${index}-${name
      //   .toString()
      //   .trim()
      //   .split(" ")
      //   .join("-")}`;
      return (
        <BreadcrumbChip
          component="a"
          href="#"
          label={name.toUpperCase()}
          onClick={onClickHandler}
        />
      );
    }
  );
};

const TextBreadcrumbsLinks = ({
  breadcrumbs,
  keyPrefix,
}: T_BreadcrumbsLinksProps): React.JSX.Element[] => {
  return breadcrumbs.map(
    ({ name, onClickHandler }: T_BreadcrumbProps, index: number) => {
      const key = `BRD-LNK-${index}-${name
        .toString()
        .trim()
        .split(" ")
        .join("-")}`;
      return (
        <Link
          underline="hover"
          key={key}
          color="inherit"
          href="#"
          onClick={onClickHandler}
          variant="h6"
        >
          {name.toUpperCase()}
        </Link>
      );
    }
  );
};

export type T_GenerateBreadcrumbLinksProps = T_BreadcrumbsLinksProps & {
  variant?: "text" | "chip" | undefined;
};

const generateBreadcrumbsLinks = ({
  variant,
  ...opt
}: T_GenerateBreadcrumbLinksProps): React.JSX.Element[] => {
  if (variant === "chip") {
    return ChipBreadcrumbLinks({ ...opt });
  }
  return TextBreadcrumbsLinks({ ...opt });
};

type T_SeparatorVariant = "default" | "arrow" | undefined;

const getBreadcrumbsSeparator = (
  variant: T_SeparatorVariant
): React.JSX.Element | any => {
  if (variant === "arrow" || true) {
    return <NavigateNextIcon fontSize="small" />;
  }
  return undefined;
};

export type T_BreadcrumbsComponentProps = T_GenerateBreadcrumbLinksProps & {
  isLoading?: boolean | undefined;
  separatorVariant?: T_SeparatorVariant;
};

export const BreadcrumbComponent = ({
  isLoading,
  ...opt
}: T_BreadcrumbsComponentProps): React.JSX.Element => {
  if (opt.breadcrumbs.length === 0) {
    return <></>;
  }

  if (isLoading) {
    return <></>;
  }

  const _seperator = getBreadcrumbsSeparator(opt.separatorVariant);
  const breadcrumbLinks = generateBreadcrumbsLinks({ ...opt });

  return (
    <Breadcrumbs separator={_seperator} aria-label="breadcrumb">
      {breadcrumbLinks}
    </Breadcrumbs>
  );
};
