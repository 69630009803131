/**
 * @file: AuthUserContext.ts
 * @date: 17 Sep 2023

 * @description: $
 *
 */

import {T_UserSession, UserSession} from "./AuthToken";


export type T_AuthUser = T_UserSession & {
    permissions?: string[];

}


export class AuthUser extends UserSession {


    constructor(userdata?: T_AuthUser) {
        super(userdata);
    };


}
