import { CasinoRounded, MenuRounded } from "@mui/icons-material";
import { AppBar, Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    navbar: {
        backgroundColor: "white",
        padding: '25px',
        width: '100%',
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
    },
    navTitle: {
        color: 'black',
        fontSize: '2rem',
        fontWeight: 'bold',
    },
    menuIcon: { 
        color: 'black', 
        fontSize:"2rem" 
    },
    avatar: {
        backgroundColor: 'black',
        color: 'white',
        fontSize: '2rem',
        marginLeft: 'auto',
    }
}));

export const Navbar = () => {
    const { classes } = useStyles();
    return (
        <Box className={classes.navbar} >
            <MenuRounded className={classes.menuIcon} />
            <Typography className={classes.navTitle}>Jackpot Junction</Typography>
            <Avatar className={classes.avatar}/>
        </Box>
    )
}