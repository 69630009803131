import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Modal,
  // Tab,
  // Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
// import { a11yProps, CustomTabPanel } from "app/components/unsorted/Tabs";
import { pallete, styles } from "app/styles";
import TransactionsTable from "app/components/unsorted/TransactionTable";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { WalletCard } from "./WalletCard";
import { usePermissions } from "../../../../app/hooks";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const form_schema = Yup.object().shape({
  holder_name: Yup.string().required("Full name is required"),
  bank_no: Yup.string().required("Account number is required"),
  swift_number: Yup.string().required("Swift code is required"),
  iban_code: Yup.string().required("IBAN code is required"),
});

// const BillingBalance = () => {
//   /** @var _permissions : T_Permission  the permissions for this component **/
//   // const _permissions = usePermissions();
//   // const { classes } = useStyles();
//   // const [value, setValue] = useState(0);
//   // const [userWallets, setUserWallets] = useState([]);
//   // const [userAccount, setUserAccounts] = useState([]);
//   // const [loading, setLoading] = useState(false);
//   // const [showSnackbar, setShowSnackbar] = useState(false);
//   // const [snackbarMessage, setSnackbarMessage] = useState("");
//   // const [severity, setSeverity] = useState<"success" | "error">("success");
//   // const [openWalletModal, setOpenWalletModal] = useState(false);

//   // const [files, setFiles] = useState<any>([]); // State to hold the uploaded files

//   // const { getRootProps, getInputProps } = useDropzone({
//   //   onDrop: (acceptedFiles: any) => {
//   //     setFiles((prevFiles: any) => [
//   //       ...prevFiles,
//   //       ...acceptedFiles.map((file: any) =>
//   //         Object.assign(file, {
//   //           preview: URL.createObjectURL(file),
//   //         })
//   //       ),
//   //     ]);
//   //   },
//   // });

//   // // Handle file change for traditional input method
//   // const handleFileChange = (event: any) => {
//   //   const fileInput = event.target.files;
//   //   setFiles((pre: any) => [...pre, ...fileInput]); // Add files to state
//   // };

//   // console.log(files);

//   // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//   //   setValue(newValue);
//   // };

//   // const handleCloseModal = () => {
//   //   setOpenWalletModal(false);
//   // };

//   // useEffect(() => {
//   //   if (value === 0) {
//   //     loadWallets();
//   //     loadVault();
//   //   } else if (value === 1) {
//   //     loadUserAccount();
//   //   }
//   // }, [value]);

//   // const loadWallets = async () => {
//   //   setLoading(true);
//   //   await getAxiosAPI("/user-account/wallet", true)
//   //     .then((res: any) => {
//   //       setLoading(false);
//   //       if (res.data.code !== 200) {
//   //         setSnackbarMessage(res.data.message);
//   //         setSeverity("error");
//   //         setShowSnackbar(true);
//   //       } else {
//   //         console.log(res.data.data);
//   //         setUserWallets(res.data.data);
//   //       }
//   //     })
//   //     .catch((err: any) => {
//   //       console.log(err);
//   //       setLoading(false);
//   //       setSnackbarMessage("Failed to fetch details!");
//   //       setShowSnackbar(true);
//   //       setSeverity("error");
//   //     });
//   // };

//   // const loadUserAccount = async () => {
//   //   await getAxiosAPI("/user-account/bank-accounts", true)
//   //     .then((res: any) => {
//   //       setLoading(false);
//   //       if (res.data.code !== 200) {
//   //         setSnackbarMessage(res.data.message);
//   //         setSeverity("error");
//   //         setShowSnackbar(true);
//   //       } else {
//   //         console.log(res.data.data);
//   //         setUserAccounts(res.data.data);
//   //       }
//   //     })
//   //     .catch((err: any) => {
//   //       console.log(err);
//   //       setLoading(false);
//   //       setSnackbarMessage("Failed to fetch details!");
//   //       setShowSnackbar(true);
//   //       setSeverity("error");
//   //     });
//   // };

//   // const handleCross = (x: any) => {
//   //   let newFiles = files.filter((item: any) => item.path !== x.path);
//   //   setFiles(newFiles);
//   // };

//   // const loadVault = async () => {
//   //   await getAxiosAPI("/user-account/vault", true)
//   //     .then((res: any) => {
//   //       if (res.data.code !== 200) {
//   //         setSnackbarMessage(res.data.message);
//   //         setSeverity("error");
//   //         setShowSnackbar(true);
//   //       } else {
//   //         console.log(res.data);
//   //       }
//   //     })
//   //     .catch((err: any) => {
//   //       console.log(err);
//   //       setSnackbarMessage("Failed to fetch details!");
//   //       setShowSnackbar(true);
//   //       setSeverity("error");
//   //     });
//   // };

//   // const handleAccountRequest = async (value: any) => {
//   //   setLoading(true);
//   //   setOpenWalletModal(false);
//   //   const payload = {
//   //     acc_holder_name: value.holder_name,
//   //     acc_number: value.bank_no,
//   //     acc_iban_number: value.iban_code,
//   //     swift_code: value.swift_number,
//   //     statement_document: "null",
//   //   };
//   //   await postAxiosAPI("/bank-accounts", payload, true)
//   //     .then((res: any) => {
//   //       setLoading(false);
//   //       loadUserAccount();
//   //       if (res.data.code !== 200) {
//   //         setSnackbarMessage(res.data.message);
//   //         setSeverity("error");
//   //         setShowSnackbar(true);
//   //       } else {
//   //         console.log(res.data);
//   //       }
//   //     })
//   //     .catch((err: any) => {
//   //       console.log(err);
//   //       setLoading(false);
//   //       setSnackbarMessage("Failed to fetch details!");
//   //       setShowSnackbar(true);
//   //       setSeverity("error");
//   //     });
//   // };

//   // const handleCopyToClipboard = (wallet: any) => {
//   //   navigator.clipboard
//   //     .writeText(wallet.wallet_address)
//   //     .then(() => {
//   //       const updatedWallets: any = userWallets.map((w: any) => {
//   //         if (w.id === wallet.id) {
//   //           return { ...w, isCopied: true };
//   //         }
//   //         return w;
//   //       });

//   //       setUserWallets(updatedWallets);
//   //       setTimeout(() => {
//   //         const resetWallets = updatedWallets.map((w: any) => {
//   //           if (w.id === wallet.id) {
//   //             return { ...w, isCopied: false };
//   //           }
//   //           return w;
//   //         });

//   //         setUserWallets(resetWallets);
//   //       }, 2000);
//   //     })
//   //     .catch((err) => console.error("Failed to copy text: ", err));
//   // };

//   return (
//     <>
//       {/* <Loader isLoading={loading} />
//       <MessageAlert
//         showBar={showSnackbar}
//         message={snackbarMessage}
//         severity={severity}
//         onClose={() => setShowSnackbar(false)}
//       /> */}
//       {/* <Box className={classes.mainBox}>
//         <Box sx={{ width: "100%" }}> */}
//       {/* <Box
//             sx={{
//               borderBottom: 1,
//               borderColor: "divider",
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-between",
//               marginBottom: "10px",
//             }}
//           >
//             <Typography
//               variant="h4"
//               color="primary"
//               className={classes.topHeadingWeb}
//             >
//               Billing & Balance
//             </Typography>
//             <Tabs
//               value={value}
//               onChange={handleChange}
//               aria-label="basic tabs example"
//             >
//               <Tab
//                 className={classes.tabsText}
//                 label="Billing & Balance"
//                 {...a11yProps(0)}
//               />
//               <Tab
//                 className={classes.tabsText}
//                 label="Payout settings"
//                 {...a11yProps(1)}
//               />
//             </Tabs>
//           </Box> */}
//       {/* <CustomTabPanel value={value} index={0}>
//             <Box>
//               <Grid container>
//                 {userWallets.length ? (
//                   userWallets.map((x: any) => (
//                     <WalletCard
//                       data={x}
//                       classes={classes}
//                       handleCopyToClipboard={handleCopyToClipboard}
//                     />
//                   ))
//                 ) : (
//                   <Box className={classes.noAccBoc}>
//                     <Typography>No Wallets Found!</Typography>
//                   </Box>
//                 )}
//                 {userWallets.length ? (
//                   <Grid item lg={4} md={6} xs={12} sx={{ padding: "0 10px" }}>
//                     <Box className={classes.addCardBox}>
//                       <Box
//                         component="img"
//                         className={classes.addCard}
//                         src="assets/icons/card-add.svg"
//                         alt="Descriptive Alt Text"
//                       />
//                     </Box>
//                   </Grid>
//                 ) : null}
//               </Grid>
//               <Grid container sm={12}>
//                 <TransactionsTable />
//               </Grid>
//             </Box>
//           </CustomTabPanel> */}
//       {/* <CustomTabPanel value={value} index={1}>
//             <Grid container>
//               {userAccount.length ? (
//                 userAccount.map((item: any) => (
//                   <Grid
//                     key={item.id}
//                     item
//                     lg={6}
//                     xs={12}
//                     className={classes.cardsGird}
//                   >
//                     <Card className={classes.credCard}>
//                       <Box className={classes.copyBoxParent}>
//                         <Typography variant="h6" color="primary">
//                           Holder Name
//                         </Typography>
//                         <Box className={classes.copyBox}>
//                           <Typography
//                             variant="body2"
//                             color="primary"
//                             sx={{ margin: "auto 5px" }}
//                           >
//                             {item.acc_holder_name}
//                           </Typography>
//                         </Box>
//                       </Box>
//                       <Box className={classes.copyBoxParent}>
//                         <Typography variant="h6" color="primary">
//                           Account Number
//                         </Typography>
//                         <Box className={classes.copyBox}>
//                           <Typography
//                             variant="body2"
//                             color="primary"
//                             sx={{ margin: "auto 5px" }}
//                           >
//                             {item.acc_number}
//                           </Typography>
//                         </Box>
//                       </Box>
//                       <Box className={classes.copyBoxParent}>
//                         <Typography variant="h6" color="primary">
//                           Swift Code
//                         </Typography>
//                         <Box className={classes.copyBox}>
//                           <Typography
//                             variant="body2"
//                             color="primary"
//                             sx={{ margin: "auto 5px" }}
//                           >
//                             {item.swift_code}
//                           </Typography>
//                         </Box>
//                       </Box>
//                       <Box className={classes.copyBoxParent}>
//                         <Typography variant="h6" color="primary">
//                           IBAN (Country code + 21 numbers)
//                         </Typography>
//                         <Box className={classes.copyBox}>
//                           <Typography
//                             variant="body2"
//                             color="primary"
//                             sx={{ margin: "auto 5px" }}
//                           >
//                             {item.acc_iban_number}
//                           </Typography>
//                         </Box>
//                       </Box>
//                     </Card>
//                   </Grid>
//                 ))
//               ) : (
//                 <Box className={classes.noAccBoc}>
//                   <Typography>No Account Found!</Typography>
//                 </Box>
//               )}
//             </Grid>
//             <Button
//               className={classes.btntabTwo}
//               variant="contained"
//               startIcon={<AddRoundedIcon />}
//               onClick={() =>
//                 _permissions.canCreate()
//                   ? setOpenWalletModal(true)
//                   : _permissions.warn()
//               }
//             >
//               Add Account
//             </Button>
//             <Modal
//               open={openWalletModal}
//               onClose={handleCloseModal}
//               aria-labelledby="modal-modal-title"
//               aria-describedby="modal-modal-description"
//             >
//               <Box sx={style}>
//                 <Typography variant="h4" align="center">
//                   New Account
//                 </Typography>
//                 <Formik
//                   initialValues={{
//                     holder_name: "",
//                     bank_no: "",
//                     swift_number: "",
//                     iban_code: "",
//                     statement_document: "",
//                   }}
//                   validationSchema={form_schema}
//                   onSubmit={(values: any) => {
//                     console.log(values, "cred");
//                     handleAccountRequest(values);
//                   }}
//                 >
//                   {({
//                     errors,
//                     touched,
//                     handleBlur,
//                     handleChange,
//                     handleSubmit,
//                     values,
//                   }: any) => (
//                     <Box>
//                       <TextField
//                         id="outlined-basic"
//                         label="Holder name"
//                         variant="outlined"
//                         autoComplete="off"
//                         className={classes.inputBox}
//                         value={values.holder_name}
//                         onBlur={handleBlur("holder_name")}
//                         onChange={handleChange("holder_name")}
//                       />
//                       {touched.holder_name && errors.holder_name && (
//                         <Typography
//                           variant="subtitle2"
//                           align="left"
//                           className={classes.errorMsg}
//                         >
//                           {errors.holder_name}
//                         </Typography>
//                       )}
//                       <TextField
//                         id="outlined-basic"
//                         label="Bank name"
//                         variant="outlined"
//                         autoComplete="off"
//                         className={classes.inputBox}
//                         value={values.bank_no}
//                         onBlur={handleBlur("bank_no")}
//                         onChange={handleChange("bank_no")}
//                       />
//                       {touched.bank_no && errors.bank_no && (
//                         <Typography
//                           variant="subtitle2"
//                           align="left"
//                           className={classes.errorMsg}
//                         >
//                           {errors.bank_no}
//                         </Typography>
//                       )}
//                       <TextField
//                         id="outlined-basic"
//                         label="NIB (21 numbers)"
//                         variant="outlined"
//                         autoComplete="off"
//                         className={classes.inputBox}
//                         value={values.swift_number}
//                         onBlur={handleBlur("swift_number")}
//                         onChange={handleChange("swift_number")}
//                       />
//                       {touched.swift_number && errors.swift_number && (
//                         <Typography
//                           variant="subtitle2"
//                           align="left"
//                           className={classes.errorMsg}
//                         >
//                           {errors.swift_number}
//                         </Typography>
//                       )}
//                       <TextField
//                         id="outlined-basic"
//                         label="IBAN Country code + (21 numbers)"
//                         variant="outlined"
//                         autoComplete="off"
//                         className={classes.inputBox}
//                         value={values.iban_code}
//                         onBlur={handleBlur("iban_code")}
//                         onChange={handleChange("iban_code")}
//                       />
//                       {touched.iban_code && errors.iban_code && (
//                         <Typography
//                           variant="subtitle2"
//                           align="left"
//                           className={classes.errorMsg}
//                         >
//                           {errors.iban_code}
//                         </Typography>
//                       )}
//                       <Box className={classes.filesBox}>
//                         <Typography variant="h6" color="primary">
//                           Bank Account Statement
//                         </Typography>
//                         <Typography variant="body2" color="primary">
//                           {files.length} Files
//                         </Typography>
//                         {files.length ? (
//                           <Box className={classes.filesListBox}>
//                             {files.map((x: any) => (
//                               <Box key={x.path} sx={{ ...styles.flexDRS }}>
//                                 <Typography variant="h6" color="primary">
//                                   {x.path} - {x.size} bytes
//                                 </Typography>
//                                 <IconButton onClick={() => handleCross(x)}>
//                                   <CloseIcon
//                                     sx={{ color: pallete.primaryPurple }}
//                                   />
//                                 </IconButton>
//                               </Box>
//                             ))}
//                           </Box>
//                         ) : null}
//                       </Box>
//                       <Box {...getRootProps()} className={classes.dragDropCard}>
//                         <input
//                           {...getInputProps()}
//                           id="file-upload-input"
//                           type="file"
//                           onChange={handleFileChange}
//                           style={{ display: "none" }}
//                           multiple
//                         />
//                         <Box
//                           component="img"
//                           src="assets/icons/dragdrop.svg"
//                           className={classes.dragDropIcon}
//                           alt="Drag and drop icon"
//                         />
//                         <Typography className={classes.dragText}>
//                           Drag-n-drop files here, or click to select files
//                         </Typography>
//                       </Box>

//                       <Box className={classes.btnsBox}>
//                         <Button
//                           onClick={() => handleCloseModal()}
//                           className={classes.formBtn0}
//                         >
//                           Cancel
//                         </Button>
//                         <Button
//                           onClick={() => handleSubmit()}
//                           className={classes.formBtn1}
//                         >
//                           Send a request
//                         </Button>
//                       </Box>
//                     </Box>
//                   )}
//                 </Formik>
//               </Box>
//             </Modal>
//           </CustomTabPanel> */}
//       {/* </Box>
//       </Box> */}
//     </>
//   );
// };

export const BillingBalance1 = () => {
  // const _permissions = usePermissions();
  const { classes } = useStyles();
  const [value, setValue] = useState(0);
  const [userWallets, setUserWallets] = useState([]);
  const [userAccount, setUserAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openWalletModal, setOpenWalletModal] = useState(false);

  const [files, setFiles] = useState<any>([]); // State to hold the uploaded files

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop: (acceptedFiles: any) => {
  //     setFiles((prevFiles: any) => [
  //       ...prevFiles,
  //       ...acceptedFiles.map((file: any) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       ),
  //     ]);
  //   },
  // });

  // Handle file change for traditional input method
  // const handleFileChange = (event: any) => {
  //   const fileInput = event.target.files;
  //   setFiles((pre: any) => [...pre, ...fileInput]); // Add files to state
  // };

  console.log(files);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  // const handleCloseModal = () => {
  //   setOpenWalletModal(false);
  // };

  useEffect(() => {
    if (value === 0) {
      loadWallets();
      loadVault();
    } else if (value === 1) {
      loadUserAccount();
    }
  }, [value]);

  const loadWallets = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account/wallet", true)
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          console.log(res.data.data);
          setUserWallets(res.data.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const loadUserAccount = async () => {
    await getAxiosAPI("/user-account/bank-accounts", true)
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          console.log(res.data.data);
          setUserAccounts(res.data.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  // const handleCross = (x: any) => {
  //   let newFiles = files.filter((item: any) => item.path !== x.path);
  //   setFiles(newFiles);
  // };

  const loadVault = async () => {
    await getAxiosAPI("/user-account/vault", true)
      .then((res: any) => {
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          console.log(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  // const handleAccountRequest = async (value: any) => {
  //   setLoading(true);
  //   setOpenWalletModal(false);
  //   const payload = {
  //     acc_holder_name: value.holder_name,
  //     acc_number: value.bank_no,
  //     acc_iban_number: value.iban_code,
  //     swift_code: value.swift_number,
  //     statement_document: "null",
  //   };
  //   await postAxiosAPI("/bank-accounts", payload, true)
  //     .then((res: any) => {
  //       setLoading(false);
  //       loadUserAccount();
  //       if (res.data.code !== 200) {
  //         setSnackbarMessage(res.data.message);
  //         setSeverity("error");
  //         setShowSnackbar(true);
  //       } else {
  //         console.log(res.data);
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.log(err);
  //       setLoading(false);
  //       setSnackbarMessage("Failed to fetch details!");
  //       setShowSnackbar(true);
  //       setSeverity("error");
  //     });
  // };

  const handleCopyToClipboard = (wallet: any) => {
    navigator.clipboard
      .writeText(wallet.wallet_address)
      .then(() => {
        const updatedWallets: any = userWallets.map((w: any) => {
          if (w.id === wallet.id) {
            return { ...w, isCopied: true };
          }
          return w;
        });

        setUserWallets(updatedWallets);
        setTimeout(() => {
          const resetWallets = updatedWallets.map((w: any) => {
            if (w.id === wallet.id) {
              return { ...w, isCopied: false };
            }
            return w;
          });

          setUserWallets(resetWallets);
        }, 2000);
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Box>
        <Grid container>
          {userWallets.length ? (
            userWallets.map((x: any) => (
              <WalletCard
                data={x}
                classes={classes}
                handleCopyToClipboard={handleCopyToClipboard}
              />
            ))
          ) : (
            <Box className={classes.noAccBoc}>
              <Typography>No Wallets Found!</Typography>
            </Box>
          )}
          {userWallets.length ? (
            <Grid item lg={4} md={6} xs={12} sx={{ padding: "0 10px" }}>
              <Box className={classes.addCardBox}>
                <Box
                  component="img"
                  className={classes.addCard}
                  src="assets/icons/card-add.svg"
                  alt="Descriptive Alt Text"
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Grid container sm={12}>
          <TransactionsTable />
        </Grid>
      </Box>
    </>
  );
};
export const BillingBalance2 = () => {
  /** @var _permissions : T_Permission  the permissions for this component **/
  const _permissions = usePermissions();
  const { classes } = useStyles();
  const [value, setValue] = useState(0);
  const [userWallets, setUserWallets] = useState([]);
  const [userAccount, setUserAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openWalletModal, setOpenWalletModal] = useState(false);

  const [files, setFiles] = useState<any>([]); // State to hold the uploaded files

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      setFiles((prevFiles: any) => [
        ...prevFiles,
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  // Handle file change for traditional input method
  const handleFileChange = (event: any) => {
    const fileInput = event.target.files;
    setFiles((pre: any) => [...pre, ...fileInput]); // Add files to state
  };

  console.log(files);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCloseModal = () => {
    setOpenWalletModal(false);
  };

  useEffect(() => {
    if (value === 0) {
      loadWallets();
      loadVault();
    } else if (value === 1) {
      loadUserAccount();
    }
  }, [value]);

  const loadWallets = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account/wallet", true)
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          console.log(res.data.code);
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          console.log(res.data.data);
          setUserWallets(res.data.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const loadUserAccount = async () => {
    await getAxiosAPI("/user-account/bank-accounts", true)
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          console.log(res.data.data);
          setUserAccounts(res.data.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const handleCross = (x: any) => {
    let newFiles = files.filter((item: any) => item.path !== x.path);
    setFiles(newFiles);
  };

  const loadVault = async () => {
    await getAxiosAPI("/user-account/vault", true)
      .then((res: any) => {
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          console.log(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const handleAccountRequest = async (value: any) => {
    setLoading(true);
    setOpenWalletModal(false);
    const payload = {
      acc_holder_name: value.holder_name,
      acc_number: value.bank_no,
      acc_iban_number: value.iban_code,
      swift_code: value.swift_number,
      statement_document: "null",
    };
    await postAxiosAPI("/bank-accounts", payload, true)
      .then((res: any) => {
        setLoading(false);
        loadUserAccount();
        if (res.data.code !== 200) {
          console.log(res.data.code);
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          console.log(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const handleCopyToClipboard = (wallet: any) => {
    navigator.clipboard
      .writeText(wallet.wallet_address)
      .then(() => {
        const updatedWallets: any = userWallets.map((w: any) => {
          if (w.id === wallet.id) {
            return { ...w, isCopied: true };
          }
          return w;
        });

        setUserWallets(updatedWallets);
        setTimeout(() => {
          const resetWallets = updatedWallets.map((w: any) => {
            if (w.id === wallet.id) {
              return { ...w, isCopied: false };
            }
            return w;
          });

          setUserWallets(resetWallets);
        }, 2000);
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };
  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Grid container>
        {userAccount.length ? (
          userAccount.map((item: any) => (
            <Grid
              key={item.id}
              item
              lg={6}
              xs={12}
              className={classes.cardsGird}
            >
              <Card className={classes.credCard}>
                <Box className={classes.copyBoxParent}>
                  <Typography variant="h6" color="primary">
                    Holder Name
                  </Typography>
                  <Box className={classes.copyBox}>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{ margin: "auto 5px" }}
                    >
                      {item.acc_holder_name}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.copyBoxParent}>
                  <Typography variant="h6" color="primary">
                    Account Number
                  </Typography>
                  <Box className={classes.copyBox}>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{ margin: "auto 5px" }}
                    >
                      {item.acc_number}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.copyBoxParent}>
                  <Typography variant="h6" color="primary">
                    Swift Code
                  </Typography>
                  <Box className={classes.copyBox}>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{ margin: "auto 5px" }}
                    >
                      {item.swift_code}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.copyBoxParent}>
                  <Typography variant="h6" color="primary">
                    IBAN (Country code + 21 numbers)
                  </Typography>
                  <Box className={classes.copyBox}>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{ margin: "auto 5px" }}
                    >
                      {item.acc_iban_number}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <Box className={classes.noAccBoc}>
            <Typography>No Account Found!</Typography>
          </Box>
        )}
      </Grid>
      <Button
        className={classes.btntabTwo}
        variant="contained"
        startIcon={<AddRoundedIcon />}
        onClick={() =>
          _permissions.canCreate()
            ? setOpenWalletModal(true)
            : _permissions.warn()
        }
      >
        Add Account
      </Button>
      <Modal
        open={openWalletModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h4" align="center">
            New Account
          </Typography>
          <Formik
            initialValues={{
              holder_name: "",
              bank_no: "",
              swift_number: "",
              iban_code: "",
              statement_document: "",
            }}
            validationSchema={form_schema}
            onSubmit={(values: any) => {
              console.log(values, "cred");
              handleAccountRequest(values);
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              values,
            }: any) => (
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Holder name"
                  variant="outlined"
                  autoComplete="off"
                  className={classes.inputBox}
                  value={values.holder_name}
                  onBlur={handleBlur("holder_name")}
                  onChange={handleChange("holder_name")}
                />
                {touched.holder_name && errors.holder_name && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.holder_name}
                  </Typography>
                )}
                <TextField
                  id="outlined-basic"
                  label="Bank name"
                  variant="outlined"
                  autoComplete="off"
                  className={classes.inputBox}
                  value={values.bank_no}
                  onBlur={handleBlur("bank_no")}
                  onChange={handleChange("bank_no")}
                />
                {touched.bank_no && errors.bank_no && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.bank_no}
                  </Typography>
                )}
                <TextField
                  id="outlined-basic"
                  label="NIB (21 numbers)"
                  variant="outlined"
                  autoComplete="off"
                  className={classes.inputBox}
                  value={values.swift_number}
                  onBlur={handleBlur("swift_number")}
                  onChange={handleChange("swift_number")}
                />
                {touched.swift_number && errors.swift_number && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.swift_number}
                  </Typography>
                )}
                <TextField
                  id="outlined-basic"
                  label="IBAN Country code + (21 numbers)"
                  variant="outlined"
                  autoComplete="off"
                  className={classes.inputBox}
                  value={values.iban_code}
                  onBlur={handleBlur("iban_code")}
                  onChange={handleChange("iban_code")}
                />
                {touched.iban_code && errors.iban_code && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.iban_code}
                  </Typography>
                )}
                <Box className={classes.filesBox}>
                  <Typography variant="h6" color="primary">
                    Bank Account Statement
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {files.length} Files
                  </Typography>
                  {files.length ? (
                    <Box className={classes.filesListBox}>
                      {files.map((x: any) => (
                        <Box key={x.path} sx={{ ...styles.flexDRS }}>
                          <Typography variant="h6" color="primary">
                            {x.path} - {x.size} bytes
                          </Typography>
                          <IconButton onClick={() => handleCross(x)}>
                            <CloseIcon sx={{ color: pallete.primaryPurple }} />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                </Box>
                <Box {...getRootProps()} className={classes.dragDropCard}>
                  <input
                    {...getInputProps()}
                    id="file-upload-input"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple
                  />
                  <Box
                    component="img"
                    src="assets/icons/dragdrop.svg"
                    className={classes.dragDropIcon}
                    alt="Drag and drop icon"
                  />
                  <Typography className={classes.dragText}>
                    Drag-n-drop files here, or click to select files
                  </Typography>
                </Box>
                <Box className={classes.btnsBox}>
                  <Button
                    onClick={() => handleCloseModal()}
                    className={classes.formBtn0}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleSubmit()}
                    className={classes.formBtn1}
                  >
                    Send a request
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    filesListBox: {
      height: "45px",
      overflowY: "scroll",
    },
    filesBox: {},
    noAccBoc: {},
    cardsGird: {
      padding: "10px 20px",
    },
    btnsBox: {
      display: "flex",
      flexDirection: "row",
    },
    formBtn0: {
      ...styles.appButton,
      width: "100%",
      margin: "5px",
      color: pallete.primaryPurple,
      backgroundColor: pallete.primaryWhite,
    },
    formBtn1: {
      ...styles.appButton,
      width: "100%",
      margin: "5px",
    },
    dragText: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#B9B9B9",
    },
    dragDropIcon: {
      height: "25px",
      width: "25px",
      margin: "0 auto",
    },
    dragDropCard: {
      border: `1px dashed ${pallete.primaryPurple}`,
      height: "80px",
      borderRadius: "4px",
      gap: "4px",
      margin: "15px 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    modalBox: {
      backgroundColor: pallete.primaryWhite,
      width: "400px",
    },
    btntabTwo: {
      ...styles.homeButton,
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      margin: "20px 0",
    },
    copyBox: {
      display: "flex",
      flexDirection: "row",
    },
    copyBoxParent: {
      ...styles.flexDRS,
      padding: "5px 0",
    },
    credCard: {
      width: "100%",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      padding: "20px 10px",
    },
    iconsBtn: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      // lineHeight: "140%",
      padding: "10px 20px",
      borderRadius: "8px",
    },
    startIconBoxCal: {
      height: "24px",
      width: "24px",
    },
    commonCardsHBox: {
      ...styles.flexDRS,
      // padding: "20px 0",
    },
    commonCardsTable: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      width: "100%",
      margin: "20px 0",
    },
    addCardBox: {
      display: "flex",
      flexDirection: "column",
    },
    addCard: {
      height: "80px",
      width: "80px",
      margin: "20px",
    },
    flexDR: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    walletTOpBox: {
      ...styles.flexDRS,
      paddingBottom: "15px",
    },
    tokenIcons: {
      height: "24px",
      width: "24px",
    },
    walletCards: {
      boxShadow:
        "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;",
      padding: "20px",
      borderRadius: "32px",
      margin: "10px 0",
    },
    walletDeatilsGrid: {
      padding: "15px",
      height: "140px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("lg")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "15px",
      },
    },
    mainGrid: {
      ...styles.flexDRS,
    },
    mainCard: {
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      padding: "10px 15px",
      margin: "40px 0",
    },
    aboveText: {
      fontWeight: "600",
      color: pallete.primaryPurple,
    },
    tabsText: {
      fontSize: "16px",
      fontWeight: 600,
      color: pallete.selections.blue,
      textTransform: "none",
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 70px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
  };
});
