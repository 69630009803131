import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { StatsCard } from "./StatsCard";
import OngoingGamesCard from "./OngoingGamesCard";
import { Box, Grid, Typography } from "@mui/material";
import { Navbar } from "./Navbar";
import {
  EmojiEventsRounded,
  MonetizationOnRounded,
  SportsEsportsRounded,
  TrendingUpRounded,
} from "@mui/icons-material";
import AddBalanceCard from "./AddBalanceCard";

const useStyles = makeStyles()((theme) => ({
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    gap: "10px",
  },
  contentContainer: {
    paddingInline: "40px",
    paddingTop: "5px",
    backgroundColor: "#e8ebee",
    paddingBottom: "15px",
    flexGrow: 1,
  },
  statsContainer: {
    background: `linear-gradient(to bottom, #624bfe 70%, #e8ebee 30%)`,
    paddingInline: "80px",
    paddingTop: "10px",
    paddingBottom: "30px",
    minHeight: "250px",
  },
}));

function CasinoDashboard() {
  const [balance, setBalance] = useState(1000);
  const [totalGames, setTotalGames] = useState(50);
  const [totalProfit, setTotalProfit] = useState(200);

  const { classes } = useStyles();

  const addBalance = (amount: number) => {
    setBalance(balance + amount);
  };

  return (
    <Box className={classes.dashboardContainer}>
      <Navbar />
      <Grid className={classes.statsContainer} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ color: "white" }}>
            Game Statistics
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            title={"Total Games"}
            value={"13"}
            color={"#dfdcff"}
            icon={<SportsEsportsRounded />}
          />
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            title={"Games Won"}
            value={"8"}
            color={"#dfdcff"}
            icon={<EmojiEventsRounded />}
          />
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            title={"Total Spent"}
            value={"$124"}
            color={"#dfdcff"}
            icon={<MonetizationOnRounded />}
          />
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            title={"Profit"}
            value={"$72"}
            color={"#dfdcff"}
            icon={<TrendingUpRounded />}
          />
        </Grid>
      </Grid>

      <Grid className={classes.contentContainer} container spacing={2}>
        <Grid item xs={8}>
          <OngoingGamesCard />
        </Grid>
        <Grid item xs={4}>
          <AddBalanceCard balance={balance} addBalance={addBalance} />
        </Grid>
      </Grid>
    </Box>
  );
}

export { CasinoDashboard };
