import {Box, Button, Card, Checkbox, FormControlLabel, Skeleton, TextField, Typography} from "@mui/material";
import React from "react";
import {T_UseWallethubPaymentState} from "./WallethubPaymentHooks";
import {useWallethubTransactionRequest} from "./WallethubPaymentQueries";


type T_ViewProps = {
    classes: any;
    paymentState: T_UseWallethubPaymentState;
    transactionId: string | any;
}

type T_AmountDetails = {
    value: number;
    currency: string;
}
export const AmountConfirmationView = ({classes, paymentState, transactionId}: T_ViewProps) => {

    const [checked, setChecked] = React.useState(false);
    const transactionRequest = useWallethubTransactionRequest(transactionId);
    const amountDetails: T_AmountDetails = {} as T_AmountDetails;

    const continueToPayment = () => {
        paymentState.update({
            isConfirmed:true,
            transactionId:transactionRequest.result.id,
            redirectUrl:transactionRequest.result.redirect_url
        });
    }

    const showPrivacyPolicy = () => {
        window.open("/privacy-policy", "PopupWindow", 'width=600,height=600');
    }



    if (transactionRequest.result) {
        amountDetails.value = transactionRequest.result.amount;
        amountDetails.currency = transactionRequest.result.currency;
    }

    return (
        <Card className={classes.paymentCard}>

            <Box className={classes.currBox}>
                <Box>
                    <Typography variant="h6">Amount</Typography>

                    {amountDetails.currency ? <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={classes.inputBox}
                        disabled={true}
                        value={amountDetails.value}
                    /> : <Skeleton variant="rectangular" width={200} height={50} />
                    }
                </Box>
                <Box sx={{marginLeft: "10px", width: "120px"}}>
                    <Typography variant="h6">Currency</Typography>
                    {
                        amountDetails.currency ?
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                className={classes.inputBox}
                                value={amountDetails.currency}
                                disabled={true}
                            /> : <Skeleton variant="rectangular" width={100} height={50} />

                    }
                </Box>
            </Box>

            <Box className={classes.checkBoxB}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={() => setChecked(!checked)}
                            checked={checked} // Set checked state based on form state
                            //onChange={handleChange("termsAccepted")} // Update form state on change
                            //onBlur={handleBlur("termsAccepted")}
                        />

                    }
                    label={''}
                />
                <Typography variant="h6" className={classes.termsText} onClick={() => showPrivacyPolicy()}>
                    I agree to the T&C and privacy policy.
                </Typography>
            </Box>


            <Button
                variant={"contained"} className={classes.submitBtn}
                disabled={!checked} onClick={continueToPayment}
            >
                Continue
            </Button>


        </Card>
    );
};
