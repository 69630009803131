import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import {Button, InputAdornment, MenuItem} from "@mui/material";
import {isEmail} from "validator";
import {usePermissions} from "app/hooks";
import { useNotification} from "app/common"; // For email validation
import {useAddTeamMemberRequest} from "./TeamsHooks";

export const TeamInvitationForm: React.FC = () => {
    const _permission = usePermissions();
    const [email, setEmail] = useState<string | null>(null); // Store one email
    const [inputValue, setInputValue] = useState<string>(""); // Input field value
    const [role, setRole] = useState<string>(""); // Store selected role
    const notification = useNotification();

    const addTeamMemberRequest = useAddTeamMemberRequest()



    const handleAddTeamMember = async () => {
        if (!_permission.canCreateAndUpdate()) {
            return _permission.warn();
        }

        await addTeamMemberRequest.EXECUTE({
            payload: {email: inputValue, role_id: role},
            onSuccessFn: (data, message) => {
                notification.success("Member has been invited.");
            },
            onErrorFn: (data, message) => {
                notification.error(message);
            }
        });
    };

    // Add email if valid
    const handleAddEmail = () => {
        if (isEmail(inputValue)) {
            setEmail(inputValue);
            setInputValue(""); // Clear input field after adding email
        }
    };

    // Handle chip deletion
    const handleDeleteChip = () => {
        setEmail(null);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && isEmail(inputValue)) {
            event.preventDefault();
            handleAddEmail();
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                flexDirection: {xs: "column", md: "row"}, // Change direction to column on small screens
            }}
        >
            {/* Email Input Field */}
            <TextField
                variant="outlined"
                fullWidth
                placeholder="Invite team member via email"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}

                InputProps={{
                    disabled : addTeamMemberRequest.isPending,
                    startAdornment: (
                        <InputAdornment position="start">
                            {/* Render chip inside input field if email is added */}
                            {email && (
                                <Chip
                                    label={email}
                                    onDelete={handleDeleteChip}
                                    size="small"
                                    sx={{marginRight: 0.5}}
                                />
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {/* Role dropdown inside the input field */}
                            <TextField
                                select
                                value={role || "Role"} // Show "Role" as placeholder when no role is selected
                                onChange={(e) => setRole(e.target.value)}
                                variant="standard"
                                sx={{width: 100}}
                                SelectProps={{
                                    disableUnderline: true,
                                    displayEmpty: true, // Display empty value when no selection is made
                                }}
                            >
                                <MenuItem disabled value="Role" selected={true}> User Role </MenuItem>
                                <MenuItem value="6"> Member</MenuItem>
                                <MenuItem value="7">Operator</MenuItem>
                            </TextField>
                        </InputAdornment>
                    ),
                }}
                disabled={!!email} // Disable input after email is added
                sx={{backgroundColor: "transparent", height: "56px"}} // Set height for input
            />

            {/* Invite Button Below Input Field on Small Screens */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddTeamMember}
                disabled={
                (!isEmail(inputValue) || !!email) || (!["6", "7"].includes(role))
                    || addTeamMemberRequest.isPending
            } // Disable if no valid email or already added
                sx={{
                    height: "54px", // Match input height
                    width: {xs: "100%", md: "auto"}, // Full width on small screens, auto on medium+
                }}
            >
                Invite
            </Button>
        </Box>
    );
};
