/**
 * Retrieves the value of a configuration key from environment variables.
 * @param configKey The configuration key.
 * @param defaultValue The default value if the configuration key is not found.
 * @returns The value of the configuration key or the default value.
 */
export const getValue = (configKey: string, defaultValue: string = ''): string => {
    // Determine the prefix for the configuration key
    const configPrefix = (configKey.toUpperCase().includes("REACT_APP_")) ? "" : "REACT_APP_";
    // Normalize the configuration key
    configKey = `${configPrefix}${configKey}`.toUpperCase();
    // Retrieve the value of the configuration key or return the default value
    return process.env[configKey] || defaultValue;
}

export const parsedUrlQueryParams = (params: any): string => {
    if (Array.isArray(params)) {
        return (params.length > 0) ? `/${params.join("/")}` : ``;
    }
    return (params && Object.keys(params).length > 0) ? `?${(new URLSearchParams(params).toString())}` : ``;
}

export const createAPIURL = (endpoint: string, params?: any): string => {
    const BASE_API_URL = getValue("BASE_URL_API");
    endpoint = endpoint.startsWith("/") ? endpoint.substring(1) : endpoint;
    endpoint = `${BASE_API_URL}/${(endpoint.endsWith("/")) ? endpoint.substring(0, endpoint.length - 1) : endpoint}`;
    return `${endpoint}${parsedUrlQueryParams(params)}`;
}

export const createQueryKeysFromURL = (endpoint: string): any[] => {
    return [endpoint]
}

export type T_ApiConfig = {
    key: any[];
    url: string;
}

type T_ApiConfigFnParams = {
    path: string;
    queryKey?: any[];
    params?: any
    method?: string;
}


export const apiConfig = ({path, queryKey, params, method}: T_ApiConfigFnParams): T_ApiConfig => {
    return {
        key: createQueryKeysFromURL(path),
        url: createAPIURL(path),
        method: method || "GET"
    } as T_ApiConfig;
}

export class ApiConfig {

    static create = (path: string, queryKey?: any[], params?: any): T_ApiConfig => {
        return {
            key: [path,...(params || [])],
            url: createAPIURL(path, params)
        }
    }
}
