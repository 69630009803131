import { ApiConfig,} from "app/config";
import { RQ } from "app/hooks";

const MONEYHUB_APIS = {
  TRANSACTION_STATS: ApiConfig.create(
    "user/money-hub-transaction-request/get-totals"
  ),
  TRANSACTION_DAY_BASED_STATS: ApiConfig.create(
    "user/money-hub-transaction-request/get-daily-totals"
  ),
  TRANSACTION_TABLE_DATA: ApiConfig.create(
    "user/money-hub-transaction-request"
  ),
};

export type T_MoneyhubTransactionStats = {
  Total: number;
  "Payment Pending": number;
  "Payment Received": number;
  "Sent To Merchant": number;
  "Settled With Merchant": number;
};

export type T_MoneyhubDayBasedTransactionStats = {
  [key: string]: T_MoneyhubTransactionStats;
};

export type T_MoneyhubTransactionTableData = {
  id: string;
  sender_id: string;
  receiver_id: string;
  sender_email: string;
  receiver_email: string;
  user_id_in_merchant_site: string;
  currency_id: number;
  currency_symbol: string;
  amount: number;
  fees: number;
  verified_at: string;
  created_at: string;
  asset_name: string;
  asset_quantity: number;
  status_id: number;
  status_name: string;
};

export type T_MoneyhubTransactionTableDataArray =
  Array<T_MoneyhubTransactionTableData>;

export const StatsKey: Array<keyof T_MoneyhubTransactionStats> = [
  "Total",
  "Payment Pending",
  "Payment Received",
  "Sent To Merchant",
  "Settled With Merchant",
];

const useMoneyHubTransactionStatsRequest =
  (): RQ.T_QueryResult<T_MoneyhubTransactionStats> => {
    return RQ.useQueryRequest<T_MoneyhubTransactionStats>({
      url: MONEYHUB_APIS.TRANSACTION_STATS.url,
      queryKey: MONEYHUB_APIS.TRANSACTION_STATS.key,
    });
  };

const useMoneyHubDayBasedTransactionStatsRequest = (days: number) => {
  return RQ.useQueryRequest<T_MoneyhubDayBasedTransactionStats>({
    url: MONEYHUB_APIS.TRANSACTION_DAY_BASED_STATS.url,
    payload: { days },
    queryKey: [...MONEYHUB_APIS.TRANSACTION_DAY_BASED_STATS.key, days],
    method: "POST",
  });
};

const useMoneyHubTransactionTableRequest = (status: Array<number>) => {
  return RQ.useQueryRequest<T_MoneyhubTransactionTableDataArray>({
    url: MONEYHUB_APIS.TRANSACTION_TABLE_DATA.url,
    payload: { status },
    queryKey: [...MONEYHUB_APIS.TRANSACTION_TABLE_DATA.key, ...status],
    method: "POST",
  });
};

export {
  useMoneyHubTransactionStatsRequest,
  useMoneyHubDayBasedTransactionStatsRequest,
  useMoneyHubTransactionTableRequest,
};
