import {useCallback, useEffect, useState} from "react";

const useTimer = (seconds:any, onCompletion:any) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [isActive, setIsActive] = useState(false);

  const start = useCallback(() => {
    setIsActive(true);
    setTimeLeft(seconds); // Reset timer to initial seconds when started
  }, [seconds]);

  const reset = useCallback(() => {
    setIsActive(false); // Stop the timer
    setTimeLeft(seconds); // Reset the time to the initial value
  }, [seconds]);

  const pause = useCallback(() => {
    setIsActive(false); // Stop the timer
  }, []);


  useEffect(() => {
    let timerId:any = null;

    if (isActive && timeLeft > 0) {
      timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsActive(false); // Stop the timer
      onCompletion();
    }

    return () => clearInterval(timerId);
  }, [isActive, timeLeft, onCompletion]);

  return { timeLeft, start , reset, pause};
};

export default useTimer;



