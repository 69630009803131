import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {useInitiateKycViewStyles} from "./KycWidgetStyles";
import {RQ} from "app/hooks";
import {T_ApiConfig} from "app/config";
import {useLoader} from "app/providers";
import Loader from "app/components/unsorted/Loader";

export type T_InitiateKycViewProps = {
    onInitHandler: () => void;
    title?: string;
    description?: string;
    btnLabel?: string;
}


export const InitiateKycView = (props: T_InitiateKycViewProps) => {
    const {classes} = useInitiateKycViewStyles();
    const {onInitHandler, title, description, btnLabel} = props;
    return (
        <Box className={classes.formBox}>
            <Typography variant="h3" align="center" className={classes.formHeading}>
                {title || 'Complete your KYC'}
            </Typography>
            <br/>
            <Typography align="center" variant="h6" color="primary">
                {description || 'Please complete your KYC to continue'}
            </Typography>
            <br/>
            <Button onClick={() => onInitHandler()} variant="contained" className={classes.submitBtn}>
                {btnLabel || 'Initiate KYC'}
            </Button>
        </Box>
    );
};

export type T_KycStatusViewProps = {
    apiConfig: T_ApiConfig;
    kycOnInitHandler: () => void;
    kycIsCompletedHandler: () => void;
}

export const KycStatusView = (props: T_KycStatusViewProps): React.JSX.Element => {
    const loader = useLoader();
    const {apiConfig, kycOnInitHandler, kycIsCompletedHandler} = props;

    const userKycStatusQuery = RQ.useQueryRequest({
        url: apiConfig.url, queryKey: apiConfig.key,
    });

    if (userKycStatusQuery.isResolving) {
        return <Loader isLoading={true}/>
    }

    if (userKycStatusQuery.isResolved) {
        loader.hide();
    }

    if (userKycStatusQuery.result) {
        const {is_kyc_completed, result} = userKycStatusQuery.result;
        if (is_kyc_completed) {
            kycIsCompletedHandler();
        }
    }

    return (<InitiateKycView onInitHandler={kycOnInitHandler}/>);
}