type T_DownloadCsvFnProps = {
    data: any[];
    headings: string[];
    fileName: string;
}

export const downloadCSVFile = async ({data, headings, fileName}: T_DownloadCsvFnProps) => {

    const mergedData:any[] = [headings,...data].map(row => `${row.join(",")}`);
    const csvContent:string = mergedData.join("\r\n");

    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};
