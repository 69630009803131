import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import OtpInput from "react-otp-input";
import { motion } from "framer-motion";

type T_Props = {
  value: string;
  onChange: (value: string) => void;
  numInputs: number;
  shouldAutoFocus: boolean;
};

const AnimatedOtpInput = ({
  value,
  onChange,
  numInputs,
  shouldAutoFocus,
}: T_Props) => {
  const { classes } = useStyles();
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const handleFocus = (index: number) => setFocusedIndex(index);
  const handleBlur = () => setFocusedIndex(null);

  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      shouldAutoFocus={shouldAutoFocus}
      inputType="number"
      containerStyle={{
        marginTop: "20px",
        marginBottom: "30px",
      }}
      renderInput={(props, index) => (
        <div className={classes.inputBoxes} key={index}>
          <motion.div
            className={classes.animatedValue}
            initial={{ opacity: 1, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            key={String(props.value)}
          >
            {props.value || ""}
          </motion.div>
          <input
            {...props}
            className={classes.inputBox}
            style={{ ...props.style, width: "100%", cursor: "pointer" }}
            value={""}
            onFocus={(event) => {
              handleFocus(index);
              props.onFocus(event);
            }}
            onBlur={(event) => {
              handleBlur();
              props.onBlur(event);
            }}
          />
          {focusedIndex === index && (
            <motion.div
              className={classes.underline}
              initial={{ y: 10 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.2 }}
            />
          )}
        </div>
      )}
    />
  );
};

export { AnimatedOtpInput };

const useStyles = makeStyles()((theme) => {
  return {
    inputBoxes: {
      margin: "20px 10px",
      border: "1px solid #36205F80",
      borderRadius: "6px",
      fontSize: "18px",
      width: "46px",
      height: "51px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        margin: "10px 5px",
      },
    },
    inputBox: {
      width: "100%",
      height: "100%",
      zIndex: 1,
      backgroundColor: "transparent",
      border: "none",
      caretColor: "transparent",
      transition: "box-shadow 0.1s ease",
      "&:focus": {
        outline: "none",
        boxShadow: "#7F56D9 0px 2px 4px 0px, #7F56D9 0px 2px 16px 0px;",
        borderRadius: "6px",
        opacity: 0.8,
      },
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        appearance: "none",
        margin: 0,
      },
    },
    animatedValue: {
      position: "absolute",
      textAlign: "center",
      pointerEvents: "none",
    },
    underline: {
      position: "absolute",
      bottom: "17%",
      left: 0,
      right: 0,
      height: "2px",
      backgroundColor: "#7F56D9",
      marginInline: 13,
    },
  };
});
