import React from 'react';

import SumsubWebSdk from '@sumsub/websdk-react'
import {Environment} from "app/common";
import {messageEventTypes as met, reviewStatusNames as rsn} from "./KycConstants";

export type T_SumsubSdkViewProps = {
    accessToken: string | undefined;
    lang?: string;
    onSuccess: (payload: { status: string; }) => void;
    onError?: (error: any) => void;
}


export const SumsubSdkView = (props:T_SumsubSdkViewProps): React.JSX.Element | any=> {

    const config = {
        lang: 'en',

    }

    const options = {
        debug:!Environment.IS_PRODUCTION,
    }


    const messageHandler = (eventName: string, payload: any) => {
        const reviewStatus:string = payload['reviewStatus'];

        if (eventName === met.onApplicantSubmitted) {
            //props.onSuccess({status: payload.status})
        }

        if (eventName === met.onApplicantStatusChanged) {
            if (reviewStatus === rsn.pending){

            }

            if (reviewStatus === rsn.completed){
                props.onSuccess({status: payload.status})
            }


        }
    }

    const accessTokenExpirationHandler = ():Promise<string> => {
        console.log('accessTokenExpirationHandler called')
        return Promise.resolve('accessTokenExpirationHandler called')
    }

    const errorHandler = (error: any) => {
        console.error('errorHandler:', error)
    }


    return (
        <SumsubWebSdk
            accessToken={props.accessToken || ""}
            expirationHandler={accessTokenExpirationHandler}
            onMessage={messageHandler}
            onError={errorHandler}
            config={config}
            testEnv={!Environment.IS_PRODUCTION}
            options={options}
            style={{
                with: '600px', height: '100%'
            }}
        />
    );
}
