import React from "react";
import { QUserAccount as QUA } from "app/queries";
import {
  DefaultTableComponent,
  T_DefaultTableComponentProps,
  TDF,
} from "app/components/table";
import { get2FAStatusProps, getKycStatusProps, redirectTo } from "app/utils";
import { UserOptions } from "app/components/popup";

const renderFunction = (data: QUA.T_UserAccount): React.JSX.Element[] => {
  // console.log(data);
  const keyPrefix: string = data.id.toString();
  return [
    <TDF.TextContent value={data.email} key={`${keyPrefix}-EM`} />,
    <TDF.TextContent value={`${data.full_name}`} key={`${keyPrefix}-FN`} />,
    <TDF.NumericContent value={`${data.mobile_no}`} key={`${keyPrefix}-PH`} />,
    <TDF.TextContent value={data.company_name} key={`${keyPrefix}-CN`} />,
    <TDF.TextContent value={data.role_name} key={`${keyPrefix}-RN`} />,

    <TDF.StatusBadge
      {...get2FAStatusProps(data.is_2fa_active)}
      key={`${keyPrefix}-2FA`}
    />,
    <TDF.StatusBadge {...getKycStatusProps(data)} key={`${keyPrefix}-KYC`} />,

    <TDF.NumericContent value={data.trade_fees} key={`${keyPrefix}-TF`} />,
    <TDF.NumericContent value={data.money_hub_fees} key={`${keyPrefix}-MHF`} />,
    <TDF.NumericContent
      value={data.crypto_hub_fees}
      key={`${keyPrefix}-CHF`}
    />,

    <TDF.DateTimeContent dateTime={`${data.dob}`} key={`${keyPrefix}-DOB`} />,
    <TDF.DateTimeContent
      dateTime={data.email_verified_at}
      key={`${keyPrefix}-DT`}
    />,
    <TDF.RawElement element={<UserOptions userData={data} />} />,
  ];
};

const customerAccountsDataMap = {
  Email: "email",
  Name: "full_name",
  Mobile: "mobile_no",
  Company: "company_name",
  Role: "role_name",
  "2FA": "is_2fa_active",
  KYC: "kyc_is_verified",
  "Trade Fees": "trade_fees",
  "Moneyhub Fees": "money_hub_fees",
  "Cryptohub Fees": "crypto_hub_fees",
  "Date Of Birth": "dob",
  Date: "email_verified_at",
};

export const CustomerAccounts = (): React.JSX.Element => {
  const merchantAccountsQuery = QUA.useUserAccountsListQuery();
  const headings: string[] = [
    ...Object.keys(customerAccountsDataMap),
    "Action",
  ];
  const tableData = merchantAccountsQuery.isResolved
    ? merchantAccountsQuery.result
    : [];

  const getMenuItemOptionsFn = async (
    data: QUA.T_UserAccount
  ): Promise<TDF.T_MenuItemOptionList> => {
    const viewAllTransactions = () => redirectTo({ query: { cid: data.id } });
    return Promise.resolve([
      { name: "View Profile", onClickHandler: viewAllTransactions },
    ]);
  };

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Customers Accounts",
    headings: headings,
    isLoading: merchantAccountsQuery.isResolving,
    initialRowsPerPage: 10,
    tableData: tableData,
    // actionColumn: true,
    renderFunction: renderFunction,
    searchOptions: {
      searchKeys: [
        "email",
        "full_name",
        "mobile_no",
        "company_name",
        "role_name",
        "is_2fa_active",
        "kyc_is_verified",
        "trade_fees",
        "money_hub_fees",
        "crypto_hub_fees",
        "email_verified_at",
      ],
    },
    downloadOptions: {
      fileName: "customer-accounts.csv",
      dataMap: customerAccountsDataMap,
    },
    // actionOptions: {getMenuItemOptionsFn}
  };

  return <DefaultTableComponent {...tableComponentProps} />;
};
