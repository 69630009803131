/**
 * @file: useAccountStatus.ts
 * @date: 12 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {ApiConfig} from "app/config";
import {RQ} from "app/hooks";

const MONEYHUB_PAYMENT_APIS = {
    TRANSACTION_REQUEST: ApiConfig.create('moneyhub-transaction-request')

}

export type T_MoneyhubTransactionRequest = {
    id: string;
    sender_id: number;
    receiver_id: number;
    valid_till: string;
    redirect_url: string;
    amount: number;
    currency: string;
}

export const useMoneyhubTransactionRequest = (transactionId: string): RQ.T_QueryResult<T_MoneyhubTransactionRequest> => {

    return RQ.useQueryRequest<T_MoneyhubTransactionRequest>({
        url: MONEYHUB_PAYMENT_APIS.TRANSACTION_REQUEST.url,
        queryKey: MONEYHUB_PAYMENT_APIS.TRANSACTION_REQUEST.key,
        params: [transactionId],
    })
}
