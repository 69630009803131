import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import ArrowForwardIosRounded from "@mui/icons-material/ArrowForwardIosRounded";

const useStyles = makeStyles()((theme) => ({
  card: {
    height: "100%",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
    backgroundColor: "white",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  },
  addBalanceButton: {
    marginTop: "10px",
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
  },
}));

const AddBalanceCard = ({ balance, addBalance }: any) => {
  const { classes } = useStyles();
  const [inputBalance, setInputBalance] = useState(1000);

  const handleBalance = (amount: number) => {
    setInputBalance((prev) => prev + amount);
  };
  return (
    <Box className={classes.card}>
      <Typography fontSize={"2rem"} fontWeight={"bold"} textAlign={"center"}>
        Wallet
      </Typography>
      <Divider />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"h5"}>Balance: </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            color: "indigo",
          }}
        >
          <Typography variant={"h6"}>All Transactions</Typography>
          <ArrowForwardIosRounded />
        </Box>
      </Box>
      <Typography fontSize={"4rem"} textAlign={"center"} fontWeight={"bold"}>
        $ {balance}
      </Typography>
      <Divider />
      <Box flexGrow={1} />
      <TextField
        label="Add Balance"
        variant="outlined"
        type="number"
        value={inputBalance}
        onChange={(e) => setInputBalance(Number(e.target.value))}
      />
      <Box display={"flex"} justifyContent={"flex-end"} gap={"10px"}>
        <Chip
          label="+$100"
          variant="outlined"
          onClick={() => handleBalance(100)}
        />
        <Chip
          label="+$200"
          variant="outlined"
          onClick={() => handleBalance(200)}
        />
        <Chip
          label="+$500"
          variant="outlined"
          onClick={() => handleBalance(500)}
        />
        <Chip
          label="+$1000"
          variant="outlined"
          onClick={() => handleBalance(1000)}
        />
      </Box>
      <Button
        className={classes.addBalanceButton}
        onClick={() => addBalance(inputBalance)}
      >
        Add Balanace
      </Button>
    </Box>
  );
};

export default AddBalanceCard;
