import React from "react";
import ComingSoonPage from "app/components/unsorted/CommingSoon";
import {TabContent} from "app/components/tabs";
import SecurityTab from "./SecurityTab";
import VerificationTab from "./VerificationTab";
import {LoginHistoryTable} from "../../management/loginHistory/loginHistoryTable";

const Security = () => {
    const tabHeading: string = "Security";
    const tabData = [
        {label: "Verification", component: <SecurityTab />},
        {label: "Security", component: <SecurityTab />},
        {label: "Login History", component: <LoginHistoryTable />},
    ];
    return TabContent({tabData,tabHeading});

};

export default Security;
