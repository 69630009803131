/**
 * @file: useAccountStatus.ts
 * @date: 12 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {useState} from "react";
import {useSessionStorage} from "app/hooks";


export type T_MyCryptState = {
    transactionId: string;
    isPaymentMethodSelected: boolean;
    isAssetSelected: boolean;
    paymentMethod?: string;
    asset?: string;
}

export type T_UseMyCryptState = T_MyCryptState & {
    update: (newStatus: Partial<T_MyCryptState>) => void;
}


export const useMyCryptState = (transactionId:any): T_UseMyCryptState => {

    const dataKey: string = transactionId;

    const _storage = useSessionStorage<T_MyCryptState>(dataKey, 
        {
            transactionId: transactionId || '', 
            isPaymentMethodSelected: false,
            isAssetSelected: false,
            paymentMethod: '',
            asset: ''
        }
    );

    const [paymentState, setPaymentState] = useState<T_MyCryptState>(() => {
        return _storage.getValue();
    });


    const updatePaymentState = (newTransactionState: Partial<T_MyCryptState>): void => {

        const updatedPaymentState: T_MyCryptState = {
            ...paymentState, ...newTransactionState
        };
        _storage.setValue(updatedPaymentState);
        setPaymentState(updatedPaymentState);
    }

    return {...paymentState, update: updatePaymentState};

}
