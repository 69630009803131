/**
 * @file: useAccountStatus.ts
 * @date: 12 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {useState} from "react";
import {useSessionStorage} from "app/hooks";


export type T_WallethubPaymentState = {
    transactionId: string;
    isOtpVerified: boolean;
    isConfirmed: boolean;
    isKycCompleted: boolean;
    isTransactionInitiated: boolean;
    transactionLink: string;
    isTransactionCompleted: boolean;
    redirectUrl:string;


}

export type T_UseWallethubPaymentState = T_WallethubPaymentState & {
    update: (newStatus: Partial<T_WallethubPaymentState>) => void;
}


export const useWallethubPaymentState = (transactionId:any): T_UseWallethubPaymentState => {

    const dataKey: string = transactionId;

    const _storage = useSessionStorage<T_WallethubPaymentState>(dataKey, {
        transactionId:'', isOtpVerified: false, isConfirmed: false, isKycCompleted:false,
        isTransactionInitiated:false, transactionLink:'', isTransactionCompleted:false,
        redirectUrl:''
    });

    const [paymentState, setPaymentState] = useState<T_WallethubPaymentState>(() => {
        return _storage.getValue();
    });


    const updatePaymentState = (newTransactionState: Partial<T_WallethubPaymentState>): void => {

        const updatedPaymentState: T_WallethubPaymentState = {
            ...paymentState, ...newTransactionState
        };
        _storage.setValue(updatedPaymentState);
        setPaymentState(updatedPaymentState);
    }

    return {...paymentState, update: updatePaymentState};

}
