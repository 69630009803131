import {
  Box,
  Grid,
  Typography,
  Link,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { styles } from "app/styles";
import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  mainGrid: {
    ...styles.flexDRC,
    padding: "50px 20px",
  },
  verticalPadding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  parasCenterAlign: {
    textAlign: "justify",
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  parasCenterAlign0: {
    padding: "10px 0",
    textAlign: "center", // Centering the text
  },
  ulFlexBox: {
    marginTop: "10px",
  },
  boxPadding20: {
    paddingLeft: "20px",
  },
}));

const TermsAndCondition = () => {
  const { classes } = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      <Grid container className={classes.mainGrid}>
        <Grid item lg={9}>
          <Typography variant="h4" align="center" color="primary">
            GENERAL TERMS OF USE
          </Typography>
          <Typography variant="h4" align="center" color="primary">
            TradeBP
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            className={classes.verticalPadding}
          >
            Last updated: April 30, 2024
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            className={classes.verticalPadding}
          >
            Effective date: April 30, 2024
          </Typography>

          {/* Section 1 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 1
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            INTRODUCTION
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            These are the general terms and conditions applicable to all
            services and products offered by TradeBP on the Website and/or
            through the Platform. Please read them carefully, as well as our{" "}
            <Link href="/privacy-policy" color="primary">
              Privacy Policy
            </Link>
            , before proceeding, as they set out the contractual basis for the
            use of the Website, the Services, and the Products.
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            <b>"TradeBP, LDA"</b> is a Portuguese limited liability company by
            quotas, with registered office at Avenida das Comunidades Europeias,
            Edifício Ecuador, no. 396, Loja 500, 2750 - 351 Cascais, registered
            with the Portuguese Commercial Registry Office under the sole
            registration and taxpayer number 516983520 (hereinafter referred to
            simply as <b>“TradeBP”</b>, <b>“we”</b> or <b>“us”</b>). TradeBP is
            registered and authorized as a virtual asset service provider in
            Portugal by Banco de Portugal, which has developed and operates an
            online platform available at{" "}
            <Link
              href="https://www.tradebp.com"
              target="_blank"
              color="primary"
            >
              www.tradebp.com
            </Link>{" "}
            for facilitating B2B trading activities on certain digital assets
            (including cryptocurrencies), among other related activities and
            products.
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            By navigating on the Website, registering, and creating a TradeBP
            Account, accessing the Platform, and/or continue to use the Services
            and the Products, you (<b>“Customer”</b>, <b>“User”</b> or{" "}
            <b>“you”</b>) are acknowledging that you have read, understood, and
            accepted these Terms, as well as the Privacy Policy, and any
            additional documents or terms referred to herein. Therefore, these
            Terms constitute a legally binding agreement between you and
            TradeBP, governing the use of the Services and the Products made
            available to you on or through the Website and the Platform.
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            IF YOU DO NOT AGREE WITH THESE TERMS AND THE PRIVACY POLICY, YOU
            SHOULD NOT CONTINUE NAVIGATING THE WEBSITE, USING THE PLATFORM NOR
            THE SERVICES, AND WE ASK YOU TO PLEASE REFRAIN FROM DOING SO.
          </Typography>

          {/* Section 2 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 2
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            RISK WARNING
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            Before using our Services or Products and generally before engaging
            in any activities involving digital assets, we strongly encourage
            you to conduct thorough research, seek professional advice, and
            evaluate your risk tolerance. Understand that the value of digital
            assets can both rise and fall rapidly. Only invest amounts you can
            afford to lose without compromising your financial well-being.
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            This Section aims at providing you with information about the main
            (but not all) risks associated with digital assets. Please note that
            each Service and each Product may use or relate to digital assets in
            different and unique ways. Therefore, each Service and each Product
            may entail its own specific risks.
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            This Section comprises a general overview on the main risks
            associated with digital assets and activities related to digital
            assets, but it is not intended and should not be relied upon as a
            comprehensive and complete guide to all of the risks associated with
            digital assets. You should gather all information you deem necessary
            to fully understand the risks involved before making a decision to
            use the Services and the Products.
          </Typography>

          <Typography variant="body1" className={classes.parasCenterAlign}>
            Digital assets, including but not limited to cryptocurrencies,
            tokens, and virtual currencies, present unique risks and
            considerations, some of which (but not all) are outlined below:
          </Typography>

          <Typography component="ol" className={classes.parasCenterAlign}>
            <li className={classes.listItem}>
              <strong>Volatility:</strong> Digital assets are known for their
              extreme price volatility. The value of digital assets can
              fluctuate significantly within short periods, exposing their
              holders to potential financial losses.
            </li>
            <li className={classes.listItem}>
              <strong>Market Risk:</strong> Digital asset markets are
              decentralized and largely unregulated. As a result, they are
              susceptible to market manipulation, fraud, and illicit activities.
              The lack of regulatory oversight and safeguards increases the risk
              of financial losses.
            </li>
            <li className={classes.listItem}>
              <strong>Cybersecurity Risks:</strong> The digital asset ecosystem
              is vulnerable to cybersecurity threats, including hacking,
              phishing attacks, and unauthorized access to digital wallets and
              exchanges. Your digital assets may be subject to theft or loss if
              appropriate security measures are not taken.
            </li>
            <li className={classes.listItem}>
              <strong>Regulatory Risk:</strong> Regulatory frameworks and legal
              treatment of digital assets vary across jurisdictions. Regulatory
              changes or restrictions imposed by governments may adversely
              affect the value, transferability, and usability of digital
              assets.
            </li>
            <li className={classes.listItem}>
              <strong>Liquidity Risk:</strong> Digital asset markets may suffer
              from low liquidity, especially for less popular or newly launched
              assets. This may result in difficulties when buying or selling
              digital assets at desired prices or executing transactions
              promptly.
            </li>
            <li className={classes.listItem}>
              <strong>Technological Risks:</strong> Digital assets rely on
              blockchain technology and other emerging technologies that are
              still evolving. Technical failures, software bugs, or
              vulnerabilities in the underlying infrastructure can lead to
              financial losses or disruption of services.
            </li>
            <li className={classes.listItem}>
              <strong>Lack of Understanding:</strong> Engaging in digital asset
              transactions requires a good understanding of the technology,
              market dynamics, and associated risks. Lack of knowledge and
              experience may lead to poor decision-making and financial losses.
            </li>
            <li className={classes.listItem}>
              <strong>Loss of Access:</strong> If you lose access to your
              digital wallets, private keys, or passwords, you may permanently
              lose control over your digital assets. It is crucial to maintain
              proper backup mechanisms and secure storage to mitigate this risk.
            </li>
            <li className={classes.listItem}>
              <strong>Lack of Investor Protection:</strong> Unlike traditional
              financial systems, digital asset markets often lack investor
              protection mechanisms such as deposit insurance or recourse to
              regulatory bodies. In case of disputes, you may have limited legal
              recourse or support.
            </li>
            <li className={classes.listItem}>
              <strong>Speculative Nature:</strong> The digital asset market is
              highly speculative, driven by investor sentiment, market trends,
              and speculation. Investing in digital assets involves a
              significant degree of risk, and potential investors should
              carefully consider their financial situation and risk appetite.
            </li>
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            Without prejudice to any of the above, we do not provide personal
            advice in relation to our Products or Services. We may provide
            factual information, information about transaction procedures and
            information about the potential risks, but any decision to use our
            Products or Services is made exclusively by you and at your own
            peril. No communication or information provided to you by TradeBP is
            intended as, or shall be considered or construed as, investment
            advice, financial advice, trading advice, legal advice, tax advice
            or any other sort of advice.
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            You are solely responsible for determining whether any investment,
            investment strategy or related transaction is appropriate for you
            according to your personal investment objectives, financial
            circumstances and risk tolerance.
          </Typography>

          <Typography className={classes.parasCenterAlign}>
            IF YOU ARE NOT COMFORTABLE OR IF YOU CANNOT TOLERATE THE RISKS
            LISTED ABOVE AND ANY OTHER RISKS ASSOCIATED WITH DIGITAL ASSETS, WE
            STRONGLY ADVISE YOU NOT TO USE THE PLATFORM, THE SERVICES OR OUR
            PRODUCTS.
          </Typography>

          {/* Section 3 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 3
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            GENERAL TERMS OF ENGAGEMENT
          </Typography>

          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            1. ELIGIBILITY
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              1.1 To access the Website and the Platform, as well as to use the
              Services and the Products, you must have full legal capacity and
              authority to comply with the obligations in these Terms, whether
              as an individual or as a legal entity. If you are an individual,
              you must be at least 18 years old and must not have any
              restriction measures imposed on you. If you represent a legal
              entity, you must have proper authorization to act on its behalf.
              In addition, to access the Services, you must be located or
              incorporated in a jurisdiction where TradeBP is authorized to
              render the Services.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              1.2 TradeBP reserves the right to amend these eligibility criteria
              at its discretion. While we aim to provide advance notice of
              changes, immediate changes may occur in response to legal or
              regulatory requirements, for your benefit, or due to other valid
              reasons. We will notify you promptly of any such changes after
              implementation.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              1.3 When accessing the Website, creating a TradeBP Account, or
              using the Services and the Products, you represent and warrant to
              us, at all times, that:
              <ul>
                <li className={classes.ulFlexBox}>
                  1.3.1 You and your authorized representatives, if applicable,
                  comply with all eligibility criteria above;
                </li>
                <li className={classes.ulFlexBox}>
                  1.3.2 You and your authorized representatives, together with
                  your ultimate beneficial owners or any entity that controls or
                  is under common control as you, as applicable, are not
                  prevented from using our Services and Products by means of any
                  embargoes or sanctions applicable to you directly or your
                  country of residence, including those imposed by the United
                  Nations, the European Union, the United States Department of
                  the Treasury's Office of Foreign Assets Control, and any other
                  relevant national or international authorities; and
                </li>
                <li className={classes.ulFlexBox}>
                  1.3.3 You are not using the Services and the Products for the
                  benefit or on behalf of any person that is not in compliance
                  with the paragraphs above or the eligibility criteria in this
                  clause.
                </li>
              </ul>
            </Typography>
          </Box>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            2. SERVICES
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              2.1 TradeBP is a blockchain based financial hub, and our vision is
              to develop and provide cutting edge technology to our customers
              and their end-users, enabling full scope of financial services
              using cryptocurrencies.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              2.2 We are registered with Banco de Portugal as a virtual asset
              service provider and authorized to provide the following services
              (<b>“Services”</b>): (i) exchange services between virtual assets
              and fiat currencies; (ii) exchange services between one or more
              virtual assets; and (iii) services of safekeeping, or safekeeping
              and administration of virtual assets or of instruments that enable
              the control, ownership, storage, or transfer of such assets,
              including private encrypted keys.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              2.3 The Products available at the Website and/or through the
              Platform are derived from the Services and are designed to provide
              practical applications of those Services. When registering with us
              and creating a TradeBP Account, you will be granted access to the
              following core Products:
              <ul>
                <li className={classes.ulFlexBox}>
                  2.3.1 <b>Custody</b> One or more digital wallets hosted
                  directly with the Platform, enabling you to store, track and
                  transfer cryptocurrencies, stable coins and other digital
                  assets;
                </li>
                <li className={classes.ulFlexBox}>
                  2.3.2 <b>Best Execution</b> Trading and execution management
                  system, connected to all major exchanges, brokers and market
                  makers globally, allowing you to obtain real-time prices for
                  buying and selling digital assets and engage in trading
                  activities with best execution and seamless settlement;
                </li>
                <li className={classes.ulFlexBox}>
                  2.3.3 <b>Spot Trading - OTC</b> Over-the-counter (OTC)
                  trading. For the avoidance of doubt, this Product is limited
                  to the placement and execution of transactions at the specific
                  request and according to specific instructions from the
                  Customer. It is not and shall not be deemed as a brokerage
                  activity or financial advice in any way.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              2.4 Our Services and Products are primarily designed for
              business-to-business (B2B) purposes. While individual consumers
              may access and use the Platform and use some of the Products, our
              core focus is on serving the needs of businesses and enterprises.
              By using our Services and Products, you acknowledge this primary
              B2B orientation.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              2.5 TradeBP may make additional products available on our Platform
              periodically. The core Products mentioned above, along with any
              other Products offered on or through the Platform, are governed by
              these Terms and may also be subject to additional specific terms
              and conditions (“Product Terms”) and other contractual documents.
              By accessing any such Products, you acknowledge that you have read
              and accepted these Terms and the Product Terms, as well as any
              additional terms referred to therein, recognizing that they may be
              updated by us at any time.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              2.6 TradeBP is not acting as your broker, intermediary, agent, or
              adviser, nor do we have any fiduciary duties to you in connection
              with or arising from any transactions or activities conducted in
              our Platform, notably by accessing and using the Products. We do
              not offer investment or consulting advice in any form, and none of
              the information or communication made available to you, either
              directly or through the Platform or the Website is to be deemed
              advice of any nature (including legal or financial advice).
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              2.7 You are entirely and solely responsible to assess and
              determine whether any investment or transaction aligns with your
              personal investment objectives, financial situation, and risk
              profile. Any losses or liabilities incurred in connection with the
              use of the Products, the Services and/or the Platform are your own
              and exclusive responsibility. We do not endorse or recommend the
              acquisition, sale, holding or any other transaction of any
              specific digital asset. Before making any investment decisions
              regarding digital assets, you should conduct proper due diligence
              and, if you deem required, in your absolute discretion, seek
              appropriate financial advice. We do not accept any responsibility
              or liability for any decisions you make concerning any transaction
              with digital assets, including buying, selling, or holding, based
              on information provided by us.
            </Typography>
          </Box>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            3. FEES
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              3.1 TradeBP may charge fees for the use of Services and/or the
              Products provided on the Platform. The specific fees associated
              with each Product or Service are detailed in the Fee Letter
              available at the Website or communicated to you in any other way,
              and as reviewed from time to time. For corporate clients, the Fee
              Letter may be communicated to you by means of a specific
              commercial offer. You are responsible for reviewing the applicable
              Fee Letter to understand the fees associated with the Products and
              Services you intend to use, either on the Platform or as provided
              otherwise by TradeBP.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              3.2 We reserve the right to update, modify, or change the fees
              specified in the Fee Letter at our sole discretion. Such updates,
              modifications, or changes will be effective upon the date
              specified in the updated Fee Letter. TradeBP will make
              commercially reasonable efforts to provide you with prior notice
              of any fee updates, modifications, or changes. Notice may be
              provided through the Platform, email, or directly at the Website.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              3.3 You acknowledge and agree that it is your sole and exclusive
              responsibility to regularly review the Fee Letter and stay
              informed about the applicable fees for the use of the Platform,
              the Services, and the Products. Continued use of the Platform, of
              the Services and of the Products, including after the effective
              date of fee updates, modifications, or changes, constitutes
              acceptance of the fees in force at the time of such use.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            4. PERSONAL DATA AND PRIVACY
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              4.1 The collection and use of personal data in connection with
              these Terms, the Services, the Products, and the use of the
              Platform and the Website, is processed in accordance with and as
              provided in our Privacy Policy, which is an integrant part of
              these Terms.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              4.2 TradeBP will be entitled to use any data and content you
              submit to the Website and to the Platform, in any way and
              irrespective of the media used to submit such data, or any data we
              collect through the Website and the Platform, for statistic,
              functionality and performance purposes or any other purposes as we
              see fit, provided we comply with all terms and conditions
              applicable to personal data.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            5. CHANGES TO THE TERMS
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              5.1 Your acknowledge and agree that we have the right to modify,
              amend, or update these Terms and the Product Terms, as well as any
              terms and conditions incorporated by reference in any of those, at
              our sole discretion and convenience
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              5.2 TradeBP will notify you of any changes to the Terms or the
              Product Terms through the Platform, email, or directly at the
              Website. In most cases, TradeBP will provide advance notice of
              changes and specify the effective date, but there may be instances
              where changes are made without advance notice.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              5.3 The updated Terms and Product Terms will become effective as
              specified in the notice provided to you. If changes are not
              effective immediately, they will become effective after the notice
              period specified in the notification.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              5.4 If you do not wish to accept the changes to the Terms or the
              Product Terms, you have the option to either close your TradeBP
              Account in accordance with the provisions outlined in these Terms
              or cancel your subscription of any specific Products (other than
              the core Products specified in these Terms). By doing so, you
              indicate your refusal to accept the revised Terms or the Product
              Terms, as applicable, and accept that no compensation shall be due
              to you by TradeBP in connection thereto.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              5.5 Unless we are given notice indicating your refusal of the
              changes, we will assume that you have accepted the revised Terms
              and/or Product Terms. Your continued access to or use of the
              Platform, the Website, or the Products will be deemed as your
              acceptance of the updated Terms or Product Terms, as applicable.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            6. LIENS AND ENCUMBRANCES
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              6.1 You acknowledge and agree that the legal framework applicable
              to digital assets may change and that any regulatory risk
              associated with any change in law, including any tax implication,
              will be borne by you. TradeBP accepts no liability, including for
              any losses, arising from changes in law.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              6.2 You acknowledge and agree that the digital assets (including
              cryptocurrencies) you may held in your TradeBP Account may –
              subject to applicable law and without your consent – be affected
              by liens and encumbrances, including any legal form of
              apprehension. You further acknowledge and accept that TradeBP may,
              without your consent and according to applicable law, limit your
              access to, encumber or transfer any digital assets held at your
              TradeBP Account, if and to the extent required by any court or
              authority of competent jurisdiction. We will promptly inform you –
              if and to the extent we are legally allowed to do so – about those
              circumstances and any underlying justifications (including
              providing any supporting documentation, when available).
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              6.3 You must not create any form of security over digital assets
              held in your TradeBP Account unless you have obtained our prior
              approval in writing. We will not be required to take any actions
              regarding creation, registration and/or enforcement of any
              security unless we have provided our specific consent to the
              creation of that security or as legally required.
            </Typography>
          </Box>

          {/* Section 4 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 4
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            TRADEBP ACCOUNT
          </Typography>

          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            7. CREATING A TRADEBP ACCOUNT
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              7.1 To access the Services, the Products and the Platform, every
              Customer – both individuals and legal entities – will need to
              create and maintain an account with TradeBP and to provide all the
              information requested on the Website for that specific purpose.
              TradeBP reserves the right to reject any application for a TradeBP
              Account without providing a reason or to limit the number of
              TradeBP Accounts you can hold.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              7.2 When opening and using a TradeBP Account, you agree to comply
              with the following provisions:
              <ul>
                <li className={classes.ulFlexBox}>
                  7.2.1 Sole Benefit: As an individual user, you will use your
                  TradeBP Account exclusively for yourself, and not on behalf of
                  any third party; as a corporate user, your TradeBP Account
                  will be used solely by your authorized representatives for
                  such purposes and such TradeBP Account will be used only for
                  your benefit and not on behalf of any third party;
                </li>
                <li className={classes.ulFlexBox}>
                  7.2.2 Information: Provide accurate and truthful information
                  and keep such information always updated and as further
                  detailed in these Terms;
                </li>
                <li className={classes.ulFlexBox}>
                  7.2.3 Access and security: Create a strong and unique password
                  for accessing your TradeBP Account, abiding by the password
                  guidelines presented during the registration process, protect
                  your password, and restrict access to your TradeBP Account by
                  any unauthorized users;
                </li>
                <li className={classes.ulFlexBox}>
                  7.2.4 Authorized representatives: As a corporate user, you
                  will be responsible for updating the list of persons
                  authorized to operate your TradeBP Account on your behalf and
                  inform us about any persons which are no longer your
                  authorized representatives; TradeBP will not be held liable
                  for any unauthorized use of your TradeBP Account by any
                  authorized representatives that are registered and authorized
                  in your client profile at any given time;
                </li>
                <li className={classes.ulFlexBox}>
                  7.2.5 Unauthorized access: Promptly notify us if you discover
                  or otherwise suspect of any security breaches or unauthorized
                  access to your TradeBP Account; and
                </li>
                <li className={classes.ulFlexBox}>
                  7.2.6 Accountability: Take responsibility for all activities
                  that take place with your TradeBP Account and accept all risks
                  of any authorized or unauthorized access to your TradeBP
                  Account.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              7.3 Although TradeBP aims at putting in place all security
              measures and authentication procedures as it deems required to
              keep your TradeBP Account secure and free of unauthorized access
              and abusive utilization, we cannot guarantee such an outcome.
              Therefore, we accept no liability for misuse or unauthorized use
              of your TradeBP Account, including any losses or damages that can
              arise as a result thereof.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            8. IDENTITY VERIFICATION AND ENHANCED DUE DILIGENCE
          </Typography>

          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              8.1 Before accessing our Services and our Products, you will be
              required to undergo our identity verification procedures. This
              entails providing accurate, complete, and up-to-date information
              about yourself and, when applicable, your authorized
              representatives.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              8.2 You acknowledge and agree that TradeBP operates under the
              supervision of Banco de Portugal and is legally required to comply
              with certain AML/CFT laws and regulations. Therefore, you hereby
              agree and accept that we may conduct all inquiries, either
              directly or through third parties, to verify your identity and
              that of your authorized representatives, as we may deem required
              or convenient to ensure full compliance with the applicable laws.
              Such inquiries may involve disclosing your personal data and that
              of your authorized representatives to third party providers, as
              further detailed in our Privacy Policy.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              8.3 Depending on several factors, you may also be required to
              undergo “Enhanced Due Diligence” procedures in accordance with
              applicable law and our internal policies. These procedures allow
              TradeBP to request additional information about yourself, your
              authorized representatives, or your business, provide relevant
              records, and also arrange for meetings with TradeBP. enhanced due
              diligence is designed to establish the source of your wealth and
              source of funds for any transactions carried out through the
              Platform.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              8.4 We retain your personal data as required for the use of the
              Services and to meet legal obligations, such as tax and accounting
              requirements, anti-money laundering compliance, or other purposes
              communicated to you, all in accordance with our Privacy Policy.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              8.5 When providing information in the context of the identity
              verification procedure and the enhanced due diligence procedures,
              you represent and warrant to us that the information provided is
              true, accurate and complete, and you have not withheld any
              information that may influence TradeBP assessment for the purposes
              of accepting your registration and creating your TradeBP Account.
              In addition, you undertake to promptly inform us and update such
              information as required from time to time for TradeBP to keep
              accurate and updated records in this regard at all times.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              8.6 Your access to your TradeBP Account and transaction limits may
              change based on ongoing information collected about you. If there
              is a reasonable suspicion that information provided by you is
              inaccurate, outdated, or incomplete, we may request corrections or
              take actions we deem necessary to ensure accurate information. You
              must comply with information requests. Failure to do so may result
              in the suspension or termination of your access to your TradeBP
              Account and/or to the Services and Products without prior notice.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            9. USE OF YOUR TRADEBP ACCOUNT
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              9.1 Operations
              <ul>
                <li className={classes.ulFlexBox}>
                  9.1.1 You are responsible for ensuring that every order and
                  instruction submitted by you in the Platform or by any other
                  means of communication available at any time, irrespective of
                  the Product you may be using, is complete and accurate.
                  TradeBP is not obligated to verify the accuracy, authenticity,
                  or validity of any given instruction or order. We may,
                  although we are not obliged to, refuse to act, or delay action
                  in the event we suspect, in our sole and absolute discretion,
                  that a given instruction or order is inaccurate, not
                  authentic, or invalid.
                </li>
                <li className={classes.ulFlexBox}>
                  9.1.2 Once you give an order or an instruction according to
                  the procedures set out in the Platform and other Product
                  Terms, that order or instruction is irrevocable and cannot be
                  reversed and you will have no right to cancel or withdraw it.
                  We will act and initiate the relevant transaction upon
                  receiving the order or instruction in our servers, subject to
                  any system requirements and third party connection that may be
                  required to process such order or instruction. TradeBP may
                  refuse or delay processing any given order or instruction to
                  the extent it violates any applicable laws or these Terms.
                </li>
                <li className={classes.ulFlexBox}>
                  9.1.3 You are the sole responsible for managing and using your
                  TradeBP Account and for all transactions and operations
                  instructed or originated in your TradeBP Account. Therefore,
                  TradeBP is not obliged to confirm the authenticity of any
                  order or instruction given in accordance with the procedures
                  set out in the Platform and other Product Terms. We will only
                  refrain from processing any order or instruction originating
                  in your TradeBP Account if we receive notice from you or from
                  your authorized representative of an unauthorized use of your
                  TradeBP Account prior to receiving or acting upon any given
                  order or instruction. You are required to promptly inform us
                  of any unauthorized or suspicious activity on your TradeBP
                  Account. We do not accept responsibility for any claims or
                  losses arising from operations executed due to unauthorized
                  instructions.
                </li>
                <li className={classes.ulFlexBox}>
                  9.1.4 TradeBP will retain information about your TradeBP
                  Account, including record of any instructions and
                  transactions, to comply with industry standards for data
                  retention and to comply with all applicable law, notably
                  regarding AML/CFT. Please refer to our Privacy Policy for
                  additional details.
                </li>
              </ul>
            </Typography>
          </Box>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              9.2 Limits
              <ul>
                <li className={classes.ulFlexBox}>
                  9.2.1 We may impose limitations and restriction to your
                  TradeBP Account, notably regarding the number or volume of
                  transactions allowed in a given time frame (a day, a week, a
                  month, etc.), or the global amount or value of fiat currency
                  or digital assets you are allowed to transfer or keep in your
                  wallet.
                </li>
                <li className={classes.ulFlexBox}>
                  9.2.2 We retain the right to change, amend, add and/or reduce
                  any limits and restrictions associated with your TradeBP
                  Account at our sole discretion, without prior notice.
                  Additionally, you may have the option to request adjustments
                  to your limits. Any modifications will be determined solely by
                  us and may be subject to further terms and conditions as we
                  consider necessary.
                </li>
              </ul>
            </Typography>
          </Box>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              9.3 Account Security and Responsibility
              <ul>
                <li className={classes.ulFlexBox}>
                  9.3.1 You and your authorized representatives, if applicable,
                  are the sole responsible to safeguard its hardware and data
                  against viruses, malicious software, and inappropriate
                  material, being required to implement all reasonable measures
                  in that regard.
                </li>
                <li className={classes.ulFlexBox}>
                  9.3.2 You and any of your authorized representative, if
                  applicable, must endeavor your best efforts to keep sufficient
                  security and control over your TradeBP Account, including
                  (without limitation) adhering to the following good practices:
                </li>
                <li className={classes.ulFlexBox}>
                  <ul>
                    <li className={classes.ulFlexBox}>
                      (a) Adhering strictly to our access control and
                      authentication mechanisms and procedures;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (b) Creating a strong and unique password and ensuring the
                      security and control of your credentials;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (c) Refrain from digitally storing your access
                      credentials;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (d) Change your password from time to time;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (e) Keeping your contact information up to date, including
                      email and phone;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (f) Logging out from your TradeBP Account after each
                      visit;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (g) Monitor your TradeBP account history to identify
                      suspicious activities.
                    </li>
                  </ul>
                </li>
                <li className={classes.ulFlexBox}>
                  9.3.5 As a corporate user, you will be responsible for the
                  actions and instruction given by your authorized
                  representatives in your TradeBP Account. You undertake to
                  update the list of individuals authorized to act on your
                  behalf and to operate the TradeBP Account on your behalf and
                  to keep that information updated at all times. TradeBP will
                  not be held liable for any unauthorized use of your TradeBP
                  Account by any authorized representatives that are registered
                  and authorized in your client profile at any given time.
                </li>
                <li className={classes.ulFlexBox}>
                  9.3.6 You acknowledge that a security breach may result in
                  unauthorized access by third parties, potentially leading to
                  the loss or theft of digital assets and/or funds held in your
                  TradeBP Account, as well as any associated accounts, including
                  linked bank accounts and credit cards.
                </li>
                <li className={classes.ulFlexBox}>
                  9.3.7 We retain the right to request, and you agree to provide
                  any relevant information and documents in connection with an
                  actual or suspected security breach, which may be shared with
                  necessary third parties to resolve the security breach.
                </li>
              </ul>
            </Typography>
          </Box>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            10. DIGITAL ASSETS AVAILABILITY
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              10.1 You will only be able to access selected digital assets in
              our Platform. While we aim at making available in our Platform all
              major digital assets (including cryptocurrency) available in the
              market, TradeBP reserves the right to choose, in its sole and
              absolute discretion, what digital assets (including
              cryptocurrencies) are available in the Platform or can be used as
              part of the Services and Products. Digital assets (including
              cryptocurrencies) available at the Platform may, therefore, vary
              from time to time.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              10.2 TradeBP assumes no obligation to allow transactions of any
              kind based on any specific digital assets (including
              cryptocurrencies) nor to make available on the Platform any
              specific digital assets (including cryptocurrencies). Our Services
              and Products may use or operate on the basis of different digital
              assets (including cryptocurrencies), which will be selected by
              TradeBP, on its sole and absolute discretion, from time to time.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              10.3 You acknowledge and agree that TradeBP may stop making
              available at the Platform any digital asset (including
              cryptocurrencies) at its sole and absolute discretion, meaning
              that you will no longer have access to those digital assets as
              part of the Services and Products. However, you will still be
              authorized to keep those digital assets in your TradeBP Account
              for a limited period and transfer those to wallet addresses
              outside TradeBP or, if that feature is available, withdraw those
              assets from your TradeBP Account. If any digital assets that are
              no longer available at TradeBP remain in your TradeBP Account
              beyond a specified period, as notified to you, TradeBP may, at its
              reasonable discretion, convert such digital assets into a
              different type of digital asset, specifically a stable coin. We
              will notify you in advance of any such conversion, allowing you to
              transfer or withdraw these digital assets within a reasonable
              period, as specified by TradeBP.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              10.4 TradeBP assumes no liability in connection with any attempts
              to use your TradeBP Account for digital assets that are not or
              cease to be made available at our Platform or in connection with
              the conversion to a different type of digital asset, as described
              in this clause.
            </Typography>
          </Box>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            11. SUSPENSION AND TERMINATION OF TRADEBP ACCOUNT
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              11.1 We reserve the right, with or without prior notice, and in
              our sole discretion, to (i) refuse, suspend, delay, block, cancel,
              or reverse (to the extent possible) any transaction that you have
              authorized, even if funds and/or assets have already been debited
              from your TradeBP Account; (ii) suspend, restrict, or terminate
              your access to any or all of the Services or Products; and/or
              (iii) deactivate or cancel your TradeBP Account, with immediate,
              effect for any reason, including but not limited to the following
              circumstances:
              <ul>
                <li className={classes.ulFlexBox}>
                  11.1.1 If we reasonably believe that such action is necessary
                  to protect the reputation of TradeBP;
                </li>
                <li className={classes.ulFlexBox}>
                  11.1.2 If we are, in our reasonable opinion, required to do so
                  by applicable laws, regulations, or any court or other
                  authority in any jurisdiction to which we are subject;
                </li>
                <li className={classes.ulFlexBox}>
                  11.1.3 If we reasonably suspect that you have acted in breach
                  of these Terms or any Product Terms;
                </li>
                <li className={classes.ulFlexBox}>
                  11.1.4 If we have concerns about the security of your TradeBP
                  Account or if we suspect that the Services or the Products are
                  being used in a fraudulent or unauthorized manner;
                </li>
                <li className={classes.ulFlexBox}>
                  11.1.5 If the use of your TradeBP Account becomes subject to
                  any pending litigation, investigation, or government
                  proceeding, or if we perceive a heightened risk of legal or
                  regulatory non-compliance associated with your TradeBP Account
                  activity; and
                </li>
                <li className={classes.ulFlexBox}>
                  11.1.6 If you take any action that may circumvent our
                  controls, such as attempting to open multiple TradeBP Accounts
                  or abusing promotions that we may offer from time to time.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              11.2 Please note that we retain the discretion to take these
              actions in the interest of maintaining the integrity and security
              of the Platform and to comply with applicable laws and
              regulations.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              11.3 We may also refrain from acting on or revert (to the extent
              possible) any given instruction if there is no sufficient funds or
              digital assets (including cryptocurrency) in your TradeBP Account
              to process the envisaged operation, plus payment of any applicable
              fees, or if your credit or debit card or any other valid payment
              method linked to your TradeBP Account is declined.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              11.4 If we refuse to execute a transaction, suspend, restrict, or
              close your TradeBP Account, or terminate your use of our Services
              and Products, we will provide notice of our actions, along with
              the reasons for refusal, suspension, or closure. Additionally, if
              applicable, we will outline the procedure for correcting any
              factual errors that led to the refusal, suspension, or closure of
              your TradeBP Account. We will promptly lift the suspension or
              execute the transaction when the reasons for refusal and/or
              suspension no longer apply. However, we are not obligated to allow
              you to reinstate a transaction at the same price or under the same
              terms as the initially suspended, reversed, or canceled
              transaction.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              11.5 We reserve the right to suspend, restrict, or terminate your
              access to any or all the Services and/or Products, or deactivate
              or cancel your TradeBP Account, without providing a specific
              reason, with a notice period of two months. You acknowledge that
              our decision to take these actions, including limiting access,
              suspension, or closure of your TradeBP Account, may be based on
              confidential criteria that are essential for our risk management
              and security protocols. You agree that TradeBP is not obliged to
              disclose the details of its risk management and security
              procedures to you.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              11.6 You have the right to terminate your TradeBP Account, at your
              discretion, without incurring in any charges, other than any
              outstanding amounts you may owe to TradeBP at that point in time.
              When initiating account termination procedures, you authorize
              TradeBP to cancel or suspend any pending transactions and deduct
              any outstanding amounts owed to us directly from your TradeBP
              Account.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              11.7 TradeBP may, under certain circumstances, not be able to
              close your TradeBP Account. These include instances where (i)
              there may be pending or threatened investigations from any
              competent authorities which require access or information about
              your TradeBP Account, (ii) you have pending transactions, (iii)
              your TradeBP Account has outstanding balances owed to us, and (iv)
              your TradeBP Account or assets in your TradeBP Account are subject
              to any seizure or claim or other legal procedure entailing
              apprehension of any rights or assets
            </Typography>
          </Box>

          {/* Section 5 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 5
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            OTHER OPERATIONAL TERMS AND CONDITIONS
          </Typography>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            12. CUSTOMER DUTIES AND OBLIGATIONS
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              12.1 When creating and using a TradeBP Account or otherwise when
              accessing the Website or the Platform, you agree that you and any
              authorized representative of yours will:
              <ul>
                <li className={classes.ulFlexBox}>
                  12.1.1 Refrain from taking any action in breach of these
                  Terms, the Product Terms and any other contractual
                  documentation entered into with TradeBP
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.2 Use the Services and the Products only in accordance
                  with and for the purposes set out in these Terms and the
                  Product Terms;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.3 Refrain from using the Services and the Products for
                  any illegal, fraudulent, malicious, or illegitimate interests;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.4 Refrain from using the Services and the Products for
                  commercial purposes, unless expressly agreed by us in writing
                  or in accordance with any applicable Product Terms
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.5 Refrain from using the Services and the Products in a
                  way that is deemed as market manipulation or negatively
                  impacts the market of any digital asset, including, without
                  limitation, pump and dump schemes, wash trading, self-trading,
                  front running, quote stuffing, and spoofing or layering,
                  regardless of whether prohibited by any applicable law;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.6 Refrain from providing false, inaccurate, or misleading
                  information in connection with your use of the Services or the
                  Products;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.6 Refrain from providing false, inaccurate, or misleading
                  information in connection with your use of the Services or the
                  Products;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.7 Keep your contact details (including your email address
                  and telephone number) up to date in your TradeBP Account in
                  order to receive any notices or alerts that we may send to
                  you;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.8 Refrain from modifying, adapting, or interfering, in
                  any way, with the whole or any part of the Platform or combine
                  or incorporate the Platform into another program or
                  application, including using any automated devices, programs
                  or algorithms on the Website or in the Platform;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.9 Refrain from disassembling, decompiling,
                  reverse-engineering or otherwise attempting to derive the
                  source code, object code underlying concepts, ideas and
                  algorithms of the Platform or any components thereof;
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.9 12.1.10 Refrain from violating any applicable law, IP
                  rights from TradeBP or from any third party, or breaching any
                  rights of publicity or privacy; and/or
                </li>
                <li className={classes.ulFlexBox}>
                  12.1.11 Refrain from accessing or using, or making any attempt
                  to assess for use, by any means, the Services and Products in
                  a jurisdiction where TradeBP is not authorized to act or where
                  it might be illegal for TradeBP to act of for the
                  Services/Products to be offered or made available.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              12.2 We reserve the right at all times to monitor, review, retain
              and/or disclose any information as necessary to satisfy any
              applicable law, regulation, sanctions programs, legal process, or
              governmental request.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              12.3 You agree to indemnify us, our affiliates and service
              providers, and each of our, or their, respective officers,
              directors, agents, employees and representatives, in respect of
              any costs (including attorneys' fees and any fines, fees or
              penalties imposed by any regulatory authority) that have been
              reasonably incurred in connection with any claims, demands or
              damages arising out of or related to your breach and / or our
              enforcement of this Terms, or your violation of any law, rule or
              regulation, or the rights of any third party.
            </Typography>
          </Box>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            13. INTELLECTUAL PROPERTY
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              13.1 The Platform and all its components, including but not
              limited to the interface, Website, software, graphics, trademarks,
              logos, images, designs, and any related intellectual property
              rights, are the exclusive property of TradeBP and are protected by
              copyright, trademark, and other applicable intellectual property
              laws. You acknowledge and agree that TradeBP owns all rights,
              title, and interest in and to the Platform and its associated IP,
              and that no rights or licenses to such IP are granted to users
              except as expressly provided herein.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              13.2 Subject to compliance with these Terms and any Product Terms,
              TradeBP grants you a limited, non-exclusive, non-transferable,
              revocable license to access and use the Platform and the Website
              solely for the purpose of accessing the Services and using the
              Products, as well as to access or use any activities as
              facilitated by or through the Platform and/or the Website from
              time to time. This license may be adapted to other specific
              permitted uses in the Product Terms
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              13.3 Users may not reproduce, modify, distribute, create
              derivative works of, publicly display, publicly perform,
              republish, download, store, or transmit any portion of the
              Platform or its content, except as expressly permitted in these
              Terms or with prior written consent from TradeBP.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              13.4 All trademarks, trade names, and logos used or displayed on
              the Platform or on the Website ("Distinctive Signs") are the
              property of TradeBP or their respective owners, where applicable.
              Costumers are not authorized to use any Distinctive Signs without
              the express written permission of TradeBP or the respective owner,
              as applicable.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              13.5 Costumers will retain ownership of any content they submit,
              post, or upload to the Website or to the Platform, if and when
              applicable ("User-Generated Content"). However, by submitting
              User-Generated Content to the Website and/or to the Platform, you
              grant us a worldwide, non-exclusive, royalty-free, sublicensable,
              and transferable license to use, reproduce, distribute, modify,
              publicly display, and publicly perform the User-Generated Content
              for the purpose of operating, promoting, and improving the
              Platform, the Website, the Services, and the Products
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              13.6 TradeBP may enforce its intellectual property rights to the
              fullest extent permitted by law and Customers agree and undertake
              not to engage in any activity that infringes or misappropriates
              TradeBP's IP or the IP rights of others. We also reserve the right
              to terminate or suspend a your TradeBP Account, in our sole
              discretion, for any violation of this clause.
            </Typography>
          </Box>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            14. CONNECTION WITH THIRD-PARTY TRADING PLATFORMS
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              14.1 General
              <ul>
                <li className={classes.ulFlexBox}>
                  14.1.1 Our Platform offers users a unified interface, creating
                  a simplified and efficient user experience to engage in
                  cryptocurrency and other digital assets trading and any
                  related activities. We aim at providing a comprehensive and
                  secure trading experience to Costumers, focusing on delivering
                  reliable data and seamless order execution.
                </li>
                <li className={classes.ulFlexBox}>
                  14.1.2 While users experience trading and settlement
                  activities on the Platform, it is important to clarify that
                  the actual execution and settlement of the transactions occur
                  on reputable third-party exchanges ("Third-Party Exchanges")
                  with which TradeBP has established agreements. Therefore, the
                  Platform offers access to real-time data, order processing,
                  and liquidity from various Third-Party Exchanges, allowing
                  users to view and execute orders and transactions on these
                  external platforms seamlessly through the Platform's
                  user-friendly interface
                </li>
                <li className={classes.ulFlexBox}>
                  14.1.3 TradeBP does not exercise direct control over the
                  operations, policies, or technical infrastructure of the
                  Third-Party Exchanges. The Platform relies on the Third-Party
                  Exchanges to execute user orders, maintain order books, and
                  handle the settlement of transactions. Therefore, TradeBP
                  cannot guarantee or be held responsible for the performance,
                  availability, security, or compliance of the Third-Party
                  Exchanges.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              14.2 Limitation of Liability for Third-Party Exchanges
              <ul>
                <li className={classes.ulFlexBox}>
                  14.2.1 Costumers acknowledge and agree that the operation and
                  performance of Third-Party Exchanges are beyond TradeBP's
                  direct control. Therefore, to the fullest extent permitted by
                  applicable law, TradeBP, its directors, officers, employees,
                  staff, and service providers shall not be liable for any
                  losses, damages, liabilities, or disruptions arising from the
                  actions, omissions, or malfunctions of Third-Party Exchanges,
                  including but not limited to:
                </li>
                <li className={classes.ulFlexBox}>
                  <ul>
                    <li className={classes.ulFlexBox}>
                      (a) Technical issues, downtime, or disruptions on
                      Third-Party Exchanges;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (b) Execution errors, delays, or interruptions in order
                      processing on Third-Party Exchanges;
                    </li>
                    <li className={classes.ulFlexBox}>
                      (c) Losses resulting from fluctuations in cryptocurrency
                      prices or market volatility on Third-Party Exchanges; or
                    </li>
                    <li className={classes.ulFlexBox}>
                      (d) Non-performance, insolvency, or regulatory actions
                      affecting Third-Party Exchanges.
                    </li>
                  </ul>
                </li>
                <li className={classes.ulFlexBox}>
                  14.2.2 Costumers agree to indemnify and hold TradeBP, its
                  directors, officers, employees, staff, and service providers
                  harmless from any claims, losses, liabilities, or damages
                  arising from their use of the Platform and interactions with
                  Third-Party Exchanges
                </li>
              </ul>
            </Typography>
          </Box>

          {/* Section 6 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 6
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            LIABILITY AND DISPUTE RESOLUTION
          </Typography>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            15. LIABILITY
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              15.1 TradeBP’s aggregate liability to you or any third parties as
              a result of any breach of these Terms or otherwise in connection
              with the use of the Services or the Products is limited to the
              actual amount of loss or damage caused directly by our breach of
              these Terms and shall in no event exceed the amount of fees paid
              by you to TradeBP in the 3-month period immediately prior to the
              event giving rise to the relevant loss. Such sum shall be paid by
              us to you in full and final settlement and satisfaction of ours
              entire liability for any and all losses and claims, howsoever
              arising, from the relevant breach.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.2 In addition to the liability cap above, in no event shall we,
              our affiliates or service providers, or any of our or their
              respective officers, directors, agents, employees or
              representatives, be liable for any of the following types of loss
              or damage arising under or in connection with this Terms or
              otherwise
              <ul>
                <li className={classes.ulFlexBox}>
                  15.2.1 any loss of profits or loss of expected revenue or
                  gains, including any loss of anticipated trading profits and /
                  or any actual or hypothetical trading losses, whether direct
                  or indirect, even if we are advised of or knew or should have
                  known of the possibility of the same;
                </li>
                <li className={classes.ulFlexBox}>
                  15.2.2 any loss of, or damage to, reputation or goodwill; any
                  loss of business or opportunity, customers, or contracts; any
                  loss or waste of overheads, management or other staff time; or
                  any other loss of revenue or actual or anticipated savings,
                  whether direct or indirect, even if we are advised of or knew
                  or should have known of the possibility of the same;
                </li>
                <li className={classes.ulFlexBox}>
                  15.2.3 any loss of use of hardware, software, or data and / or
                  any corruption of data; including but not limited to any
                  losses or damages arising out of or relating to any
                  inaccuracy, defect or omission of digital currency price data;
                  any error or delay in the transmission of such data; and / or
                  any interruption in any such data; and
                </li>
                <li className={classes.ulFlexBox}>
                  15.2.4 any loss or damage whatsoever which does not arise
                  directly as a result of our breach of this Terms (whether or
                  not you are able to prove such loss or damage).
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.3 We will not be liable for any losses for which you have not
              submitted a claim to us within one calendar year as from the date
              on which the facts giving rise to the claim have occurred or for
              which proceedings have not been started within six months as from
              the date of the claim.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.4 The limitation of liability is subject to any obligations
              that we have under applicable law and regulation, including our
              obligation to exercise reasonable care and skill in our provision
              of the Services. However, nothing in these terms shall limit or
              undermine the Customer’s own duties of diligence and care. e not
              been started within six months as from the date of the claim.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.5 Without prejudice for any liability limitations foreseen in
              these Terms or in any Product Terms, you irrevocably agree, to the
              full extent permitted by law, that you will have no recourse
              against our shareholders, investors, directors, officers,
              employees, staff, service providers, or advisers (“TradeBP
              Persons”), in connection with any loss you may suffer as a result
              of our breach of these Terms or otherwise. You will only be
              entitled to make a claim against TradeBP and not against any
              TradeBP Person, directly or indirectly, at any legal title. You
              acknowledge and agree that this limitation is of the essence for
              TradeBP and one without which we would not accept to engage in any
              commercial relationship with you. This provision is negotiated and
              agreed also for the benefit of every and all the TradeBP Persons,
              and any TradeBP Person will be entitled to enforce and uphold this
              provision against you at any time.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.6 Our Services and Products, as well as the Website and the
              Platform, are made available on an "as is" and "as available"
              basis, with no further promises, representations or warranties
              made by us, notably in terms of title, merchantability, fitness
              for a particular purpose and/or non-infringement. In particular,
              we do not represent or warrant, in any way:
              <ul>
                <li className={classes.ulFlexBox}>
                  15.6.1 That access to our Services and Products, as well as
                  the Website and the Platform, will be continuous,
                  uninterrupted, timely, or error-free;
                </li>
                <li className={classes.ulFlexBox}>
                  15.6.2 About the accuracy, order, timeliness or completeness
                  of historical digital assets price data available on the
                  Website;
                </li>
                <li className={classes.ulFlexBox}>
                  15.6.3 That our Services and Products, or any portion thereof,
                  will function on any particular hardware or devices; and
                </li>
                <li className={classes.ulFlexBox}>
                  15.6.4 Regarding the amount of time needed to complete or
                  processing any order, which is dependent upon many factors
                  outside of our control.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.7 Links to third-party websites (including, without limitation,
              content, materials, and/or information in the third-party
              websites) may be provided as a convenience but they are not
              controlled by us. You acknowledge and agree that we are not
              responsible for any aspect of the content, materials, information
              or services contained in any third-party websites accessible or
              linked from the Platform or the Website
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.8 We are not liable for any breach of the Terms or any Product
              Terms, including delays, failure in performance or interruption of
              service, where they arise directly or indirectly from facts or
              circumstances related to third party providers that may be used or
              intervene for the provision of the Services, notably banks and
              other financial institutions, payment processors and payment
              service providers. In particular, TradeBP will not be liable for
              any delays in processing any payments or any order that arise from
              delays or refusals caused by procedures, internal rules or
              compliance tasks undertaken by payment service providers or any
              financial institutions (e.g. in the event any payment service
              provider, payment processor, bank, etc., refuses to process a
              certain payment on any grounds, whether or not such refusal is
              temporally or can be resolved).
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              15.8 We are not liable for any breach of the Terms or any Product
              Terms, including delays, failure in performance or interruption of
              service, where they arise directly or indirectly from facts or
              circumstances related to third party providers that may be used or
              intervene for the provision of the Services, notably banks and
              other financial institutions, payment processors and payment
              service providers. In particular, TradeBP will not be liable for
              any delays in processing any payments or any order that arise from
              delays or refusals caused by procedures, internal rules or
              compliance tasks undertaken by payment service providers or any
              financial institutions (e.g. in the event any payment service
              provider, payment processor, bank, etc., refuses to process a
              certain payment on any grounds, whether or not such refusal is
              temporally or can be resolved).
            </Typography>
          </Box>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            16. CONTACTS, COMPLAINTS AND DISPUTE RESOLUTION
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              16.1 In the event you feel you need to get in touch with TradeBP,
              for any matter, we encourage to do it via email at
              info@tradebp.com. When you contact us please provide us with your
              name, email address, and any other information that we may need to
              identify you, your TradeBP Account, and any additional details as
              you see fit. Although we will endeavor all commercial reasonably
              efforts to swiftly resolve your query, we cannot guarantee to
              process every matter within any given timeframe
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              16.2 In the event of a dispute with TradeBP, you agree to initiate
              contact with our support team as the primary means to attempt
              resolution. Should the dispute remain unresolved through our
              support team's efforts, both you and TradeBP commit to follow the
              complaint resolution process outlined in this clause 16.2. You are
              required to utilize this following process before pursuing any
              legal action
              <ul>
                <li className={classes.ulFlexBox}>
                  16.2.1 Filing a Complaint: If your dispute persists after your
                  initial contact with TradeBP Support, please provide a
                  detailed description of your complaint, the preferred
                  resolution method, and any pertinent information you believe
                  is relevant.
                </li>
                <li className={classes.ulFlexBox}>
                  16.2.2 Complaint Review: A designated customer complaints
                  officer ("Officer") will assess your complaint. The Officer
                  will evaluate your complaint based on the information you have
                  provided, and any information held by TradeBP. Within 15
                  business days from the date of receiving your complaint, the
                  Officer will address all issues raised in your complaint by
                  sending you an email (<b>"Resolution Notice"</b>). This
                  Resolution Notice will:
                </li>
                <li className={classes.ulFlexBox}>
                  <ul>
                    <li className={classes.ulFlexBox}>
                      (a) Offer to resolve your complaint according to your
                      requested resolution method; or
                    </li>
                    <li className={classes.ulFlexBox}>
                      (b) Make a determination rejecting your complaint,
                      accompanied by the reasons for the rejection; or
                    </li>
                    <li className={classes.ulFlexBox}>
                      (c) Present an alternative solution to resolve your
                      complaint.
                    </li>
                  </ul>
                </li>
                <li className={classes.ulFlexBox}>
                  In certain cases, if the Officer is unable to respond to your
                  complaint within 15 business days (unless restricted by
                  applicable law), they will send you a holding reply,
                  explaining the reasons for the delay in responding to your
                  complaint and specifying the deadline by which they will
                  provide a response.
                </li>
                <li className={classes.ulFlexBox}>
                  16.2.3 Binding Resolution: Please note that any offer of
                  resolution presented to you will only become binding upon
                  TradeBP if you accept it. It's important to clarify that an
                  offer of resolution does not constitute an admission of
                  wrongdoing or liability by TradeBP concerning the subject
                  matter of the complaint.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              16.3 If we have not been able to resolve your complaint via the
              complaint process set out above, you may escalate your complaint
              via the dispute processes below as applicable to you.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              16.4 Both you and TradeBP agree that we shall not commence any of
              the dispute processes below in relation to the whole or part of
              your complaint until the complaint process set out above has been
              completed, although nothing shall be construed as preventing
              either party from seeking conservatory or similar interim relief
              in any court of competent jurisdiction.
            </Typography>
            <Typography className={classes.parasCenterAlign}>
              16.5 For complaints or disputes arising out of or in connection
              with this Terms or the provision of the Services, or the use of
              the Products, the Website and the Platform, that cannot be
              resolved via the complaint process set out above, you submit to
              the non-exclusive jurisdiction of the courts of Lisbon, Portugal,
              without prejudice to any mandatory legal provisions applicable to
              you.
            </Typography>
          </Box>

          {/* Section 7 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 7
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            GENERAL PROVISIONS
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>17. COMPLIANCE WITH LAW</b>: When using the Services, assessing
            the Website or using the Platform, you must comply with all
            applicable laws, regulations, licensing requirements and third-party
            rights (including, without limitation, data privacy laws and AML/CFT
            laws).
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>18. NOTICES AND COMMUNICATIONS</b>: We may give notice to you by
            email to your email account as listed, from time to time, in TradeBP
            Account. It is your responsibility to ensure that the email account
            is up to date and accurate and notices will be deemed to be given,
            and are deemed to be received, if and when sent to your email
            account.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>19. NO PARTNERSHIP</b>: Nothing in this Terms, in the Product
            Terms or elsewhere on the Website or the Platform shall be read as
            creating or deemed to create any partnership, joint venture or other
            form of commercial or fiduciary collaboration, incorporated or
            unincorporated, between you and TradeBP. The Services and the
            Products, as well as the Website and the Platform, are made
            available for your independent use, subject to these Terms and any
            Product Terms, and its use shall not, in any circumstances, be
            deemed to create any kind of partnership or commercial relationship
            other than as specifically set out in these Terms and the Product
            Terms. Each party will retain, at all times, its independence and
            will not be deemed under the supervision or subordinated to each
            other. TradeBP will not be deemed to have any fiduciary duties or
            obligations to you. None of the parties hereto is authorized to
            present itself, in any way, as partner or associate of one another
            or make any statements or take any actions that are suitable of
            creating the impression of an association between TradeBP and you.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>20. TAXES</b>: It is your sole responsibility to determine
            whether, and to what extent, any taxes apply to any transactions you
            conduct through the Platform or by using the Services, and to
            withhold, collect, report and remit the correct amount of tax to the
            appropriate tax authorities. You acknowledge that we may make
            certain reports to tax authorities regarding your operation of your
            TradeBP Account and the use of the Services.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>21. ENTIRE AGREEMENT</b>: These Terms and the Product Terms
            (including any documents to which these Terms and the Product Terms
            may refer to) comprise the entire agreement between you and TradeBP
            as to the subject matter hereof, and it supersedes any and all prior
            discussions, agreements and understandings of any kind (including
            without limitation any prior versions of this Terms and the Product
            Terms) between us
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>22. TRANSFER AND ASSIGNMENT</b>: These Terms and the Product
            Terms are personal to you, and you cannot transfer or assign your
            rights, licenses, interests and/or obligations to anyone else. We
            may transfer or assign our rights, licenses, interests and / or our
            obligations at any time, including as part of a merger, acquisition
            or other corporate reorganization involving TradeBP. Subject to the
            foregoing, these Terms will bind and inure to the benefit of the
            parties, their successors and permitted assigns.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>23. INVALIDITY</b>: If any provision in these Terms or in the
            Product Terms is determined to be invalid or unenforceable under any
            applicable law, this will not affect the validity of any other
            provision. If any provision is found unenforceable, the
            unenforceable provision will be severed, and the remaining
            provisions will be enforced.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>24. NO WAIVER</b>: Failure or delay in exercising any of our
            rights under these Terms or the Product Terms shall not be deemed,
            in any event, a waiver to any of those rights. A waiver of any
            specific right will only occur if made in righting and signed by our
            authorized representatives and will be valid only to the specific
            circumstances to which it applies and is intended for and cannot be
            construed as to apply to any other facts or circumstances of a
            similar or different nature
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>25. LANGUAGE</b>: These Terms and the Product Terms and any
            information or notifications that you or we are to provide should be
            in English language. Any translation of these Terms and the Product
            Terms or other document is provided for your convenience only and
            may not accurately represent the information in the original
            English. In the event of any inconsistency, the English language
            version shall prevail.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>26. CHANGE OF CONTROL</b>: In the event that TradeBP is acquired
            by or merged with a third-party entity, we reserve the right, in any
            of these circumstances, to transfer or assign the information we
            have collected from you and our relationship with you (including
            these Terms and the Product Terms) as part of such merger,
            acquisition, sale, or other change of control of TradeBP or its
            business, or any part thereof.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>27. SURVIVAL</b>: All clauses and sub-clauses of these Terms or
            in the Product Terms, which by their nature extend beyond the
            expiration or termination of these Terms or the Product Terms, will
            continue to be binding and operate after the termination or
            expiration of these Terms or the Product Terms.
          </Typography>
          <Typography className={classes.parasCenterAlign}>
            <b>28. GOVERNING LAW AND JURISDICTION</b>: These Terms and the
            Product Terms shall be governed and construed in accordance with the
            laws of Portugal and any disputes arising under or in connection
            with these Terms and the Product Terms shall be finally settles in
            the relevant Portuguese Courts.
          </Typography>

          {/* Section 8 */}
          <Typography variant="h6" align="center" color="primary">
            SECTION 8
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            DEFINITIONS AND INTERPRETATION
          </Typography>
          <Typography className={classes.parasCenterAlign} fontWeight={"bold"}>
            29. DEFINITIONS
          </Typography>
          <Box className={classes.boxPadding20}>
            <Typography className={classes.parasCenterAlign}>
              29.1 When used in this Terms or in any Product Terms, following
              capitalized terms shall have the meanings set out below
            </Typography>

            <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
              <Table aria-label="data table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={"bold"}>AML/CFT</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        means all laws and regulations regarding anti-money
                        laundering and countering the financing of terrorism as
                        may be in force at any time in Portugal or any other
                        jurisdiction where TradeBP is allowed to operate;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      Customer, User or You
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it Section 1 of these Terms;
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      Distinctive Signs
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      the meaning ascribed to it clause 13.4 of these Terms;uyt
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Fee Letter</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means the document available at the Website or
                      communicated to you in any other way, detailing the fees
                      applicable to the use of Services and/or the Products
                      provided on the Platform or otherwise by TradeBP, as
                      amended or supplemented from time to time; for corporate
                      clients, the Fee Letter may be communicated by means of a
                      specific commercial offer or incorporated in a specific
                      agreement entered into with these clients
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Officer</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it clause 16.2.2 of these
                      Terms
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Platform</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means the digital platform that TradeBP make accessible to
                      you via the Website for the use of the Services and the
                      Products;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Privacy Policy</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means the privacy policy applying to the Website, the
                      Platform, the Services and the Products, which is
                      available at the Website
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Product Terms</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means the specific terms and conditions that apply to the
                      use of a certain Product available at or through the
                      Website and/or the Platform;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Products</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means all products made available by TradeBP at the
                      Website or through the Platform, from time to time,
                      including Best Execution, Spot Trading – OTC, CryptoHub,
                      Moneyhub, Custody and CyberReady;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      Resolution Notice
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it clause 16.2.2 of these
                      Terms;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Services</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it in clause 2.2 of these
                      Terms;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Terms</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means these terms of use, together with any other
                      documents expressly incorporated by reference, including
                      the Product Terms, in each case as amended or supplemented
                      from time to time;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      Third-Party Exchanges
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it clause 14.1.2 of these
                      Terms
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>TradeBP Account</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means any account opened by TradeBP for you at the Website
                      for the purpose of accessing and operating the Platform,
                      using the Services and the Products;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>TradeBP Persons</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it clause 15.5 of these Terms;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      TradeBP, we or us
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it Section 1 of these Terms;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      User-Generated Content
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      has the meaning ascribed to it clause 13.5 of these Terms;
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Website</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      means the website located at{" "}
                      <Link href="https://www.tradebp.com">
                        www.tradebp.com
                      </Link>
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
            <Typography className={classes.parasCenterAlign}>
              29.2 These Terms shall be interpreted in accordance with the
              following provisions:
              <ul>
                <li className={classes.ulFlexBox}>
                  29.2.1 Clause headings and numbering are for convenience only
                  and do not affect the meaning, priority or interpretation of
                  any clause or sub-clause of these Terms;
                </li>
                <li className={classes.ulFlexBox}>
                  29.2.2 References to clauses and sections are to the clauses
                  and sections of these Terms;
                </li>
                <li className={classes.ulFlexBox}>
                  29.2.3 Unless the context otherwise requires, words in the
                  singular shall include the plural and the plural shall include
                  the singular, and a reference to one gender shall include a
                  reference to the other genders;
                </li>
                <li className={classes.ulFlexBox}>
                  29.2.4 A person includes a natural person, a legal entity or
                  unincorporated body (whether having separate legal personality
                  or not) and that person's successors;
                </li>
                <li className={classes.ulFlexBox}>
                  29.2.5 The terms “include” or “including” shall mean including
                  without limitation and include without limitation
                  respectively;{" "}
                </li>
                <li className={classes.ulFlexBox}>
                  29.2.6 Any reference to a document is to that document as
                  amended, varied, or novated from time to time otherwise than
                  in breach of these Terms or that document; and
                </li>
                <li className={classes.ulFlexBox}>
                  29.2.7 In the event of inconsistency between these Terms
                  (including any documents referred to in these Terms) the
                  inconsistency shall be solved by giving such provisions and
                  documents the following order of precedence:
                </li>
                <li className={classes.ulFlexBox}>
                  <ul>
                    <li className={classes.ulFlexBox}>(a) Product Terms</li>
                    <li className={classes.ulFlexBox}>
                      (b) Privacy Notice; and
                    </li>
                    <li className={classes.ulFlexBox}>(c) Terms</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermsAndCondition;
