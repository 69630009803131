import {createAPIURL} from "app/config";

export const KEYS = {
    USER_MENUS: ["user-menus"],
    USER_LIST: ["user-list"],
    COUNTRY_LIST: ['country-list'],
    ASSETS_LIST: ['assets-list'],
    BEST_PRICE: ['best-price'],
    CURRENCY_EXCHANGE_RATES: ['currency-exchange-rates'],
    CURRENCY_LIST: ['currency-list'],


}

export const URLS = {

    VERIFICATION: {
        GET_DETAILS: createAPIURL("verification/details"),
        SEND_OTP: createAPIURL("verification/send-otp"),
        VERIFY_OTP: createAPIURL("verification/verify-otp"),
        SETUP_GOOGLE_AUTH: createAPIURL("verification/setup-google-2fa"),
        VERIFY_GOOGLE_AUTH: createAPIURL("verification/verify-google-2fa"),
        RESET_GOOGLE_AUTH: createAPIURL("verification/reset-google-2fa"),
        KYC_INITIATE: createAPIURL("verification/kyc-initiate"),
        KYC_RESULT: createAPIURL("verification/kyc-result"),
    },

    CRYPTO: {
        GET_CURRENCY_EXCHANGE_RATES_FROM_CRYPTO: "https://price-api.crypto.com/price/v1/currency/all",
        GET_TOKEN_PRICES_FROM_CRYPTO: "https://price-api.crypto.com/price/v1/token-price/bitcoin",
        GET_ASSETS_FROM_COINCAP: "https://api.coincap.io/v2/assets",
        CALCULATE_FX_FROM_BITFINEX: "https://api-pub.bitfinex.com/v2/calc/fx",
        GET_BEST_PRICE_FROM_SERVICE: "", // This URL needs to be provided
        GET_CURRENCY_LIST: createAPIURL("currency"),
    },

    AUTH: {
        LOGIN_USER: createAPIURL("auth/access"),
        REFRESH_TOKEN: createAPIURL("auth/refresh"),
        LOGOUT_USER: createAPIURL("auth/logout"),

    },
    ACCOUNT: {
        REGISTER_USER: createAPIURL("user-account/register"),
        GET_DETAILS: createAPIURL("user-account/details"),
        CREATE_PASSWORD: createAPIURL("user-account/password/create"),
        CHANGE_PASSWORD: createAPIURL("user-account/password/change"),
        MENUS: createAPIURL("user-account/menus"),
    },
    INPUT_DATA: {
        GET_COUNTRY_LIST: createAPIURL("country"),
    }
}
