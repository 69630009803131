import React from "react";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import {makeStyles} from "tss-react/mui";
import {Box, Card, Typography} from "@mui/material";

// Dummy data - replace this with your actual data
const data = [
  { name: "Jan", rate: "1.01", type: "+", BTC: 30000 },
  { name: "Feb", rate: "1.01", type: "-", BTC: 29000 },
  { name: "Mar", rate: "1.01", type: "-", BTC: 28000 },
  { name: "Apr", rate: "1.01", type: "+", BTC: 60000 },
  { name: "May", rate: "1.01", type: "-", BTC: 55000 },
  { name: "Jun", rate: "1.01", type: "-", BTC: 28000 },
  { name: "Jul", rate: "1.01", type: "+", BTC: 45000 },
  { name: "Aug", rate: "1.01", type: "-", BTC: 28000 },
  { name: "Sep", rate: "1.01", type: "+", BTC: 56000 },
  { name: "Oct", rate: "1.01", type: "-", BTC: 28000 },
  { name: "Nov", rate: "1.01", type: "-", BTC: 25000 },
  { name: "Dec", rate: "1.01", type: "+", BTC: 65000 },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  const { classes } = useStyles();
  if (active && payload && payload.length) {
    // console.log(payload);
    return (
      <Card className={classes.cardTooltip}>
        <Box className={classes.tooltipBox}>
          <Box component="img" src="assets/icons/eth.svg" />
          <Typography variant="body1" sx={{ paddingLeft: "10px" }}>
            ETH
          </Typography>
          <Box
            className={
              payload[0].payload.type === "+"
                ? classes.plusRating
                : classes.minusRating
            }
          >
            <Typography
              variant="subtitle1"
              className={
                payload[0].payload.type === "+"
                  ? classes.ratingAmtP
                  : classes.ratingAmtN
              }
            >
              {payload[0].payload.type}
              {payload[0].payload.rate}
            </Typography>
            <Box
              component="img"
              className={classes.grphnIcons}
              src={
                payload[0].payload.type === "+"
                  ? "assets/icons/arrow-up-right.svg"
                  : "assets/icons/arrow-down-right.svg"
              }
            />
          </Box>
        </Box>
        <Typography variant="body1" sx={{ paddingTop: "10px" }}>
          $ {payload[0].value.toLocaleString()}
        </Typography>
      </Card>
    );
  }

  return null;
};

const GraphComponent = ({ dynamicData = data }: any) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={dynamicData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="BTC"
          stroke="#82ca9d"
          activeDot={{ r: 8 }}
        />
        {/* You can add more <Line /> elements if you have more data series to display */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GraphComponent;

const useStyles = makeStyles()((theme) => {
  return {
    tooltipBox: {
      display: "flex",
      flexDirection: "row",
    },
    cardTooltip: {
      padding: "10px",
      borderRadius: "8px",
      background: "rgba(255, 255, 255, 0.80)",
      boxShadow: "0px 0px 12px 0px rgba(42, 23, 76, 0.10)",
      backdropFilter: "blur(6px)",
    },
    grphnIcons: {
      height: "15px",
      width: "15px",
      padding: "2px",
    },
    ratingAmtP: {
      color: "#00E069",
      fontWeight: 600,
    },
    ratingAmtN: {
      color: "#E13A05",
      fontWeight: 600,
    },
    plusRating: {
      borderRadius: "4px",
      backgroundColor: "#E5FFF1",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
      marginLeft: "10px",
    },
    minusRating: {
      borderRadius: "4px",
      backgroundColor: "#FFF0EB",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
      marginLeft: "10px",
    },
  };
});
