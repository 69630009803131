// MessageSnackbar.tsx

import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, {AlertColor} from "@mui/material/Alert";
import {Typography} from "@mui/material";

interface MessageSnackbarProps {
  showBar: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const MessageAlert: React.FC<MessageSnackbarProps> = ({
  showBar,
  message,
  severity,
  onClose,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={showBar}
      autoHideDuration={4000}
      onClose={onClose}
    >
      <Alert
        onClose={onClose} 
        severity={severity}
        sx={{ width: "100%"}}
      >
        <Typography variant="body2">
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default MessageAlert;
