import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../Style";
import { animate, motion, Variants } from "framer-motion";

const Verification = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const cardVariants: Variants = {
    offscreen: {
      opacity: 0,
      scale: 0.5,
    },
    onscreen: {
      opacity: 1,
      scale: 1,
      // y: 50,
      rotate: [-10, 0] ,
      transition: {
        // type: "spring",
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
        repeatDelay: 1.5,
      },

      // animate: {
      // },
    },
  };

  return (
    <>
      <Box className={classes.verificationBox}>
        <Typography color="primary" variant="h1" align="center">
          Verification
        </Typography>
        <Grid container className={classes.securityGrid}>
          <Grid item lg={6} className={classes.securityLeftGrid}>
            <Typography
              color="primary"
              variant="h5"
              sx={{ fontWeight: 600, padding: "20px 0" }}
            >
              Your Personal Account{" "}
            </Typography>
            <Typography color="primary" variant="body2">
              To get started, simply create an account on our website and follow
              the instructions provided. We will require a complete registration
              process, including KYB and AML checks.
            </Typography>
            <Button
              sx={{ marginTop: "50px" }}
              className={classes.btn1}
              onClick={() => navigate("/signup")}
            >
              Get Started
            </Button>
          </Grid>
          <Grid item lg={4}>
            {/* <motion.div
              // initial={{ opacity: 0, scale: 0.5 }}
              // animate={{ opacity: 1, scale: 1 }}
              // transition={{
              //   duration: 2,
              //   repeat: Infinity,
              //   repeatType: "reverse",
              //   repeatDelay: 1.5,
              // }}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={cardVariants}
            > */}
              <Box
                component="img"
                className={classes.securityImgs}
                src="/assets/images/verify_img.png"
                alt="Descriptive Alt Text"
              />
            {/* </motion.div> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Verification;
