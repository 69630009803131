import React from "react";
import Box from "@mui/material/Box";

import TransactionsTable from "app/components/unsorted/TransactionTable";

export const TradingHistory: React.FC = () => {
  return (
    <Box>
      <TransactionsTable />
    </Box>
  );
};
