import {RQ} from "app/hooks"
import {ApiConfig} from "app/config";


export const MONEYHUB_APIS = {
    CRUD: ApiConfig.create("integration-credentials/moneyhub"),
    VERIFY: ApiConfig.create("integration-credentials/moneyhub/verify"),
}


export type T_IntegrationCredential = {
    id: string;
    user_id: number;
    api_key: string;
    secret_key: string;
    description: string;
    expiration_date: Date;
    is_active: boolean;
    usage_limit: number;
    last_used_at: Date;
    ip_restrictions: string[];
    permissions: string[];
}
export type T_IntegrationCredentialList = T_IntegrationCredential[];


export const useMoneyHubCredentialQuery = (): RQ.T_QueryResult<T_IntegrationCredentialList> => {
    return RQ.useQueryRequest<T_IntegrationCredentialList>({
        url: MONEYHUB_APIS.CRUD.url,
        queryKey: MONEYHUB_APIS.CRUD.key,
    });
}

export const useCreateMoneyHubCredentialRequest = (): RQ.T_MutationResult<T_IntegrationCredentialList> => {
    return RQ.usePostRequest<T_IntegrationCredentialList>({
        url: MONEYHUB_APIS.CRUD.url, mutationKey: MONEYHUB_APIS.CRUD.key, invalidate: true,
    });
}

export const useUpdateMoneyHubCredentialRequest = (): RQ.T_MutationResult<T_IntegrationCredentialList> => {
    return RQ.usePutRequest<T_IntegrationCredentialList>({
        url: MONEYHUB_APIS.CRUD.url, mutationKey: MONEYHUB_APIS.CRUD.key, invalidate: true,
    });
}
