import { ApiConfig } from "app/config";
import { RQ } from "app/hooks";

const WHITELISTED_ADDRESS_APIS = {
    GET_WHITELISTED_ADDRESS: ApiConfig.create(
        "user/whitelisted-wallet-address"
    ),
    VERIFY_WHITELISTED_ADDRESS: ApiConfig.create(
        "user/whitelisted-wallet-address/verify"
    ),
};

const useWhitelistedAddressQuery = () : RQ.T_QueryResult<any> => {
    return RQ.useQueryRequest<any>({
        url: WHITELISTED_ADDRESS_APIS.GET_WHITELISTED_ADDRESS.url,
        queryKey: WHITELISTED_ADDRESS_APIS.GET_WHITELISTED_ADDRESS.key,
    });
};

export const useVerifyWhitelistedAddress = (): RQ.T_MutationResult<any> => {
    return RQ.usePostRequest<any>({
        url: WHITELISTED_ADDRESS_APIS.VERIFY_WHITELISTED_ADDRESS.url,
    });
}


export {
    useWhitelistedAddressQuery,
};
