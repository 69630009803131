import { makeStyles, pallete, styles } from "app/styles";

export const useFinanceReportStyles = makeStyles()((theme) => {
  return {
    btntabTwo: {
      ...styles.homeButton,
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      margin: "20px 0",
    },
    copyBoxParent: {
      ...styles.flexDRS,
      padding: "5px 0",
    },
    copyBox: {
      display: "flex",
      flexDirection: "row",
    },
    credCard: {
      width: "100%",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      padding: "20px 10px",
    },
    gridItems: {
      padding: "20px",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    tooltipBox: {
      display: "flex",
      flexDirection: "row",
    },
    cardTooltip: {
      padding: "10px",
      borderRadius: "8px",
      background: "rgba(255, 255, 255, 0.80)",
      boxShadow: "0px 0px 12px 0px rgba(42, 23, 76, 0.10)",
      backdropFilter: "blur(6px)",
    },
    grphnIcons: {
      height: "15px",
      width: "15px",
      padding: "2px",
    },
    ratingAmtP: {
      color: "#00E069",
      fontWeight: 600,
    },
    ratingAmtN: {
      color: "#E13A05",
      fontWeight: 600,
    },
    plusRating: {
      borderRadius: "4px",
      backgroundColor: "#E5FFF1",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
      marginLeft: "10px",
    },
    minusRating: {
      borderRadius: "4px",
      backgroundColor: "#FFF0EB",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
      marginLeft: "10px",
    },
    startIconBox: {
      height: "24px",
      width: "24px",
    },
    iconsBtn: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      // lineHeight: "140%",
      padding: "10px 20px",
      borderRadius: "8px",
      textTransform: "none",
    },
    startIconBoxEth: {
      borderRadius: "50%",
      // padding: "5px",
      height: "24px",
      width: "24px",
    },
    commonCardsHBox: {
      ...styles.flexDRS,
      padding: "10px",
    },
    commonCards: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
    },
    tabsText: {
      fontSize: "16px",
      fontWeight: 600,
      color: pallete.selections.blue,
      textTransform: "none",
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "100px 5px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
  };
});
