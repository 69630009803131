import {Box, Typography} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {pallete} from "app/styles";
import {useNavigate} from "react-router-dom";

const AuthScreenLeftBox = ({
  title,
  bttomText,
  routeName,
  path,
}: {
  title: string;
  bttomText: string;
  routeName: string;
  path: string;
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.headingBox}>
      <Box
        component="img"
        className={classes.iconWeb}
        src="assets/logos/tradeBpLogin.svg"
        alt="Descriptive Alt Text"
        onClick={() => navigate("/")}
      />
      <Typography variant="h1" className={classes.headingTypo}>
        {title}
      </Typography>
      <Box className={classes.bottomText}>
        <Typography variant="h5" color={pallete.primaryWhite}>
          {bttomText}{" "}
        </Typography>
        <Typography
          variant="h5"
          className={classes.routeName}
          onClick={() => navigate(path)}
        >
          <b>{routeName}</b>
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthScreenLeftBox;
const useStyles = makeStyles()((theme) => {
  return {
    routeName: {
      paddingLeft: "10px",
      cursor: "pointer",
      color: pallete.primaryWhite,
      "&:hover": {
        color: pallete.black,
        textDecorationLine: "underline",
        transition: "ease-in",
      },
    },
    headingBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      position: "relative",
    },
    headingTypo: {
      color: pallete.primaryWhite,
    },
    iconWeb: {
      height: "45px",
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer",
    },
    bottomText: {
      display: "flex",
      flexDirection: "row",
      position: "absolute",
      left: 0,
      bottom: "10px",
      right: 0,
    },
  };
});
