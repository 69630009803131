import React from "react";
import {useLoginStyles} from "./LoginStyles";
import Login from "./Login";

const LoginView = (): React.JSX.Element => {
    const {classes} = useLoginStyles();
    return Login(classes)

}


export default LoginView;
