import React from "react";
import { Box, Card, Dialog, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import WalletTransactionTable from "./WalletTransactionTable";

type T_Props = {
    open: boolean;
    onClose: () => void;
    wallet_id: String;
};

export const WalletDetailedDialog = ({ open, onClose, wallet_id }: T_Props) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerData, setDrawerData] = React.useState<{ [key: string]: any }>(
        {}
    );

    const onTransactionClick = (data: any) => {
        setDrawerData(data);
        handleDrawerOpen();
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleClose = () => {
        handleDrawerClose();
        onClose();
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ margin: "20px" }}
            PaperProps={{ sx: { borderRadius: "20px" } }}
        >
            <Box
                padding={"30px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"20px"}
            >
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <IconButton onClick={handleClose}>
                        <Close fontSize="large" />
                    </IconButton>
                </Box>
                <Box display={"flex"} flexDirection={"row"} gap={"10px"}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            height: "100%",
                        }}
                    >
                        <WalletTransactionTable
                            wallet_id={wallet_id}
                            onTransactionClick={onTransactionClick}
                        />
                    </Box>
                    {drawerOpen && (
                        <Box
                            sx={{
                                minWidth: "30%",
                                maxWidth: "30%",
                                height: "100%",
                                padding: "20px",
                            }}
                        >
                            <Card 
                                sx={{ 
                                    borderRadius: "20px",
                                    padding: "20px",
                                    height: "100%",
                                    boxShadow: "0px 0px 24px 0px rgba(0,0,0,0.1)",
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "#F7F6F9",
                                }}
                                variant="outlined"
                            >
                                <Box
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    alignItems={"center"}
                                >   
                                    <Typography variant="h5" color={"#36205F"} fontWeight={"bold"} marginRight={"auto"}>
                                        Transaction Details
                                    </Typography>
                                    <IconButton onClick={handleDrawerClose}>
                                        <Close fontSize="large" />
                                    </IconButton>
                                </Box>
                                <Divider 
                                    sx={{
                                        marginTop: "10px",
                                        marginBottom: "15px",
                                        marginhorizontal: "5px",
                                    }}
                                />
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    gap={"20px"}
                                    flexGrow={1}
                                    sx={{
                                        overflowX: "auto",
                                        "::-webkit-scrollbar": {
                                            height: "5px",
                                        }
                                    }}
                                >
                                    {Object.keys(drawerData).map(
                                        (key: string) => {
                                            return (
                                                <Box
                                                    display={"flex"}
                                                    gap={"5px"}
                                                    flexDirection={"column"}
                                                >
                                                    <Box>
                                                        <Typography variant="h6" fontWeight={"bold"} color={"#36205F"}>
                                                            {key} : 
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body1">
                                                            {drawerData[key] || "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>
                            </Card>
                        </Box>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};
